import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useStore } from '../providers/StoreProvider';
import { useSaveOrder } from './useSaveOrder';
import { useSendMetrics } from './useSendMetrics';
import { useScrollToError } from './useScrollToError';
import { IStep } from '../store/stepStore/stepStore.typings';
import { useEvent } from '@core/hooks/useEvent';
import { useEffect } from 'react';

type NextStepOptions = {
  params?: {
    [key: string]: string;
  };
  scrollTopFlag?: boolean;
  validation?: boolean;
  updateFlag?: boolean;
};

type PrevStepOptions = {
  scrollTopFlag?: boolean;
};

interface IUseChangeStep {
  nextStep: (options?: NextStepOptions) => Promise<boolean>;
  prevStep: (options?: PrevStepOptions) => Promise<boolean>;
  changeStepById: (id: number) => Promise<boolean>;
  nextStepWithCreateOrder: () => Promise<void>;
}

const getIndexOfStep = (pathname: string, steps: IStep[]): number =>
  steps.findIndex((step) => {
    const path = pathname;
    const link = step.link[step.link.length - 1] == '/' ? step.link.slice(0, -1) : step.link;
    const linkLocation = path[path.length - 1] == '/' ? path.slice(0, -1) : path;

    return link === linkLocation;
  });

export const useChangeStep = (): IUseChangeStep => {
  const navigate = useNavigate();
  const location = useLocation();
  const { saveOrder } = useSaveOrder();
  const store = useStore();
  const { scrollToError, scrollToTop } = useScrollToError();
  const { sendCompleteStep } = useSendMetrics();

  const setActualStep = useEvent((pathname: string) => {
    const { steps } = store.step;
    const index = getIndexOfStep(pathname, steps);
    if (index < 0) return;
    const step = steps[index];
    store.step.changeStep(step.id, step.title);
  });

  useEffect(() => {
    setActualStep(location.pathname);
  }, [setActualStep, location.pathname]);

  const changeStepById = useEvent<IUseChangeStep['changeStepById']>(async (id) => {
    if (!store) {
      return false;
    }

    const { steps } = store.step;

    if (!steps || steps.length === 0) {
      return false;
    }

    function goToTheNextStep(nextStepData: IStep | undefined) {
      if (nextStepData) {
        navigate({
          pathname: nextStepData.link,
          search: location.search,
        });
      }
    }

    if (id && steps[id] !== undefined) {
      goToTheNextStep(steps.find((step) => step.id === id));
    }

    return true;
  });

  const nextStep = useEvent<IUseChangeStep['nextStep']>(async (options = {} as NextStepOptions) => {
    const { scrollTopFlag = false, updateFlag = true, params, validation = true } = options || {};
    const { steps } = store.step;

    if (!steps || steps.length === 0) {
      return false;
    }

    const index = getIndexOfStep(location.pathname, steps);

    if (index == -1) {
      return false;
    }

    // За счет validator subscribe проверяются только смонтированные поля
    const res = validation ? await store.form.validateStep() : true;
    if (!res) {
      scrollToError();
      return false;
    }

    let indexDraft;
    if (updateFlag) {
      indexDraft = await saveOrder();
    }

    sendCompleteStep(index);

    if (steps[index + 1] !== undefined) {
      const nextStepData = steps.find((step, indexNext) => indexNext === index + 1);

      if (nextStepData) {
        if (scrollTopFlag) {
          scrollToTop();
        }

        const paramsUrl = createSearchParams(location.search);
        if (params) {
          Object.keys(params).forEach((key) => {
            paramsUrl.set(key, params?.[key]);
          });
        }

        if (indexDraft !== null && indexDraft !== undefined) {
          paramsUrl.set('draft', indexDraft.toString());
        }

        navigate({
          pathname: nextStepData.link,
          search: paramsUrl.toString(),
        });
      }
    }

    return true;
  });

  const prevStep = useEvent<IUseChangeStep['prevStep']>(async (options = {} as PrevStepOptions) => {
    const { scrollTopFlag = false } = options || {};
    const steps = store?.step.steps;
    if (!steps || steps.length === 0) {
      return false;
    }

    const index = getIndexOfStep(location.pathname, steps);
    if (index === 0) {
      return false;
    }

    if (steps[index - 1] !== undefined) {
      const prevStepData = steps.find((step, indexNext) => indexNext === index - 1);
      if (prevStepData) {
        if (scrollTopFlag) {
          scrollToTop();
        }
        navigate({
          pathname: prevStepData.link,
          search: location.search,
        });
      }
    }
    return true;
  });

  const nextStepWithCreateOrder = useEvent(async () => {
    if (!store) {
      return;
    }

    const createFlag = await store.createOrder();
    if (!createFlag) {
      return;
    }

    const nt = await nextStep();
    if (!nt) {
    }
  });

  return { nextStep, nextStepWithCreateOrder, prevStep, changeStepById };
};
