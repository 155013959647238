import { getBrandsModelsList } from '@core/features/controllers/vehicle';
import { VehicleBrand } from '@core/features/vehicle/vehicle.types';

export const getModelsByBrand = (vehicles?: VehicleBrand[], brand?: string) => {
  if (!brand) return [];
  const found = vehicles?.find((i) => i.brand.toLowerCase() === brand.toLowerCase());
  if (!found) return null;

  return found.models.map((i) => i.model);
};

export const getBrandsModels = async (brand?: string) => {
  const { vehicles } = await getBrandsModelsList();
  const brands = vehicles?.map((i) => i.brand) || [];

  const models = getModelsByBrand(vehicles, brand);

  return { brands, models };
};
