import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { differenceInDays } from 'date-fns';
import { getDate } from './helpers';
import { CalendarHeaderStyle, createCalendarHeader } from './createCalendarHeader';
import { createDaysWeek, DaysWeekStyle } from './createDaysWeek';
import s from './createCalendar.module.scss';

export type CalendarProps = {
  value: Date;
  onChange: (date: Date) => void;
  min?: Date;
  max?: Date;
  className?: string;
};

export type CalendarStyle = {
  root: string;
  days: string;
  day: string;
  inactive: string;
  active: string;
  disabled: string;
};

export const createCalendar = <T extends CalendarProps>(
  style: CalendarStyle,
  {
    headerStyle,
    daysWeekStyle,
  }: {
    headerStyle: CalendarHeaderStyle;
    daysWeekStyle: DaysWeekStyle;
  }
): React.FC<T> => {
  const CalendarHeader = createCalendarHeader(headerStyle);
  CalendarHeader.displayName = 'CalendarHeader';

  const DaysWeek = createDaysWeek(daysWeekStyle);
  DaysWeek.displayName = 'DaysWeek';

  const cls = {
    root: cn(s.root, style.root),
    days: cn(s.days, style.days),
    day: cn(s.day, style.day),
    disabled: cn(s.disabled, style.disabled),
    active: cn(s.active, style.active),
    inactive: cn(s.inactive, style.inactive),
  };

  return ({ value, onChange, min, max, className }) => {
    const [showDate, setShowDate] = useState(value);

    useEffect(() => setShowDate(value), [value]);

    const daysList = () => {
      const year = showDate.getFullYear();
      const month = showDate.getMonth();

      const startDay = -new Date(year, month, 1).getDay() + 2;
      const start = new Date(year, month, startDay > 1 ? startDay - 7 : startDay);
      const lastDay = new Date(year, month + 1, 0).getDay();
      const end = new Date(year, month + 1, lastDay ? 7 - lastDay : 0);

      const startDate = start.getDate();
      const startMonth = start.getMonth();
      const startYear = start.getFullYear();

      const dateValue = getDate(value.getFullYear(), value.getMonth(), value.getDate());
      const dateMin = min && getDate(min.getFullYear(), min.getMonth(), min.getDate());
      const dateMax = max && getDate(max.getFullYear(), max.getMonth(), max.getDate());

      const calendar = [];
      for (let i = 0; i <= differenceInDays(end, start); i++) {
        const curr = getDate(startYear, startMonth, startDate + i);
        const active = curr.getTime() === dateValue.getTime();

        const disabled = (() => {
          if (dateMin && curr.getTime() < dateMin.getTime()) return true;
          if (dateMax && curr.getTime() > dateMax.getTime()) return true;
          return false;
        })();

        calendar.push(
          <span
            role="presentation"
            key={i}
            onClick={() => {
              if (disabled) return;
              onChange(curr);
            }}
            className={cn(
              cls.day,
              month !== curr.getMonth() && cls.inactive,
              active && cls.active,
              disabled && cls.disabled
            )}
          >
            {curr.getDate()}
          </span>
        );
      }

      return calendar;
    };

    const nextMonth = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const newValue = new Date(showDate);
      newValue.setDate(1);
      newValue.setMonth(newValue.getMonth() + 1);
      setShowDate(newValue);
    };

    const prevMonth = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const newValue = new Date(showDate);
      newValue.setDate(1);
      newValue.setMonth(newValue.getMonth() - 1);
      setShowDate(newValue);
    };

    return (
      <div className={cn(cls.root, className)}>
        <CalendarHeader date={showDate} onBack={prevMonth} onNext={nextMonth} />

        <DaysWeek />

        <div className={cls.days}>{daysList()}</div>
      </div>
    );
  };
};
