import { getInfoOldPolicy, useStore } from '@core';
import { useEffect } from 'react';
import { validateLicensePlate } from '@utils/validate';
import { useEvent } from '@core/hooks/useEvent';

export const useLicencePlateSideEffect = () => {
  const store = useStore();
  const licensePlate = store.form.fields.licensePlate.value?.replace(/\s/g, '');

  useEffect(() => {
    (async () => {
      if (licensePlate) {
        if (
          !store.form.fields.prevPolicySerial.value &&
          !store.form.fields.prevPolicyNumber.value &&
          !store.form.flagSendOldPolicyData
        ) {
          if (!validateLicensePlate(licensePlate)) return;
          const infoOldPolice = await getInfoOldPolicy(licensePlate);

          store.form.setFlagSendOldPolicyData(true);

          if (infoOldPolice && !infoOldPolice.errors.length && infoOldPolice.results) {
            store.form.fields.hasPrevPolicy.value = 1;

            if (infoOldPolice.results.policy) {
              const _vArray = infoOldPolice.results.policy.split(' ');
              if (_vArray.length == 2) {
                store.form.fields.prevPolicySerial.value = _vArray[0];
                store.form.fields.prevPolicySerial.error = '';

                store.form.fields.prevPolicyNumber.value = _vArray[1];
                store.form.fields.prevPolicyNumber.error = '';

                store.form.fields.prevPolicyUnmaskId.value = infoOldPolice.results.unmaskId;
              }

              if (infoOldPolice.results.skId) {
                store.form.fields.prevPolicyCompany.value = infoOldPolice.results.skId;
                store.form.fields.prevPolicyCompany.error = '';
              }
            }
          }
        }
      }
      store.form.fields.licensePlate.error = '';
    })();
  }, [licensePlate, store.form]);
};

export const useTypeSideEffect = () => {
  const store = useStore();
  const type = store.form.fields.type.value;

  const reset = useEvent(() => {
    store.form.fields.licensePlate.error = '';
  });

  useEffect(reset, [type, reset]);
};

export const useOwnerDriverSideEffect = () => {
  const store = useStore();
  const value = store.form.fields.owner_driverSelect.value;

  useEffect(() => {
    (async () => {
      if (`${value}` !== 'new') {
        const driver = store.form.drivers[value as number];
        if (driver) {
          store.form.fields.owner_lastname.value = driver.lastname.value;
          store.form.fields.owner_firstname.value = driver.firstname.value;
          store.form.fields.owner_middlename.value = driver.middlename.value;
          store.form.fields.owner_birthdate.value = driver.birthdate.value;
        }
      }
    })();
  }, [value, store.form]);
};

export const usePolicyHolderSideEffect = () => {
  const store = useStore();
  const value = store.form.fields.policyholder_driverSelect.value;

  useEffect(() => {
    (async () => {
      if (`${value}` !== 'new') {
        const driver = store.form.drivers[value as number];

        if (driver) {
          store.form.fields.policyholder_lastname.value = driver.lastname.value;
          store.form.fields.policyholder_firstname.value = driver.firstname.value;
          store.form.fields.policyholder_middlename.value = driver.middlename.value;
          store.form.fields.policyholder_birthdate.value = driver.birthdate.value;
        }
      }
    })();
  }, [value, store.form]);
};

export const useFormFieldsSideEffect = () => {
  useLicencePlateSideEffect();
  useOwnerDriverSideEffect();
  usePolicyHolderSideEffect();
  useTypeSideEffect();
};
