import cn from 'classnames';
import React from 'react';
import { daysWeek } from './helpers';
import s from './createDaysWeek.module.scss';

export type DaysWeekStyle = {
  root: string;
  day: string;
};

export type DaysWeekProps = React.HTMLAttributes<HTMLDivElement>;

export const createDaysWeek = (style: DaysWeekStyle): React.FC<DaysWeekProps> => {
  const cls = {
    root: cn(s.root, style.root),
    day: cn(s.day, style.day),
  };

  return ({ className, ...props }) => (
    <div className={cn(cls.root, className)} {...props}>
      {daysWeek.map((day, index) => (
        <span className={cls.day} key={index}>
          {day}
        </span>
      ))}
    </div>
  );
};
