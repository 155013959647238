import { createSelect, SelectProps, SelectStyle } from '@core/common/components/_factories/createSelect/createSelect';
import { SelectIcon } from '../SelectIcon';
import { Options } from '../Options';
import { InputBase } from '../InputBase';
import s from './Select.module.scss';

export type { SelectProps };

export const Select = createSelect({ InputBase, SelectIcon, Options }, s as SelectStyle);

Select.displayName = 'Select';
