import React from 'react';
import cn from 'classnames';

import s from './Button.module.scss';

export type LinkAsButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  styleType?: 'transparent' | 'default';
};

export const LinkAsButton: React.FC<LinkAsButtonProps> = ({ className, styleType, ...props }) => (
  <a className={cn(s.root, styleType === 'transparent' && s.transparent, className)} {...props} />
);
