// todo/arkadii[lib] move to lib
import { PolicyOfferUpsale } from '@core/features/upsale/upsale.types';
import { allUpsalesExtendedData } from '@core/common/constants/upsales';

export const getUpsaleDesc = ({ packet, upsaleName, packetName }: PolicyOfferUpsale) => {
  const data =
    allUpsalesExtendedData[`${upsaleName} ${packet}`] ||
    allUpsalesExtendedData[packetName || ''] ||
    allUpsalesExtendedData[upsaleName] ||
    null;

  if (data?.name) {
    return data;
  }

  if (!data?.name && packetName) {
    return { ...data, name: packetName };
  }

  return null;
};
