import React, { useEffect, useMemo, useState } from 'react';
import { useSearch, observer, getBrandsModelsList } from '@core';
import cn from 'classnames';
import { useSelectHandlers } from '@core/hooks/useInputHandlers';
import type { ISelectOption } from '@core/common/components/_factories/select.types';
import {
  InputBrandModelBaseProps,
  InputBrandModelBaseValue,
} from '@core/common/components/_factories/createInputBrandModelBase';
import s from './createInputBrandModel.module.scss';
import { VehicleBrand } from '@core/features/vehicle/vehicle.types';
import { getModelsByBrand } from '@utils/getBrandsModels';

export type InputBrandModelProps = Pick<
  InputBrandModelBaseProps,
  'disabled' | 'columns' | 'whiteSelects' | 'viewSelects' | 'className' | 'skeleton'
>;

export type InputBrandModelStyle = {
  root: string;
};

const BRAND = 'brand';
const MODEL = 'model';

export const createInputBrandModel = <T extends InputBrandModelProps>(
  {
    InputBrandModelBase,
  }: {
    InputBrandModelBase: React.ComponentType<InputBrandModelBaseProps>;
  },
  style?: InputBrandModelStyle
): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
  };
  return observer(({ className, ...props }) => {
    const { searchFunction, defaultOptionsSearchValue } = useSearch();
    const [vehicles, setVehicles] = useState<VehicleBrand[]>();
    useEffect(() => {
      getBrandsModelsList().then((res) => setVehicles(res.vehicles));
    }, []);

    const brands = useMemo(() => vehicles?.map((i) => i.brand), [vehicles]);

    const [brandOptions, setBrandOptions] = useState<ISelectOption[]>(() => defaultOptionsSearchValue(BRAND));
    const searchBrand = async (val: string) => setBrandOptions(await searchFunction(val, BRAND));
    const brand = useSelectHandlers({
      name: BRAND,
      extra: { brands },
    });

    const models = useMemo(() => getModelsByBrand(vehicles, brand.value), [brand.value, vehicles]);

    const [modelOptions, setModelOptions] = useState<ISelectOption[]>(() => defaultOptionsSearchValue(MODEL));
    const searchModel = async (val: string) => setModelOptions(await searchFunction(val, MODEL));
    const model = useSelectHandlers({
      name: MODEL,
      nameId: 'modelId',
      extra: { models },
    });

    useEffect(() => {
      if (!model.value) {
        setModelOptions(defaultOptionsSearchValue(MODEL));
      }
    }, [brand.value, defaultOptionsSearchValue, model.value]);

    const onChange = (value: InputBrandModelBaseValue) => {
      brand.handleChange(value.brand);
      model.handleChange(value.model);
    };

    const value: InputBrandModelBaseValue = {
      brand: brand.value,
      model: model.value,
    };

    return (
      <InputBrandModelBase
        {...props}
        className={cn(cls.root, className)}
        flagAutoBrand={brand.flagAuto}
        flagAutoModel={model.flagAuto}
        errorBrand={brand.error}
        errorModel={model.error}
        onClearBrand={brand.clearAutoValue}
        onClearModel={model.clearAutoValue}
        onBlurBrand={brand.handleBlur}
        onBlurModel={model.handleBlur}
        onSelectBrand={brand.handleSelect}
        onSelectModel={model.handleSelect}
        onChange={onChange}
        searchBrand={searchBrand}
        searchModel={searchModel}
        brandOptions={brandOptions}
        modelOptions={modelOptions}
        value={value}
      />
    );
  });
};
