import { ValidationFn, HumanNameFeedback, Options, StringFeedback } from '../../common/types';
import { humanNameRegex, humanNameRegexSoft } from '../../common/constants';
import { stringRule } from './string';
import { humanNameFeedback } from '../../common/feedbacks';

export const humanNameRule =
  (options?: Options<HumanNameFeedback & StringFeedback>): ValidationFn<string> =>
  (value, deps, params) => {
    const { required, feedback = {} } =
      options?.getConditionalOptions?.({
        deps,
        params,
        currentDeps: params?.currentDeps || deps,
        defaultOptions: options,
      }) ||
      options ||
      {};
    const _feedback = { ...humanNameFeedback, ...feedback };

    return stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type === 'soft' ? false : required,
      regexp: params?.type === 'soft' ? humanNameRegexSoft : humanNameRegex,
      message: options?.feedback?.INVALID_NUMAN_NAME,
      feedback: _feedback as unknown as StringFeedback,
    })(value, deps, { ...params, type: 'hard' });
  };
