import { useEffect, RefObject } from 'react';

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void,
  on: boolean
) => {
  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      const el = ref?.current;
      if (el && !el.contains(ev.target as Node)) {
        return handler();
      }
    };

    if (on) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler, ref, on]);
};
