import { useEffect } from 'react';
import { useStore } from '@core';
import { useEvent } from '@core/hooks/useEvent';

export const useResetFormByFirstStep = () => {
  const store = useStore();
  const reset = useEvent(() => {
    if (store.step.currentStep === 1) {
      store.form.resetFields();
    }
  });

  useEffect(reset, [store.step.currentStep, reset]);
};
