import React from 'react';

export const transformHumanName = (value: string): string => {
  if (!value) return value;
  const [first, ...other] = value.replace(/^[-\s_]+/, '').split('');
  return `${first.toUpperCase()}${other.join('')}`;
};

export const getValueByEventHumanName = (event: React.ChangeEvent<HTMLInputElement>): string =>
  transformHumanName(event.target.value);
