import { CAPTCHA_KEY } from '../../constants/captcha';

let tokenCaptcha = '';

export const setCaptchaToken = (token: string) => (tokenCaptcha = token);
export const getCaptchaTokenParams = (): { [key: string]: string } =>
  tokenCaptcha === 'localhost' ? { ignore_captcha: '1', token: 'localhost' } : { token: tokenCaptcha };

export const reloadCaptcha = async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as typeof window & { grecaptcha: any };

  if (!win.grecaptcha) {
    return;
  }

  const token = await win.grecaptcha.execute(CAPTCHA_KEY, { action: 'homepage' });
  if (token) {
    if (window.location.href.indexOf('localhost') === -1) {
      setCaptchaToken(token);
      return token;
    }
    setCaptchaToken('localhost');
    return 'localhost';
  }
};
