import {
  companiesWithUpsales,
  getActiveOffersStorage,
  getOffersStorage,
  isTimeDifferenceLess,
  removeActiveOffersStorage,
  useChangeStep,
  useLinkPay,
  useReloadOrder,
  useStore,
} from '@core';
import { useEffect, useState } from 'react';
import { GroupedUpsales } from '@core/features/upsale/upsale.types';
import { getDateString } from '@utils/offers';
import { parseDate } from '@utils/parseDate';
import { useEvent } from '@core/hooks/useEvent';
import { fetchUpSaleList, getGroupedUpSales } from '@core/features/upsale/helpers/helpers';
import { logError } from '@utils/logger';

export const useStepCompanyLink = (params?: { withoutUpsales?: boolean }) => {
  const { withoutUpsales } = params || {};
  const { reloadOrder } = useReloadOrder();
  const { prevStep } = useChangeStep();
  const store = useStore();

  const [groupedUpSales, setGroupedUpSales] = useState<GroupedUpsales | null>(null);
  const [loadingUpSales, setLoadingUpSales] = useState(true);
  const { loading, link, getLinkPay, companyId, activeCompany, error, resetLinkPay } = useLinkPay();

  const offerResult = activeCompany?.offer?.results?.[0];
  const EID = offerResult?.eId?.toString();
  const date = getDateString(parseDate(store.form.getValueField('date').value || null));
  const totalSum = store.calc.totalSum;

  const updateUpsaleList = useEvent(async () => {
    const list = store.calc.activeOffer?.upsaleList || (await fetchUpSaleList(EID || ''));
    if (!list) return;
    // Для синхронных операций, иначе не срабатывает обновление суммы
    await new Promise((resolve) => setTimeout(resolve));
    if (store.calc.activeOffer && !store.calc.activeOffer.upsaleList?.length) {
      store.calc.activeOffer.upsaleList = list;
    }
    setGroupedUpSales(
      getGroupedUpSales(
        list,
        store.calc.upsales.excludeTypes ||
          store.calc.upsales?.[store.calc.activeOffer?.sk as number]?.excludeTypes ||
          []
      )
    );
  });

  useEffect(() => {
    (async () => {
      try {
        // TODO это нужно до тех пор, пока текста апсейлов не станут приходить с сервера
        const enable = companiesWithUpsales.includes(Number(companyId));

        if (offerResult && enable && !withoutUpsales) {
          setLoadingUpSales(true);
          await updateUpsaleList();
        }
      } catch (e) {
        logError(e, 'ERROR IN UPSALE LIST REQUEST');
      } finally {
        setLoadingUpSales(false);
      }
    })();
  }, [EID, offerResult, companyId, updateUpsaleList, withoutUpsales]);

  const setActiveOffer = useEvent(() => offerResult && store.calc.setActiveOffer(offerResult));
  const resetSelectedUpsales = useEvent(() => store.calc.resetSelectedUpsales());

  const values = Object.values(store.calc.selectedUpsales || {}).join('_');

  useEffect(setActiveOffer, [offerResult, setActiveOffer]);
  useEffect(() => resetSelectedUpsales, [resetSelectedUpsales]);
  useEffect(resetLinkPay, [values, resetLinkPay]);

  const onCheckUpsale = (checked: boolean, group: string, id: string) => {
    if (checked) {
      store.calc.selectedUpsales[group] = id;
    } else {
      delete store.calc.selectedUpsales[group];
    }
  };

  const onBack = async () => {
    await reloadOrder();
    await prevStep();
    await removeActiveOffersStorage();
    store.calc.activeOffer = null;
    store.calc.selectedUpsales = {};
  };

  const _getLinkPay = async (selectedUpsalesKeys?: string[]) => {
    const offers = await getOffersStorage();

    if (!offers || !offers.offersData.dateCreate || !isTimeDifferenceLess(offers.offersData.dateCreate.toString())) {
      return onBack();
    }

    const activeOffers = await getActiveOffersStorage();

    if (!activeOffers || !companyId || !activeOffers[companyId]) {
      return onBack();
    }

    const activeOffer = activeOffers[companyId];
    if (activeOffer.offer.results.length == 0) {
      return onBack();
    }

    return await getLinkPay(activeOffer, selectedUpsalesKeys);
  };

  return {
    onBack,
    onCheckUpsale,
    totalSum,
    date,
    companyName: store.calc.activeOffer?.skName,
    groupedUpSales,
    getLinkPay: _getLinkPay,
    loading,
    error,
    link,
    loadingUpSales,
    companyId,
    activeCompany,
    selectedUpsales: store.calc.selectedUpsales,
  };
};
