import React, { FC } from 'react';
import cn from 'classnames';
import s from './HistoryItem.module.scss';
import { HistoryItemProps } from '@core/common/components/_factories/createHistory/createHistoryItem';
import { Button } from '../../../../../common/components/Button';

export const HistoryItem: FC<HistoryItemProps> = ({ className, model, brand, onClick }) => (
  <div className={cn(s.root, className)}>
    <div className={s.line}>
      {brand && (
        <div className={s.box}>
          <div className={s.title}>Марка</div>
          <div className={s.value}>{brand}</div>
        </div>
      )}
      {model && (
        <div className={s.box}>
          <div className={s.title}>Модель</div>
          <div className={s.value}>{model}</div>
        </div>
      )}
    </div>

    <Button onClick={onClick}>Продолжить</Button>
  </div>
);
