import { useCallback } from 'react';

import { useScroll } from './useScroll';

export const useScrollToError = () => {
  const { scroll } = useScroll();
  const scrollToError = useCallback(() => {
    const errors = document.querySelector('[data-error="true"]');
    if (!errors) {
      return false;
    }

    if (errors.parentElement) {
      scroll(errors.parentElement);
    }

    return true;
  }, [scroll]);

  const scrollToTop = useCallback(() => {
    scroll(undefined, true);
    return true;
  }, [scroll]);

  const scrollToElementById = useCallback(
    (id: string | number) => {
      const element = document.querySelector(`#${id}`);
      if (element) {
        scroll(element);
        return true;
      }

      return false;
    },
    [scroll]
  );

  return { scrollToError, scrollToTop, scrollToElementById };
};
