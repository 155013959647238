import { ValidationFn, ValidationResult, DadateFeedback, Options, DadataData } from '../../common/types';
import { NEED_FULL_ADDRESS, NEED_HOUSE_OR_AREA_NUMBER } from '../../common/texts';
import { stringRule } from './string';
import { objRule } from './obj';
import { dadataFeedback } from '../../common/feedbacks';
import { findFirstStringProperty } from '../../features/methods';

export const dadataRule =
  (options?: Options<DadateFeedback>): ValidationFn<{ value: string; data: DadataData }> =>
  (value, deps, params) => {
    const { required, feedback = {} } =
      options?.getConditionalOptions?.({
        deps,
        params,
        currentDeps: params?.currentDeps || deps,
        defaultOptions: options,
      }) ||
      options ||
      {};
    const _feedback = { ...dadataFeedback, ...feedback };
    const prepareValidationResult = findFirstStringProperty as (error: ValidationResult) => string;
    const obj = objRule({
      prepareValidationResult,
      required,
      checkStructure: true,
      feedback: { INVALID_OBJ_FORMAT: _feedback.INVALID_DADATA },
    });
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...(params || {}), deps, defaultIsSkipped })) return null;

    return obj({
      value: stringRule({ required: true, feedback: { REQUIRED: NEED_FULL_ADDRESS } }),
      data: (_value, _deps, _params) => {
        if (!_value) return { message: _feedback.INVALID_DADATA };
        const __value = _value as DadataData;
        if (!__value.kladr_id)
          return prepareValidationResult({
            kladr_id: stringRule({ required: true, feedback: { REQUIRED: NEED_HOUSE_OR_AREA_NUMBER } })(
              __value.kladr_id,
              _deps,
              _params
            ),
          });
        if (!__value.house && !__value.stead)
          return prepareValidationResult({
            house: stringRule({ required: true, feedback: { REQUIRED: NEED_HOUSE_OR_AREA_NUMBER } })(
              __value.house || '',
              _deps,
              _params
            ),
            stead: stringRule({ required: true, feedback: { REQUIRED: NEED_HOUSE_OR_AREA_NUMBER } })(
              __value.stead || '',
              _deps,
              _params
            ),
          });

        return null;
      },
    })(value, deps, params);
  };

export const cityDadataRule =
  (
    options?: Options<DadateFeedback, { keyOfStringValue: string }>
  ): ValidationFn<{ value: string; data: { kladr_id: string; house: string } }> =>
  (value, deps, params) => {
    const {
      required,
      keyOfStringValue = '',
      feedback = {},
    } = options?.getConditionalOptions?.({
      deps,
      params,
      currentDeps: params?.currentDeps || deps,
      defaultOptions: options,
    }) ||
    options ||
    {};
    const _feedback = { ...dadataFeedback, ...feedback };
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...(params || {}), deps, defaultIsSkipped })) return null;

    if (!value) return required ? _feedback.REQUIRED : null;

    if (typeof value !== 'object') return _feedback.INVALID_DADATA;
    if (!('data' in value) || !('value' in value) || !('city' in value.data)) return _feedback.INVALID_DADATA;
    if (deps?.[keyOfStringValue] && value.value !== deps?.[keyOfStringValue]) return _feedback.INVALID_DADATA;
    return null;
  };
