import React, { useEffect } from 'react';
import { observer, useStore } from '@core';

import { Step } from './components/Step/Step';

import s from './Steps.module.scss';

export const Steps: React.FC = observer(() => {
  const store = useStore();

  useEffect(() => {
    const step = document.querySelector('[data-step-active="true"]');
    if (!step) return;
    const timeoutId = setTimeout(() => {
      step?.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [store.step.currentStep]);

  const showSteps = store.step.steps.filter((step) => step.showList);

  const renderSteps = () => {
    const result = [];
    for (let i = showSteps.length - 1, wasActive = false; i >= 0; i--) {
      const step = showSteps[i];
      const active = wasActive || store?.step.titleCurrentStep == step.title;
      if (active) wasActive = true;
      result.unshift(<Step className={s.step} active={active} key={step.id} data={step} />);
    }
    return result;
  };

  return <div className={s.wrapper}>{renderSteps()}</div>;
});
