export const licensePlateLetters = 'АВЕКМНОРСТУХ';
export const addressLetters = '\\wа-яё\\s,\\.\\()\\-/\\|';
export const licensePlateAutoRegexp = new RegExp(`^[${licensePlateLetters}]\\d{3}[${licensePlateLetters}]{2}\\d{2,3}$`);
export const licensePlateBikeRegexp = new RegExp(`^\\d{4}[${licensePlateLetters}]{2}\\d{2,3}$`);
export const licensePlateSoftRegexp = new RegExp(`^[\\d${licensePlateLetters}\\s]*$`, 'i');
export const docSerialString = '\\d{2}[\\dA-ZА-Я]{2}';
export const docSerialRegexp = new RegExp(`^${docSerialString}$`, 'i');
export const docSerialNumberRegexp = /^[\da-zа-я]{4}\s?[\da-zа-я]{6}$/i;
export const docSerialNumberRegexpSoft = /^[\da-zа-я\s]*$/i;
export const snilsRegexp = /^\d{3}-\d{3}-\d{3}-\d{2}$/i;
export const snilsRegexpSoft = /^[\d-]*$/i;
export const licenseNumberForeignRegexp = /^[\da-z\s]*$/i;
export const licenseSerialForeignRegexp = /^[\da-z\s]*$/i;
export const ptsRegexp = new RegExp(`^${docSerialString}\\s?\\d{6}$`, 'i');
export const ptsRegexpSoft = /^[\dA-ZА-Я\s]*$/i;
export const stsRegexp = new RegExp(`^${docSerialString}\\s?\\d{6}$`, 'i');
export const stsRegexpSoft = /^[\dA-ZА-Я\s]*$/i;
export const etsRegexp = /^\d{15}$/;
export const etsRegexpSoft = /^\d*$/;
export const intRegexp = /^\d*$/;
export const floatRegexp = /^[\d.]*$/;
export const intNegativeRegexp = /^[\d-]*$/;
export const floatNegativeRegexp = /^[\d.-]*$/;
export const passwordSerialRegexp = /^\d{4}$/;
export const passwordNumberRegexp = /^\d{6}$/;
export const policySerialRegexp = /^[a-zа-я\d]{3}$/i;
export const policySerialRegexpSoft = /^[a-zа-я\d]*$/i;
export const policyNumberRegexp = /^[\d*]+$/i;
export const policyNumberRegexpSoft = /^[\d*]*$/i;
export const policyNumberStrictRegexp = /^\d+$/i;
export const policyNumberStrictRegexpSoft = /^\d*$/i;
export const vinRegexp = /^[a-z\d*]{17}$/i;
export const vinRegexpSoft = /^[a-z\d*]*$/i;
export const vinStrictRegexp = /^[a-z\d]{17}$/i;
export const vinStrictRegexpSoft = /^[a-z\d]*$/i;
export const bodyNumRegexp = /^[a-z\d]{9,12}$/i;
export const bodyNumRegexpSoft = /^[a-z\d]*$/i;
export const chassisNumRegexp = /^\d{4,6}$/i;
export const chassisNumRegexpSoft = /^\d*$/i;
export const docTypeRegexp = /^[012]$/i;
export const addressRegexp = new RegExp(`^[${addressLetters}]*$`, 'i');
export const phoneRegexp = /^\+7\s\((\d{3})\)\s(\d{3})-(\d{2})-(\d{2})$/;
export const phoneRegexpSoft = /^[+\d-\s()]*$/;
export const emailRegexp = /^[a-z\d%]+([_.-][a-z\d%]+)*@[a-z\d]+([_.-][a-z\d]+)*\.[a-z]{2,}$/i;
export const emailRegexpSoft = /^[\d@\w.%+-]*$/;
export const humanNameRegex = /^[-a-zа-яё\s]+$/i;
export const humanNameRegexSoft = /^[-a-zа-яё\s]*$/i;

export const MIN_YEAR = 1900;
export const MAX_YEAR = new Date().getFullYear();
export const MIN_DATE = new Date(`${MIN_YEAR}-01-01`);
export const MAX_DATE = new Date();
export const MIN_AGE = 16;
export const MAX_AGE = 110;
export const MIN_POWER = 0;
export const MAX_POWER = 9999;
export const MIN_BIRTHDAY_DATE = new Date();
MIN_BIRTHDAY_DATE.setFullYear(MIN_BIRTHDAY_DATE.getFullYear() - MAX_AGE);
export const MAX_BIRTHDAY_DATE = new Date();
MAX_BIRTHDAY_DATE.setFullYear(MAX_BIRTHDAY_DATE.getFullYear() - MIN_AGE);
export const MIN_POLICY_DATE = new Date();
MIN_POLICY_DATE.setDate(MIN_POLICY_DATE.getDate() + 1);
export const MAX_POLICY_DATE = new Date();
MAX_POLICY_DATE.setDate(MAX_POLICY_DATE.getDate() - 1);
MAX_POLICY_DATE.setMonth(MAX_POLICY_DATE.getMonth() + 2);
