import React, { useEffect } from 'react';
import { useEvent } from '@core/hooks/useEvent';
import cn from 'classnames';
import { GroupedUpsales, SelectedUpsales } from '@core/features/upsale/upsale.types';
import { Preloader } from '@core/common/components/Preloader/Preloader';
import { CompanyLogo, PartnerLogoType } from '../../../../../../partners/src/components/CompanyLogo';
import { UpsalesProps } from '@core/common/components/_factories/createUpsales';
import s from './createOffer.module.scss';

export type OfferProps = {
  className?: string;

  logoType?: PartnerLogoType;
  companyId: string;
  companyName?: string;
  loadingUpSales: boolean;
  selectedUpsales: SelectedUpsales | null;
  groupedUpsales: GroupedUpsales | null;
  totalSum: number;
  date: string;
  error: string | null;
  link: string | null;
  autogen?: boolean;
  withoutUpsales?: boolean;
  onCheckUpsale: (checked: boolean, group: string, id: string) => void;
  loadingLinkPay: boolean;
  getLinkPay: (selectedUpsalesKeys?: string[]) => Promise<string | void | null | { error: string }>;
};

export type OfferStyle = {
  root?: string;
  top?: string;
  logo?: string;
  date?: string;
  price?: string;
  upsales?: string;
  email?: string;
  error?: string;
  btn?: string;
  name?: string;
  main?: string;
  face?: string;
  formingLink?: string;
};

export const createOffer = (
  {
    btnProps,
    linkProps,
    LinkAsButton,
    Button,
    OfferEmailField,
    LoadingIcon,
    Upsales,
  }: {
    btnProps?: Record<string, unknown>;
    linkProps?: Record<string, unknown>;
    Button: React.ComponentType<{
      className?: string;
      disabled?: boolean;
      onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    }>;
    LinkAsButton: React.ComponentType<{
      href?: string;
      target?: string;
      className?: string;
    }>;
    OfferEmailField: React.ComponentType<{ className?: string }>;
    LoadingIcon: React.ComponentType;
    Upsales: React.ComponentType<UpsalesProps>;
  },
  style?: OfferStyle
): React.FC<OfferProps> => {
  const cls = {
    root: cn(s.root, style?.root),
    top: cn(s.top, style?.top),
    logo: cn(s.logo, style?.logo),
    date: cn(s.date, style?.date),
    price: cn(s.price, style?.price),
    upsales: cn(s.upsales, style?.upsales),
    email: cn(s.email, style?.email),
    error: cn(s.error, style?.error),
    btn: cn(s.btn, style?.btn),
    face: cn(s.face, style?.face),
    main: cn(s.main, style?.main),
    name: cn(s.name, style?.name),
    formingLink: cn(s.formingLink, style?.formingLink),
  };
  return ({
    className,
    getLinkPay,
    link,
    loadingUpSales,
    groupedUpsales,
    date,
    companyId,
    totalSum,
    error,
    loadingLinkPay,
    selectedUpsales,
    onCheckUpsale,
    companyName,
    autogen,
    withoutUpsales,
    logoType = 'colorful',
  }) => {
    const genLinkPay = useEvent(() => getLinkPay(Object.values(selectedUpsales || {})));

    const button = (() => {
      if (loadingUpSales) return null;

      if (error) return <p className={cls.error}>{error}</p>;

      if (typeof link === 'string') {
        return (
          <LinkAsButton {...(linkProps || {})} className={cls.btn} href={link} target="_blank">
            Оплатить
          </LinkAsButton>
        );
      }

      if (loadingLinkPay) {
        return (
          <p className={cls.formingLink}>
            <LoadingIcon />
            Идёт формирование ссылки...
          </p>
        );
      }

      return (
        <Button {...(btnProps || {})} className={cls.btn} onClick={genLinkPay}>
          Получить ссылку
        </Button>
      );
    })();

    useEffect(() => {
      if (autogen) {
        genLinkPay();
      }
    }, [autogen, genLinkPay]);

    return (
      <div className={cn(cls.root, className)}>
        <div className={cls.main}>
          <div className={cls.top}>
            <div className={cls.face}>
              <CompanyLogo className={cls.logo} companyId={+companyId} type={logoType} />
              <div className={cls.name}>{companyName}</div>
            </div>
            <div className={cls.date}>{`c ${date}`}</div>
            <div className={cls.price}>{`${totalSum} ₽`}</div>
          </div>
          {!withoutUpsales &&
            (loadingUpSales ? (
              <Preloader text="Загружаются дополнительные возможности" />
            ) : (
              <Upsales
                className={cls.upsales}
                groupedUpsales={groupedUpsales}
                onCheckUpsale={onCheckUpsale}
                selectedUpsales={selectedUpsales}
                disabled={loadingLinkPay}
              />
            ))}
        </div>
        <OfferEmailField className={cls.email} />
        {button}
      </div>
    );
  };
};
