import React from 'react';

import { useStore, observer, PASSPORT_FOREIGN_LIST } from '@core';

import { FormWrapper } from '../../../../../common/components/FormWrapper/FormWrapper';
import { FormRow } from '../../../../../common/components/FormRow';
import { InputDefault } from '../../../../../common/components/Input/InputDefault';
import { InputDate } from '../../../../../common/components/Input/InputDate';
import { InputMask } from '../../../../../common/components/Input/InputMask';
import { SelectDefault } from '../../../../../common/components/Input/SelectDefault';
import { SelectDadata } from '../../../../../common/components/Input/SelectDadata';

import s from './OwnerForm.module.scss';
import { PolicyholderForm } from '../PolicyholderForm/PolicyholderForm';
import { Switch } from '../../../../../common/components/Input/Switch';
import { transformHumanName } from '@utils/getValueByEventHumanName';
import { getDriversOptions } from '@utils/getDriversOptions';

export const OwnerForm: React.FC = observer(() => {
  const store = useStore();

  if (!store) {
    return null;
  }

  const driversOption = getDriversOptions(store.form.drivers);

  return (
    <div className={s.ownerFormContainer}>
      <FormWrapper>
        <FormRow>
          <div className={s.ownerFormSelectDefault}>
            <SelectDefault
              view="fullWidth"
              placeholder="Выбор собственника"
              name="owner_driverSelect"
              options={driversOption}
            />
          </div>
        </FormRow>
        <div className={s.ownerFormBox}>
          <FormRow columns>
            <InputDefault view="fullWidth" transform={transformHumanName} placeholder="Фамилия" name="owner_lastname" />
            <InputDefault view="fullWidth" transform={transformHumanName} placeholder="Имя" name="owner_firstname" />
          </FormRow>
          <FormRow columns>
            <InputDefault
              view="fullWidth"
              transform={transformHumanName}
              placeholder="Отчество"
              name="owner_middlename"
            />
            <InputDate view="fullWidth" placeholder="Дата рождения" name="owner_birthdate" />
          </FormRow>
          <FormRow columns>
            <SelectDefault
              view="fullWidth"
              placeholder="Документ"
              name="owner_passportForeign"
              options={PASSPORT_FOREIGN_LIST}
            />
            {store.form.fields.owner_passportForeign.value == 0 && (
              <InputMask
                view="fullWidth"
                placeholder="Серия и номер документа"
                mask="passport"
                name="owner_passportNumber"
              />
            )}
          </FormRow>
          {store.form.fields.owner_passportForeign.value == 1 && (
            <FormRow columns>
              <InputDefault view="fullWidth" placeholder="Серия (если есть)" name="owner_passportSerialForeign0" />
              <InputDefault view="fullWidth" placeholder="Номер" name="owner_passportNumberForeign0" />
            </FormRow>
          )}
          <FormRow columns>
            <InputDate view="fullWidth" placeholder="Дата выдачи" name="owner_passportDate" />
            <SelectDadata view="fullWidth" placeholder="Адрес регистрации собственника" name="owner_address" />
          </FormRow>

          <Switch name="insurerIsOwner">Страхователь и собственник одно лицо</Switch>
        </div>
      </FormWrapper>
      {!store.form.fields.insurerIsOwner.value && (
        <div className={s.ownerFormBox}>
          <PolicyholderForm />
        </div>
      )}
    </div>
  );
});
