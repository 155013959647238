import React from 'react';
import { OSAGOWrapper, OSAGORouter } from '@core';

import { steps } from '../common/constants/steps';
import { routes } from '../routes/routes';
import { Layout } from '../common/components/Layout/Layout';

import '../scss/base/index.scss';
import { SessionStorage } from '@core/services/storage/session-storage';
/*
  Чтобы отключить компании, нужно передать в useEnabledPartners({ insuranceCompanies: ['alfa', ...] }) 
  из baseOsago, массив строк с названием на латиницы компаний, которые нужно включить
*/
const App: React.FC = () => (
  <React.StrictMode>
    <OSAGOWrapper>
      <Layout>
        <OSAGORouter
          draftStorage={new SessionStorage({ expire: 60 * 15 })}
          prefix="iframe/t2/"
          routes={routes}
          steps={steps}
          stepForOpenSavedOrder={3}
          idAgent="2c4a584858e25d21c41199810a29314d"
          chat={{
            style: {
              widgetHeaderBackground: '#333',
            },
          }}
          upsales={{
            notRequired: true,
            required: true,
          }}
        />
      </Layout>
    </OSAGOWrapper>
  </React.StrictMode>
);

export default App;
