import {
  applyAliases,
  createGetMask,
  defaultClear,
  defaultTransform,
  getMaxMaskPos,
  getMinMaskPos,
  correctCarriagePosition,
  defaultMaskPreparer,
} from './helpers';
import { CreateFormatterRecord, CreateMaskRecord, FormatterRecord, MaskRecord } from './types';

export const createMaskRecord: CreateMaskRecord = (mask, symbols, options) => {
  const { clear = defaultClear, transform = defaultTransform, aliases = [] } = options || {};
  const getMask = createGetMask(mask, symbols);
  const min = getMinMaskPos(mask, symbols);
  const max = getMaxMaskPos(mask, symbols);

  const getCorrectPosition = (value: string, defaultPosition: number) => {
    // Корректной позицией будет длина отформатированного value до каретки по умолчанию
    const position = getMask(value.slice(0, defaultPosition)).length;

    // У маски могут быть символы в начале или в конце, каретка не может налазить на символы маски
    if (position < min) return min;
    if (position > max) return max;
    return position;
  };

  const getValue: MaskRecord['getValue'] = (value, params) => {
    const { transform: needTransform = true, clear: needClear = true } = params || {};
    const _value = needTransform ? transform(value) : value;
    const preparedValue = getMask(applyAliases(_value, aliases));

    return needClear ? clear(preparedValue) : preparedValue;
  };

  return {
    length: options?.length,
    mask,
    symbols,
    getMask: (value, params) => {
      const { transform: needTransform = true, clear: needClear = false } = params || {};
      const prepared = getMask(needTransform ? transform(value) : value);
      return needClear ? clear(prepared) : prepared;
    },
    getValue,
    isMask: (value, params) => {
      const clean = getValue(value, { ...params, clear: true });
      const cleanMask = clear(mask);
      if (value !== getValue(value, params)) return false;
      if (clean.length > cleanMask.length) return false;
      if (options?.length) {
        const length = options.length > 0 ? options.length : cleanMask.length + options.length;
        return clean.length >= length && clean.length <= cleanMask.length;
      }
      return cleanMask.length === clean.length;
    },
    changeValue: (event, params) => {
      const { transform: needTransform = true, clear: needClear = true } = params || {};
      const input = event.target;
      const value = needTransform ? transform(input.value) : input.value;
      const preparedValue = getValue(input.value, { ...(params || {}), clear: false });

      if (typeof input.selectionStart === 'number') {
        const position = getCorrectPosition(value, input.selectionStart);
        correctCarriagePosition(position, input);
      }

      return needClear ? clear(preparedValue) : preparedValue;
    },
  };
};

export const createFormatter: CreateFormatterRecord = (formatter, options) => {
  const { clear = defaultClear, maskPreparer = defaultMaskPreparer } = options || {};

  const getCorrectPosition = (value: string, defaultPosition: number) =>
    // Корректной позицией будет длина отформатированного value до каретки по умолчанию
    formatter(value.slice(0, defaultPosition)).length;

  const getValue: FormatterRecord['getValue'] = (value, params) => {
    const { clear: needClear = true } = params || {};
    const preparedValue = formatter(value);

    return needClear ? clear(preparedValue) : preparedValue;
  };

  return {
    getMask: (value) => maskPreparer(formatter(value)),
    getValue,
    changeValue: (event, params) => {
      const { clear: needClear = true } = params || {};
      const input = event.target;
      const { value } = input;
      const preparedValue = getValue(value, { ...(params || {}), clear: false });

      if (typeof input.selectionStart === 'number') {
        const position = getCorrectPosition(value, input.selectionStart!);
        correctCarriagePosition(position, input);
      }

      return needClear ? clear(preparedValue) : preparedValue;
    },
  };
};
