import { ValidationFn, EmailOptions } from '../common/types';
import { stringRule } from './sharedRules';
import { emailRegexp, emailRegexpSoft } from '../common/constants';
import { INVALID_EMAIL_FORMAT } from '../common/texts';

export const emailRule =
  (options?: EmailOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? emailRegexpSoft : emailRegexp,
      message: options?.feedback?.INVALID_EMAIL_FORMAT || INVALID_EMAIL_FORMAT,
    })(value, deps, { ...params, type: 'hard' });
