import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore } from '@core';
import { getOptions } from '@core/store/formStore/companiesModelsFieldStore/helpers';
import { logError } from '@utils/logger';
import { useValidator } from '@core/providers/ValidatorProvider/ValidatorProvider';
import {
  InputCompanyModelsProps,
  InputCompanyModelsValue,
} from '@core/common/components/_factories/createInputCompanyModels';
import {
  fetchBrandsModelsList,
  listsTypeToFieldKey,
  getCompaniesOptionsByStore,
  CompaniesOptions,
  useFetchBrandsModelsList,
} from '@core/hooks/useFetchBrandsModelsList';
import { PartnerCompany } from '@core/features/company/company.types';
import s from './createFieldCompaniesModels.module.scss';

export type FieldCompaniesModelsOriginProps = {
  className?: string;
};

export type FieldCompaniesModelsProps = {
  className?: string;
  loading?: boolean;
};

export type FieldCompaniesModelsStyle = {
  root: string;
  title: string;
};

const title = 'Выбранная модель не найдена в списке некоторых компаний. Уточните марку из списка:';

export const createFieldCompaniesModelsOrigin = <T extends FieldCompaniesModelsOriginProps>(
  {
    InputCompanyModels,
  }: {
    InputCompanyModels: React.ComponentType<InputCompanyModelsProps>;
  },
  style?: FieldCompaniesModelsStyle
): FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    title: cn(s.title, style?.title),
  };

  return observer(({ className }) => {
    const store = useStore();

    const { subscribe, validateByKey } = useValidator();

    useEffect(() => subscribe('companiesModels'), [subscribe]);

    const { companies, value } = store.form.companiesModelsField.get();
    const { values, visibleOptions, errors } = value;
    const [hiddenSks, setHiddenSks] = useState<number[]>([]);

    // используется для единоразовой мемоизации companiesOptions и выполнения функции initialState только при монтировании
    const [companiesOptions, setCompaniesOptions] = useState<CompaniesOptions>([]);

    const valueCopy = useRef(value);
    valueCopy.current = value;

    useEffect(() => {
      setCompaniesOptions(
        companies.reduce((acc, { id: companyId }) => {
          const brand = valueCopy.current.visibleOptions[companyId]?.brand?.[0]?.value;
          const brandFromOption = brand === valueCopy.current.values[companyId]?.brand;

          // Запрашиваем список брендов
          if (!valueCopy.current.options[companyId]?.brand?.length) {
            acc.push({
              sk: +companyId,
              type: 'list_brands',
            });
          }

          // Если выставлен бренд, запрашиваем и список моделей
          if (
            !valueCopy.current.options[companyId]?.model?.length &&
            valueCopy.current.options[companyId]?.brand?.length === 1 &&
            brandFromOption
          ) {
            acc.push({
              sk: +companyId,
              brand,
              type: 'list_models',
            });
          }

          return acc;
        }, [] as CompaniesOptions)
      );
    }, [companies]);

    /**
     * Дожидаемся загрузки всех опций, ищем брэнд/модель в полученном списке, если нашли - устанавливаем.
     * Если установили бренд - запрашиваем модели и пробуем установить
     * */
    useFetchBrandsModelsList(companiesOptions, {
      handleAllCompaniesOptions: (result) => {
        const modelsFetchList: CompaniesOptions = [];
        result.forEach((item) => {
          if (item.status === 'fulfilled') {
            const response = item.value;
            if (!response.list?.length) return;
            const type = listsTypeToFieldKey(response.type);
            if (store.form.companiesModelsField.value.options?.[response.sk]?.[type]) {
              store.form.companiesModelsField.value.options[response.sk][type] = response.list;
            }
            const _value = store.form.fields[type].value?.toLowerCase();
            const found = response.list.find((i) => i.toLowerCase() === _value);
            const company = { id: response.sk } as PartnerCompany;
            if (found) {
              // Устанавливает значение и опцию
              store.form.companiesModelsField.select({ title: found, value: found }, company, { type });
              if (type === 'brand') {
                modelsFetchList.push({ sk: +response.sk, brand: found, type: 'list_models' });
              }
              if (type === 'model') {
                setHiddenSks((v) => [...v, company.id]);
              }
            }
          }
        });
        store.form.companiesModelsField.checkError();
        store.form.companiesModelsField.prepareOptions();
        if (modelsFetchList.length) setCompaniesOptions(modelsFetchList);
      },
    });

    const onChange = (v: InputCompanyModelsValue) => {
      store.form.companiesModelsField.change(v);
      store.form.companiesModelsField.checkError();
      store.form.companiesModelsField.prepareOptions();
    };

    const controllers = useRef<Record<string, AbortController>>({});

    const search: InputCompanyModelsProps['search'] = async (text, company, { type }) => {
      if (!text || text.length < 2) return;
      try {
        controllers.current[company.id]?.abort?.();
        controllers.current[company.id] = new AbortController();
        const newOptions = await getOptions(text, company, {
          type,
          brand: value.values[company.id]?.brand,
          localOptions: value.options[company.id]?.[type],
          options: {
            signal: controllers.current[company.id].signal,
          },
        });
        store.form.companiesModelsField.setVisibleOptions(newOptions, company, { type });
        store.form.companiesModelsField.addOptions(
          newOptions?.map((i) => i.value),
          company,
          { type }
        );
        store.form.companiesModelsField.prepareOptions();
      } catch (e) {
        const err = e as Error;
        if (/network error/i.test(err.message)) {
          // todo уведомить пользователя о проблемах со связью
          return;
        }
        logError(err);
      }
    };

    const onSelect: InputCompanyModelsProps['onSelect'] = (option, company, params) => {
      store.form.companiesModelsField.select(option, company, params);
      store.form.companiesModelsField.prepareOptionsOfAllCompanies(option.value, params.type);
      store.form.companiesModelsField.checkError();
      store.form.companiesModelsField.prepareOptions();

      const _companiesOptions = getCompaniesOptionsByStore(store.form.companiesModelsField.value.values, params.type);

      // Запрашиваем список опций по указанным компаниям и типу
      fetchBrandsModelsList(_companiesOptions, {
        handleCompanyOptions: (response) => {
          if (!response?.list?.length) return;
          const type = listsTypeToFieldKey(response.type);
          const _company = { id: response.sk } as PartnerCompany;

          // Добавляем во все опции и пробуем найти нужное совпадение
          store.form.companiesModelsField.addOptions(response.list, _company, { type });
          const _value = store.form.fields[type].value?.toLowerCase();
          const found = response.list.find((i) => i.toLowerCase() === _value);
          if (!found) return;

          // Устанавливает значение и опцию
          store.form.companiesModelsField.select({ title: found, value: found }, _company, { type });
          store.form.companiesModelsField.checkError();
          store.form.companiesModelsField.prepareOptions();
        },
      });
    };

    const onBlur: InputCompanyModelsProps['onBlur'] = (option, company, params) => {
      const type = params?.type;
      const _value = store.form.companiesModelsField.value.values[company.id]?.[type];
      const brands = store.form.companiesModelsField.value.visibleOptions[company.id]?.brand
        ?.map((i) => i.value)
        .filter((i) => i !== '-1');
      const models = store.form.companiesModelsField.value.visibleOptions[company.id]?.model
        ?.map((i) => i.value)
        .filter((i) => i !== '-1');

      store.form.companiesModelsField.setError(
        (validateByKey(_value, `${type}CompaniesModels`, { extra: { brands, models } }) as string) || '',
        company,
        params
      );
    };

    const filteredCompanies = companies?.filter((i) => !hiddenSks.includes(i.id));

    if (!filteredCompanies?.length) return null;

    return (
      <div className={cn(cls.root, className)}>
        <div className={cls.title}>{title}</div>
        <InputCompanyModels
          options={visibleOptions}
          search={search}
          whiteSelects
          viewSelects="fullWidth"
          columns
          onBlur={onBlur}
          errors={errors}
          companies={filteredCompanies}
          onSelect={onSelect}
          onChange={onChange}
          value={values}
        />
      </div>
    );
  });
};

export const createFieldCompaniesModels = (
  {
    InputCompanyModels,
  }: {
    InputCompanyModels: React.ComponentType<InputCompanyModelsProps>;
  },
  style?: FieldCompaniesModelsStyle
): FC<FieldCompaniesModelsProps> => {
  const FieldCompaniesModelsOrigin = createFieldCompaniesModelsOrigin({ InputCompanyModels }, style);

  FieldCompaniesModelsOrigin.displayName = 'FieldCompaniesModelsOrigin';

  return observer(({ className, loading }) => {
    const store = useStore();

    if (!store || loading) return null;

    return <FieldCompaniesModelsOrigin className={className} />;
  });
};
