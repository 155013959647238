import React from 'react';
import cn from 'classnames';
import { IMask } from '@core/common/components/_factories/input.types';
import { mapMask } from '@core/common/components/_factories/utils/input-mask';
import { getMaskSymbols } from '../../../../../masks/helpers';
import s from './createMask.module.scss';

export type MaskProps = { mask?: IMask; active: boolean; value: string; className?: string };
export type MaskStyle = { root: string; item: string };

export const createMask = <T extends MaskProps>(style: MaskStyle): React.FC<T> => {
  const cls = {
    root: cn(s.root, style.root),
    item: cn(s.item, style.item),
  };
  return ({ mask = null, active = false, value, className }) => {
    if (!mask || (!active && !value)) return null;
    const maskRecord = mapMask[mask];

    const symbols = getMaskSymbols(maskRecord, value);

    return (
      <div className={cn(cls.root, 'Mask', className)}>
        {symbols?.map((maskSymbol, i) => (
          <div className={cls.item} key={i} style={maskSymbol.hidden ? { visibility: 'hidden' } : undefined}>
            {maskSymbol.symbol}
          </div>
        ))}
      </div>
    );
  };
};
