import { useEffect } from 'react';

/**
 * Хук использует resizeObserver и позволяет масштабировать высоту экрана для iframe. Обязательно реализация в iframe-libs.
 */

const HEIGHT_PADDING = 100;
export const useSendHeightOutside = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    const resizeObserver = new ResizeObserver(() => {
      if (root && window.top) {
        const height = Math.max(root.scrollHeight, root.offsetHeight) + HEIGHT_PADDING;
        window.top.postMessage(height, '*');
      }
    });

    if (root) {
      resizeObserver.observe(root);
    }
  }, []);
};
