import { useMemo, useRef } from 'react';

export type Callback = (...args: any[]) => any;

export const useEvent = <T extends Callback = Callback>(callback: T): T => {
  const copy = useRef<T>();
  copy.current = callback;

  return useMemo<T>(() => ((...args) => copy.current?.(...args)) as T, []);
};
