import {
  createCalendar,
  CalendarProps,
  CalendarStyle,
} from '@core/common/components/_factories/createCalendarDropdown/createCalendar';
import { CalendarHeaderStyle } from '@core/common/components/_factories/createCalendarDropdown/createCalendarHeader';
import { DaysWeekStyle } from '@core/common/components/_factories/createCalendarDropdown/createDaysWeek';
import headerStyle from './CalendarHeader.module.scss';
import daysWeekStyle from './DaysWeek.module.scss';
import s from './Calendar.module.scss';

export type { CalendarProps };

export const Calendar = createCalendar(s as CalendarStyle, {
  headerStyle: headerStyle as CalendarHeaderStyle,
  daysWeekStyle: daysWeekStyle as DaysWeekStyle,
});
