import React, { FC } from 'react';
import cn from 'classnames';
import { FormRowProps } from '@core/common/components/_factories/createFormRow';
import { InputCompanyModelProps } from '@core/common/components/_factories/createInputCompanyModel';
import { InputCompanyModelsProps } from './types';
import s from './createInputCompanyModels.module.scss';

export type InputCompanyModelsStyle = {
  root: string;
  item: string;
  exclude: string;
};

export const createInputCompanyModels = <T extends InputCompanyModelsProps>(
  {
    FormRow,
    InputCompanyModel,
  }: {
    FormRow: React.ComponentType<FormRowProps>;
    InputCompanyModel: React.ComponentType<InputCompanyModelProps>;
  },
  style?: InputCompanyModelsStyle
): FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    item: cn(s.item, style?.item),
    exclude: cn(s.exclude, style?.exclude),
  };
  return ({
    className,
    viewSelects,
    whiteSelects,
    search,
    flagAuto,
    options,
    columns = true,
    companies,
    errors,
    value,
    onChange,
    onSelect,
    onType,
    onFocus,
    onBlur,
  }) => {
    if (!companies?.length) return null;
    return (
      <div className={cn(cls.root, className)}>
        {(() => {
          const mold: React.ReactNode[][] = [[]];
          companies.forEach((company) => {
            const elem = (
              <InputCompanyModel
                flagAutoBrand={flagAuto?.[company.id]?.brand}
                flagAutoModel={flagAuto?.[company.id]?.model}
                errorModel={errors?.[company.id]?.model}
                errorBrand={errors?.[company.id]?.brand}
                errorExclude={errors?.[company.id]?.exclude}
                viewSelects={viewSelects}
                whiteSelects={whiteSelects}
                onBlurBrand={(event) => onBlur?.(event, company, { type: 'brand' })}
                onBlurModel={(event) => onBlur?.(event, company, { type: 'model' })}
                onFocusBrand={(event) => onFocus?.(event, company, { type: 'brand' })}
                onFocusModel={(event) => onFocus?.(event, company, { type: 'model' })}
                onSelectBrand={(_value) => onSelect?.(_value, company, { type: 'brand' })}
                onSelectModel={(_value) => onSelect?.(_value, company, { type: 'model' })}
                onTypeBrand={(_value) => onType?.(_value, company, { type: 'brand' })}
                onTypeModel={(_value) => onType?.(_value, company, { type: 'model' })}
                searchBrand={(text, event) => search(text, company, { type: 'brand', event })}
                searchModel={(text, event) => search(text, company, { type: 'model', event })}
                brandOptions={options[company.id]?.brand || []}
                modelOptions={options[company.id]?.model || []}
                className={cn(cls.item, value?.[company.id]?.exclude && cls.exclude)}
                company={company}
                key={company.id}
                value={value?.[company.id]}
                onChange={(v) => onChange?.({ ...(value || {}), [company.id]: v })}
              />
            );
            const last = mold[mold.length - 1];
            if (columns && last.length < 2) last.push(elem);
            else mold.push([elem]);
          });

          const last = mold[mold.length - 1];
          if (last.length === 1) last.push(<div key="plug" />);

          return mold.map((item, i) => (
            <FormRow columns={columns} key={i}>
              {item}
            </FormRow>
          ));
        })()}
      </div>
    );
  };
};
