import { IMask } from '@core/common/components/_factories/input.types';
import React, { useState } from 'react';
import { ParamsToOutside } from '@core/common/components/_factories/createInputBase';
import { CalendarDropdownProps } from '@core/common/components/_factories/createCalendarDropdown/createCalendarDropdown';
import { InputMaskBaseProps } from '../createInputMaskBase';

export type InputDateBaseProps = Omit<
  InputMaskBaseProps,
  'postfix' | 'bottom' | 'mask' | 'onChangeActive' | 'maxLength'
> & {
  min?: Date;
  max?: Date;
};

const mask: IMask = 'date';

export const getTwoDigits = (num: number) => {
  if (num < 10) return `0${num}`;
  return num.toString();
};

export const createInputDateBase =
  <T extends InputDateBaseProps>({
    DateIcon,
    CalendarDropdown,
    InputMaskBase,
  }: {
    DateIcon: React.ComponentType<{ view: boolean; onClick: (event: React.MouseEvent<HTMLSpanElement>) => void }>;
    CalendarDropdown: React.ComponentType<CalendarDropdownProps>;
    InputMaskBase: React.ComponentType<InputMaskBaseProps>;
  }): React.FC<T> =>
  ({ min, max, onBlur, ...props }) => {
    const [active, setActive] = useState(false);
    const icon = ({ flagAuto }: ParamsToOutside) => <DateIcon view={!flagAuto} onClick={() => setActive((v) => !v)} />;

    const bottom = ({ value, onChange }: ParamsToOutside) => {
      const localValue = value ? new Date(value.split('.').reverse().join('-')) : new Date();
      const onChangeCalendar = (date: Date) => {
        onChange(`${getTwoDigits(date.getDate())}.${getTwoDigits(date.getMonth() + 1)}.${date.getFullYear()}`);
        setActive(false);
      };

      return <CalendarDropdown onChange={onChangeCalendar} opened={active} value={localValue} min={min} max={max} />;
    };

    const onChangeActive = (a: boolean) => {
      if (!a) setActive(false);
    };

    return (
      <InputMaskBase
        {...props}
        onBlur={(e) => {
          onBlur?.(e);
          if (e.relatedTarget) {
            setActive(false);
          }
        }}
        onChangeActive={onChangeActive}
        postfix={icon}
        bottom={bottom}
        mask={mask}
      />
    );
  };
