import { ValidationFn, BodyNumOptions, ChassisNumOptions, VinOptions } from '../common/types';
import { stringRule } from './sharedRules';
import {
  bodyNumRegexp,
  bodyNumRegexpSoft,
  chassisNumRegexp,
  chassisNumRegexpSoft,
  vinRegexp,
  vinRegexpSoft,
} from '../common/constants';
import { INVALID_BODY_NUM_FORMAT, INVALID_CHASSIS_NUM_FORMAT, INVALID_VIN_FORMAT } from '../common/texts';

export const vinRule =
  (options?: VinOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? vinRegexpSoft : vinRegexp,
      message: options?.feedback?.INVALID_VIN_FORMAT || INVALID_VIN_FORMAT,
      isSkipped: (prms) => !prms.deps?.vehicleDoc || prms.deps?.vehicleDoc !== 'vin',
    })(value, deps, { ...params, type: 'hard' });

export const bodyNumRule =
  (options?: BodyNumOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? bodyNumRegexpSoft : bodyNumRegexp,
      message: options?.feedback?.INVALID_BODY_NUM_FORMAT || INVALID_BODY_NUM_FORMAT,
      isSkipped: (prms) => !prms.deps?.vehicleDoc || prms.deps?.vehicleDoc !== 'body',
    })(value, deps, { ...params, type: 'hard' });

export const chassisNumRule =
  (options?: ChassisNumOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? chassisNumRegexpSoft : chassisNumRegexp,
      message: options?.feedback?.INVALID_CHASSIS_NUM_FORMAT || INVALID_CHASSIS_NUM_FORMAT,
      isSkipped: (prms) => !prms.deps?.vehicleDoc || prms.deps?.vehicleDoc !== 'chassis',
    })(value, deps, { ...params, type: 'hard' });
