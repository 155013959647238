import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import {
  updateOrder,
  setOrderIdStorage,
  createOrder,
  getOrderKeyStorage,
  removeOffersStorage,
  removeActiveOffersStorage,
  getDraftsStorage,
  setDraftsStorage,
} from '@core';
import { useStore } from '../providers/StoreProvider';
import { useEvent } from '@core/hooks/useEvent';
import { PolicyOrderDraft } from '@core/features/order/order.types';
import { logError } from '@utils/logger';
import { useDraftStorage } from '@core/common/components/StorageProvider/StorageProvider';

interface IUseSaveOrder {
  saveOrder: () => Promise<string | undefined>;
}

export const useSaveOrder = (): IUseSaveOrder => {
  const store = useStore();

  const draftStorage = useDraftStorage();

  const updateDrafts = async (objectStore: PolicyOrderDraft, location: Location) => {
    const arrDraft = await getDraftsStorage(draftStorage);
    const drafts = [...(arrDraft?.drafts || [])];

    const params = new URLSearchParams(location.search);
    const id = params.get('draft');

    const index = drafts.findIndex((i) => i.id === id);

    if (index > -1) {
      drafts[index] = objectStore;
    } else {
      drafts.push(objectStore);
    }

    await setDraftsStorage(drafts, draftStorage);
  };

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const saveOrder = useEvent(async () => {
    if (!store || loading) return;

    setLoading(true);

    await removeOffersStorage();
    await removeActiveOffersStorage();

    const objectStore = store.createObjectToLocalSave();

    const objectToSave = await store.createObjectFormData();

    const orderKey = await getOrderKeyStorage();
    if (orderKey) {
      const order_id = await updateOrder({
        order_data: objectToSave,
        order_key: orderKey,
        phone: store.form.fields.phone.value || '',
        step: store.step.currentStep,
      });

      if (order_id) {
        await setOrderIdStorage(order_id.order_id);
      }
    } else {
      const idAgent = store.calc.idAgent || undefined;
      try {
        await createOrder({ orderData: objectToSave, idAgent });
        await store.calc.initCalculatorToStore();
      } catch (e) {
        logError(e);
        setLoading(false);
        return;
      }
    }

    await updateDrafts(objectStore, location);

    setLoading(false);

    return objectStore.id;
  });

  return { saveOrder };
};
