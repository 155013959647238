/**
 * Лучше всего перебраться на uuid.js
 *
 * Как было:
 * чат: (Math.random() + 1).toString(36).substring(2)
 * черновик: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
 *
 * В данном случае нет никакой существенной разницы как генерировать id, поэтому используем один и тот же подход.
 */

export const uuid = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substring(2);
