import { ValidationFn, AddressOptions } from '../common/types';
import { stringRule } from './sharedRules';
import { addressRegexp } from '../common/constants';
import { INVALID_ADDRESS_FORMAT } from '../common/texts';

export const addressRule =
  (options?: AddressOptions): ValidationFn<string> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: addressRegexp,
      message: options?.feedback?.INVALID_ADDRESS_FORMAT || INVALID_ADDRESS_FORMAT,
    })(value, deps, { ...params, type: 'hard' });
