import { getActiveOffersStorage, getLinkOffersByIdSk, removeActiveOffersStorage, setActiveOffersStorage } from '@core';
import { errors } from '@core/constants/texts';

type GetLinkPayFn = (
  idCompany: string,
  eId: number,
  phone: string,
  successUrl: string,
  failUrl: string,
  upsales?: string
) => Promise<string | null | { error: string }>;

export const getPayLink: GetLinkPayFn = async (idCompany, eId, phone, successUrl, failUrl, upsales) => {
  const oldActiveOffers = (await getActiveOffersStorage()) || {};

  if (!oldActiveOffers[idCompany]) {
    return '';
  }

  if (oldActiveOffers[idCompany].loading) {
    return '';
  }

  if (oldActiveOffers[idCompany].error) {
    return { error: '404' };
  }

  // if (oldActiveOffers[idCompany].link) {
  //   return oldActiveOffers[idCompany].link;
  // }

  oldActiveOffers[idCompany].loading = true;
  await setActiveOffersStorage(oldActiveOffers);

  const link = await getLinkOffersByIdSk({
    q: 'payurl',
    eId,
    phone,
    successUrl,
    failUrl,
    upsales,
  });

  if (
    link &&
    link.results &&
    link.errors?.length == 0 &&
    link.results &&
    typeof link.results === 'object' &&
    'payurl' in link.results &&
    typeof (link.results as { payurl: string }).payurl === 'string'
  ) {
    const newActiveOffers = (await getActiveOffersStorage()) || {};
    const payurl = (link.results as { payurl: string }).payurl;

    if (newActiveOffers[idCompany]) {
      newActiveOffers[idCompany].link = payurl;
      newActiveOffers[idCompany].loading = false;
    }

    await setActiveOffersStorage(newActiveOffers);
    return payurl;
  }

  if (link.errors.length) {
    if (link.errors[0].code == 109 && link.errors[0].error.indexOf('Создать дубль расчета') !== -1) {
      return { error: '109' };
    }
    const newActiveOffers = (await getActiveOffersStorage()) || {};
    newActiveOffers[idCompany].loading = false;
    newActiveOffers[idCompany].error = errors.pay_link_error;
    await setActiveOffersStorage(newActiveOffers);

    return { error: '404' };
  }
  await removeActiveOffersStorage();
  return null;
};
