import React, { useState } from 'react';
import { useSearch, observer } from '@core';
import { useSelectHandlers } from '@core/hooks/useInputHandlers';
import { ISelectOption } from '@core/common/components/_factories/input.types';
import { SelectBaseToStored } from '@core/common/components/_factories/select.types';
import { SearchingSelectProps } from '@core/common/components/_factories/createSearchingSelect';

export type SelectSearchProps = Omit<SelectBaseToStored<SearchingSelectProps>, 'search' | 'options'>;

export const createSelectSearch = <T extends SelectSearchProps>({
  SearchingSelect,
}: {
  SearchingSelect: React.ComponentType<SearchingSelectProps>;
}): React.FC<T> =>
  observer(
    ({
      name,
      index,
      validationName,
      validationSubscribe,
      onSelect,
      nameId,
      disabled,
      validOnInput = true,
      ...props
    }) => {
      const { searchFunction, defaultOptionsSearchValue } = useSearch();

      const [options, setOptions] = useState<ISelectOption[]>(() => defaultOptionsSearchValue(name));

      const { value, clearAutoValue, flagAuto, error, handleSelect, handleChange, handleBlur } = useSelectHandlers({
        index,
        name,
        nameId,
        validOnInput,
        validationName,
        validationSubscribe,
        onSelect,
      });

      const search = async (val: string) => setOptions(await searchFunction(val, name));

      return (
        <SearchingSelect
          {...props}
          name={name}
          flagAuto={flagAuto}
          onChange={handleChange}
          error={error}
          disabled={disabled}
          onClear={clearAutoValue}
          onBlur={handleBlur}
          onSelect={handleSelect}
          search={search}
          value={value}
          options={options}
        />
      );
    },
  );
