export const REQUIRED = 'Поле обязательно для заполнения';
export const NEED_FULL_ADDRESS = 'Необходимо ввести полный адрес';
export const NEED_HOUSE_OR_AREA_NUMBER = 'Необходимо ввести номер дома или адрес участка';
export const CANT_BE_EARLIER = ({ min }: { min: Date; max?: Date; value?: Date }) =>
  `Не может быть ранее ${min.toLocaleDateString()}`;

export const CANT_BE_LATER = ({ max }: { min?: Date; max: Date; value?: Date }) =>
  `Не может быть позднее ${max.toLocaleDateString()}`;

export const CANT_BE_LESS = ({ min }: { min: number; max?: number; value?: number }) => `Не может быть меньше ${min}`;

export const CANT_BE_MORE = ({ max }: { min?: number; max: number; value?: number }) => `Не может быть больше ${max}`;

export const INVALID_STRING_FORMAT = `Некорректный формат строки`;

export const INVALID_ADDRESS_FORMAT = `Некорректный формат адреса`;

export const INVALID_DADATA = `Некорректный адрес`;

export const INVALID_BODY_NUM_FORMAT = 'Некорректный номер кузова';

export const INVALID_OBJ_FORMAT = 'Некорректный формат объекта';

export const INVALID_BOOLEAN_FORMAT = 'Некорректный формат (да/нет)';

export const INVALID_CHASSIS_NUM_FORMAT = 'Некорректный номер шасси';

export const INVALID_DATE_FORMAT = 'Некорректный формат даты: ДД.ММ.ГГГГ';

export const INVALID_DOC_NUMBER = 'Некорректный номер документа';
export const INVALID_DOC_SERIAL = 'Некорректная серия документа';
export const INVALID_DOC_NUMBER_SERIAL = 'Некорректная серия и номер';
export const INVALID_SNILS = 'Некорректный номер снилса';

export const INVALID_DOC_TYPE_FORMAT = 'Некорректный тип документа';

export const INVALID_POLICY_SERIAL_FORMAT = 'Некорректный формат серии полиса';
export const INVALID_POLICY_NUMBER_FORMAT = 'Некорректный формат номера полиса';

export const INVALID_PHONE_FORMAT = 'Некорректный формат телефона';
export const INVALID_EMAIL_FORMAT = 'Некорректный формат email';

export const INVALID_FORMAT = ({
  message,
}: {
  message?: string | undefined;
  regexp?: RegExp;
  value: string;
  enum?: string[];
}) => message || 'Некорректный формат';

export const INVALID_LICENSE_PLATE_AUTO = 'Некорректный номер';
export const INVALID_LICENSE_PLATE_BIKE = 'Некорректный номер';

export const INVALID_NUMAN_NAME = 'Некорректное имя';

export const INVALID_NUMBER_FORMAT = 'Некорректный формат числа';

export const INVALID_PASSWORD_NUMBER = 'Некорректный номер паспорта';

export const INVALID_PERSONAL_USAGE_OF_CAR =
  'В настоящее время можно оформить полис только для личных целей (не для аренды, проката и такси)';

export const INVALID_PTS_FORMAT = 'Некорректный формат ПТС';

export const INVALID_STS_FORMAT = 'Некорректный формат СТС';

export const INVALID_ETS_FORMAT = 'Некорректный формат ЭПТС';

export const INVALID_BRAND = 'Некорректная марка';
export const INVALID_MODEL = 'Некорректная модель';

export const INVALID_VIN_FORMAT = 'VIN код содержит 17 символов, только латиницу и числа, проверьте правильность ввода';

export const INVALID_ARRAY_FORMAT = 'Должен быть массивом';

export const INVALID_INT_FORMAT = 'Только целые числа';
