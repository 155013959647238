import type { PartnerCompany } from '../company.types';

export const createDefaultCompaniesList = (): PartnerCompany[] => [
  {
    id: 1,
    prefix: 'alfastrahovanie',
    title: 'АльфаСтрахование',
  },
  {
    id: 5,
    prefix: 'rosgosstrah',
    title: 'Росгосстрах',
  },
  {
    id: 7,
    prefix: 'soglasie',
    title: 'Согласие',
  },
  {
    id: 10,
    prefix: 'maks',
    title: 'МАКС',
  },
  {
    id: 25,
    prefix: 'yugoria',
    title: 'Югория',
  },
  {
    id: 27,
    prefix: 'zurich',
    title: 'Зетта Страхование',
  },
  {
    id: 33,
    prefix: 'vsk',
    title: 'ВСК',
  },
  {
    id: 36,
    prefix: 'renessans',
    title: 'Ренессанс Страхование',
  },
  {
    id: 39,
    prefix: 'intouch',
    title: 'Интач',
  },
  {
    id: 46,
    prefix: 'sogaz',
    title: 'СОГАЗ',
  },
  {
    id: 52,
    prefix: 'gelios_rezerv',
    title: 'Гелиос',
  },
  {
    id: 61,
    prefix: 'osk',
    title: 'ОСК',
  },
  {
    id: 75,
    prefix: 'evro_polis',
    title: 'Абсолют Страхование',
  },
  {
    id: 107,
    prefix: 'tinkoff',
    title: 'Тинькофф Страхование',
  },
  {
    id: 144,
    prefix: 'sber',
    title: 'Сбербанк',
  },
];
