import React, { FC } from 'react';
import cn from 'classnames';
import { useId } from '@core/hooks/useId';
import { BooleanFieldOriginProps } from '@core/common/components/_factories/createBooleanField/types';
import s from './createCheckBoxClean.module.scss';

export type CheckboxCleanProps = BooleanFieldOriginProps;

export type CheckboxCleanStyle = {
  root?: string;
  checkbox?: string;
  content?: string;
};

export const createCheckBoxClean = <T extends CheckboxCleanProps>(style: CheckboxCleanStyle): FC<T> => {
  const cls = {
    root: cn(s.root, style.root),
    checkbox: cn(s.checkbox, style.checkbox),
    content: cn(s.content, style.content),
  };

  return ({ children, className, name, checked, disabled, onCheck }) => {
    const id = useId();
    return (
      <label htmlFor={id} className={cn(cls.root, className)}>
        <input
          id={id}
          type="checkbox"
          disabled={disabled}
          className={cls.checkbox}
          checked={checked}
          name={name}
          onChange={(e) => onCheck(e.target.checked)}
        />
        <span className={cls.content}>{children}</span>
      </label>
    );
  };
};
