export const getValueUrl = (title: string): string | null => {
  const url = new URL(window.location.href);
  return url.searchParams.get(title) || null;
};

export const isLink = (input: unknown): input is string =>
  typeof input === 'string' &&
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/.test(
    input
  );
