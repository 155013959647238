import type { ISteps } from '@core';

import {
  URL_INDEX,
  URL_STEP_CAR_DATA,
  URL_STEP_COMPANY_LINK_PAY,
  URL_STEP_COMPANY_OFFERS,
  URL_STEP_DRIVERS_DATA,
  URL_STEP_OTHER_DATA,
  URL_STEP_OWNER_DATA,
  URL_STEP_PRELIMINARY_CALCULATION,
} from './url';

/* FIXME: Добавить сюда методы для gtm */
export const steps: ISteps = [
  {
    id: 1,
    title: 'Ввод номера',
    link: URL_INDEX,
    showList: true,
    gtmMark: {
      action: 'demo',
    },
    status: 'ALLOWED',
  },

  {
    id: 2,
    title: 'Предварительный расчет',
    link: URL_STEP_PRELIMINARY_CALCULATION,
    showList: true,
    gtmMark: {
      action: 'demo',
    },
    status: 'ALLOWED',
  },
  {
    id: 3,
    title: 'Уточнение данных',
    link: URL_STEP_CAR_DATA,
    showList: true,
    gtmMark: {
      action: 'demo',
    },
    status: 'ALLOWED',
  },
  {
    id: 4,
    title: 'Уточнение данных',
    link: URL_STEP_DRIVERS_DATA,
    showList: false,
    gtmMark: {
      action: 'demo',
    },
    status: 'ALLOWED',
  },
  {
    id: 5,
    title: 'Уточнение данных',
    link: URL_STEP_OWNER_DATA,
    showList: false,
    gtmMark: {
      action: 'demo',
    },
    status: 'ALLOWED',
  },
  {
    id: 6,
    title: 'Уточнение данных',
    link: URL_STEP_OTHER_DATA,
    showList: false,
    gtmMark: {
      action: 'demo',
    },
    status: 'ALLOWED',
  },
  {
    id: 7,
    title: 'Выбор компании',
    link: URL_STEP_COMPANY_OFFERS,
    showList: true,
    gtmMark: {
      action: 'step_8',
    },
    status: 'ALLOWED',
  },
  {
    id: 8,
    title: 'Выбор компании',
    link: URL_STEP_COMPANY_LINK_PAY,
    showList: false,
    gtmMark: {
      action: 'step_9',
    },
    status: 'ALLOWED',
  },
];
