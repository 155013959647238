import { useChangeStep, useStore } from '@core';
import React, { useMemo, useState } from 'react';
import { PolicyOfferUpsale, SelectedUpsales } from '@core/features/upsale/upsale.types';
import { getGroupedUpSales, getTotalSum } from '@core/features/upsale/helpers/helpers';

export const useTableRow = ({
  companyId,
  setActiveOffer,
  price,
  upsaleList,
}: {
  upsaleList?: PolicyOfferUpsale[];
  companyId: string | number;
  price?: number;
  setActiveOffer?: (id: string) => Promise<boolean>;
}) => {
  const store = useStore();
  const { nextStep } = useChangeStep();
  const [selectedUpsales, setSelectedUpsales] = useState<SelectedUpsales | null>({});
  const onCheckUpsale = (checked: boolean, group: string, id: string) => {
    setSelectedUpsales((v) => {
      const res = { ...v };
      if (checked) {
        res[group] = id;
      } else {
        delete res[group];
      }
      return res;
    });
  };

  const groupedUpSales = useMemo(
    () =>
      upsaleList
        ? getGroupedUpSales(
            upsaleList,
            store.calc.upsales.excludeTypes || store.calc.upsales?.[companyId as number]?.excludeTypes || []
          )
        : {},
    [companyId, store.calc.upsales, upsaleList]
  );
  const totalSum = price ? Math.ceil(getTotalSum(Number(price || 0), upsaleList, selectedUpsales)) : 0;

  const onPay = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!setActiveOffer) return;

    e.preventDefault();

    const activeFlag = await setActiveOffer(companyId.toString());
    if (activeFlag) {
      store.calc.selectedUpsales = selectedUpsales as SelectedUpsales;
      if (store.calc.activeOffer) store.calc.activeOffer.upsaleList = upsaleList;
      nextStep({ params: { company: companyId.toString() }, scrollTopFlag: true, updateFlag: false });
    }
  };

  return {
    selectedUpsales,
    totalSum,
    groupedUpSales,
    onCheckUpsale,
    onPay,
  };
};
