import { http } from '@utils/http/http';
import {
  ApiGetPreliminaryCostProps,
  ApiGetPreliminaryCostReturn,
  ApiSetOrderTrackProps,
  ApiSetOrderTrackReturn,
  ApiUpdateOrderProps,
  ApiUpdateOrderReturn,
  ApiSetOrderProps,
  ApiSetOrderReturn,
  OffersOrderProps,
  OffersOrderData,
  LinkOffersOrderData,
  ApiGetOrderKeyByHKProp,
  ApiGetOrderKeyByHKPropReturn,
} from '../../typings/order.typings';

import {
  URL_ORDER_TRACK,
  URL_ORDER,
  URL_PRELIMINARY_COST,
  URL_ORDER_UPDATE,
  URL_OFFERS_ORDER,
  URL_LINK_OFFER_ORDER,
  URL_AUTH,
} from '../../constants/api';
import type { PolicyOfferPayLinkInput } from '@core/features/offer/offer.types';
import { ApiGetOrderKeyByEKProp, ApiGetOrderKeyByEKPropReturn } from '@core/typings/order.typings';
import { HttpProperties } from '@core/typings/http.typings';

/**
 * Запрос предварительного расчёта, возвращает диапазон стоимости
 * @param props {ApiGetPreliminaryCostProps}
 * @param options {HttpProperties}
 * @returns Promise<ApiGetPreliminaryCostReturn>
 */
export const apiGetPreliminaryCost = async (
  props: ApiGetPreliminaryCostProps,
  options?: Omit<HttpProperties, 'url'>
): Promise<ApiGetPreliminaryCostReturn> =>
  await http.post({
    ...(options || {}),
    url: URL_PRELIMINARY_COST,
    data: {
      ...props,
    },
  });

/**
 * Запрос ?
 * @param props {ApiSetOrderTrackProps}
 * @returns Promise<string>
 */
export const apiSetOrderTrack = async (props: ApiSetOrderTrackProps): Promise<ApiSetOrderTrackReturn> =>
  await http.post({
    url: URL_ORDER_TRACK,
    data: {
      ...props,
    },
  });

/**
 * Запрос на обновление данных для order (при изменении значений формы)
 * @param props {ApiUpdateOrderProps}
 * @returns Promise<ApiUpdateOrderReturn>
 */
export const apiUpdateOrder = async (props: ApiUpdateOrderProps): Promise<ApiUpdateOrderReturn> =>
  await http.post({
    url: URL_ORDER_UPDATE,
    data: {
      ...props,
    },
  });

/**
 * Запрос ?
 * @param props {ApiSetOrderProps}
 * @returns Promise<ApiSetOrderReturn>
 */
export const apiSetOrder = async (props: ApiSetOrderProps): Promise<ApiSetOrderReturn> =>
  await http.post({
    url: URL_ORDER,
    data: {
      ...props,
    },
  });

/**
 * Запрос на получение offers от конкретной компании
 * @param props {OffersOrderProps}
 * @returns Promise<OffersOrderData>
 */
export const apiOffersByIdSk = async (props: OffersOrderProps): Promise<OffersOrderData> =>
  await http.post({
    url: URL_OFFERS_ORDER,
    data: {
      ...props,
    },
  });

/**
 * Запрос на получение payLink по конкретному offer (`eId`).
 * @param props {LinkOffersOrderProps}
 * @returns Promise<{results: string}>
 */

export const apiLinkOffersByIdSk = async (props: PolicyOfferPayLinkInput): Promise<LinkOffersOrderData> => {
  const { upsales, ...rest } = props;
  return await http.get({
    url: URL_LINK_OFFER_ORDER + (upsales || ''),
    params: {
      ...rest,
    },
  });
};

/**
 * Запрос `order_key`
 * @param params {ApiGetOrderKeyByEKProp}
 * @returns Promise<ApiGetTokenPropReturn>
 */
export const apiGetOrderKeyByEK = async (params: ApiGetOrderKeyByEKProp): Promise<ApiGetOrderKeyByEKPropReturn> =>
  await http.get({
    url: URL_AUTH,
    params,
  });

/**
 * Запрос `order_key`
 * @param params {ApiGetOrderKeyByEKProp}
 * @returns Promise<ApiGetTokenPropReturn>
 */
export const apiGetOrderKeyByHK = async (params: ApiGetOrderKeyByHKProp): Promise<ApiGetOrderKeyByHKPropReturn> =>
  await http.get({
    url: URL_AUTH,
    params,
  });
