import React from 'react';
import { ISteps, useHistoryOrder } from '@core';
import 'swiper/scss';
import cn from 'classnames';
import { getFilteredDrafts } from '@utils/getFilteredDrafts';
import { createHistoryItem, HistoryItemProps, HistoryItemStyle } from './createHistoryItem';
import s from './createHistory.module.scss';

export type { HistoryItemStyle };

export type HistoryProps = {
  className?: string;
};

export type HistoryStyle = {
  root: string;
  title: string;
  main: string;
  item: string;
};

export const createHistory = <T extends HistoryProps>(
  {
    title = 'История расчетов',
    steps,
    limit = 3,
    minStep,
    maxStep,
    HistoryItem,
  }: {
    title?: React.ReactNode;
    limit?: number;
    steps: ISteps;
    minStep: number;
    maxStep: number;
    HistoryItem: React.FC<HistoryItemProps>;
  },
  style?: HistoryStyle
): React.FC<T> => {
  const getCorrectStep = (step: number) => {
    if (!step) return minStep;
    if (step < minStep) return minStep;
    if (step > maxStep) return maxStep;
    return step;
  };

  const cls = {
    root: cn(s.root, style?.root),
    main: cn(s.main, style?.main),
    title: cn(s.title, style?.title),
    item: cn(s.item, style?.item),
  };

  return ({ className }) => {
    const { drafts, initHistory } = useHistoryOrder();

    if (!drafts.length) return null;

    const filteredDrafts = getFilteredDrafts(drafts, limit).map(
      ({ index, step, carName, licensePlate, brand, model }) => {
        const correct = getCorrectStep(step);
        const { link, title } = steps[correct];
        return (
          <HistoryItem
            className={cls.item}
            key={index}
            carName={carName}
            brand={brand}
            model={model}
            licensePlate={licensePlate}
            onClick={() => initHistory({ index, step: correct, title, searchUrl: link })}
          />
        );
      }
    );

    if (!filteredDrafts.length) return null;

    return (
      <div className={cn(cls.root, className)}>
        <h2 className={cls.title}>{title}</h2>
        <div className={cls.main}>{filteredDrafts}</div>
      </div>
    );
  };
};
