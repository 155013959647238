import type { PartnerCompany } from '@core/features/company/company.types';
import { OffersMap } from '@core/typings/order.typings';
import { useStore } from '../providers/StoreProvider';

interface IUseEnabledPartners {
  enabledInsuranceCompanies: PartnerCompany[];
  interestedCompanies: PartnerCompany[];
  // disablePartners: (companies: number[]) => void;
}

export const usePartners = (): IUseEnabledPartners => {
  const store = useStore();
  const enabledInsuranceCompanies = store?.partner.enabledInsuranceCompanies || [];

  const { value } = store?.form.companiesModelsField || {};

  // Интересуемые компании. Партнеры, за исключением тех, у которых стоит галочка "не интересует эта компания", и тех, у кого недостаточно данных
  const interestedCompanies = enabledInsuranceCompanies.filter((i) => {
    if (!(i.id in value.values)) return true;
    return !value.values[i.id].exclude && value.values[i.id].brand && value.values[i.id].model;
  });

  return { enabledInsuranceCompanies, interestedCompanies };
};

interface UseGroupedPartnersOutput {
  okCompanies: PartnerCompany[];
  notOkCompanies: PartnerCompany[];
  loadingCompanies: PartnerCompany[];
}

export const useGroupedPartners = (offers: OffersMap | null, companyOwnerId?: number): UseGroupedPartnersOutput => {
  const { interestedCompanies } = usePartners();

  const okCompanies: PartnerCompany[] = [];
  const okOwnerCompanies: PartnerCompany[] = [];
  const notOkCompanies: PartnerCompany[] = [];
  const loadingCompanies: PartnerCompany[] = [];

  for (let i = 0; i < interestedCompanies.length; i++) {
    const comp = interestedCompanies[i];
    const offer = offers?.[comp.id];
    if (offer?.errors?.length) {
      notOkCompanies.push(comp);
    } else if (offer?.results?.length) {
      if (companyOwnerId == comp.id) okOwnerCompanies.push(comp);
      else okCompanies.push(comp);
    } else {
      loadingCompanies.push(comp);
    }
  }

  return {
    okCompanies: [...okOwnerCompanies, ...okCompanies],
    notOkCompanies,
    loadingCompanies,
  };
};
