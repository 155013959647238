export const regions = [
  {
    name: 'Республика Адыгея',
    codes: ['01'],
  },
  {
    name: 'Республика Башкортостан',
    codes: ['02', '102', '702'],
  },
  {
    name: 'Республика Бурятия',
    codes: ['03'],
  },
  {
    name: 'Республика Алтай',
    codes: ['04'],
  },
  {
    name: 'Республика Дагестан',
    codes: ['05'],
  },
  {
    name: 'Республика Ингушетия',
    codes: ['06'],
  },
  {
    name: 'Кабардино-Балкарская Республика',
    codes: ['07'],
  },
  {
    name: 'Республика Калмыкия',
    codes: ['08'],
  },
  {
    name: 'Республика Карачаево-Черкесия',
    codes: ['09'],
  },
  {
    name: 'Республика Карелия',
    codes: ['10'],
  },
  {
    name: 'Республика Коми',
    codes: ['11'],
  },
  {
    name: 'Республика Марий Эл',
    codes: ['12'],
  },
  {
    name: 'Республика Мордовия',
    codes: ['13', '113'],
  },
  {
    name: 'Республика Саха (Якутия)',
    codes: ['14'],
  },
  {
    name: 'Республика Северная Осетия — Алания',
    codes: ['15'],
  },
  {
    name: 'Республика Татарстан',
    codes: ['16', '116', '716'],
  },
  {
    name: 'Республика Тыва',
    codes: ['17'],
  },
  {
    name: 'Удмуртская Республика',
    codes: ['18'],
  },
  {
    name: 'Республика Хакасия',
    codes: ['19'],
  },
  {
    name: 'Чувашская Республика',
    codes: ['21', '121'],
  },
  {
    name: 'Алтайский край',
    codes: ['22', '122'],
  },
  {
    name: 'Краснодарский край',
    codes: ['23', '93', '123', '193'],
  },
  {
    name: 'Красноярский край',
    codes: ['24', '84', '88', '124'],
  },
  {
    name: 'Приморский край',
    codes: ['25', '125'],
  },
  {
    name: 'Ставропольский край',
    codes: ['26', '126'],
  },
  {
    name: 'Хабаровский край',
    codes: ['27', '127'],
  },
  {
    name: 'Амурская область',
    codes: ['28'],
  },
  {
    name: 'Архангельская область',
    codes: ['29'],
  },
  {
    name: 'Астраханская область',
    codes: ['30'],
  },
  {
    name: 'Белгородская область',
    codes: ['31'],
  },
  {
    name: 'Брянская область',
    codes: ['32'],
  },
  {
    name: 'Владимирская область',
    codes: ['33'],
  },
  {
    name: 'Волгоградская область',
    codes: ['34', '134'],
  },
  {
    name: 'Вологодская область',
    codes: ['35'],
  },
  {
    name: 'Воронежская область',
    codes: ['36', '136'],
  },
  {
    name: 'Ивановская область',
    codes: ['37'],
  },
  {
    name: 'Иркутская область',
    codes: ['38', '85', '138'],
  },
  {
    name: 'Калининградская область',
    codes: ['39', '91'],
  },
  {
    name: 'Калужская область',
    codes: ['40'],
  },
  {
    name: 'Камчатский край',
    codes: ['41', '82'],
  },
  {
    name: 'Кемеровская область',
    codes: ['42', '142'],
  },
  {
    name: 'Кировская область',
    codes: ['43'],
  },
  {
    name: 'Костромская область',
    codes: ['44'],
  },
  {
    name: 'Курганская область',
    codes: ['45'],
  },
  {
    name: 'Курская область',
    codes: ['46'],
  },
  {
    name: 'Ленинградская область',
    codes: ['47', '147'],
  },
  {
    name: 'Липецкая область',
    codes: ['48'],
  },
  {
    name: 'Магаданская область',
    codes: ['49'],
  },
  {
    name: 'Московская область',
    codes: ['50', '90', '150', '190', '750', '790'],
  },
  {
    name: 'Мурманская область',
    codes: ['51'],
  },
  {
    name: 'Нижегородская область',
    codes: ['52', '152'],
  },
  {
    name: 'Новгородская область',
    codes: ['53'],
  },
  {
    name: 'Новосибирская область',
    codes: ['54', '154'],
  },
  {
    name: 'Омская область',
    codes: ['55'],
  },
  {
    name: 'Оренбургская область',
    codes: ['56', '156'],
  },
  {
    name: 'Орловская область',
    codes: ['57'],
  },
  {
    name: 'Пензенская область',
    codes: ['58'],
  },
  {
    name: 'Пермский край',
    codes: ['59', '81', '159'],
  },
  {
    name: 'Псковская область',
    codes: ['60'],
  },
  {
    name: 'Ростовская область',
    codes: ['61', '161', '761'],
  },
  {
    name: 'Рязанская область',
    codes: ['62'],
  },
  {
    name: 'Самарская область',
    codes: ['63', '163', '763'],
  },
  {
    name: 'Саратовская область',
    codes: ['64', '164'],
  },
  {
    name: 'Сахалинская область',
    codes: ['65'],
  },
  {
    name: 'Свердловская область',
    codes: ['66', '96', '196'],
  },
  {
    name: 'Смоленская область',
    codes: ['67'],
  },
  {
    name: 'Тамбовская область',
    codes: ['68'],
  },
  {
    name: 'Тверская область',
    codes: ['69', '169'],
  },
  {
    name: 'Томская область',
    codes: ['70'],
  },
  {
    name: 'Тульская область',
    codes: ['71'],
  },
  {
    name: 'Тюменская область',
    codes: ['72'],
  },
  {
    name: 'Ульяновская область',
    codes: ['73', '173'],
  },
  {
    name: 'Челябинская область',
    codes: ['74', '174', '774'],
  },
  {
    name: 'Забайкальский край',
    codes: ['75', '80'],
  },
  {
    name: 'Ярославская область',
    codes: ['76'],
  },
  {
    name: 'Москва',
    codes: ['77', '97', '99', '177', '199', '197', '777', '797', '799'],
  },
  {
    name: 'Санкт-Петербург',
    codes: ['78', '98', '178', '198'],
  },
  {
    name: 'Еврейская автономная область',
    codes: ['79'],
  },
  {
    name: 'Республика Крым',
    codes: ['82'],
  },
  {
    name: 'Ненецкий автономный округ',
    codes: ['83'],
  },
  {
    name: 'Ханты-Мансийский автономный округ Югра',
    codes: ['86', '186'],
  },
  {
    name: 'Чукотский автономный округ',
    codes: ['87'],
  },
  {
    name: 'Ямало-Ненецкий автономный округ',
    codes: ['89'],
  },
  {
    name: 'Севастополь',
    codes: ['92'],
  },
  {
    name: 'Байконур',
    codes: ['94'],
  },
  {
    name: 'Чеченская республика',
    codes: ['20', '95'],
  },
];
