import { ValidationFn, NumberOptions } from '../common/types';
import { intRule, stringRule } from './sharedRules';
import { intRegexp, MAX_POWER, MIN_POWER } from '../common/constants';

export const powerRule =
  (options?: NumberOptions): ValidationFn<string | number> =>
  (value, deps, params) => {
    if (params?.type === 'soft') {
      if (typeof value === 'string') {
        return stringRule({ regexp: intRegexp })(value, deps, { ...params, type: 'hard' });
      }
      return null;
    }

    const correct = value && typeof value === 'string' ? parseInt(value, 10) : value;
    return intRule({ ...(options || {}), min: MIN_POWER, max: MAX_POWER })(correct as number, deps, params);
  };
