import React from 'react';
import cn from 'classnames';
import s from './createPlaceholder.module.scss';

export type PlaceholderProps = { placeholder?: string; active: boolean; error?: boolean };

export type PlaceholderStyle = {
  root: string;
  active: string;
  error: string;
};

export const createPlaceholder = (style: PlaceholderStyle): React.FC<PlaceholderProps> => {
  const cls = {
    root: cn(s.root, style?.root),
    active: cn(s.active, style?.active),
    error: cn(s.error, style?.error),
  };

  return ({ placeholder = null, active = false, error }) => {
    if (!placeholder) return null;

    return (
      <span className={cn(cls.root, active && cls.active, error && cls.error)} aria-label={placeholder}>
        {placeholder}
      </span>
    );
  };
};
