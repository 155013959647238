import React, { FC } from 'react';

import cn from 'classnames';

import s from './Container.module.scss';

export type ContainerProps = React.HTMLAttributes<HTMLDivElement>;

export const Container: FC<ContainerProps> = ({ className, ...props }) => (
  <div {...props} className={cn(s.root, className)} />
);
