import React, { createContext, FC, useContext } from 'react';
import {
  Validator,
  ValidationFn,
  parseDate,
  vinStrictRegexp,
  vinStrictRegexpSoft,
  policyNumberStrictRegexp,
  policyNumberStrictRegexpSoft,
  cityDadataRule,
  brandRule,
  modelRule,
  addressRule,
  bodyNumRule,
  chassisNumRule,
  docNumberETSRule,
  docNumberPTSRule,
  docNumberSTSRule,
  licensePlateRule,
  powerRule,
  stringRule,
  vinRule,
  yearRule,
  docDateRule,
  humanNameRule,
  birthdateRule,
  licenseNumberForeignRule,
  licenseSerialForeignRule,
  docSerialNumberRule,
  dateRule,
  policyNumberRule,
  phoneRule,
  emailRule,
  licensePlateAutoRule,
  licensePlateBikeRule,
  dadataRule,
  MIN_AGE,
  MIN_BIRTHDAY_DATE,
  snilsRule,
  intRule,
} from '../../../validator';
import { companiesModelsRule, driversRule, driversRules } from './rules';
import {
  CAR_TYPE,
  LICENSE_FOREIGN_LIST,
  DOC_TYPE_LIST,
  VEHICLE_DOC_LIST,
  VEHICLE_PURPOSE_LIST,
  POLICY_SERIAL_LIST,
  PASSPORT_FOREIGN_LIST,
  PASSPORT_FOREIGN_RENE_LIST,
  PERIOD_OF_USE,
  isTruck,
  isBus,
} from '../../constants/calculator-const';

export type ValidatorProviderProps = {
  children: React.ReactNode;
};

export const validator = new Validator({
  rules: {
    stoa: (() => null) as ValidationFn<string>,
    personalUsageOfCar: (() => null) as ValidationFn<string>,
    multidrive: (() => null) as ValidationFn<number>,
    enablePeriod: (() => null) as ValidationFn<boolean>,
    expYearOnly: (() => null) as ValidationFn<boolean>,
    hasPrevPolicy: (() => null) as ValidationFn<boolean>,
    useTrailer: (() => null) as ValidationFn<boolean>,
    prevLicense: (() => null) as ValidationFn<boolean>,
    insurerIsOwner: (() => null) as ValidationFn<boolean>,
    licenseForeign: stringRule({ required: true, asString: true, enum: LICENSE_FOREIGN_LIST.map((i) => i.value) }),
    docType: stringRule({ required: true, enum: DOC_TYPE_LIST.map((i) => i.value), asString: true }) as ValidationFn<
      string | number
    >,
    type: stringRule({ required: true, asString: true, enum: CAR_TYPE.map((i) => i.value) }),

    vehicleDoc: stringRule({ required: true, enum: VEHICLE_DOC_LIST.map((i) => i.value) }),
    purpose: stringRule({ required: true, enum: VEHICLE_PURPOSE_LIST.map((i) => i.value) }),
    licensePlate: licensePlateRule() as ValidationFn<string>,
    licensePlateAuto: licensePlateAutoRule(),
    licensePlateBike: licensePlateBikeRule(),
    brand: brandRule({ required: true }),
    model: modelRule({ required: true }),
    year: yearRule({ required: true }),
    usePeriod: stringRule({
      isSkipped: ({ defaultIsSkipped, deps }) => !deps?.enablePeriod && !!defaultIsSkipped?.(),
      required: true,
      asString: true,
      enum: PERIOD_OF_USE.map((i) => i.value),
    }),
    power: powerRule({ required: true }),
    vin: vinRule({
      required: true,
      getConditionalOptions: ({ defaultOptions, deps, params }) => {
        if (deps?.unmask_id) return defaultOptions;
        return {
          ...defaultOptions,
          regexp: params?.type === 'soft' ? vinStrictRegexpSoft : vinStrictRegexp,
        };
      },
    }),
    bodyNum: bodyNumRule({ required: true }),
    chassisNum: chassisNumRule({ required: true }),
    docNumberPTS: docNumberPTSRule({ required: true }),
    docNumberSTS: docNumberSTSRule({ required: true }),
    docNumberETS: docNumberETSRule({ required: true }),
    docDate: docDateRule({
      required: true,
      getConditionalOptions: ({ deps, defaultOptions }) => {
        if (!deps?.year || (typeof deps.year !== 'string' && typeof deps.year !== 'number')) return defaultOptions;
        const year = typeof deps.year === 'number' ? deps?.year : parseInt(deps?.year, 10);

        return {
          ...defaultOptions,
          min: new Date(new Date().setFullYear(year, 0, 1)),
        };
      },
    }),

    ...driversRules,

    drivers: driversRule({ required: true }),
    owner_legalStatus: (() => null) as ValidationFn<string>,
    owner_driverSelect: (() => null) as ValidationFn<boolean>,
    owner_passportForeign: stringRule({
      required: true,
      asString: true,
      enum: PASSPORT_FOREIGN_RENE_LIST.map((i) => i.value),
    }),
    owner_city: addressRule({ required: true }),
    owner_city_dadata: cityDadataRule({ required: true, keyOfStringValue: 'owner_city' }),
    owner_lastname: humanNameRule({ required: true }),
    owner_firstname: humanNameRule({ required: true }),
    owner_middlename: humanNameRule(),
    owner_birthdate: birthdateRule({ required: true }),
    owner_passportNumber: docSerialNumberRule({
      required: true,
    }),
    owner_passportNumberForeign0: licenseNumberForeignRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => {
        const licenseForeign = deps?.owner_passportForeign as string;

        if (licenseForeign !== undefined && licenseForeign != '1') return true;
        return !!defaultIsSkipped?.();
      },
    }),
    owner_passportSerialForeign0: licenseSerialForeignRule({
      isSkipped: ({ deps, defaultIsSkipped }) => {
        const licenseForeign = deps?.owner_passportForeign as string;

        if (licenseForeign !== undefined && licenseForeign != '1') return true;
        return !!defaultIsSkipped?.();
      },
    }),
    owner_passportDate: docDateRule({
      required: true,
      getConditionalOptions: ({ defaultOptions, currentDeps }) => {
        const birthdate = currentDeps?.owner_birthdate as string;
        let min: Date;
        try {
          min = parseDate(birthdate);
          min.setFullYear(min.getFullYear() + MIN_AGE);
        } catch (e) {
          min = MIN_BIRTHDAY_DATE;
        }

        return { ...defaultOptions, min };
      },
    }),
    owner_address_dadata: dadataRule({ required: true }),
    owner_inn: stringRule({
      required: true,
      getConditionalOptions: ({ defaultOptions, deps }) => {
        const isIP = deps?.owner_legalStatus == '2';
        return {
          ...(defaultOptions || {}),
          message: `ИНН должен состоять из ${isIP ? '12' : '10'} цифр`,
          regexp: isIP ? /^\d{12}$/ : /^\d{10}$/,
        };
      },
    }),
    owner_ogrnip: stringRule({ required: true, message: `ОГРНИП должен состоять из 15 цифр`, regexp: /^\d{15}$/ }),
    owner_kpp: stringRule({ required: true, message: `КПП должен состоять из 9 цифр`, regexp: /^\d{9}$/ }),
    owner_form: (value, deps, params) =>
      stringRule({
        required: params?.type !== 'soft',
        regexp: /^[а-яё]*$/i,
      })(value as string, deps, { ...(params || {}), type: 'hard' }),
    owner_name: stringRule({ required: true }),
    owner_docSerial: (value, deps, params) =>
      stringRule({
        required: params?.type !== 'soft',
        regexp: /^[а-яёa-z\d-]*$/i,
      })(value as string, deps, { ...(params || {}), type: 'hard' }),
    owner_docNumber: (value, deps, params) =>
      stringRule({
        required: params?.type !== 'soft',
        regexp: /^\d*$/i,
      })(value as string, deps, { ...(params || {}), type: 'hard' }),
    owner_docDate: docDateRule({
      required: true,
    }),

    policyholder_legalStatus: (() => null) as ValidationFn<string>,
    policyholder_driverSelect: (() => null) as ValidationFn<boolean>,
    policyholder_passportForeign: stringRule({
      required: true,
      asString: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
      enum: PASSPORT_FOREIGN_RENE_LIST.map((i) => i.value),
    }),
    policyholder_city: addressRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_city_dadata: cityDadataRule({
      required: true,
      keyOfStringValue: 'policyholder_city',
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_lastname: humanNameRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_firstname: humanNameRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_middlename: humanNameRule({
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_birthdate: birthdateRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_passportNumber: docSerialNumberRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => {
        const licenseForeign = deps?.policyholder_passportForeign as string;

        if ((licenseForeign !== undefined && licenseForeign != '0') || !!deps?.insurerIsOwner) return true;
        return !!defaultIsSkipped?.();
      },
    }),
    policyholder_passportNumberForeign0: licenseNumberForeignRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => {
        const licenseForeign = deps?.policyholder_passportForeign as string;

        if ((licenseForeign !== undefined && licenseForeign != '1') || !!deps?.insurerIsOwner) return true;
        return !!defaultIsSkipped?.();
      },
    }),
    policyholder_passportSerialForeign0: licenseSerialForeignRule({
      isSkipped: ({ deps, defaultIsSkipped }) => {
        const licenseForeign = deps?.policyholder_passportForeign as string;

        if ((licenseForeign !== undefined && licenseForeign != '1') || !!deps?.insurerIsOwner) return true;
        return !!defaultIsSkipped?.();
      },
    }),
    policyholder_passportDate: docDateRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
      getConditionalOptions: ({ defaultOptions, currentDeps }) => {
        const birthdate = currentDeps?.policyholder_birthdate as string;
        let min: Date;
        try {
          min = parseDate(birthdate);
          min.setFullYear(min.getFullYear() + MIN_AGE);
        } catch (e) {
          min = MIN_BIRTHDAY_DATE;
        }

        return { ...defaultOptions, min };
      },
    }),
    policyholder_address_dadata: dadataRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_inn: stringRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
      getConditionalOptions: ({ defaultOptions, deps }) => {
        const isIP = deps?.policyholder_legalStatus == '2';
        return {
          ...(defaultOptions || {}),
          message: `ИНН должен состоять из ${isIP ? '12' : '10'} цифр`,
          regexp: isIP ? /^\d{12}$/ : /^\d{10}$/,
        };
      },
    }),
    policyholder_ogrnip: stringRule({
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
      required: true,
      message: `ОГРНИП должен состоять из 15 цифр`,
      regexp: /^\d{15}$/,
    }),
    policyholder_kpp: stringRule({
      required: true,
      message: `КПП должен состоять из 9 цифр`,
      regexp: /^\d{9}$/,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_form: (value, deps, params) =>
      stringRule({
        isSkipped: ({ deps: _deps, defaultIsSkipped }) => !!_deps?.insurerIsOwner || !!defaultIsSkipped?.(),
        required: params?.type !== 'soft',
        regexp: /^[а-яё]*$/i,
      })(value as string, deps, { ...(params || {}), type: 'hard' }),
    policyholder_name: stringRule({
      required: true,
      isSkipped: ({ deps, defaultIsSkipped }) => !!deps?.insurerIsOwner || !!defaultIsSkipped?.(),
    }),
    policyholder_docSerial: (value, deps, params) =>
      stringRule({
        isSkipped: ({ deps: _deps, defaultIsSkipped }) => !!_deps?.insurerIsOwner || !!defaultIsSkipped?.(),
        required: params?.type !== 'soft',
        regexp: /^[а-яёa-z\d-]*$/i,
      })(value as string, deps, { ...(params || {}), type: 'hard' }),
    policyholder_docNumber: (value, deps, params) =>
      stringRule({
        isSkipped: ({ deps: _deps, defaultIsSkipped }) => !!_deps?.insurerIsOwner || !!defaultIsSkipped?.(),
        required: params?.type !== 'soft',
        regexp: /^\d*$/i,
      })(value as string, deps, { ...(params || {}), type: 'hard' }),
    policyholder_docDate: docDateRule({
      required: true,
    }),

    date: dateRule({
      required: true,
      min: new Date(new Date().setDate(new Date().getDate() + 1)),
      max: new Date(new Date().setMonth(new Date().getMonth() + 2)),
    }),

    prevPolicySerial: stringRule({
      required: true,
      enum: POLICY_SERIAL_LIST.map((i) => i.value),
      isSkipped: ({ deps, defaultIsSkipped }) => deps?.hasPrevPolicy != '1' || !!defaultIsSkipped?.(),
    }),

    prevPolicyNumber: policyNumberRule({
      required: true,
      getConditionalOptions: ({ defaultOptions, deps, params }) => {
        if (deps?.prevPolicyUnmaskId) return defaultOptions;
        return {
          ...defaultOptions,
          regexp: params?.type === 'soft' ? policyNumberStrictRegexpSoft : policyNumberStrictRegexp,
        };
      },
      isSkipped: ({ deps, defaultIsSkipped }) => deps?.hasPrevPolicy != '1' || !!defaultIsSkipped?.(),
    }),

    weight: intRule({
      required: true,
      min: 1,
      isSkipped: ({ deps, defaultIsSkipped }) => !isTruck(String(deps?.type)) || !!defaultIsSkipped?.(),
    }),

    maxWeight: intRule({
      required: true,
      min: 1,
      isSkipped: ({ deps, defaultIsSkipped }) => !isTruck(String(deps?.type)) || !!defaultIsSkipped?.(),
    }),

    seats: intRule({
      required: true,
      min: 1,
      isSkipped: ({ deps, defaultIsSkipped }) => !isBus(String(deps?.type)) || !!defaultIsSkipped?.(),
    }),

    phone: phoneRule({
      required: true,
    }),
    email: emailRule({
      required: true,
    }),
    promoCode: stringRule(),
    companiesModels: companiesModelsRule(),

    snilsOwner: snilsRule(),
    snilsPolicyholder: snilsRule(),

    brandCompaniesModels: brandRule({
      required: true,
      isSkipped: ({ currentDeps, defaultIsSkipped }) => !!currentDeps?.exclude || !!defaultIsSkipped?.(),
    }),
    modelCompaniesModels: modelRule({
      required: true,
      isSkipped: ({ currentDeps, defaultIsSkipped }) => !!currentDeps?.exclude || !!defaultIsSkipped?.(),
    }),
  },
  logger: (type, info) => {
    if (type === 'rule-not-found' && info.key.includes('_dadata')) return;
    console.error(type, info); // eslint-disable-line no-console
  },
});

export const ValidatorContext = createContext(validator);

export const useValidator = () => useContext(ValidatorContext);

export const ValidatorProvider: FC<ValidatorProviderProps> = ({ children }) => (
  <ValidatorContext.Provider value={validator}>{children}</ValidatorContext.Provider>
);
