import React from 'react';
import classNames from 'classnames';
import arrow from '../../../../../assets/images/icons/arrow.svg';
import s from './SelectIcon.module.scss';

export const SelectIcon: React.FC<{ view: boolean; open: boolean }> = ({ view, open }) => {
  if (!view) {
    return null;
  }

  return (
    <span className={classNames(s.selectIconWrapper, { [s.listIconActive]: open })}>
      <img className={classNames(s.listIcon)} src={arrow} alt="<" />
    </span>
  );
};
