import React from 'react';
import cn from 'classnames';
import s from './Skeleton.module.scss';

type SkeletonBaseProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
};

export const Skeleton: React.FC<SkeletonBaseProps> = ({ className, ...props }) => (
  <div {...props} className={cn(s.root, className)} />
);
