import {
  Obj,
  ObjFeedback,
  Options,
  StructureOptions,
  ValidateOptions,
  ValidationFn,
  ValidationResult,
  ValidationRules,
} from '../../common/types';
import { objFeedback } from '../../common/feedbacks';
import { validate } from '../../features/methods';

export const objRule =
  (options?: StructureOptions<ObjFeedback>) =>
  <T extends Obj>(rules: ValidationRules<T>): ValidationFn<T> =>
  (data: T | null, deps, params): ValidationResult | null => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    const skip = isSkipped({
      ...params,
      deps,
      defaultIsSkipped,
    });
    if (skip) return null;

    const {
      required,
      checkStructure,
      feedback = {},
    } = options?.getConditionalOptions?.({
      deps,
      params,
      currentDeps: params?.currentDeps || deps,
      defaultOptions: options,
    }) ||
    options ||
    {};
    const _feedback = { ...objFeedback, ...feedback };

    if (!data) return required ? _feedback.REQUIRED : null;
    if (typeof data !== 'object') return _feedback.INVALID_OBJ_FORMAT;

    const { changeParams } = params || {};
    const _changeParams = changeParams || (({ defaultParams }) => defaultParams);

    const result = validate(
      data,
      _changeParams({
        value: data,
        deps,
        defaultParams: {
          ...params,
          currentDeps: params?.currentDeps || data,
          checkStructure,
          rules,
          deps: deps as T,
        } as Options<unknown>,
      }) as Omit<ValidateOptions<T>, 'onlySubscribers'>
    );

    if (options?.prepareValidationResult) return options.prepareValidationResult(result);
    return result;
  };
