import { useStore } from '@core';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useEvent } from '@core/hooks/useEvent';
import { licencePlateAutoMask, licencePlateBikeMask } from '../../masks';

export const LIC_KEY = 'lic';

export const useLicencePlateFromUrl = (): { licencePlate: string } | null => {
  const [search] = useSearchParams();
  const value = search.get(LIC_KEY);
  if (!value) return null;
  const licencePlate = decodeURI(value).replace(/\s/g, '');
  if (!licencePlate) return null;

  if (licencePlateAutoMask.isMask(licencePlate) || licencePlateBikeMask.isMask(licencePlate)) {
    return { licencePlate };
  }
  return null;
};

export const useLicencePlateUrl = () => {
  const store = useStore();
  const [search, setSearch] = useSearchParams();

  const searchCopy = useRef(search);
  searchCopy.current = search;

  const licencePlate = store.form.fields.licensePlate.value?.replace(/\s/g, '');

  const changeLicencePlate = useEvent((value: string) => {
    store.form.fields.licensePlate.value = value;
  });

  const init = useRef(false);

  useEffect(() => {
    if (init.current) {
      if (licencePlate) searchCopy.current.set(LIC_KEY, encodeURI(licencePlate));
      else searchCopy.current.delete(LIC_KEY);
      setSearch(searchCopy.current);
    } else {
      const value = searchCopy.current.get(LIC_KEY);
      if (value) changeLicencePlate(decodeURI(value));
    }
    init.current = true;
  }, [licencePlate, setSearch, changeLicencePlate]);
};
