import React, { FC } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { monthList } from './helpers';
import s from './createCalendarHeader.module.scss';

export type CalendarHeaderProps = {
  className?: string;
  date: Date;
  onBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onNext: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export type CalendarHeaderStyle = {
  root: string;
  title: string;
  btn: string;
  left: string;
  right: string;
};

export const createCalendarHeader = (style: CalendarHeaderStyle): FC<CalendarHeaderProps> => {
  const cls = {
    root: cn(s.root, style.root),
    title: cn(s.title, style.title),
    btn: cn(s.btn, style.btn),
    left: cn(s.left, style.left),
    right: cn(s.right, style.right),
  };

  return ({ className, date, onBack, onNext }) => {
    const title = Number.isNaN(date.getTime()) ? (
      <div>Некорректная дата</div>
    ) : (
      <div className={cls.title}>{`${monthList[+format(date, 'M') - 1]} ${format(date, 'yyyy')}`}</div>
    );
    return (
      <div className={cn(cls.root, className)}>
        <button type="button" className={cn(cls.btn, cls.left)} onClick={onBack} />
        {title}
        <button type="button" className={cn(cls.btn, cls.right)} onClick={onNext} />
      </div>
    );
  };
};
