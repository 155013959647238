import { useFetchCompaniesModelsByBrandModel } from '@core/hooks/useFetchCompaniesModelsByBrandModel';
import { useEvent } from '@core/hooks/useEvent';
import { useStore } from '@core';

export const useCompaniesModelsByModelId = () => {
  const store = useStore();

  useFetchCompaniesModelsByBrandModel(
    store.form.fields.modelId.value,
    useEvent(({ companiesModels }) => {
      store.form.companiesModelsField.setCompaniesModels(companiesModels, store.partner.enabledInsuranceCompanies, {
        brand: store.form.fields.brand.value,
        model: store.form.fields.model.value as string,
      });
    })
  );
};
