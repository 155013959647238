import { http } from '@utils/http/http';
import { IApiDadataProps, IApiDadataReturn } from '../../typings/dadata.typings';
import { URL_DADATA } from '../../constants/api';

/**
 * Запрос предлагаемых адресов.
 * @param props {IApiDadataProps}
 * @returns Promise<IApiDadataReturn>
 */
export const apiGetDadata = async (props: IApiDadataProps): Promise<IApiDadataReturn> =>
  await http.get({
    url: URL_DADATA,
    params: {
      ...props,
    },
  });
