import React from 'react';
import classNames from 'classnames';

import { useChangeStep, observer, useStore } from '@core';

import { Button } from '../Button/Button';

import s from './FormNavControl.module.scss';

export const FormNavControl: React.FC = observer(() => {
  const store = useStore();
  const { prevStep, nextStep } = useChangeStep();

  if (!store) {
    return null;
  }

  return (
    <div className={s.navBox}>
      <Button
        styleType="second"
        onClick={(e) => {
          e.preventDefault();
          prevStep();
        }}
      >
        Назад
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          nextStep();
        }}
      >
        Дальше
      </Button>
    </div>
  );
});
