import {
  createInputCompanyModel,
  InputCompanyModelStyle,
  InputCompanyModelProps,
  InputCompanyModelValue,
} from '@core/common/components/_factories/createInputCompanyModel';
import { InputBrandModelBase } from '../InputBrandModelBase';
import { CompanyShortView } from '../../Views';
import { SwitchOrigin } from '../_common/SwitchOrigin';
import s from './InputCompanyModel.module.scss';

export type { InputCompanyModelProps, InputCompanyModelValue };

export const InputCompanyModel = createInputCompanyModel(
  { CompanyShortView, InputBrandModelBase, CheckboxBase: SwitchOrigin },
  s as InputCompanyModelStyle
);

InputCompanyModel.displayName = 'InputCompanyModel';
