import { Select } from '../_common/Select';
import {
  createSelectDefault,
  SelectDefaultProps,
} from '@core/common/components/_factories/createSelectDefault/createSelectDefault';

export type { SelectDefaultProps };

export const SelectDefault = createSelectDefault({ Select });

SelectDefault.displayName = 'SelectDefault';
