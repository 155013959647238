import { PartnerLogoType } from './CompanyLogo.types';
import { mapColor48x48, mapDarkLarge, mapColorLarge } from '@osago/partners';
import { logError } from '@utils/logger';

export const getSrc = (companyId: number, type: PartnerLogoType): string | null => {
  switch (type) {
    case 'square':
      return mapColor48x48[companyId];

    case 'colorful':
      return mapColorLarge[companyId];

    case 'black-white':
      return mapDarkLarge[companyId];

    default: {
      const unhandled: never = type;
      logError(new Error(`unhandled type: ${unhandled}`));
      return null;
    }
  }
};
