import { setPreliminaryCost } from '@core/store/utils/setPreliminaryCost';
import { useStore } from '@core';
import { useEffect } from 'react';
import { ENABLE_PRELIMINARY_COST } from '@core/common/constants/preliminaryCost';

export const usePreliminaryCost = () => {
  const store = useStore();
  const year = store.form.fields.year.value;
  const power = store.form.fields.power.value;
  const owner_city = store.form.fields.owner_city.value;

  useEffect(() => {
    if (ENABLE_PRELIMINARY_COST) setPreliminaryCost.call(store.form);
  }, [store.form, year, power, owner_city]);
};
