import type { PolicyOrder } from '@core';
import { getOrderKeyStorage } from '@core';
import { createDrivers } from '@core/features/driver/helpers/createDrivers';
import type { FormDriver } from '@core/features/driver/driver.types';
import { createVehicle } from '@core/features/vehicle/helpers/createVehicle';
import type { PolicyOrderForm } from '@core/features/order/order.types';
import { createLegalEntity } from '@core/features/legalEntity/helpers/createLegalEntity';
import { convertDate } from '@utils/date';
import { CompanyModel } from '@core/features/vehicle/vehicle.types';

export const createPolicyOrder = async ({
  form,
  drivers,
  stepComplete,
  urlParams,
  companiesModels,
}: {
  form: PolicyOrderForm;
  drivers: FormDriver[];
  stepComplete: unknown;
  urlParams: Record<string, string>[];
  companiesModels: CompanyModel[] | undefined;
}): Promise<PolicyOrder> => {
  const orderKey = await getOrderKeyStorage();
  const objectToSend: PolicyOrder = {
    registeredAbroad: 0,
    registrationWay: 0,
    violations: 0,
    cc: 0,
    link_options: {},
    stepComplete,
    order_key: orderKey || '',
    useTrailer: form.useTrailer.value,
    usePeriod: +form.usePeriod.value,
    multidrive: form.multidrive.value,
    purpose: form.purpose.value,
    licensePlate: form.licensePlate.value,
    phone: form.phone.value,
    email: form.email.value,
    date: convertDate(form.date.value),

    // todo/arkadii check typo
    reuestedLicense: form.reuestedLicense.value,
    insurerIsOwner: form.insurerIsOwner.value,

    vehicle: createVehicle(form, companiesModels),
    owner: createLegalEntity('owner_')(form),
  };

  if (urlParams.length !== 0) {
    objectToSend.url_params = urlParams;
  }

  if (form.prevPolicySerial.value && !form.prevPolicySerial.error) {
    objectToSend.prevPolicySerial = form.prevPolicySerial.value;
  }

  if (form.prevPolicyNumber.value && !form.prevPolicyNumber.error) {
    objectToSend.prevPolicyNumber = form.prevPolicyNumber.value;
  }

  if (form.prevPolicyCompany.value && !form.prevPolicyCompany.error) {
    objectToSend.prevPolicyCompany = +form.prevPolicyCompany.value;
  }

  if (form.promoCode.value && !form.promoCode.error) {
    objectToSend.mmvb_data = { promo_code: form.promoCode.value };
  }

  if (objectToSend.multidrive == 0) {
    objectToSend.drivers = createDrivers(drivers);
  }

  if (form.insurerIsOwner.value == 0) {
    objectToSend.insurer = createLegalEntity('policyholder_')(form);
  }

  if (form.hasPrevPolicy.value == 1) {
    objectToSend.hasPrevPolicy = form.hasPrevPolicy.value;
    objectToSend.prevPolicySerial = form.prevPolicySerial.value;
    objectToSend.prevPolicyNumber = form.prevPolicyNumber.value;
    objectToSend.prevPolicyUnmaskId = form.prevPolicyUnmaskId.value;
  }

  return objectToSend;
};
