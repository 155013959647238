import { IRootStore } from './rootStore.typings';
import { checkIframe } from '@utils/iframe';
import { IFieldKey } from '@core';
import { uiStore } from './uiStore/uiStore';
import { formStore } from './formStore/formStore';
import { stepStore } from './stepStore/stepStore';
import { partnerStore } from './partnerStore/partnerStore';
import { calcInitStore } from './calcInitStore/calcInitStore';
import { createOrder, getOffersStorage, getOrderKeyStorage } from '@core';
import { createPolicyOrder } from '@core/features/order/helpers/createPolicyOrder';
import { createDrivers } from '@core/features/driver/helpers/createDrivers';
import type { FormDriver } from '@core/features/driver/driver.types';
import type { PolicyOrderDraft } from '@core/features/order/order.types';
import { createPolicyOrderDraft } from '@core/features/order/helpers/createPolicyOrderDraft';
import { createVehicle } from '@core/features/vehicle/helpers/createVehicle';
import { createLegalEntity } from '@core/features/legalEntity/helpers/createLegalEntity';
import { createFormField } from '@core/features/field/helpers/createFormField';
import { updateStoreByPolicyOrder } from '@core/features/order/helpers/updateStoreByPolicyOrder';
import { logError } from '@utils/logger';

/**
 * @property {createObjectToLocalSave} - Сформировать черновик
 */
export const rootStore = (): IRootStore => ({
  ui: uiStore,
  step: stepStore,
  form: formStore,
  partner: partnerStore,
  calc: calcInitStore,

  setValueFromPolicyOrder(policyOrder) {
    return updateStoreByPolicyOrder(policyOrder, this);
  },

  // todo/arkadii add validations
  async createOrder() {
    const objectToSend = await this.createObjectFormData();

    /* Проверка на order_key */
    const orderKey = await getOrderKeyStorage();
    if (!orderKey) {
      try {
        await createOrder({
          orderData: objectToSend,
          idAgent: this.calc.idAgent ? this.calc.idAgent : undefined,
        });
      } catch (e) {
        logError(e);
        return false;
      }
    }

    return true;
  },

  createObjectVehicle() {
    return createVehicle(this.form.fields, this.form.companiesModelsField.getCompaniesModels());
  },

  createObjectDrivers() {
    return createDrivers(this.form.drivers);
  },

  createObjectOwner() {
    return createLegalEntity('owner_')(this.form.fields);
  },

  createObjectPolicyholder() {
    return createLegalEntity('policyholder_')(this.form.fields);
  },

  createObjectFormData() {
    return createPolicyOrder({
      form: this.form.fields,
      drivers: this.form.drivers,
      stepComplete: this.step.currentStep,
      urlParams: this.calc.urlPrefix,
      companiesModels: this.form.companiesModelsField.getCompaniesModels(),
    });
  },

  createObjectToLocalSave(): PolicyOrderDraft {
    return createPolicyOrderDraft({
      step: this.step.currentStep,
      form: this.form.fields,
      drivers: this.form.drivers,
      flagSendOldPolicyData: this.form.flagSendOldPolicyData,
      companiesModels: this.form.companiesModelsField.value,
      companies: this.form.companiesModelsField.companies,
    });
  },

  setValueFromLocalObject(obj) {
    console.log({ obj });
    Object.keys(obj.fields).map((key) => {
      if (key in this.form.fields) {
        if (key === 'date' && checkIframe()) {
          return;
        }

        this.form.setValueField(key as IFieldKey, obj.fields[key] as never);
      }
    });

    const drivers: FormDriver[] = [];

    obj.drivers.forEach((driver) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectDriver: any = {};
      Object.keys(driver).map((key) => {
        if (key in driver) {
          objectDriver[key] = createFormField(driver[key]);
        }
      });
      drivers.push(objectDriver as FormDriver);
    });

    if (drivers.length !== 0) {
      this.form.drivers = drivers.slice();
    }

    if (obj.flagSendOldPolicyData) {
      this.form.flagSendOldPolicyData = true;
    }

    if (obj.companiesModels) {
      this.form.companiesModelsField = {
        ...(this.form.companiesModelsField || {}),
        value: obj.companiesModels,
        companies: obj.companies,
      };
    }
  },

  async createOffersFormData() {
    const offers = await getOffersStorage();

    if (!offers || !offers.offersData || !offers.offersData.offers) {
      return [];
    }

    const metricOffers = Object.keys(offers.offersData.offers).map((key) => {
      const item = offers.offersData.offers[key];

      if (item.errors && item.errors.length !== 0) {
        return {
          title: item.errors[0].skName || '',
          answer: 0,
          price: 0,
        };
      }

      if (item.results && item.results.length !== 0) {
        return {
          title: item.results[0].skName,
          answer: 1,
          price: item.results[0].total,
        };
      }

      return {
        title: '',
        answer: 0,
        price: 0,
      };
    });

    return metricOffers || [];
  },
});
