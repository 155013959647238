import { SwitchOrigin } from '../_common/SwitchOrigin';
import {
  createBooleanStoredField,
  BooleanFieldProps,
  BooleanStoredFieldStyle,
} from '@core/common/components/_factories/createBooleanStoredField';
import s from './Switch.module.scss';

export type ISwitch = BooleanFieldProps;

export const Switch = createBooleanStoredField<ISwitch>(SwitchOrigin, s as BooleanStoredFieldStyle);

Switch.displayName = 'Switch';
