import { ValidationFn, DateFeedback, DateOptions } from '../../common/types';
import { MAX_DATE, MIN_DATE } from '../../common/constants';
import { dateFeedback } from '../../common/feedbacks';

const shortMonth = [4, 6, 9, 11];

const getDMY = (value: string, feedback: DateFeedback = dateFeedback): [string, string, string] | never => {
  if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) return value.split('.') as [string, string, string];
  if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    const [y, m, d] = value.split('-');
    return [d, m, y];
  }
  throw feedback.INVALID_DATE_FORMAT;
};
export const parseDate = (value: Date | string, feedback: DateFeedback = dateFeedback): Date => {
  if (typeof value !== 'string') {
    if (Number.isNaN(value.getTime())) throw feedback.INVALID_DATE_FORMAT;
    return value;
  }
  if (!/^\d{2}\.\d{2}\.\d{4}$/.test(value) && !/^\d{4}-\d{2}-\d{2}$/.test(value)) throw feedback.INVALID_DATE_FORMAT;
  let result: Date;
  const [d, m, y] = getDMY(value, feedback);

  if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
    result = new Date(`${m}/${d}/${y}`);
  } else {
    result = new Date(value);
  }

  const month = parseInt(m, 10);
  const day = parseInt(d, 10);

  if (day > 31) {
    throw feedback.INVALID_DATE_FORMAT;
  } else if (month === 2) {
    if (day > 29) throw feedback.INVALID_DATE_FORMAT;
    if (day === 29) {
      const year = parseInt(y, 10);
      if (year % 4 !== 0) throw feedback.INVALID_DATE_FORMAT;
    }
  } else if (shortMonth.includes(month) && day > 30) {
    throw feedback.INVALID_DATE_FORMAT;
  }
  if (Number.isNaN(result.getTime())) throw feedback.INVALID_DATE_FORMAT;
  return result;
};

export const dateRule =
  (options?: DateOptions): ValidationFn<Date | string> =>
  (value, deps, params) => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...(params || {}), deps, defaultIsSkipped })) return null;

    let min: Date;
    let max: Date;

    const {
      required,
      feedback = {},
      max: _max,
      min: _min,
    } = options?.getConditionalOptions?.({
      deps,
      params,
      currentDeps: params?.currentDeps || deps,
      defaultOptions: options,
    }) ||
    options ||
    {};
    const _feedback = { ...dateFeedback, ...feedback };

    try {
      min = (_min ? parseDate(_min, _feedback) : MIN_DATE) as Date;
      min.setHours(0, 0, 0, 0);
    } catch (e) {
      throw new Error(`invalid min: ${_min}`);
    }

    try {
      max = (_max ? parseDate(_max, _feedback) : MAX_DATE) as Date;
      max.setHours(0, 0, 0, 0);
    } catch (e) {
      throw new Error(`invalid max: ${_max}`);
    }

    if (!value) return required ? _feedback.REQUIRED : null;
    if (typeof value === 'string' || value instanceof Date) {
      try {
        const date = parseDate(value, _feedback);
        date.setHours(0, 0, 0, 0);
        if (max && date > max) return _feedback.CANT_BE_LATER({ min, max, value: date });
        if (min && date < min) return _feedback.CANT_BE_EARLIER({ min, max, value: date });

        return null;
      } catch (e) {
        return e as string;
      }
    }
    return _feedback.INVALID_DATE_FORMAT;
  };
