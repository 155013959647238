import type { PolicyOrderDrafts } from '@core/features/order/order.types';
import { LOCAL_STORAGE_DRAFTS } from '../../constants/keys';
import { TStorage } from './types';

export const getDraftsStorage = async (storage: TStorage): Promise<PolicyOrderDrafts | null> => {
  try {
    return await storage.getItem<PolicyOrderDrafts>(LOCAL_STORAGE_DRAFTS);
  } catch (e) {
    return null;
  }
};

/** SET */
// arkadii/todo limit drafts count, during the dev process (maybe agents cases are pretty similar) it can became over 50
export const setDraftsStorage = async (value: unknown, storage: TStorage): Promise<boolean> => {
  try {
    await storage.setItem(LOCAL_STORAGE_DRAFTS, { drafts: value });
  } catch (error) {
    console.log(error);
  }
  return true;
};
