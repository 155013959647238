import { inputs } from '@core/constants/texts';
import {
  CompaniesModelsField,
  CompanyModelFieldError,
  CompanyModelFieldOptions,
  CompanyModelFieldTotalOptions,
  CompanyModelFieldValue,
} from '@core/typings/fields.typings';
import { PartnerCompany } from '@core/features/company/company.types';
import { CompanyModel } from '@core/features/vehicle/vehicle.types';

export const createCompaniesModelsField = (
  companies: PartnerCompany[] = [],
  companiesModels?: CompanyModel[]
): CompaniesModelsField =>
  companies.reduce(
    (result, company) => {
      const companyValue = companiesModels?.find((i) => i.sk === company.id);

      const value: CompanyModelFieldValue = {
        exclude: false,
        model: companyValue?.model || '',
        brand: companyValue?.brand || '',
      };

      const option: CompanyModelFieldOptions = {
        model: value.model
          ? [{ title: value.model, value: value.model }]
          : [{ title: inputs.model.empty_brand, value: '-1' }],
        brand: value.brand
          ? [{ title: value.brand, value: value.brand }]
          : [{ title: inputs.brand.empty, value: '-1' }],
      };

      const totalOption: CompanyModelFieldTotalOptions = {
        model: [],
        brand: [],
      };

      const errors: CompanyModelFieldError = {
        exclude: '',
        model: '',
        brand: '',
      };

      Object.assign(result.visibleOptions, { [company.id]: option });
      Object.assign(result.values, { [company.id]: value });
      Object.assign(result.errors, { [company.id]: errors });
      Object.assign(result.options, { [company.id]: totalOption });

      return result;
    },
    { visibleOptions: {}, values: {}, errors: {}, options: {} } as CompaniesModelsField
  );
