import {
  createInputBrandModelBase,
  InputBrandModelBaseStyle,
  InputBrandModelBaseProps,
  InputBrandModelBaseValue,
} from '@core/common/components/_factories/createInputBrandModelBase';
import { SearchingSelect } from '../_common/SearchingSelect';
import { FormRow } from '../../FormRow';
import s from './InputBrandModelBase.module.scss';

export type { InputBrandModelBaseProps, InputBrandModelBaseValue };

export const InputBrandModelBase = createInputBrandModelBase(
  { FormRow, SearchingSelect },
  s as InputBrandModelBaseStyle
);

InputBrandModelBase.displayName = 'InputBrandModelBase';
