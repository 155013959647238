import { CompanyModel } from '@core/features/vehicle/vehicle.types';
import { PartnerCompany } from '@core/features/company/company.types';
import { createCompaniesModelsField } from '@core/features/companiesModels/helpers/createCompaniesModelsField';
import { CompaniesModelsField } from '@core/typings/fields.typings';

export const prepareValueForComparisons = (value: string): string => `${value}`.toLowerCase().replace(/[-_\s]/g, '');

export const getNotFulledCompaniesModels = (
  companiesModels: CompanyModel[],
  companiesList: PartnerCompany[],
  initial: { brand: string; model: string }
): { value: CompaniesModelsField; companies: PartnerCompany[] } => {
  const companies: PartnerCompany[] = [];
  const brand = initial.brand && prepareValueForComparisons(initial.brand);
  const model = initial.model && prepareValueForComparisons(initial.model);
  companiesModels.forEach((company) => {
    const found = companiesList.find((partner) => partner.id === company.sk);
    if (!found) return;
    if (!company.brand || !company.model) return companies.push(found);
    if (
      (brand && prepareValueForComparisons(company.brand) !== brand) ||
      (model && prepareValueForComparisons(company.model) !== model)
    ) {
      companies.push(found);
    }
  });
  return {
    value: createCompaniesModelsField(companies, companiesModels),
    companies,
  };
};
