import { http } from '@utils/http/http';
import { DataPromoCodeCheck, PropsPromoCodeCheck } from '../../typings/promoCode.typings';
import { URL_PROMO_CODE } from '../../constants/api';

/**
 * Запрос на проверку валидности промокода
 * @param props {PropsPromoCodeCheck}
 * @returns Promise<DataPromoCodeCheck>
 */
export const apiGetCheckPromoCode = async (props: PropsPromoCodeCheck): Promise<DataPromoCodeCheck> =>
  await http.get({
    url: URL_PROMO_CODE,
    params: {
      ...props,
    },
  });
