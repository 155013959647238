import { differenceInMinutes, format, isMatch, parse } from 'date-fns';

export const convertDate = (date: string, from = 'dd.MM.yyyy', to = 'yyyy-MM-dd') => {
  // проверяем формат даты, вероятно конвертация и не нужна
  if (isMatch(date, to)) {
    return date;
  }

  try {
    return date ? format(parse(date, from, new Date()), to) : '';
  } catch (e) {
    console.error(`Некорректная дата "${date}"`, e);
    return '';
  }
};

export const isTimeDifferenceLess = (date: string, minutes = 5) => {
  const dd = new Date(date);
  // todo/arkadii replace isNaN to Number.isNaN and remove instanceof check
  return dd instanceof Date && !isNaN(dd.valueOf()) && differenceInMinutes(new Date(), dd) <= minutes;
};
