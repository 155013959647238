import { useEffect, useRef, useState } from 'react';
import { PolicyOrder } from '@core/features/order/order.types';
import { OffersOrderData, OffersOrderError } from '@core/typings/order.typings';
import { isTimeDifferenceLess } from '@utils/date';
import { LegalEntity } from '@core/features/legalEntity/legalEntity.types';
import { useSendMetrics } from '@core/hooks/useSendMetrics';
import { useStore } from '@core/providers/StoreProvider';
import { getOffersByIdSk } from '@core/features/controllers/order';
import { usePartners } from '@core/hooks/usePartners';
import { getOffersStorage, getOrderKeyStorage, setOffersStorage } from '@core/services/storage/methods';
import { companiesWithUpsales, companyOptionLabels } from '@core/common/constants/upsales';
import { useIsMounted } from '@core/hooks/useIsMounted';
import { ICalcInitStore } from '@core/store/calcInitStore/calcInitStore.typings';
import { useEvent } from '@core/hooks/useEvent';
import { logError } from '@utils/logger';
import { useReloadOrder } from '@core/hooks/useReloadOrder';

export type OffersHookOffer = OffersOrderData & {
  loading: boolean;
  date: string;
};

export type OffersHookOffers = {
  [sk: string]: OffersHookOffer;
};

interface OffersHookData {
  getOffers: () => Promise<void>;
  setActiveOffer: (idOffer: string) => Promise<boolean>;
  offers: OffersHookOffers | null;
  companyOptionLabels: { [key: number]: string };
}

const getFullName = (person: LegalEntity | undefined): string =>
  (Number(person?.legalStatus) == 1
    ? person?.name
    : `${person?.lastname || ''} ${person?.firstname || ''} ${person?.middlename || ''}`) || '';

type UpsaleUIOptions = {
  upsaleRequiredEnable?: number;
  upsaleEnable?: number;
};

const upsalesFlags = (companyId: number, options: ICalcInitStore['upsales'] & { prefix: string }): UpsaleUIOptions => {
  // 1. если компания апсейлы не поддерживает, то флаги не отправляем ({})
  // 2. если компания апсейлы поддерживает, то отправляем флаг в зависимости от настройки витрины

  const defaults = {
    upsaleRequiredEnable: options.required ? 1 : 0,
    upsaleEnable: options.notRequired ? 1 : 0,
  };

  return companiesWithUpsales.indexOf(companyId) !== -1 ? defaults : {};
};

export const prepareObjOrderVehicle = (objOrder: PolicyOrder, companyId: number): PolicyOrder => {
  if (!objOrder?.vehicle) return objOrder;

  const result = { ...objOrder };
  const { companiesModels, ...vehicle } = result.vehicle || {};
  result.vehicle = vehicle;
  const found = companiesModels?.find((i) => i.sk === companyId);
  if (found) {
    result.vehicle.brand = found.brand;
    result.vehicle.model = found.model;
  }
  return result;
};

export const prepareObjOrder = (objOrder: PolicyOrder, companyId: number): PolicyOrder => {
  const result = prepareObjOrderVehicle(objOrder, companyId);

  // Без этого все компании отклоняют заявку, а пользователь даже не видит причину (в случае если отключили тогл)
  if (!result.prevPolicySerial) {
    delete result.prevPolicySerial;
    delete result.prevPolicyNumber;
  }

  return result;
};

export const getNewDate = (error: OffersOrderError): string =>
  error.error
    .replace(/.*(\d{2}\.\d{2}\.\d{4}).*/, '$1')
    .split('.')
    .reverse()
    .join('-');

export const useOffers = (): OffersHookData => {
  const store = useStore();
  const { sendInsResponse } = useSendMetrics();
  const { interestedCompanies } = usePartners();
  const { reloadOrder } = useReloadOrder();

  const [offers, setOffers] = useState({} as OffersHookOffers);
  const addOffer = async (sk: number, offer: OffersOrderData, date: string) => {
    setOffers((v) => ({ ...v, [sk]: { ...offer, date } }));
  };

  const fetched = useRef(false);
  const isMounted = useIsMounted();

  const getOffers = useEvent(async () => {
    fetched.current = true;

    const objOrder = await store.createObjectFormData();

    const query = (id: number, date?: string) => {
      setOffers((v) => ({ ...v, [id]: { ...(v[id] || {}), loading: true } }));
      const preparedOrder = prepareObjOrder(objOrder, id);
      const correctDate = (date || preparedOrder.date) as string;
      getOffersByIdSk({
        ...preparedOrder,
        date: correctDate,
        sk: [id],
        name: getFullName(objOrder.owner),
        ...upsalesFlags(id, {
          required: store.calc.upsales[id]?.required ?? store.calc.upsales.required ?? false,
          notRequired: store.calc.upsales[id]?.notRequired ?? store.calc.upsales.notRequired ?? false,
          prefix: store.calc.prefix,
        }),
      })
        .then(async (response) => {
          if (!isMounted()) return;
          const errorDate = response.result?.errors?.find((e) => /не ранее чем \d{2}\.\d{2}\.\d{4}/.test(e.error));
          const error401 = response.result?.errors?.find((e) => e.code === 401);

          if (errorDate) {
            query(id, getNewDate(errorDate));
          } else if (error401) {
            await reloadOrder();
            query(id);
          } else {
            addOffer(response.id, response.result, correctDate);
          }
        })
        .catch(logError)
        .finally(() => {
          if (!isMounted()) return;
          setOffers((v) => ({ ...v, [id]: { ...(v[id] || {}), loading: false } }));
        });
    };

    interestedCompanies.forEach((company) => query(company.id));
  });

  const keys = Object.keys(offers);
  const loading = !!keys.length && keys.some((key) => offers[key].loading);

  useEffect(() => {
    if (!loading && fetched.current) {
      sendInsResponse();
    }
  }, [loading, sendInsResponse]);

  useEffect(() => {
    (async () => {
      const idOrder = (await getOrderKeyStorage()) || '';
      const oldOffers = await getOffersStorage();

      if (oldOffers?.offersData?.offers) {
        oldOffers.offersData.offers = { ...oldOffers.offersData.offers, ...offers };
        await setOffersStorage(oldOffers.offersData);
      }

      const offersData = {
        idOrder,
        offers,
        dateCreate: new Date(),
      };
      await setOffersStorage(offersData);
    })();
  }, [offers]);

  const { sendSelectOffer } = useSendMetrics();

  return {
    getOffers,
    offers,
    setActiveOffer: useEvent(async (idOffer: string) => {
      const oldOffers = await getOffersStorage();
      const dateCreate = oldOffers?.offersData?.dateCreate;
      const isOfferInvalid = !dateCreate || !offers[idOffer] || !isTimeDifferenceLess(dateCreate.toString());

      if (isOfferInvalid) {
        setOffers({});
        getOffers();
        return false;
      }

      const offer = offers[idOffer];
      if (offer.errors?.length) return false;

      sendSelectOffer({
        title: offer.results?.[0]?.skName || '',
        price: offer.results?.[0]?.total,
      });

      return await store.calc.setActiveOfferData(idOffer, offer);
    }),
    companyOptionLabels,
  };
};
