import { CheckboxBase } from '../CheckboxBase';
import {
  createBooleanStoredField,
  BooleanFieldProps,
} from '@core/common/components/_factories/createBooleanStoredField';

export type CheckboxProps = BooleanFieldProps;

export const Checkbox = createBooleanStoredField<CheckboxProps>(CheckboxBase);

Checkbox.displayName = 'Checkbox';
