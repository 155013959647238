import { IMask } from '@core/common/components/_factories/input.types';
import React from 'react';
import { mapMask } from '../utils/input-mask';
import { MaskProps } from '../createMask';
import { InputBaseProps } from '../createInputBase';

export type InputMaskBaseProps = Omit<InputBaseProps, 'getValueByEvent' | 'children' | 'prepareValueForInput'> & {
  mask: IMask;
  alwaysVisibleMask?: boolean;
};

export const createInputMaskBase =
  <T extends InputMaskBaseProps>(
    InputBase: React.ComponentType<InputBaseProps>,
    Mask: React.ComponentType<MaskProps>
  ): React.FC<T> =>
  ({ mask, alwaysVisibleMask, ...props }) => {
    const maskRecord = mapMask[mask];

    return (
      <InputBase {...props} prepareValueForInput={maskRecord.getMask} getValueByEvent={maskRecord.changeValue}>
        {({ active, value }) => {
          const masked = maskRecord.getMask(value);

          return <Mask mask={mask} active={alwaysVisibleMask ? true : active || !!value} value={masked} />;
        }}
      </InputBase>
    );
  };
