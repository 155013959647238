import { createCompaniesModelsField } from '@core/features/companiesModels/helpers/createCompaniesModelsField';
import { inputs } from '@core/constants/texts';
import { getCorrectValue } from '@core/store/formStore/companiesModelsFieldStore/helpers';
import { CompanyModel } from '@core/features/vehicle/vehicle.types';
import { getNotFulledCompaniesModels } from '@core/features/companiesModels/helpers/getNotFulledCompaniesModels';
import { CorrectedOption } from '@core/typings/fields.typings';
import { ICompaniesModelsFiledStore } from './companiesModelsFieldStore.typings';

export const companiesModelsFieldStore: ICompaniesModelsFiledStore = {
  value: createCompaniesModelsField(),

  companies: [],

  reset() {
    this.value = createCompaniesModelsField();
    this.companies = [];
  },

  getCompaniesModels() {
    const result: CompanyModel[] = [];
    this.companies.forEach((company) => {
      if (
        !this.value.values[company.id].exclude &&
        this.value.values[company.id].brand &&
        this.value.values[company.id].model
      ) {
        result.push({
          sk: company.id,
          brand: this.value.values[company.id].brand,
          model: this.value.values[company.id].model,
        });
      }
    });
    return result.length ? result : undefined;
  },

  checkError() {
    Object.keys(this.value.values).forEach((companyId) => {
      const item = this.value.values[companyId];
      if (item.exclude) {
        this.value.errors[companyId].model = '';
        this.value.errors[companyId].brand = '';
        return;
      }
      if (item.brand) this.value.errors[companyId].brand = '';
      if (item.model) this.value.errors[companyId].model = '';
    });
  },

  setCompaniesModels(companiesModels, companiesList, initial) {
    const { value, companies } = getNotFulledCompaniesModels(companiesModels, companiesList, initial);
    this.value = value;
    this.companies = companies;
  },

  get() {
    return {
      // FIXME разобраться, почему без этого обновления options происходят с запаздыванием
      value: JSON.parse(JSON.stringify(this.value)),
      companies: this.companies,
    };
  },

  change(value) {
    this.value.values = getCorrectValue(this.value.values, value);
  },

  // Подстраивает модель под состояние марки, если в опциях пустой массив, исправляет, и пишет, что ничего не найдено
  prepareOptions() {
    Object.keys(this.value.values).forEach((companyId) => {
      const item = this.value.values[companyId];
      const option = this.value.visibleOptions[companyId];
      if (!item.brand && !item.model) {
        option.brand = [{ value: '-1', title: inputs.brand.empty }];
        option.model = [{ value: '-1', title: inputs.model.empty_brand }];
        return;
      }
      if (!item.model && option.brand.length === 1 && option.brand[0].value === item.brand) {
        option.model = [{ value: '-1', title: inputs.model.empty }];
      }
    });
    Object.keys(this.value.visibleOptions).forEach((companyId) => {
      const option = this.value.visibleOptions[companyId];
      if (!option.model.length) {
        this.value.visibleOptions[companyId].model = [{ value: '-1', title: inputs.common.not_found }];
      }
      if (!option.brand.length) {
        this.value.visibleOptions[companyId].brand = [{ value: '-1', title: inputs.common.not_found }];
      }
    });
  },

  // Проверяет список options у других компаний и если находит совпадения - выставляет
  prepareOptionsOfAllCompanies(value, type) {
    const text = value.toLowerCase();
    const result: CorrectedOption[] = [];

    Object.keys(this.value.visibleOptions).forEach((companyId) => {
      const options = this.value.options[companyId];

      // Если нет опций - нечего подставлять
      if (!options?.[type]) return;
      const values = this.value.values[companyId];

      // Если значение уже выставлено - не изменяем
      if (values[type]) return;
      const visibleOptions = this.value.visibleOptions[companyId];

      const canChangeBrand = type === 'brand';
      const canChangeModel =
        type === 'model' && visibleOptions.brand.length === 1 && visibleOptions.brand[0].value === values.brand;
      if (canChangeBrand || canChangeModel) {
        const found = options[type].find((i) => i.toLowerCase() === text);
        if (!found) return;
        values[type] = found;
        visibleOptions[type] = [{ value: found, title: found }];
        result.push({
          type,
          companyId,
          _value: found,
        });
      }
    });

    return result;
  },

  select(option, company, { type }) {
    this.value.values[company.id][type] = option.value;
    this.value.visibleOptions[company.id][type] = [option];
  },

  setError(error, company, { type }) {
    this.value.errors[company.id][type] = error;
  },

  addOptions(options, company, { type }) {
    if (!options?.length) return;
    const existOptions = this.value.options[company.id]?.[type] || [];
    this.value.options[company.id] = {
      ...(this.value.options[company.id] || {}),
      [type]: Array.from(new Set([...existOptions, ...options])),
    };
  },

  // Устанавливаем отображаемые опции
  setVisibleOptions(options, company, { type }) {
    if (this.value.visibleOptions?.[company.id]?.[type]) this.value.visibleOptions[company.id][type] = options;
  },
};
