import {
  createInputBase,
  InputBaseStyle,
  InputBaseProps,
  ParamsToOutside,
} from '@core/common/components/_factories/createInputBase';
import { Placeholder } from '../Placeholder';
import { ErrorBlock } from '../ErrorBlock';
import { AutoIcon } from '../AutoIcon';
import { Skeleton } from '../../../Skeleton';
import s from './InputBase.module.scss';

export type { ParamsToOutside, InputBaseProps };

export const InputBase = createInputBase(
  { SkeletonBase: Skeleton, ErrorBlock, AutoIcon, Placeholder },
  s as InputBaseStyle
);

InputBase.displayName = 'InputBase';
