import {
  Obj,
  ValidationFn,
  ValidationResultRecord,
  ArrFeedback,
  StructureOptions,
  ValidationParams,
  Options,
} from '../../common/types';
import { arrFeedback } from '../../common/feedbacks';

export const arrayRule =
  (options?: StructureOptions<ArrFeedback>) =>
  <T, D extends Obj = Obj>(rule: ValidationFn<T, D>): ValidationFn<T[]> =>
  (value: T[] | null, deps, params) => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...(params || {}), deps, defaultIsSkipped })) return null;

    const {
      required,
      checkStructure,
      prepareValidationResult,
      feedback = {},
    } = options?.getConditionalOptions?.({
      deps,
      params,
      currentDeps: params?.currentDeps || deps,
      defaultOptions: options,
    }) ||
    options ||
    {};
    const _feedback = { ...arrFeedback, ...feedback };

    if (!value) return required ? _feedback.REQUIRED : null;
    if (!Array.isArray(value)) return _feedback.INVALID_ARRAY_FORMAT;

    const { changeParams } = params || {};
    const _changeParams = changeParams || (({ defaultParams }) => defaultParams);

    const res = value.map((v) =>
      rule(
        v,
        deps as D,
        _changeParams({
          value: v,
          deps,
          defaultParams: { checkStructure, ...params } as Options<unknown>,
        }) as ValidationParams
      )
    ) as ValidationResultRecord[];
    if (!res.filter(Boolean).length) return null;

    if (prepareValidationResult) return prepareValidationResult(res);
    return res;
  };
