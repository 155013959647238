import { FormDriver } from '@core/features/driver/driver.types';
import { join } from '@utils/join';

export const getDriversOptions = (drivers: FormDriver[]) =>
  [{ value: 'new', title: 'Собственник не будет вписан в полис как водитель' }].concat(
    drivers.map((driver, index) => ({
      value: index.toString(),
      title: join(' - ')(`Водитель ${index + 1}`, join(' ')(driver.firstname.value, driver.lastname.value)),
    }))
  );
