import { http } from '@utils/http/http';
import { ApiGetTokenProp, ApiGetTokenPropReturn } from '../../typings/token.typings';
import { ERROR_TYPE_GET_TOKEN_TEXT } from '../../constants/error';
import { URL_AUTH } from '../../constants/api';

/**
 * Запрос токена по `order_key` и значений формы.
 * @param props {ApiGetTokenProp}
 * @returns Promise<ApiGetTokenPropReturn>
 */
export const apiGetToken = async ({ order_key, from }: ApiGetTokenProp): Promise<ApiGetTokenPropReturn> => {
  if (!order_key) {
    throw new Error(ERROR_TYPE_GET_TOKEN_TEXT);
  }

  return await http.get({
    url: URL_AUTH,
    params: {
      ok: order_key,
      from: from ?? undefined,
    },
  });
};
