import React, { createContext, FC, useContext } from 'react';
import { storage, TStorage } from '@core/services/storage';

export type StorageProviderProps = {
  draftStorage?: TStorage;
};

export const DraftStorageContext = createContext(storage as TStorage);

export const useDraftStorage = () => useContext(DraftStorageContext);

export const StorageProvider: FC<StorageProviderProps> = ({ draftStorage = storage, children }) => (
  <DraftStorageContext.Provider value={draftStorage}>{children}</DraftStorageContext.Provider>
);
