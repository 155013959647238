import React from 'react';
import { DropdownProps } from '@core/common/components/_factories/createDropdown';
import { CalendarProps } from './createCalendar';

export type CalendarDropdownProps = CalendarProps & Omit<DropdownProps, 'onChange'>;

export const createCalendarDropdown =
  <T extends CalendarDropdownProps>({
    Dropdown,
    Calendar,
  }: {
    Dropdown: React.ComponentType<DropdownProps>;
    Calendar: React.ComponentType<CalendarProps>;
  }): React.FC<T> =>
  ({ opened, value, onChange, min, max, ...props }) => {
    const onMousedownRoot = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <Dropdown opened={opened} onMouseDown={onMousedownRoot} {...props}>
        <Calendar min={min} max={max} value={value} onChange={onChange} />
      </Dropdown>
    );
  };
