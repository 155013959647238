export const months = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

export const REQUIRED_UPSALES_TYPE = 'REQUIRED';
export const REQUIRED_UPSALES_OPTION = 'required_upsale';
