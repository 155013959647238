import type { FormDriver } from '@core/features/driver/driver.types';
import { createEmptyPolicyOrderForm } from '@core/features/order/helpers/createEmptyPolicyOrderForm';
import { createEmptyDriverForm } from '@core/features/driver/helpers/createEmptyDriverForm';
import { setDriverValue as helperSetDriverValue } from '@core/features/driver/helpers/setDriverValue';
import { companiesModelsFieldStore } from '@core/store/formStore/companiesModelsFieldStore/companiesModelsFieldStore';
import { validator } from '@core/providers/ValidatorProvider/ValidatorProvider';
import { PolicyOrderForm } from '@core/features/order/order.types';
import { prepareCompaniesModelsToValidation } from '@core/store/formStore/companiesModelsFieldStore/helpers';
import { CompanyModelFieldValue } from '@core/typings/fields.typings';
import { getBrandsModels } from '@utils/getBrandsModels';
import { IFieldKey, IFormStore } from './formStore.typings';

const emptyDriver = createEmptyDriverForm();

export const formStore: IFormStore = {
  fields: createEmptyPolicyOrderForm(),
  companiesModelsField: companiesModelsFieldStore,

  drivers: [emptyDriver],
  dataByForm: {
    preliminaryData: {
      data: null,
      loading: false,
    },
  },
  flagSendOldPolicyData: false,
  vehicleByLicensePlateLoading: false,

  // fields
  getValueField(name) {
    return this.fields[name];
  },

  resetFields() {
    this.fields = createEmptyPolicyOrderForm();
    this.companiesModelsField.reset();
    this.drivers = [emptyDriver];
    this.dataByForm = {
      preliminaryData: {
        data: null,
        loading: false,
      },
    };
    this.flagSendOldPolicyData = false;
    this.vehicleByLicensePlateLoading = false;
  },

  async setValueField(name, value) {
    this.fields[name].value = value;
  },

  setFlagAutoField(name, value) {
    this.fields[name].flagAuto = value;
  },

  async setErrorField(name, error) {
    if (name in this.fields) {
      this.fields[name].error = error || '';
    }
  },

  // drivers
  async setDriverValue(index, name, value) {
    const driver = this.drivers[index];

    this.drivers[index] = helperSetDriverValue(driver, name, value);
  },
  getDriverValue(index, name) {
    return this.drivers[index][name];
  },

  addDrivers() {
    this.drivers.push(emptyDriver);
    return this.drivers.length - 1;
  },

  removeDriver(index) {
    if (this.drivers.length === 1) {
      return;
    }

    const arr = [...this.drivers];
    arr.splice(index, 1);

    this.drivers = [...arr];
  },

  clearDrivers() {
    this.drivers = [emptyDriver];
  },

  setFlagAutoDriverField(index, name, value) {
    this.drivers[index][name].flagAuto = value;
  },

  async setErrorDriverField(index, name, error) {
    this.drivers[index][name].error = error;
  },

  async validateStep() {
    const objectToValid: { [key: string]: unknown } = {};

    Object.keys(this.fields).forEach((key) => {
      objectToValid[key] = this.fields[key as IFieldKey].value;
    });

    objectToValid.drivers = this.drivers.map((driver) => {
      const tempDriver: { [key: string]: unknown } = {};
      Object.keys(driver).forEach((key) => {
        tempDriver[key] = driver[key as keyof FormDriver].value;
      });
      return tempDriver;
    });

    objectToValid.companiesModels = prepareCompaniesModelsToValidation(this.companiesModelsField);

    const { brands, models } = await getBrandsModels(this.fields.brand.value);

    const errors = validator.validate(objectToValid, {
      onlySubscribers: true,
      extra: { brands, models },
      changeParamsMap: {
        companiesModels: ({ defaultParams, value }) => {
          const _brands = this.companiesModelsField.value.visibleOptions[(value as { sk: string }).sk]?.brand
            ?.map((i) => i.value)
            .filter((i) => i !== '-1');
          const _models = this.companiesModelsField.value.visibleOptions[(value as { sk: string }).sk]?.model
            ?.map((i) => i.value)
            .filter((i) => i !== '-1');
          return {
            ...defaultParams,
            extra: {
              brands: _brands,
              models: _models,
            },
          };
        },
      },
    });

    Object.keys(errors || {}).forEach((key) => {
      if (key === 'drivers') {
        const { drivers } = errors as Record<'drivers', Array<Record<string, string> | null>>;
        drivers.forEach((driverError, index) => {
          if (!driverError) return;
          Object.keys(driverError || {}).forEach((driverKey) => {
            this.setErrorDriverField(index, driverKey as keyof FormDriver, driverError[driverKey]);
          });
        });
        return;
      }
      if (key === 'companiesModels') {
        const { companiesModels } = errors as Record<
          'companiesModels',
          Array<Record<'brand' | 'model' | 'sk', string> | null>
        >;
        companiesModels.forEach((err, index) => {
          if (!err) return;
          const { sk } = (objectToValid.companiesModels as Array<CompanyModelFieldValue & { sk: string }>)[index];
          if (err.brand) this.companiesModelsField.value.errors[sk].brand = err.brand;
          if (err.model) this.companiesModelsField.value.errors[sk].model = err.model;
        });
        return;
      }
      this.setErrorField(key as keyof PolicyOrderForm, (errors as Record<string, string>)[key]);
    });

    return !errors;
  },

  setFlagSendOldPolicyData(flag) {
    this.flagSendOldPolicyData = flag;
  },
};
