import { ValidationFn, PolicyNumberOptions, PolicySerialOptions } from '../common/types';
import { stringRule } from './sharedRules';
import {
  policyNumberRegexp,
  policyNumberRegexpSoft,
  policySerialRegexp,
  policySerialRegexpSoft,
} from '../common/constants';
import { INVALID_POLICY_NUMBER_FORMAT, INVALID_POLICY_SERIAL_FORMAT } from '../common/texts';

export const policySerialRule =
  (options?: PolicySerialOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? policySerialRegexpSoft : policySerialRegexp,
      message: options?.feedback?.INVALID_POLICY_SERIAL_FORMAT || INVALID_POLICY_SERIAL_FORMAT,
    })(value, deps, { ...params, type: 'hard' });

export const policyNumberRule =
  (options?: PolicyNumberOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? policyNumberRegexpSoft : policyNumberRegexp,
      message: options?.feedback?.INVALID_POLICY_NUMBER_FORMAT || INVALID_POLICY_NUMBER_FORMAT,
    })(value, deps, { ...params, type: 'hard' });
