import React, { useEffect } from 'react';
import { observer, useScrollToError, CAR_TYPE, useStore } from '@core';
import { isBus, isTruck, VEHICLE_PURPOSE_LIST } from '@core/constants/calculator-const';
import { Container } from '../../../common/components/Container/Container';
import { FormRow } from '../../../common/components/FormRow';
import { IdentifiersForm } from './components/IdentifiersForm';
import { FormNavControl } from '../../../common/components/FormNavControl/FormNavControl';
import { SelectDefault } from '../../../common/components/Input/SelectDefault';
import { InputDefault } from '../../../common/components/Input/InputDefault';
import { SelectDadata } from '../../../common/components/Input/SelectDadata';
import { InputMask } from '../../../common/components/Input/InputMask';
import { CarPageSwitchers } from '../../../common/components/CarPageSwitchers/CarPageSwitchers';
import { InputBrandModel } from '../../../common/components/Input/InputBrandModel';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { FieldCompaniesModels } from './components/FieldCompaniesModels';
import { Steps } from '../../../common/components/Steps/Steps';
import s from './StepCarData.module.scss';

export const StepCarData: React.FC = observer(() => {
  const store = useStore();

  const vehicleType = String(store.form.fields.type.value);

  const licencePlateMask = vehicleType == '7' ? 'licensePlateMoto' : 'licensePlate';
  const truck = isTruck(vehicleType);
  const bus = isBus(vehicleType);

  const { scrollToTop } = useScrollToError();
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <Container className={s.root}>
      <Steps />
      <StepTitle>Уточнение данных</StepTitle>
      <div className={s.carPage}>
        <InputBrandModel>
          <InputDefault placeholder="Год выпуска автомобиля" name="year" validOnInput />
        </InputBrandModel>
        <FieldCompaniesModels className={s.companiesModels} />
        <FormRow columns>
          <SelectDefault view="fullWidth" placeholder="Тип ТС" name="type" options={CAR_TYPE} />
          <InputDefault view="fullWidth" placeholder="Мощность, л. с." name="power" />
          <SelectDadata view="fullWidth" name="owner_city" placeholder="Город регистрации собственника" />
        </FormRow>
        <FormRow columns>
          <InputMask
            view="fullWidth"
            placeholder="Номер авто"
            mask={licencePlateMask}
            name="licensePlate"
            validOnInput
          />
          <SelectDefault
            view="fullWidth"
            placeholder="Цель использования"
            name="purpose"
            options={VEHICLE_PURPOSE_LIST}
          />
        </FormRow>
        {truck && (
          <FormRow columns>
            <InputDefault view="fullWidth" placeholder="Масса без нагрузки, кг" name="weight" />
            <InputDefault view="fullWidth" placeholder="Разрешенная макс. масса, кг" name="maxWeight" />
          </FormRow>
        )}
        {bus && (
          <FormRow columns>
            <InputDefault view="fullWidth" placeholder="Число пассажирских мест" name="seats" />
          </FormRow>
        )}
        <IdentifiersForm />
        <CarPageSwitchers />
        <FormNavControl />
      </div>
    </Container>
  );
});
