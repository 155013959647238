import { Driver, IFieldDriverKey, IFieldKey, useStore } from '@core';
import { useValidator } from '@core/providers/ValidatorProvider/ValidatorProvider';
import { useEffect } from 'react';
import { getObjectToValid } from '@utils/getObjectToValid';
import { ISelectOption } from '@core/common/components/_factories/input.types';
import { useEvent } from '@core/hooks/useEvent';

export type InputHandlersParams = {
  name: string;
  validationName?: string;
  validationRuleName?: string;
  index?: number;
  validOnInput?: boolean;
  extra?: unknown;
  validationSubscribe?: boolean;
};

export type SelectHandlersParams = InputHandlersParams & {
  nameId?: string;
  onSelect?: (option: ISelectOption) => void;
};

export const useInputHandlers = ({
  name,
  validationName,
  index,
  validOnInput,
  extra,
  validationRuleName,
  validationSubscribe = true,
}: InputHandlersParams) => {
  const store = useStore();

  const { subscribe, validateByKey } = useValidator();

  const _validationName = validationName || name;

  useEffect(() => {
    if (validationSubscribe) return subscribe(validationRuleName || _validationName);
  }, [subscribe, validationRuleName, _validationName, validationSubscribe]);

  const deps = getObjectToValid(store.form);
  const drivers = deps.drivers as Driver[];
  const diver = typeof index === 'number' ? drivers?.[index] : undefined;

  const field =
    index === undefined
      ? store.form.getValueField(name as IFieldKey)
      : store.form.getDriverValue(index, name as IFieldDriverKey);

  const setError = useEvent((error: string) => {
    if (index === undefined) store.form.setErrorField(_validationName as IFieldKey, error);
    else store.form.setErrorDriverField(index, _validationName as IFieldDriverKey, error);
  });

  const { error, value: validationValue } =
    index === undefined
      ? store.form.getValueField(_validationName as IFieldKey)
      : store.form.getDriverValue(index, _validationName as IFieldDriverKey);

  const setFlagAuto = (flagAuto: boolean) => {
    if (index === undefined) store.form.setFlagAutoField(name as IFieldKey, flagAuto);
    else store.form.setFlagAutoDriverField(index, name as IFieldDriverKey, flagAuto);
  };

  const onChange = (_value: string, _name = name) => {
    if (index === undefined) store.form.setValueField(_name as IFieldKey, _value);
    else store.form.setDriverValue(index, _name as IFieldDriverKey, _value);
  };

  const onChangeValidating = useEvent((_value: null | string | number) => {
    if (index === undefined) store.form.setValueField(_validationName as IFieldKey, _value);
    else store.form.setDriverValue(index, _validationName as IFieldDriverKey, _value);
  });

  const { flagAuto } = field;
  const value = field.value?.toString() || '';

  const handleChange = (_value: string) => {
    setFlagAuto(false);
    setError('');

    if (!validOnInput) return onChange(_value);

    if (
      !validateByKey(_value, (validationRuleName || _validationName) as never, {
        type: 'soft',
        deps: deps as never,
        currentDeps: diver as never,
        extra,
      })
    )
      return onChange(_value);
  };

  useEffect(() => {
    setError('');
  }, [setError, value]);

  useEffect(() => {
    if (!value && validationName) onChangeValidating(null);
  }, [onChangeValidating, validationName, value]);

  const handleBlur = () => {
    setError(
      (validateByKey(validationValue || value, (validationRuleName || _validationName) as never, {
        type: 'hard',
        deps: deps as never,
        currentDeps: diver as never,
        extra,
      }) as string) || ''
    );
  };

  const clearAutoValue = () => {
    setFlagAuto(false);
    setError('');
  };

  return {
    value,
    error,
    flagAuto,
    onChange,
    handleChange,
    clearAutoValue,
    handleBlur,
  };
};

export const useSelectHandlers = ({
  nameId,
  name,
  validationName,
  validationSubscribe,
  index,
  validOnInput,
  onSelect,
  extra,
}: SelectHandlersParams) => {
  const result = useInputHandlers({ extra, name, index, validOnInput, validationName, validationSubscribe });
  const { onChange } = result;

  const handleSelect = (option: ISelectOption) => {
    onSelect?.(option);
    if (nameId) {
      onChange(option.value, nameId);
      onChange(option.title);
    } else {
      onChange(option.value);
    }
  };

  return {
    ...result,
    handleSelect,
  };
};
