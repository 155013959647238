import React, { useEffect } from 'react';
import { useScrollToError } from '@core';

import { FormNavControl } from '../../../common/components/FormNavControl/FormNavControl';
import { Container } from '../../../common/components/Container/Container';

import { PolicyForm } from './components/PolicyForm';
import { OtherForm } from './components/OtherForm';
import { ContactForm } from './components/ContactForm';
import { PromoCode } from './components/PromoCodeForm';

import s from './StepOtherData.module.scss';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { Steps } from '../../../common/components/Steps/Steps';

export const StepOtherData: React.FC = () => {
  const { scrollToTop } = useScrollToError();
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <Container className={s.root}>
      <Steps />
      <StepTitle>Дополнительно</StepTitle>
      <PolicyForm />
      <OtherForm />
      <ContactForm />
      <PromoCode />
      <FormNavControl />
    </Container>
  );
};
