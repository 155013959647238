import { initInsurance } from '@core';
import type { PartnerCompany } from '../company.types';

// todo/arkadii rewrite in a declarative style
export const disableCompanies = async (
  insuranceCompanies: number[],
  enabledInsuranceCompanies: number[] | null
): Promise<PartnerCompany[] | null> => {
  const insuranceList = await initInsurance();
  let newArr: PartnerCompany[] = [];

  if (insuranceList && Array.isArray(insuranceList) && insuranceList.length !== 0) {
    insuranceList.forEach((insurance) => {
      if (insurance.disabled == 0) {
        newArr.push({
          id: insurance.id,
          prefix: insurance.url,
          title: insurance.name,
        });
      }
    });
  } else {
    // newArr = this.enabledInsuranceCompanies;
    return null;
  }

  if (enabledInsuranceCompanies && Array.isArray(enabledInsuranceCompanies) && enabledInsuranceCompanies.length !== 0) {
    newArr = newArr.filter((comp) => enabledInsuranceCompanies.indexOf(comp.id) !== -1);
  } else if (insuranceCompanies && insuranceCompanies.length !== 0) {
    newArr = newArr.filter((comp) => insuranceCompanies.indexOf(comp.id) === -1);
  }

  return newArr;
};
