import {
  BrandOptions,
  ModelOptions,
  StringFeedback,
  StringOptions,
  ValidationFn,
  DocNumberETSOptions,
  DocNumberPTSOptions,
  DocNumberSTSOptions,
} from '../common/types';
import { stringRule } from './sharedRules';
import { etsRegexp, etsRegexpSoft, ptsRegexp, ptsRegexpSoft, stsRegexp, stsRegexpSoft } from '../common/constants';
import {
  INVALID_BRAND,
  INVALID_ETS_FORMAT,
  INVALID_MODEL,
  INVALID_PTS_FORMAT,
  INVALID_STS_FORMAT,
} from '../common/texts';
import { stringFeedback } from '../common/feedbacks';

export const docNumberPTSRule =
  (options?: DocNumberPTSOptions): ValidationFn<string, { docType: string | number }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? ptsRegexpSoft : ptsRegexp,
      message: options?.feedback?.INVALID_PTS_FORMAT || INVALID_PTS_FORMAT,
      isSkipped: (prms) => prms?.deps?.docType != '0',
    })(value, deps, { ...params, type: 'hard' });

export const docNumberSTSRule =
  (options?: DocNumberSTSOptions): ValidationFn<string, { docType: string | number }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? stsRegexpSoft : stsRegexp,
      message: options?.feedback?.INVALID_STS_FORMAT || INVALID_STS_FORMAT,
      isSkipped: (prms) => prms?.deps?.docType != '1',
    })(value, deps, { ...params, type: 'hard' });

export const docNumberETSRule =
  (options?: DocNumberETSOptions): ValidationFn<string, { docType: string | number }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? etsRegexpSoft : etsRegexp,
      message: options?.feedback?.INVALID_ETS_FORMAT || INVALID_ETS_FORMAT,
      isSkipped: (prms) => prms?.deps?.docType != '2',
    })(value, deps, { ...params, type: 'hard' });

export const createBrandModelRule =
  <T extends StringOptions<StringFeedback & { INVALID_BRAND?: string; INVALID_MODEL?: string }>>(
    feedbackKey: 'INVALID_BRAND' | 'INVALID_MODEL',
    listKey: 'brands' | 'models',
    message: string,
  ) =>
  (options?: T): ValidationFn<string> =>
  (value, deps, params) => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...(params || {}), deps, defaultIsSkipped })) return null;

    const raw = stringRule({ required: true })(value as string, deps, params);
    if (raw) return raw;

    const { feedback = {} } =
      options?.getConditionalOptions?.({
        deps,
        params,
        currentDeps: params?.currentDeps || deps,
        defaultOptions: options,
      }) ||
      options ||
      {};
    const _feedback = { ...stringFeedback, ...feedback };

    const list = (params?.extra as Record<typeof listKey, string[]>)?.[listKey]?.map((i) => i?.toLowerCase());
    return list?.includes((value || '')?.toString().toLowerCase()) ? null : _feedback[feedbackKey] || message;
  };

export const brandRule = createBrandModelRule<BrandOptions>('INVALID_BRAND', 'brands', INVALID_BRAND);
export const modelRule = createBrandModelRule<ModelOptions>('INVALID_MODEL', 'models', INVALID_MODEL);
