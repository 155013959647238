import {
  createFieldCompaniesModels,
  FieldCompaniesModelsStyle,
  FieldCompaniesModelsProps,
  FieldCompaniesModelsOriginProps,
} from '@core/common/components/_factories/createFieldCompaniesModels';
import { InputCompanyModels } from '../../../../../common/components/Input/InputCompanyModels';
import s from './FieldCompaniesModels.module.scss';

export type { FieldCompaniesModelsProps, FieldCompaniesModelsOriginProps };

export const FieldCompaniesModels = createFieldCompaniesModels({ InputCompanyModels }, s as FieldCompaniesModelsStyle);

FieldCompaniesModels.displayName = 'FieldCompaniesModels';
