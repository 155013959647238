import { storage } from '@core';
import { getValueCookie } from '../cookie/get';
import { getValueUrl } from '../url/url';

/**
 * Возвращает параметр по ключу из localStorage, cookies или query-params
 * @param key
 */
export const dataByKey = async (key: string): Promise<string> =>
  (await storage.getItem<string>(key)) || getValueCookie(key) || getValueUrl(key) || '';
