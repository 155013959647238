import { http } from '@utils/http/http';

import {
  ApiGetVehicleByLicensePlateProps,
  GetVehicleReturn,
  ApiGetBrandsModelsListReturn,
  ApiSearchVehicleListByParamsProps,
  ApiSearchVehicleListByParamsReturn,
  ApiGetVehicleListByParamsProps,
  ApiGetVehicleListByParamsReturn,
} from '../../typings/vehicle.typings';

import {
  URL_DATA_DICTIONARY,
  API_INGURU_6_2,
  URL_SEARCH_VEHICLE_BY_PARAMS,
  URL_LIST_VEHICLE_BY_PARAMS,
} from '../../constants/api';

import { getCaptchaTokenParams } from '../captcha/captcha';
import { HttpProperties } from '@core/typings/http.typings';

/**
 * Запрос данных о транспортном средстве по госномеру.
 * Дополнительно возвращаются модели производителя (?)
 * @param props {ApiGetVehicleByLicensePlateProps}
 * @returns Promise<ApiGetVehicleByLicensePlateReturn>
 */
export const apiGetVehicleByLicensePlate = async (props: ApiGetVehicleByLicensePlateProps): Promise<GetVehicleReturn> =>
  await http.get({
    url: API_INGURU_6_2,
    params: {
      q: 'vehicle',
      ...props,
      ...getCaptchaTokenParams(),
    },
  });

/**
 * Запрос данных о производителях и моделях.
 * @returns Promise<ApiGetBrandsModelsListReturn>
 */
export const apiGetBrandsModelsList = async (): Promise<ApiGetBrandsModelsListReturn> =>
  await http.get({
    url: URL_DATA_DICTIONARY,
  });

/**
 * Запрос поиска транспортного средства.
 * @param props {ApiSearchVehicleListByParamsProps}
 * @param options {HttpProperties}
 * @returns Promise<ApiSearchVehicleListByParamsReturn>
 */
export const apiSearchVehicleListByParams = async (
  props: ApiSearchVehicleListByParamsProps,
  options?: Omit<HttpProperties, 'url'>
): Promise<ApiSearchVehicleListByParamsReturn> =>
  await http.get({
    ...(options || {}),
    url: URL_SEARCH_VEHICLE_BY_PARAMS,
    params: {
      ...props,
    },
  });

/**
 * Запрос поиска транспортного средства.
 * @param props {ApiGetVehicleListByParamsProps}
 * @returns Promise<ApiGetVehicleListByParamsReturn>
 */
export const apiGetVehicleListByParams = async (
  props: ApiGetVehicleListByParamsProps
): Promise<ApiGetVehicleListByParamsReturn> =>
  await http.get({
    url: URL_LIST_VEHICLE_BY_PARAMS,
    params: {
      ...props,
    },
  });
