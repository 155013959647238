import { ValidationFn, LicenceNumberOptions, LicenceSerialOptions } from '../common/types';
import { stringRule } from './sharedRules';
import { licenseNumberForeignRegexp, licenseSerialForeignRegexp } from '../common/constants';
import { INVALID_DOC_NUMBER, INVALID_DOC_SERIAL } from '../common/texts';

export const licenseNumberForeignRule =
  (options?: LicenceNumberOptions): ValidationFn<string> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: licenseNumberForeignRegexp,
      message: options?.feedback?.INVALID_DOC_NUMBER || INVALID_DOC_NUMBER,
    })(value, deps, { ...params, type: 'hard' });

export const licenseSerialForeignRule =
  (options?: LicenceSerialOptions): ValidationFn<string> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: licenseSerialForeignRegexp,
      message: options?.feedback?.INVALID_DOC_SERIAL || INVALID_DOC_SERIAL,
    })(value, deps, { ...params, type: 'hard' });
