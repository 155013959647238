import {
  CANT_BE_EARLIER,
  CANT_BE_LATER,
  CANT_BE_LESS,
  CANT_BE_MORE,
  INVALID_ARRAY_FORMAT,
  INVALID_BOOLEAN_FORMAT,
  INVALID_DADATA,
  INVALID_DATE_FORMAT,
  INVALID_FORMAT,
  INVALID_INT_FORMAT,
  INVALID_NUMAN_NAME,
  INVALID_NUMBER_FORMAT,
  INVALID_OBJ_FORMAT,
  INVALID_STRING_FORMAT,
  REQUIRED,
} from './texts';

export const arrFeedback = {
  REQUIRED,
  INVALID_ARRAY_FORMAT,
};

export const boolFeedback = {
  REQUIRED,
  INVALID_BOOLEAN_FORMAT,
};

export const dadataFeedback = {
  REQUIRED,
  INVALID_OBJ_FORMAT,
  INVALID_DADATA,
};

export const dateFeedback = {
  REQUIRED,
  INVALID_DATE_FORMAT,
  CANT_BE_LATER,
  CANT_BE_EARLIER,
};

export const humanNameFeedback = {
  INVALID_NUMAN_NAME,
};

export const numberFeedback = {
  INVALID_INT_FORMAT,
  REQUIRED,
  INVALID_NUMBER_FORMAT,
  CANT_BE_MORE,
  CANT_BE_LESS,
};

export const objFeedback = {
  REQUIRED,
  INVALID_OBJ_FORMAT,
};

export const stringFeedback = {
  REQUIRED,
  INVALID_FORMAT,
  INVALID_STRING_FORMAT,
};
