import {
  createInputBrandModel,
  InputBrandModelProps,
  InputBrandModelStyle,
} from '@core/common/components/_factories/createInputBrandModel';
import { InputBrandModelBase } from '../InputBrandModelBase';
import s from './InputBrandModel.module.scss';

export type { InputBrandModelProps };

export const InputBrandModel = createInputBrandModel({ InputBrandModelBase }, s as InputBrandModelStyle);

InputBrandModel.displayName = 'InputBrandModel';
