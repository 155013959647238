import { parse } from 'date-fns';

export const parseDate = (date: string | null): Date | null => {
  if (!date) return null;
  if (/\d{2}\.\d{2}\.\d{4}/.test(date)) return parse(date, 'dd.MM.yyyy', new Date());

  const try1 = new Date(date);
  if (!isNaN(try1.getTime())) return try1;
  return null;
};
