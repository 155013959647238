import React, { useEffect } from 'react';
import cn from 'classnames';
import { parseDate } from '@utils/parseDate';
import { getDateString, getErrorText } from '@utils/offers';
import { TableOffersRowProps } from '@core/common/components/_factories/createTableOffers/createTableOffersRow';
import { PartnerLogoType } from '../../../../../../partners/src/components/CompanyLogo';
import { REQUIRED_UPSALES_OPTION } from '@core/constants/shared';
import { useGroupedPartners } from '@core/hooks/usePartners';
import { useEvent } from '@core/hooks/useEvent';
import { OffersHookOffers } from '@core/hooks/useOffers';
import { PolicyOffer } from '@core/features/offer/offer.types';
import s from './createTableOffers.module.scss';

const MINUTE = 60000;

export type TableOffersRawParams = {
  TableOffersRow: React.ComponentType<TableOffersRowProps>;
};

export type TableOffersProps = {
  className?: string;
  logoType?: PartnerLogoType;
  offers: OffersHookOffers | null;
  topOfferId?: number;
  setActiveOffer?: (id: string) => Promise<boolean>;
  showErrorPartners?: boolean;
  onNotFound: () => void;
  withoutUpsalesRow?: (id: number) => boolean;
};

export type TableOffersStyle = {
  root?: string;
  wrapper?: string;
};

export const createTableOffers = <T extends TableOffersProps>(
  { TableOffersRow }: TableOffersRawParams,
  style?: TableOffersStyle
): React.FC<T> => {
  const cls = {
    wrapper: cn(s.wrapper, style?.wrapper),
    root: cn(s.root, style?.root),
  };

  return ({
    className,
    logoType,
    withoutUpsalesRow,
    topOfferId,
    setActiveOffer,
    offers,
    onNotFound,
    showErrorPartners,
  }) => {
    const { okCompanies, notOkCompanies, loadingCompanies } = useGroupedPartners(offers, topOfferId);

    const onTimeout = useEvent(() => {
      if (!okCompanies.length && !loadingCompanies.length) {
        onNotFound();
      }
    });

    useEffect(() => {
      const timeoutId = setTimeout(onTimeout, MINUTE);
      return () => clearTimeout(timeoutId);
    }, [onTimeout]);

    useEffect(onTimeout, [onTimeout, okCompanies.length, loadingCompanies.length]);

    const getDate = (id: string | number): string => getDateString(parseDate(offers?.[id]?.date || null));
    const getPrice = (offer: PolicyOffer | undefined) => offer?.total || 0;

    return (
      <div className={cn(cls.root, className)}>
        <div className={cls.wrapper}>
          {(() => {
            const topOfferCompany = notOkCompanies.find((i) => i.id === topOfferId);
            const topOffer = topOfferCompany && offers?.[topOfferCompany.id]?.results?.[0];
            const { upsaleList, enableCalculationOptionsStep, productOptions } = topOffer || {};

            return (
              topOfferCompany && (
                <TableOffersRow
                  special
                  isTopOffer
                  offer={topOffer}
                  logoType={logoType}
                  withoutUpsales={!!withoutUpsalesRow?.(topOfferCompany.id)}
                  companyName={topOfferCompany.title}
                  companyId={topOfferCompany.id}
                  upsaleList={upsaleList}
                  hasExtraUpsales={enableCalculationOptionsStep}
                  hasExtraRequiredUpsales={productOptions?.includes(REQUIRED_UPSALES_OPTION)}
                  price={getPrice(topOffer)}
                  setActiveOffer={setActiveOffer}
                  date={getDate(topOfferCompany.id)}
                />
              )
            );
          })()}
          {okCompanies.map((company) => {
            const offer = offers?.[company.id]?.results?.[0];
            if (!offer) return null;
            const { productOptions, upsaleList, enableCalculationOptionsStep } = offer;
            return (
              <TableOffersRow
                isTopOffer={topOfferId === company.id}
                logoType={logoType}
                key={company.id}
                offer={offer}
                withoutUpsales={!!withoutUpsalesRow?.(company.id)}
                upsaleList={upsaleList}
                hasExtraUpsales={enableCalculationOptionsStep}
                hasExtraRequiredUpsales={productOptions?.includes(REQUIRED_UPSALES_OPTION)}
                companyName={company.title}
                companyId={company.id}
                price={getPrice(offer)}
                setActiveOffer={setActiveOffer}
                date={getDate(company.id)}
              />
            );
          })}
          {showErrorPartners &&
            notOkCompanies.map((company) => {
              const error = getErrorText(company.title, offers?.[company.id]?.errors?.[0]);
              return (
                <TableOffersRow
                  logoType={logoType}
                  key={company.id}
                  withoutUpsales={!!withoutUpsalesRow?.(company.id)}
                  companyName={company.title}
                  companyId={company.id}
                  error={error}
                  date={getDate(company.id)}
                />
              );
            })}
          {loadingCompanies.map((company) => (
            <TableOffersRow
              logoType={logoType}
              key={company.id}
              withoutUpsales={!!withoutUpsalesRow?.(company.id)}
              companyName={company.title}
              companyId={company.id}
              loading
              date={getDate(company.id)}
            />
          ))}
        </div>
      </div>
    );
  };
};
