import { useStore } from '@core/providers/StoreProvider';

import { getDadataList } from '@core';
import { regions } from '../common/constants/region-code';

export const useRegionCode = () => {
  const store = useStore();

  const initCityByLicense = async (licensePlate: string) => {
    const code = licensePlate.match(/[0-9]{2,3}$/gi);

    if (!code) {
      return false;
    }

    const region = regions.find((reg) => reg.codes.indexOf(code.toString()) !== -1);
    if (!region) {
      return false;
    }

    const dadata = await getDadataList({ addr_text_search: region.name });
    if (dadata.length === 0) {
      return false;
    }

    if (!store) {
      return false;
    }

    const city = dadata[0];

    await store.form.setValueField('owner_city', city.value);
    await store.form.setValueField('owner_city_dadata', city as never);

    return true;
  };

  return { initCityByLicense };
};
