import { PolicyOrderDraft } from '@core/features/order/order.types';

const unknownName = 'Неизвестное название';

export const getFilteredDrafts = (drafts: PolicyOrderDraft[], limit = 3) =>
  drafts.slice(0, limit).map((draft, index) => {
    const brand = (draft.fields.brand || unknownName) as string;
    const model = (draft.fields.model || unknownName) as string;
    const set = new Set([brand, model]);
    const carName = Array.from(set).join(' ');

    const licensePlate = (draft.fields.licensePlate as string) || 'Без номера';

    return {
      step: draft.step,
      index,
      carName,
      brand,
      model,
      licensePlate,
    };
  });
