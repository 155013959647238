import React from 'react';
import type { RouteObject } from '@core';

import {
  URL_INDEX,
  URL_STEP_CAR_DATA,
  URL_STEP_COMPANY_OFFERS,
  URL_STEP_DRIVERS_DATA,
  URL_STEP_OTHER_DATA,
  URL_STEP_OWNER_DATA,
  URL_STEP_PRELIMINARY_CALCULATION,
  URL_STEP_COMPANY_LINK_PAY,
} from '../common/constants/url';

import { MainPage } from '../features/pages/MainPage/MainPage';
import { StepPreliminaryPage } from '../features/pages/StepPreliminaryPage/StepPreliminaryPage';
import { StepCarData } from '../features/pages/StepCarData/StepCarData';
import { StepDriversPage } from '../features/pages/StepDriversPage/StepDriversPage';
import { StepOwnerPage } from '../features/pages/StepOwnerPage/StepOwnerPage';
import { StepOtherData } from '../features/pages/StepOtherData/StepOtherData';
import { StepCompanyOffers } from '../features/pages/StepCompanyOffers/StepCompanyOffers';
import { StepCompanyLinkPay } from '../features/pages/StepCompanyLinkPay/StepCompanyLinkPay';

export const routes: RouteObject[] = [
  {
    path: URL_INDEX,
    index: true,
    element: <MainPage />,
  },
  {
    path: URL_STEP_PRELIMINARY_CALCULATION,
    element: <StepPreliminaryPage />,
  },
  {
    path: URL_STEP_CAR_DATA,
    element: <StepCarData />,
  },
  {
    path: URL_STEP_DRIVERS_DATA,
    element: <StepDriversPage />,
  },
  {
    path: URL_STEP_OWNER_DATA,
    element: <StepOwnerPage />,
  },
  {
    path: URL_STEP_OTHER_DATA,
    element: <StepOtherData />,
  },
  {
    path: URL_STEP_COMPANY_OFFERS,
    element: <StepCompanyOffers />,
  },
  {
    path: URL_STEP_COMPANY_LINK_PAY,
    element: <StepCompanyLinkPay />,
  },
];
