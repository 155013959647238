import React from 'react';
import s from './Preloader.module.scss';
import trobber from '../../../../../ui/ui-bankiros/src/assets/images/icons/trobber.png';

export const Preloader: React.FC<{ text?: string }> = ({ text }) => (
  <div className={s.root}>
    <div className={s.holder}>
      <img src={trobber} alt="" className={s.loader} />
    </div>
    {text && <p className={s.text}>{text}</p>}
  </div>
);
