import { IFieldKey } from '@core';
import { FormDriver } from '@core/features/driver/driver.types';
import { IFormStore } from '@core/store/formStore/formStore.typings';

export const getObjectDriversToValid = (drivers: IFormStore['drivers']) =>
  drivers.map((driver) => {
    const tempDriver: { [key: string]: unknown } = {};
    Object.keys(driver).map((key) => {
      tempDriver[key] = driver[key as keyof FormDriver].value;
    });
    return tempDriver;
  });

export const getObjectToValid = (form: IFormStore) => {
  const objectToValid: Record<string, unknown> = {};

  Object.keys(form.fields).forEach((key) => {
    objectToValid[key] = form.fields[key as IFieldKey].value;
  });

  objectToValid.drivers = getObjectDriversToValid(form.drivers);

  return objectToValid;
};
