import React from 'react';

import { VEHICLE_PURPOSE_LIST } from '@core/constants/calculator-const';
import { CAR_TYPE } from '@core';
import { FormWrapper } from '../../../../../common/components/FormWrapper/FormWrapper';

import { FormRow } from '../../../../../common/components/FormRow';
import { SelectDefault } from '../../../../../common/components/Input/SelectDefault';
import { InputDefault } from '../../../../../common/components/Input/InputDefault';
import { SelectDadata } from '../../../../../common/components/Input/SelectDadata';
import { InputBrandModel } from '../../../../../common/components/Input/InputBrandModel';

export const PreliminaryPageForm: React.FC<{ loading: boolean }> = ({ loading }) => (
  <FormWrapper>
    <InputBrandModel skeleton={loading} viewSelects="fullWidth" columns>
      <InputDefault skeleton={loading} view="fullWidth" placeholder="Год выпуска" name="year" maxLength={4} />
    </InputBrandModel>
    <FormRow columns>
      <SelectDefault skeleton={loading} view="fullWidth" placeholder="Тип ТС" name="type" options={CAR_TYPE} />
      <InputDefault skeleton={loading} view="fullWidth" placeholder="Мощность, л. с." name="power" />
      <SelectDadata
        skeleton={loading}
        view="fullWidth"
        name="owner_city"
        placeholder="Город регистрации собственника"
      />
      <SelectDefault
        skeleton={loading}
        view="fullWidth"
        placeholder="Цель использования"
        name="purpose"
        options={VEHICLE_PURPOSE_LIST}
      />
    </FormRow>
  </FormWrapper>
);
