import React from 'react';
import { observer } from '@core';
import { useInputHandlers } from '@core/hooks/useInputHandlers';
import { InputBaseToStored } from '@core/common/components/_factories/input.types';
import { InputBaseProps } from '../../../../../../ui/ui-bankiros/src/common/components/Input/_common/InputBase';

export const withValidation =
  () =>
  <Props extends InputBaseProps>(InputComponent: React.ComponentType<Props>) =>
    observer(
      ({
        name,
        index,
        validationName,
        validationSubscribe,
        validOnInput = true,
        onBlur,
        ...props
      }: InputBaseToStored<Props>) => {
        const { value, clearAutoValue, flagAuto, error, handleChange, handleBlur } = useInputHandlers({
          index,
          name,
          validOnInput,
          validationName,
          validationSubscribe,
        });

        const blur = (e: React.FocusEvent<HTMLInputElement>) => {
          onBlur?.(e);
          handleBlur();
        };

        return (
          <InputComponent
            {...(props as unknown as Props)}
            name={name}
            clearAutoValue={clearAutoValue}
            flagAuto={flagAuto}
            error={error}
            onChange={handleChange}
            onBlur={blur}
            value={value}
          />
        );
      }
    );
