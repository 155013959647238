import { useEffect } from 'react';
import { apiSearchVehicleListByParams } from '@core/services/api/vehicle';
import { CompanyModel } from '@core/features/vehicle/vehicle.types';

export const useFetchCompaniesModelsByBrandModel = (
  modelId: string | number | undefined | null,
  callback: (args: { companiesModels: CompanyModel[] }) => void
) => {
  useEffect(() => {
    if (!modelId) return;
    const controller = new AbortController();
    apiSearchVehicleListByParams({ id: modelId, q: 'models' }, { signal: controller.signal }).then((_res) => {
      if (_res.results) {
        const companiesModels = _res.results as CompanyModel[];
        callback({ companiesModels });
      }
    });

    return () => controller.abort();
  }, [modelId, callback]);
};
