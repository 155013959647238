import React from 'react';

import s from './LicenseWarning.module.scss';

import warning from '../../../../../assets/images/icons/warning.svg';

export const LicenseWarning = () => (
  <div className={s.licenseWarningContainer}>
    <img src={warning} alt="" />
    <div className={s.licenseWarningText}>
      <div className={s.licenseWarningBold}>Не удалось сделать автозаполнение по гос. номеру</div>
      <div className={s.licenseWarningLight}>
        Чтобы увидеть предварительную стоимость, пожалуйста, заполните необходимые данные
      </div>
    </div>
  </div>
);
