import { IMask } from '@core/common/components/_factories/input.types';
import {
  MaskRecord,
  licencePlateBikeMask,
  licencePlateAutoMask,
  phoneMask,
  createMaskRecord,
  dateMask,
  passportMask,
  driversLicenceMask,
  ptsMask,
  stsMask,
  snilsMask,
} from '../../../../../masks';

export type IMapMask = {
  [Prop in IMask]: MaskRecord;
};

export const mapMask: IMapMask = {
  licensePlateMoto: licencePlateBikeMask,
  licensePlate: licencePlateAutoMask,
  passport: passportMask,
  snils: snilsMask,
  driversLicence: driversLicenceMask,
  phone: phoneMask,
  pts: ptsMask,
  sts: stsMask,
  ets: createMaskRecord('_'.repeat(15), { _: /\d/ }),
  vin: createMaskRecord('_'.repeat(17), { _: /[a-z\d*]/i }),
  bodyNum: createMaskRecord('_'.repeat(12), { _: /[a-z\d]/i }, { length: 9 }),
  chassisNum: createMaskRecord('_'.repeat(6), { _: /\d/i }, { length: 4 }),
  date: dateMask,
  ogrnip: createMaskRecord('_'.repeat(15), { _: /\d/ }),
  kpp: createMaskRecord('_'.repeat(9), { _: /\d/ }),
  inn: createMaskRecord('_'.repeat(12), { _: /\d/ }, { length: -2 }),
};
