import React from 'react';
import cn from 'classnames';

import { observer, IStep, useChangeStep, useStore } from '@core';

import s from './Step.module.scss';

export type StepProps = {
  data: IStep;
  active: boolean;
  className?: string;
};
export const Step: React.FC<StepProps> = observer(({ data, active, className }) => {
  const { changeStepById } = useChangeStep();
  const store = useStore();

  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (store && store.step.currentStep > data.id) {
      changeStepById(data?.id);
    }
  };

  return (
    <div
      data-step-active={active}
      title={data.title}
      className={cn(s.step, active && s.active, className)}
      onClick={onClick}
    />
  );
});

Step.displayName = 'Step';
