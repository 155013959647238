import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import s from './createDropdown.module.scss';

export type DropdownProps = React.HTMLAttributes<HTMLDivElement> & {
  opened: boolean;
};

const RESERVE = 16;

export type DropdownStyle = {
  root: string;
  top: string;
};

export const createDropdown = (style: DropdownStyle, reserve = RESERVE): FC<DropdownProps> => {
  const cls = {
    root: cn(s.root, style.root),
    top: cn(s.top, style.top),
  };

  return ({ className, opened, ...props }) => {
    const [top, setTop] = useState(false);
    const root = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      if (opened) {
        const rect = root.current?.getBoundingClientRect();
        if (!rect) return;
        const outside = rect.bottom - window.innerHeight;
        setTop(outside + reserve > 0);
      } else {
        setTop(false);
      }
    }, [opened]);

    if (!opened) return null;

    return <div className={cn(cls.root, className, top && cls.top)} ref={root} {...props} />;
  };
};
