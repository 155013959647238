import React from 'react';

import s from './DateIcon.module.scss';

import calendar from '../../../../../assets/images/icons/calendar.svg';

export const DateIcon: React.FC<{ view: boolean; onClick: (event: React.MouseEvent<HTMLSpanElement>) => void }> = ({
  view,
  onClick,
}) => {
  if (!view) {
    return null;
  }

  return (
    <span className={s.dateIconWrapper} onClick={onClick}>
      <img src={calendar} alt="" />
    </span>
  );
};
