import React, { FC } from 'react';
import cn from 'classnames';
import s from './Popover.module.scss';

interface PopoverProps {
  onClose?: VoidFunction;
  innerHtml?: string;
  className?: string;
  children?: React.ReactNode;
}

export const Popover: FC<PopoverProps> = ({ className, onClose, innerHtml, children }) => (
  <div className={cn(s.root, className)}>
    {innerHtml ? <div dangerouslySetInnerHTML={{ __html: innerHtml }} /> : children}
    {onClose && <div onClick={onClose} className={s.close} />}
  </div>
);
