import React from 'react';
import cn from 'classnames';

import s from './Button.module.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  styleType?: 'transparent' | 'accent' | 'default' | 'second';
};

export const Button: React.FC<ButtonProps> = ({ className, styleType, ...props }) => (
  <button className={cn(s.root, styleType && s[styleType], className)} {...props} />
);
