export type UpsaleDescription = {
  name: string;
  group: string;
  sk: number;
  text: string;
};

export const companiesWithUpsales = [1, 36, 98, 39, 10];

export const maxUpsales: Record<string, UpsaleDescription> = {
  ns_prg: {
    group: 'accident',
    sk: 10,
    text: `
				<div>
					<p>
					Программа страхования «Добрый путь!» поможет Вам возместить непредвиденные расходы на лечение, если в результате ДТП пострадал водитель или пассажир застрахованного автомобиля.
					</p>
					<p>
						Можно застраховаться от следующих рисков:
					</p>
					<ul>
						<li>
							Травма в результате ДТП (выплата в соответствии с таблицей страховых выплат №1);
						</li>
						<li>
							Инвалидность в результате ДТП (при установлении I группы инвалидности выплата в размере 90% страховой суммы/лимита выплат, II группы – 75%, III группы – 50%, категории «ребенок-инвалид» – 100%);
						</li>
						<li>
							Смерть в результате ДТП (выплата в размере 100% страховой суммы/лимита выплат);
						</li>
						<li>
							Страхование осуществляется по «паушальной системе» (при одновременном страховании водителя и пассажиров). 
						</li>
					</ul>
					<p>
						Лимиты выплат по «паушальной системе»: 				
					</p>
					<ul>
						<li>
							40% от страховой суммы, если пострадало одно застрахованное лицо;
						</li>
						<li>
							35% от страховой суммы, если пострадали два застрахованных лица;
						</li>
						<li>
							30% от страховой суммы, если пострадали три застрахованных лица;
						</li>
						<li>
							В равных долях от страховой суммы, если пострадали более трех застрахованных лиц.
						</li>
					</ul>
				</div>
			`,
    name: '',
  },
  kasko: {
    name: '',
    group: 'kasko',
    sk: 10,
    text: `
      <div>
        <p>В случае аварии с установленным виновником, застрахованным по ОСАГО,  вы получаете направление на ремонт или получите выплату в денежной форме по соглашению в течение 12 дней, вместо 20 по ОСАГО. А все проблемы с взысканием ущерба берет на себя страховая компания.</p>
        <p>
          Страховые риски:
        </p>
        <ul>
          <li>
            «ДТП по вине установленного третьего лица»
          </li>
          <li>
            «Несчастный случай» по паушальной системе (на салон): Риск «Смерть в результате несчастного случая»
          </li>
        </ul>
        <p>
          <strong>Страховая сумма</strong>					
        </p>
        <p>
          Невычитаемая (неагрегатная).
        </p>
        <p>
          По риску «ДТП по вине установленного третьего лица»: <br/>
          Устанавливается в размере действительной стоимости ТС при действительной стоимости до 420&nbsp;000&nbsp;рублей.<br/>
          Устанавливается в размере 420&nbsp;000&nbsp;рублей при действительной стоимости более 420&nbsp;000 рублей.	
        </p>
        <p>
          По риску «Несчастный случай»: 
          Устанавливается в размере 300&nbsp;000&nbsp;рублей
        </p>
      </div>
    `,
  },

  kasko_prg: {
    sk: 10,
    text: `
			<div>
				<p>В случае аварии с установленным виновником, застрахованным по ОСАГО,  вы получаете направление на ремонт или получите выплату в денежной форме по соглашению в течение 12 дней, вместо 20 по ОСАГО. А все проблемы с взысканием ущерба берет на себя страховая компания.</p>
				<p>
					Страховые риски:
				</p>
				<ul>
					<li>
						«ДТП по вине установленного третьего лица»
					</li>
					<li>
						«Несчастный случай» по паушальной системе (на салон): Риск «Смерть в результате несчастного случая»
					</li>
				</ul>
				<p>
					<strong>Страховая сумма</strong>					
				</p>
				<p>
					Невычитаемая (неагрегатная).
				</p>
				<p>
					По риску «ДТП по вине установленного третьего лица»: <br/>
					Устанавливается в размере действительной стоимости ТС при действительной стоимости до 420&nbsp;000&nbsp;рублей.<br/>
					Устанавливается в размере 420&nbsp;000&nbsp;рублей при действительной стоимости более 420&nbsp;000 рублей.	
				</p>
				<p>
					По риску «Несчастный случай»: 
					Устанавливается в размере 300&nbsp;000&nbsp;рублей
				</p>
			</div>
		`,
    group: 'kasko',
    name: '',
  },
};

export const alfaUpsales: Record<string, UpsaleDescription> = {
  ADDITIONAL_PROTECTION_100000: {
    name: 'Покрытие 100&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 100&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  ADDITIONAL_PROTECTION_200000: {
    name: 'Покрытие 200&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 200&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  ADDITIONAL_PROTECTION_240000: {
    name: 'Покрытие 240&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 240&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  ADDITIONAL_PROTECTION_280000: {
    name: 'Покрытие 280&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 280&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  ADDITIONAL_PROTECTION_400000: {
    name: 'Покрытие 400&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 400&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  KASKO500: {
    name: 'КАСКО от бесполисных',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП при отсутствии полиса ОСАГО у виновника (виновник установлен). Возмещение до 400&nbsp;000&nbsp;руб.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  KASKO1: {
    name: 'КАСКО от чужих ошибок',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП по вине установленных третьих лиц. Возмещение до 400&nbsp;000&nbsp;руб. Хищение не включено в покрытие.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  KASKO3: {
    name: 'КАСКО за 3',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП по вине установленных третьих лиц. Возмещение до 600&nbsp;000&nbsp;руб. Хищение не включено в покрытие.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  KASKO5: {
    name: 'КАСКО ПЯТЬ',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП по вине установленных третьих лиц. Возмещение до 100 % стоимости ТС. Хищение не включено в покрытие.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  KASKO10: {
    name: 'КАСКО в десятку',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>Хищение и ДТП по вине установленных третьих лиц. Возмещение до 100 % стоимости ТС.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  KASKOGO: {
    name: 'KASKOGO',
    group: '',
    sk: 1,
    text: `
			<div>
				<p>Расширенный лимит до 600 000 рублей, если ДТП не по вашей вине - ремонт как по КАСКО.</p>
			</div>
		`,
  },
};

export const renaissanceUpsales: Record<string, UpsaleDescription> = {
  'KaskoUpsaleForEOsagoMultidrive LINK_IRIS_19': {
    name: 'Каско - Падение предметов',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p>Особенности продукта:</p>
				<p>
					Легковые ТС (категория В), со сроком эксплуатации до 10 лет.<br/>
					Страхование производится без осмотра ТС.
				</p>
				<p>Продукт предусматривает страхование от следующих рисков:</p>
				<p><strong>«Падение на ТС инородных предметов»</strong></p>
				<p>
					Повреждения ТС в результате падения снега, наледи, деревьев и/или их частей, рекламных щитов, элементов конструкций зданий и сооружений.<br/>
					Не является страховым случаем повреждение ветрового стекла без повреждения иных элементов ТС.
				</p>
				<ul>
					<li>
						Лимит возмещения – «По первому страховому случаю».
					</li>
					<li>
						Страховая сумма по этим рискам – 400 000 руб.
					</li>
					<li>
						Вне зависимости от характера и степени повреждения ТС, обращение в компетентные органы является обязательным.
					</li>
				</ul>
			</div>
		`,
  },
  'KaskoUpsaleForEOsagoPool LINK_IRIS_18': {
    name: 'Каско - Стихийные бедствия',
    group: 'kasko',
    sk: 36,
    text: `
      <div>
        <p>
          Страховая сумма - 400 000 ₽
        </p>
        <p>Что застраховано - застрахован риск «Ущерб» только в результате стихийного бедствия</p>
      </div>
    `,
  },
  'KaskoUpsaleForEOsagoMultidrive LINK_IRIS_18': {
    name: 'Каско - Стихийные бедствия',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p>Особенности продукта:</p>
				<p>
					Легковые ТС (категория В), со сроком эксплуатации до 10 лет.<br/>
					Страхование производится без осмотра ТС.
				</p>
				<p>Продукт предусматривает страхование от следующих рисков:</p>
				<p><strong>«Стихийные бедствия»</strong></p>
				<p>
					Стихийное бедствие – наводнение, затопление; удар молнии; сильный ветер (скорость ветра не менее 60 км/ч (16,7 м/с)); град, оползень, сель, землетрясение, обвал, оседание грунта, если факт наступления события подтвержден документально соответствующими гидрометеорологическими службами и/или иными компетентными органами.
				</p>
				<ul>
					<li>
						Лимит возмещения – «По первому страховому случаю».
					</li>
					<li>
						Страховая сумма по этим рискам – 400 000 руб.
					</li>
					<li>
						Вне зависимости от характера и степени повреждения ТС, обращение в компетентные органы является обязательным.
					</li>
				</ul>
			</div>
		`,
  },
  'KaskoUpsaleForEOsagoMultidrive LINK_IRIS_20': {
    name: 'Каско - Стихийные бедствия + Падение предметов',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p>Особенности продукта:</p>
				<p>
					Легковые ТС (категория В), со сроком эксплуатации до 10 лет.<br/>
					Страхование производится без осмотра ТС.
				</p>
				<p>Продукт предусматривает страхование от следующих рисков:</p>
				<p><strong>«Падение на ТС инородных предметов»</strong></p>
				<p>
					Повреждения ТС в результате падения снега, наледи, деревьев и/или их частей, рекламных щитов, элементов конструкций зданий и сооружений.<br/>
					Не является страховым случаем повреждение ветрового стекла без повреждения иных элементов ТС.
				</p>
				<p><strong>«Стихийные бедствия»</strong></p>
				<p>
					Стихийное бедствие – наводнение, затопление; удар молнии; сильный ветер (скорость ветра не менее 60 км/ч (16,7 м/с)); град, оползень, сель, землетрясение, обвал, оседание грунта, если факт наступления события подтвержден документально соответствующими гидрометеорологическими службами и/или иными компетентными органами.
				</p>
				<ul>
					<li>
						Лимит возмещения – «По первому страховому случаю».
					</li>
					<li>
						Страховая сумма по этим рискам – 400 000 руб.
					</li>
					<li>
						Вне зависимости от характера и степени повреждения ТС, обращение в компетентные органы является обязательным.
					</li>
				</ul>
			</div>
		`,
  },
  'KaskoUpsaleForEOsagoPool LINK_IRIS_20': {
    name: 'МИНИ КАСКО «Стихийные бедствия + Падение на ТС инородных предметов»',
    sk: 36,
    text: `
			<div>
				<p>Срок страхования: 1 год</p>
				<p>Страховая сумма: 200 000</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						"Ущерб" только в результате стихийного бедствия и/или падения на ТС инородных предметов
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  KaskoNoPolicyUpsale: {
    name: 'КАСКО от бесполисных',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p><strong>Страховка от бесполисных. Покрывает случаи когда наш клиент не виноват, есть установленный виновник, при этом у виновника нет полиса ОСАГО или он поддельный</strong></p>
	
				<p>
					Страхователь: ФЛ
				</p>
				<p>
					ТС: B категория от 0 до 15 лет включительно
				</p>
				<p>
					Осмотр: не требуется
				</p>
				<p>
					Срок страхования: 1 год
				</p>
				
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Риски:

						<ul>
							<li>Ущерб при ДТП со 2-м участником, наш клиент не виноват, есть установленный виновник, у виновника нет ОСАГО</li>
						</ul>
					</li>
					<li>
						Урегулирование:

						<ul>
							<li>Ремонт на СТОА по направлению Страховщика</li>
						</ul>
					</li>
				</ul>
				<p>Страховые суммы:</p>
				<ul>
					<li>
						<strong>400 000 руб</strong>						
					</li>
				</ul>
			</div>
		`,
  },
  AccidentInsurantExpressMiniForEOsago: {
    name: 'НС Экспресс-мини для страхователя',
    sk: 36,
    text: `
			<div>
				<p>Срок страхования - 1 месяц</p>
				<p>Риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая
					</li>
					<li>
						Инвалидность в результате несчастного случая
					</li>
					<li>
						Телесные повреждения в результате несчастного случая
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  AccidentDriversExpressMiniForEOsago: {
    name: 'НС Экспресс-мини для водителя',
    sk: 36,
    text: `
			<div>
				<p>Срок страхования - 1 месяц</p>
				<p>Риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая
					</li>
					<li>
						Инвалидность в результате несчастного случая
					</li>
					<li>
						Телесные повреждения в результате несчастного случая
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  AccidentPassengersForEOsago350: {
    name: 'Страховка пассажиров от несчастного случая при ДТП (350&nbsp;000&nbsp;₽)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: все, кто находился в машине в момент ДТП, до 5 человек (водитель и пассажиры) в возрасте от 18 до 70 лет.</p>
				<p>Срок страхования: 1 год</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая при ДТП<br />
						Размер выплаты: 100%
					</li>
					<li>
						Инвалидность в результате несчастного случая при ДТП<br />
						Размер выплаты: в зависимости от группы инвалидности: 1 — 100%, 2 — 75%, 3 — 50%
					</li>
					<li>
						Телесные повреждения в результате несчастного случая при ДТП<br />
						Размер выплаты: в соответствии с таблицей выплат
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  AccidentPassengersForEOsago700: {
    name: 'Страховка пассажиров от несчастного случая при ДТП (700&nbsp;000&nbsp;₽)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: все, кто находился в машине в момент ДТП, до 5 человек (водитель и пассажиры) в возрасте от 18 до 70 лет.</p>
				<p>Срок страхования: 1 год</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая при ДТП<br />
						Размер выплаты: 100%
					</li>
					<li>
						Инвалидность в результате несчастного случая при ДТП<br />
						Размер выплаты: в зависимости от группы инвалидности: 1 — 100%, 2 — 75%, 3 — 50%
					</li>
					<li>
						Телесные повреждения в результате несчастного случая при ДТП<br />
						Размер выплаты: в соответствии с таблицей выплат
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  AccidentPassengersPoolForEOsago150: {
    name: 'НС Апсейл. Пассажиры покрытие - до&nbsp;150&nbsp;000&nbsp;рублей',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Телесные повреждения в результате несчастного случая
					</li>
					<li>
						Инвалидность в результате несчастного случая
					</li>
					<li>
						Смерть в результате несчастного случая
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },

  AccidentPassengersForEOsago1000: {
    name: 'Страховка пассажиров от несчастного случая при ДТП (1&nbsp;000&nbsp;000&nbsp;₽)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: все, кто находился в машине в момент ДТП, до 5 человек (водитель и пассажиры) в возрасте от 18 до 70 лет.</p>
				<p>Срок страхования: 1 год</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая при ДТП<br />
						Размер выплаты: 100%
					</li>
					<li>
						Инвалидность в результате несчастного случая при ДТП<br />
						Размер выплаты: в зависимости от группы инвалидности: 1 — 100%, 2 — 75%, 3 — 50%
					</li>
					<li>
						Телесные повреждения в результате несчастного случая при ДТП<br />
						Размер выплаты: в соответствии с таблицей выплат
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  AccidentInsurantExpressMiniForEOsago125: {
    name: ' НС Экспресс-мини (страховая сумма 125&nbsp;000&nbsp;рублей)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: страхователь</p>
				<p>ТС: все категории, кроме A</p>
				<p>Срок страхования: 1 месяц</p>
				<p>Застрахованные риски и условия выплаты:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая<br/>
						Размер выплаты: 100% страховой суммы
					</li>
					<li>
						Инвалидность в результате несчастного случая<br/>
						Размер выплаты: при установлении 1-й группы инвалидности – 100% страховой суммы; при установлении 2-й группы инвалидности – 75% от страховой суммы; при установлении 3-й группы инвалидности – 50% от страховой суммы;
					</li>
					<li>
						Телесные повреждения в результате несчастного случая<br/>
						Размер выплаты: % от страховой суммы в соответствии с Таблицей размеров страховых выплат из 83 пунктов
					</li>
				</ul>
				<p>Общая сумма выплат по страховым случаям, произошедшим в течение срока действия Полиса, не может превышать размера общей страховой суммы. Выплата страхового возмещения производится в течение 14 (четырнадцать) банковских дней с момента получения Страховщиком всех необходимых и дополнительно затребованных документов наличными денежными средствами или безналичным переводом в рублях.</p>
			</div>
		`,
    group: 'accident',
  },
};

export const allUpsalesExtendedData: Record<string, UpsaleDescription> = {
  ...maxUpsales,
  ...alfaUpsales,
  ...renaissanceUpsales,
};

export const options = [
  {
    key: 'KaskoUpsaleForEOsagoMultidrive LINK_IRIS_19',
    name: 'Каско - Падение предметов',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p>Особенности продукта:</p>
				<p>
					Легковые ТС (категория В), со сроком эксплуатации до 10 лет.<br/>
					Страхование производится без осмотра ТС.
				</p>
				<p>Продукт предусматривает страхование от следующих рисков:</p>
				<p><strong>«Падение на ТС инородных предметов»</strong></p>
				<p>
					Повреждения ТС в результате падения снега, наледи, деревьев и/или их частей, рекламных щитов, элементов конструкций зданий и сооружений.<br/>
					Не является страховым случаем повреждение ветрового стекла без повреждения иных элементов ТС.
				</p>
				<ul>
					<li>
						Лимит возмещения – «По первому страховому случаю».
					</li>
					<li>
						Страховая сумма по этим рискам – 400 000 руб.
					</li>
					<li>
						Вне зависимости от характера и степени повреждения ТС, обращение в компетентные органы является обязательным.
					</li>
				</ul>
			</div>
		`,
  },
  {
    key: 'KaskoUpsaleForEOsagoMultidrive LINK_IRIS_18',
    name: 'Каско - Стихийные бедствия',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p>Особенности продукта:</p>
				<p>
					Легковые ТС (категория В), со сроком эксплуатации до 10 лет.<br/>
					Страхование производится без осмотра ТС.
				</p>
				<p>Продукт предусматривает страхование от следующих рисков:</p>
				<p><strong>«Стихийные бедствия»</strong></p>
				<p>
					Стихийное бедствие – наводнение, затопление; удар молнии; сильный ветер (скорость ветра не менее 60 км/ч (16,7 м/с)); град, оползень, сель, землетрясение, обвал, оседание грунта, если факт наступления события подтвержден документально соответствующими гидрометеорологическими службами и/или иными компетентными органами.
				</p>
				<ul>
					<li>
						Лимит возмещения – «По первому страховому случаю».
					</li>
					<li>
						Страховая сумма по этим рискам – 400 000 руб.
					</li>
					<li>
						Вне зависимости от характера и степени повреждения ТС, обращение в компетентные органы является обязательным.
					</li>
				</ul>
			</div>
		`,
  },
  {
    key: 'KaskoUpsaleForEOsagoMultidrive LINK_IRIS_20',
    name: 'Каско - Стихийные бедствия + Падение предметов',
    group: 'kasko',
    sk: 36,
    text: `
			<div>
				<p>Особенности продукта:</p>
				<p>
					Легковые ТС (категория В), со сроком эксплуатации до 10 лет.<br/>
					Страхование производится без осмотра ТС.
				</p>
				<p>Продукт предусматривает страхование от следующих рисков:</p>
				<p><strong>«Падение на ТС инородных предметов»</strong></p>
				<p>
					Повреждения ТС в результате падения снега, наледи, деревьев и/или их частей, рекламных щитов, элементов конструкций зданий и сооружений.<br/>
					Не является страховым случаем повреждение ветрового стекла без повреждения иных элементов ТС.
				</p>
				<p><strong>«Стихийные бедствия»</strong></p>
				<p>
					Стихийное бедствие – наводнение, затопление; удар молнии; сильный ветер (скорость ветра не менее 60 км/ч (16,7 м/с)); град, оползень, сель, землетрясение, обвал, оседание грунта, если факт наступления события подтвержден документально соответствующими гидрометеорологическими службами и/или иными компетентными органами.
				</p>
				<ul>
					<li>
						Лимит возмещения – «По первому страховому случаю».
					</li>
					<li>
						Страховая сумма по этим рискам – 400 000 руб.
					</li>
					<li>
						Вне зависимости от характера и степени повреждения ТС, обращение в компетентные органы является обязательным.
					</li>
				</ul>
			</div>
		`,
  },
  {
    key: 'KaskoUpsaleForEOsagoPool LINK_IRIS_20',
    name: 'МИНИ КАСКО «Стихийные бедствия + Падение на ТС инородных предметов»',
    sk: 36,
    text: `
			<div>
				<p>Срок страхования: 1 год</p>
				<p>Страховая сумма: 200 000</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						"Ущерб" только в результате стихийного бедствия и/или падения на ТС инородных предметов
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentInsurantExpressMiniForEOsago',
    name: 'НС Экспресс-мини для страхователя',
    sk: 36,
    text: `
			<div>
				<p>Срок страхования - 1 месяц</p>
				<p>Риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая
					</li>
					<li>
						Инвалидность в результате несчастного случая
					</li>
					<li>
						Телесные повреждения в результате несчастного случая
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentDriversExpressMiniForEOsago',
    name: 'НС Экспресс-мини для водителя',
    sk: 36,
    text: `
			<div>
				<p>Срок страхования - 1 месяц</p>
				<p>Риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая
					</li>
					<li>
						Инвалидность в результате несчастного случая
					</li>
					<li>
						Телесные повреждения в результате несчастного случая
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentPassengersForEOsago350',
    name: 'Страховка пассажиров от несчастного случая при ДТП (350&nbsp;000&nbsp;₽)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: все, кто находился в машине в момент ДТП, до 5 человек (водитель и пассажиры) в возрасте от 18 до 70 лет.</p>
				<p>Срок страхования: 1 год</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая при ДТП<br />
						Размер выплаты: 100%
					</li>
					<li>
						Инвалидность в результате несчастного случая при ДТП<br />
						Размер выплаты: в зависимости от группы инвалидности: 1 — 100%, 2 — 75%, 3 — 50%
					</li>
					<li>
						Телесные повреждения в результате несчастного случая при ДТП<br />
						Размер выплаты: в соответствии с таблицей выплат
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentPassengersForEOsago700',
    name: 'Страховка пассажиров от несчастного случая при ДТП (700&nbsp;000&nbsp;₽)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: все, кто находился в машине в момент ДТП, до 5 человек (водитель и пассажиры) в возрасте от 18 до 70 лет.</p>
				<p>Срок страхования: 1 год</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая при ДТП<br />
						Размер выплаты: 100%
					</li>
					<li>
						Инвалидность в результате несчастного случая при ДТП<br />
						Размер выплаты: в зависимости от группы инвалидности: 1 — 100%, 2 — 75%, 3 — 50%
					</li>
					<li>
						Телесные повреждения в результате несчастного случая при ДТП<br />
						Размер выплаты: в соответствии с таблицей выплат
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentPassengersPoolForEOsago150',
    name: 'НС Апсейл. Пассажиры покрытие - до&nbsp;150&nbsp;000&nbsp;рублей',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Телесные повреждения в результате несчастного случая
					</li>
					<li>
						Инвалидность в результате несчастного случая
					</li>
					<li>
						Смерть в результате несчастного случая
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentPassengersForEOsago1000',
    name: 'Страховка пассажиров от несчастного случая при ДТП (1&nbsp;000&nbsp;000&nbsp;₽)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: все, кто находился в машине в момент ДТП, до 5 человек (водитель и пассажиры) в возрасте от 18 до 70 лет.</p>
				<p>Срок страхования: 1 год</p>
				<p>Застрахованные риски:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая при ДТП<br />
						Размер выплаты: 100%
					</li>
					<li>
						Инвалидность в результате несчастного случая при ДТП<br />
						Размер выплаты: в зависимости от группы инвалидности: 1 — 100%, 2 — 75%, 3 — 50%
					</li>
					<li>
						Телесные повреждения в результате несчастного случая при ДТП<br />
						Размер выплаты: в соответствии с таблицей выплат
					</li>
				</ul>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'AccidentInsurantExpressMiniForEOsago125',
    name: ' НС Экспресс-мини (страховая сумма 125&nbsp;000&nbsp;рублей)',
    sk: 36,
    text: `
			<div>
				<p>Застрахованные: страхователь</p>
				<p>ТС: все категории, кроме A</p>
				<p>Срок страхования: 1 месяц</p>
				<p>Застрахованные риски и условия выплаты:</p>
				<ul>
					<li>
						Смерть в результате несчастного случая<br/>
						Размер выплаты: 100% страховой суммы
					</li>
					<li>
						Инвалидность в результате несчастного случая<br/>
						Размер выплаты: при установлении 1-й группы инвалидности – 100% страховой суммы; при установлении 2-й группы инвалидности – 75% от страховой суммы; при установлении 3-й группы инвалидности – 50% от страховой суммы;
					</li>
					<li>
						Телесные повреждения в результате несчастного случая<br/>
						Размер выплаты: % от страховой суммы в соответствии с Таблицей размеров страховых выплат из 83 пунктов
					</li>
				</ul>
				<p>Общая сумма выплат по страховым случаям, произошедшим в течение срока действия Полиса, не может превышать размера общей страховой суммы. Выплата страхового возмещения производится в течение 14 (четырнадцать) банковских дней с момента получения Страховщиком всех необходимых и дополнительно затребованных документов наличными денежными средствами или безналичным переводом в рублях.</p>
			</div>
		`,
    group: 'accident',
  },
  {
    key: 'astro_volga',
    name: '«Автозащита +»',
    group: 'accident',
    sk: 98,
    text: `
			<div>
				<p>Защита водителя от НС в результате ДТП, когда ОСАГО отсутствует или не работает:</p>
				<ul>
					<li>
						Водитель пострадал в результате ДТП по своей вине
					</li>
					<li>
						Водитель, получивший травму в результате ДТП, получит страховое возмещение, даже если нет второго участника ДТП
					</li>
					<li>
						Возмещение вреда жизни и здоровью водителя если у виновника ДТП нет полиса ОСАГО
					</li>
				</ul>
			</div>
		`,
    alwaysSelected: 1,
  },
  {
    key: 'Покрытие 100 000 ₽',
    name: 'Покрытие 100&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 100&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'Покрытие 200 000 ₽',
    name: 'Покрытие 200&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 200&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'Покрытие 240 000 ₽',
    name: 'Покрытие 240&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 240&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'Покрытие 280 000 ₽',
    name: 'Покрытие 280&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 280&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'Покрытие 400 000 ₽',
    name: 'Покрытие 400&nbsp;000&nbsp;₽',
    group: '397',
    sk: 1,
    text: `
			<div>
				<p>При страховом случае (ДТП) страховая компания осуществит выплату, которую можно потратить на лечение.</p>
				<p>Общее страховое покрытие: 400&nbsp;000&nbsp;руб.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/pravila-straxovaniya-zhizni-i-zdorovya-ot-neschastnyx-sluchaev-i-boleznej.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'КАСКО от бесполисных',
    name: 'КАСКО от бесполисных',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП при отсутствии полиса ОСАГО у виновника (виновник установлен). Возмещение до 400&nbsp;000&nbsp;руб.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'КАСКО от чужих ошибок',
    name: 'КАСКО от чужих ошибок',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП по вине установленных третьих лиц. Возмещение до 400&nbsp;000&nbsp;руб. Хищение не включено в покрытие.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'КАСКО за 3',
    name: 'КАСКО за 3',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП по вине установленных третьих лиц. Возмещение до 600&nbsp;000&nbsp;руб. Хищение не включено в покрытие.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'КАСКО ПЯТЬ',
    name: 'КАСКО ПЯТЬ',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>ДТП по вине установленных третьих лиц. Возмещение до 100 % стоимости ТС. Хищение не включено в покрытие.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
  {
    key: 'КАСКО в десятку',
    name: 'КАСКО в десятку',
    group: '046',
    sk: 1,
    text: `
			<div>
				<p>Хищение и ДТП по вине установленных третьих лиц. Возмещение до 100 % стоимости ТС.</p>
				<p>Лимит возмещения составляет 100% от страховой суммы, число обращений не ограничено, а ремонт будет сделан с использованием новых запчастей у официальных дилеров марки, если автомобиль не старше пяти лет, или на лучших сертифицированных станциях техобслуживания.</p>
				<p>
					<a href="https://www.alfastrah.ru/docs/Prav_strah_sredstv_naz_transp.pdf" target="_blank">Условия страхования</a>
				</p>
			</div>
		`,
  },
];

export const groups: { [key: string]: string } = {
  kasko: 'Добавить к полису ОСАГО дополнительную защиту КАСКО',
  accident: 'Добавить к полису ОСАГО защиту от несчастного случая при ДТП',
  astro_volga: 'В стоимость включено дополнительное страхование',
  all: '',
  '046': 'Добавить к полису ОСАГО дополнительную защиту КАСКО',
  '397': 'Добавить к полису ОСАГО защиту от несчастного случая при ДТП',
  null: ' NULL ',
  required: 'В стоимость включена дополнительная защита',
  required_papper: 'В стоимость включена дополнительная защита',
};

const textCompanyOptionLabelsOne =
  'Доступны дополнительные виды страхования. Вы сможете выбрать их перед оплатой, если захотите увеличить страховое покрытие своих рисков.';
const textCompanyOptionLabelsTwo = 'В стоимость включена дополнительная защита — полис «Автозащита +»';

export const companyOptionLabels = {
  36: textCompanyOptionLabelsOne,
  1: textCompanyOptionLabelsOne,
  39: textCompanyOptionLabelsOne,
  10: textCompanyOptionLabelsOne,
  98: textCompanyOptionLabelsTwo,
};
