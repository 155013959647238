import { Symbols } from '../types';
import { createMaskRecord } from '../createMaskRecord';

const symbols: Symbols = {
  0: /\d/,
  A: /[АВЕКМНОРСТУХ]/,
};

export const licencePlateAutoMask = createMaskRecord('A 000 AA 000', symbols, { length: -1 });
export const licencePlateBikeMask = createMaskRecord('0000 AA 000', symbols, { length: -1 });
