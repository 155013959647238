import type { FormDriver } from '@core/features/driver/driver.types';
import { createFormField } from '@core/features/field/helpers/createFormField';

export const createEmptyDriverForm = (): FormDriver => ({
  lastname: createFormField(''),
  firstname: createFormField(''),
  middlename: createFormField(''),
  birthdate: createFormField(''),
  expdate: createFormField(''),
  licenseSerial: createFormField(''),
  licenseNumber: createFormField(''),
  licenseSerialForeign0: createFormField(''),
  licenseNumberForeign0: createFormField(''),
  licenseForeign: createFormField(0),
  id: createFormField(null),
  driver: createFormField(false),
  idx: createFormField(null),
  age: createFormField(null),
  exp: createFormField(null),
  prevLicense: createFormField(0),
  prevLicenseForeign: createFormField(0),
  prevLicenseSerial: createFormField(''),
  prevLicenseNumber: createFormField(''),
  prevLicenseSerialForeign0: createFormField(''),
  prevLicenseNumberForeign0: createFormField(''),
  prevLicenseLastname: createFormField(''),
  prevLicenseFirstname: createFormField(''),
  prevLicenseMiddlename: createFormField(''),
  prevLicenseDate: createFormField(''),
  expYearOnly: createFormField(0),
  expyear: createFormField(''),
  prevLicenseName: createFormField(0),
});
