import {
  getActiveOffersStorage,
  getOffersStorage,
  getOrderKeyStorage,
  initCalculator,
  removeOffersStorage,
  setActiveOffersStorage,
  setOffersStorage,
} from '@core';
import { ICalcInitStore } from './calcInitStore.typings';
import { getPayLink } from '../../features/offer/helpers/getPayLink';
import { errors } from '@core/constants/texts';
import { getTotalSum } from '@core/features/upsale/helpers/helpers';

export const calcInitStore: ICalcInitStore = {
  cachedLinks: {},
  calcIdsData: {},
  prefix: '',
  idAgent: '',
  urlPrefix: [],
  upsales: {
    notRequired: false,
    required: false,
  },
  selectedUpsales: {},
  activeOffer: null,
  topOffer: null,

  setSelectedUpsales(selectedUpsales) {
    this.selectedUpsales = selectedUpsales || {};
  },

  setActiveOffer(offer) {
    this.activeOffer = offer;
  },

  resetSelectedUpsales() {
    this.selectedUpsales = {};
  },

  get totalSum() {
    if (!this.activeOffer) {
      return 0;
    }

    const policySum = this.activeOffer.total ?? 0;

    return Math.ceil(getTotalSum(policySum, this.activeOffer.upsaleList, this.selectedUpsales));
  },

  setPrefix(prefix) {
    this.prefix = prefix;
  },

  initIdAgent(idAgent) {
    this.idAgent = idAgent;
  },

  async initCalculatorToStore(props) {
    this.calcIdsData = await initCalculator(props);
    return this.calcIdsData || {};
  },

  offersData: {
    offers: {},
    dateCreate: null,
    idOrder: '',
  },

  async setOffersData(offers) {
    const idOrder = (await getOrderKeyStorage()) || '';
    const oldOffers = await getOffersStorage();

    if (oldOffers?.offersData?.offers) {
      oldOffers.offersData.offers = Object.assign(oldOffers.offersData.offers, offers);
      await setOffersStorage(oldOffers.offersData);
    }

    this.offersData = {
      idOrder,
      offers: Object.assign({}, offers),
      dateCreate: new Date(),
    };
    await setOffersStorage(this.offersData);
  },

  clearOffersData() {
    this.offersData = {
      offers: {},
      dateCreate: null,
      idOrder: '',
    };

    removeOffersStorage();
  },

  async setActiveOfferData(id, offer) {
    const oldActiveOffers = (await getActiveOffersStorage()) || {};

    if (oldActiveOffers[id]) {
      return true;
    }

    const currentOffer = {
      offer: Object.assign({}, offer),
      link: '',
      dateCreate: new Date(),
      loading: false,
      error: '',
    };

    const activeOffers = {
      [id]: currentOffer,
    };

    await setActiveOffersStorage(Object.assign(oldActiveOffers, activeOffers));
    return true;
  },

  async getLinkPay(...args) {
    const [company, eId, phone, sk, selectedUpsalesKeys] = args;
    const cachedKey = JSON.stringify(args);

    if (this.cachedLinks[cachedKey]) {
      return this.cachedLinks[cachedKey];
    }

    return getPayLink(
      company,
      eId,
      phone,
      `https://osago.finuslugi.ru/${this.prefix}#company=${sk}&success=1&calculation=4`,
      `https://osago.finuslugi.ru/${this.prefix}#company=${sk}&success=0&calculation=4`,
      selectedUpsalesKeys && `?upsaleSelectedList[]=${selectedUpsalesKeys.join('&upsaleSelectedList[]=')}`
    ).then((res) => {
      // todo/arkadii avoid this temp solution
      if (typeof res === 'object' && res?.error === '109') {
        throw new Error('code:109');
      }

      if (typeof res !== 'string') {
        throw new Error(res?.error ?? errors.pay_link_error);
      }

      this.cachedLinks = {
        ...this.cachedLinks,
        [cachedKey]: res,
      };

      return res;
    });
  },

  sendOffersStatus: false,
};
