import React, { useEffect } from 'react';
import { observer, useStore } from '@core';

import cross from '@assets/images/icons/cross.svg';
import { Switch } from '../../../../../common/components/Input/Switch';

import { DriverFormItem } from '../DriverFormItem/DriverFormItem';

import { useValidator } from '@core/providers/ValidatorProvider';
import s from './DriversForm.module.scss';
import { Button } from '../../../../../common/components/Button';

export const DriversForm: React.FC = observer(() => {
  const store = useStore();

  const { subscribe } = useValidator();

  const enable = !store.form.fields.multidrive.value;

  useEffect(() => {
    if (enable) return subscribe('drivers');
  }, [enable, subscribe]);

  return (
    <>
      <div className={s.driversFormHeading}>
        <Switch name="multidrive">Полис без ограничений, для любого количества водителей</Switch>
      </div>
      {!store.form.fields.multidrive.value && (
        <div>
          {store.form.drivers.map((_, index) => (
            <div key={index} className={s.driversFormBox}>
              <DriverFormItem index={index} />

              {index === store.form.drivers.length - 1 && (
                <Button className={s.add} onClick={() => store.form.addDrivers()}>
                  <img alt="add" src={cross} />
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
});
