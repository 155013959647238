import { createOfferEmailField } from '@core/common/components/_factories/createOfferEmailField';
import { InputDefault } from '../../../../common/components/Input/InputDefault';
import edit from '../../../../assets/images/icons/edit.svg';
import s from './OfferEmailField.module.scss';

export const OfferEmailField = createOfferEmailField(
  {
    InputDefault,
    edit,
  },
  s
);
