import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import type { RouteObject } from 'react-router-dom';
import { Router } from '../../../router/Router';
import type { ISteps } from '../../../store/stepStore/stepStore.typings';
import { useStore } from '../../../providers/StoreProvider';
import { useStartCalc } from '../../../hooks/useStartCalc';
import { useSendHeightOutside } from '../../../hooks/useSendHeightOutside';
import { chatOptions, useChat } from '../../../hooks/useChat';
import { IRootStore } from '../../../store/rootStore.typings';
import { useLicencePlateUrl } from '@core/hooks/useLicencePlateUrl';
import { useResetFormByFirstStep } from '@core/hooks/useResetFormByFirstStep';
import { useClearDraftByStep } from '@core/hooks/useClearDraftByStep';
import { useCompaniesModelsByModelId } from '@core/hooks/useCompaniesModelsByModelId';
import { useEvent } from '@core/hooks/useEvent';
import { useFormFieldsSideEffect } from '@core/hooks/useFormFieldsSideEffect';
import { UpsaleSettings } from '@core/features/upsale/upsale.types';
import { StorageProvider } from '@core/common/components/StorageProvider/StorageProvider';
import { TStorage } from '@core/services/storage';

const useRemoteSettings = (store: IRootStore) => {
  useEffect(() => {
    /** FIXME: SAVE ALL */
    if (window.top) {
      window.top.postMessage('ready', '*');
    }

    function initSettings(e: MessageEvent) {
      if (e && e.data && typeof e.data === 'string' && e.data.indexOf('settings:') >= 0) {
        let settings: { url_get_params_str?: string; topOffer?: string };
        try {
          settings = JSON.parse(e.data.substring(9));
        } catch (err) {
          settings = {};
        }

        if (settings.url_get_params_str) {
          const newParams = [];
          const searchParams = new URLSearchParams(settings.url_get_params_str.replace('?', ''));
          if (searchParams.has('partner_id')) {
            newParams.push({ partner_id: searchParams.get('partner_id') || '' });
          }
          store.calc.urlPrefix = newParams;
        }

        if (settings.topOffer) {
          store.calc.topOffer = settings.topOffer;
        }

        window.removeEventListener('message', initSettings);
      }
    }

    window.addEventListener('message', initSettings);

    return () => {
      window.removeEventListener('message', initSettings);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useAppChatInit = (store: IRootStore, props: RouterProps) => {
  const { startChat } = useChat();
  const { prefix, steps, idAgent, chat, stepForOpenSavedOrder = 3 } = props;

  useEffect(() => {
    // todo/arkadii имеет смысл комплексный апдейт сделать на основе всего конфига разом
    //  store.applyConfig(settings)
    store?.calc.setPrefix(prefix);
    store?.step.initSteps(steps, stepForOpenSavedOrder);

    if (idAgent) {
      store?.calc.initIdAgent(idAgent);
    }

    if (chat) {
      startChat(chat);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useDisablePartners = (store: IRootStore, props: RouterProps) => {
  const { disabledInsuranceCompanies = [], enabledInsuranceCompanies = null } = props;

  const disablePartners = useEvent<VoidFunction>(() =>
    store.partner.disablePartners(disabledInsuranceCompanies, enabledInsuranceCompanies)
  );

  useEffect(disablePartners, [disablePartners]);
};

interface RouterProps {
  draftStorage?: TStorage;
  routes: RouteObject[];
  steps: ISteps;
  prefix: string;
  stepForOpenSavedOrder?: number;
  idAgent?: string;
  disabledInsuranceCompanies?: number[];
  enabledInsuranceCompanies?: number[] | null;
  chat?: chatOptions;
  upsales?: UpsaleSettings;
  redirectTo?: string;
}

export const OSAGORouter: React.FC<RouterProps> = observer((props) => {
  const store = useStore();

  useStartCalc({ upsales: props.upsales });
  useSendHeightOutside();
  useClearDraftByStep();
  useDisablePartners(store, props);
  useAppChatInit(store, props);
  useRemoteSettings(store);
  useResetFormByFirstStep();
  useLicencePlateUrl();
  useFormFieldsSideEffect();
  useCompaniesModelsByModelId();

  return (
    <StorageProvider draftStorage={props.draftStorage}>
      {store.ui.uiLoading && store.ui.captchaInit ? <Router routes={props.routes} /> : null}
    </StorageProvider>
  );
});
