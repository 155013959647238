import { useStore } from '@core';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const DRAFT_KEY = 'draft';

export const useClearDraftByStep = () => {
  const [search, setSearch] = useSearchParams();
  const store = useStore();
  useEffect(() => {
    if (store.step.currentStep === 1 && search.has(DRAFT_KEY)) {
      search.delete(DRAFT_KEY);
      setSearch(search);
    }
  }, [search, setSearch, store.step.currentStep]);
};
