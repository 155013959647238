import React, { useEffect, useRef, useState } from 'react';
import { ISelectOption } from '@core/common/components/_factories/input.types';
import cn from 'classnames';
import s from './createOptions.module.scss';

export type OptionsProps = React.HTMLAttributes<HTMLDivElement> & {
  options: ISelectOption[];
  open: boolean;
  handlerSelect: (option: ISelectOption) => void;
};

export type OptionsStyle = {
  root: string;
  wrapper: string;
  item: string;
  active: string;
};

export const INACTIVE = '-1';

export const createOptions = <T extends OptionsProps>(style: OptionsStyle): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    wrapper: cn(s.wrapper, style?.wrapper),
    item: cn(s.item, style?.item),
    active: cn(s.active, style?.active),
  };
  return ({ options, className, open, handlerSelect, ...props }) => {
    const root = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState(-1);

    const activeCopy = useRef(active);
    activeCopy.current = active;

    useEffect(() => {
      setActive(-1);
      if (open && options?.length) {
        const fn = (e: KeyboardEvent) => {
          const items = root.current?.querySelectorAll('li');
          switch (e.code) {
            case 'Enter': {
              e.preventDefault();
              items?.[activeCopy.current]?.click();
              break;
            }

            case 'ArrowUp':
            case 'ArrowDown':
              {
                e.preventDefault();
                if (e.code === 'ArrowUp') setActive((v) => (v === 0 ? options.length - 1 : v - 1));
                else if (e.code === 'ArrowDown') setActive((v) => (v === options.length - 1 ? 0 : v + 1));
                setTimeout(() => {
                  const item = items?.[activeCopy.current];
                  if (item?.dataset.id === INACTIVE) return;
                  item?.scrollIntoView({ block: 'center', inline: 'center' });
                });
              }
              break;

            default:
              break;
          }
        };

        window.addEventListener('keydown', fn);

        return () => window.removeEventListener('keydown', fn);
      }
    }, [open, options.length]);

    if (!open) return null;

    const handlerClick = (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      const { id } = e.currentTarget.dataset;
      if (id === INACTIVE) return;

      const opt = options.find((op) => op.value === id);
      if (opt) handlerSelect(opt);
    };

    return (
      <div {...props} className={cn(cls.root, className)} ref={root}>
        <ul className={cls.wrapper}>
          {options?.map((option, index) => {
            const _active = option.value !== INACTIVE;
            return (
              <li
                onMouseEnter={() => _active && setActive(index)}
                role="presentation"
                className={cn(cls.item, index === active && _active && cls.active)}
                data-id={option.value}
                key={option.value}
                onClick={handlerClick}
              >
                {option.title}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
};
