import type { FormDriver } from '@core/features/driver/driver.types';

export const setDriverValue = <K extends keyof FormDriver>(
  initialDriver: FormDriver,
  name: K,
  value: string | number | null
): FormDriver => {
  const driver = { ...initialDriver };

  if (name === 'expyear') {
    driver[name].value = value;
    driver.expdate.value = `31.12.${String(value)}`;
  }

  driver[name].value = value;

  return driver;
};
