import cn from 'classnames';
import React from 'react';

import s from './SquareSwitch.module.scss';

export type SquareSwitchProps = {
  className?: string;
  checked: boolean;
  onCheck: (checked: boolean) => void;
  uncheckedNode?: React.ReactNode;
  checkedNode?: React.ReactNode;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
};

export const SquareSwitch = ({
  className,
  checked,
  onCheck,
  checkedNode,
  uncheckedNode,
  buttonProps = {},
}: SquareSwitchProps) => (
  <div className={cn(s.root, className)}>
    <button {...buttonProps} className={cn(s.btn, !checked && s.checked)} type="button" onClick={() => onCheck(false)}>
      {uncheckedNode}
    </button>
    <button {...buttonProps} className={cn(s.btn, checked && s.checked)} type="button" onClick={() => onCheck(true)}>
      {checkedNode}
    </button>
  </div>
);
