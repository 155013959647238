import { getUpsalesList } from '@core';
import { GroupedUpsales, IUpsales, PolicyOfferUpsale, SelectedUpsales } from '@core/features/upsale/upsale.types';
import { REQUIRED_UPSALES_TYPE } from '@core/constants/shared';

export const getCorrectKey = (upSale: PolicyOfferUpsale): string => {
  if (!upSale.type) return 'uncategorised';
  return upSale.type === 'NS' ? 'accident' : upSale.type;
};

export const getGroupedUpSales = (_upSales: PolicyOfferUpsale[], excludeTypes: string[]) => {
  const result: GroupedUpsales = {};

  _upSales.forEach((upSale) => {
    if (excludeTypes.find((i) => i.toLowerCase() === upSale.type?.toLowerCase())) return;
    const key = getCorrectKey(upSale);
    if (result[key]?.length) {
      return result[key].push(upSale);
    }
    result[key] = [upSale];
  });

  return result;
};

export const fetchUpSaleList = async (eId: string): Promise<IUpsales | null> => {
  const res = await getUpsalesList({ eId: eId || '' });
  return res?.upsaleList || null;
};

export const isRequired = (type: string | undefined | null) => type?.toUpperCase().startsWith(REQUIRED_UPSALES_TYPE);

export const getUpsalesSum = (
  upsaleList: PolicyOfferUpsale[] | undefined | null,
  selectedUpsales?: SelectedUpsales | undefined | null
) => {
  const selectedKeys = Object.values(selectedUpsales || {});

  return (
    upsaleList?.reduce(
      (acc, item) => (isRequired(item?.type) || selectedKeys.includes(item.id) ? acc + +item.packetPrice : acc),
      0
    ) || 0
  );
};

export const getTotalSum = (
  policySum: number,
  upsaleList: PolicyOfferUpsale[] | undefined | null,
  selectedUpsales?: SelectedUpsales | undefined | null
) => {
  const upsalesSum = getUpsalesSum(upsaleList, selectedUpsales);

  return policySum + upsalesSum;
};
