export const TOKEN_KEY = 'token';
export const TOKEN_KEY_TIME = 'token_time';
export const ORDER_KEY = 'order_key';
export const ORDER_DATA = 'order_data';
export const OLD_ORDER_KEY = 'old_order_key';
export const ADV_P_ID = 'adv_p_id';
export const OLD_ADV_P_ID = 'old_adv_p_id';
export const ORDER_ID = 'order_id';

export const LOCAL_STORAGE_DRAFTS = 'osago_drafts';
export const LOCAL_STORAGE_OFFERS = 'osago_offers';
export const LOCAL_STORAGE_ACTIVE_OFFERS = 'osago_active_offers';
