import {
  createCheckBoxClean,
  CheckboxCleanProps,
  CheckboxCleanStyle,
} from '@core/common/components/_factories/createCheckBoxClean';
import s from './CheckboxClean.module.scss';

export type { CheckboxCleanProps };
export const CheckboxClean = createCheckBoxClean(s as CheckboxCleanStyle);

CheckboxClean.displayName = 'CheckboxClean';
