import React from 'react';
import cn from 'classnames';
import { useStore, observer } from '@core';

import s from './PreliminaryCost.module.scss';

export type PreliminaryCostProps = {
  className?: string;
};

export const PreliminaryCost = observer(({ className }: PreliminaryCostProps) => {
  const store = useStore();
  if (!store) return null;

  let priceMin = 0;
  let priceMax = 0;
  let priceAverage = 0;
  if (store.form.dataByForm.preliminaryData.data) {
    priceMin = Math.round(store.form.dataByForm.preliminaryData.data.results.min * 0.5);
    priceMax = Math.round(store.form.dataByForm.preliminaryData.data.results.max * 0.75);
    priceAverage = Math.round(store.form.dataByForm.preliminaryData.data.results.max * 0.5);
  }

  if (priceMin.toLocaleString() === '0') return null;

  return (
    <div className={cn(s.root, className)}>
      <div>
        <div className={s.caption}>Предварительно</div>без учета стажа вождения
      </div>
      <div className={s.price}>
        {priceAverage.toLocaleString().replace(',', ' ')}
        <span>₽</span>
      </div>
      <div className={s.desc}>Средняя стоимость</div>
      <div className={s.range}>
        <div className={s.rangeMin}>
          <div className={s.rangeTop}>{priceMin.toLocaleString().replace(',', ' ')} ₽</div>
          <div className={s.rangeBottom}>минимальная</div>
        </div>
        <div className={s.rangeDelimiter}>—</div>
        <div className={s.rangeMax}>
          <div className={s.rangeTop}>{priceMax.toLocaleString().replace(',', ' ')} ₽</div>
          <div className={s.rangeBottom}>максимальная</div>
        </div>
      </div>
    </div>
  );
});

PreliminaryCost.displayName = 'PreliminaryCost';
