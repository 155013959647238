import React from 'react';
import { IFieldDriverKey, IFieldKey, observer, useStore } from '@core';
import { ISelectOption } from '@core/common/components/_factories/input.types';
import { SelectBaseToStored } from '@core/common/components/_factories/select.types';
import { SearchingSelectProps } from '@core/common/components/_factories/createSearchingSelect';

export type SelectSearchProps = Omit<SelectBaseToStored<SearchingSelectProps>, 'search' | 'options'>;

export type SelectDadataProps = SelectSearchProps;

export const createSelectDadata = (SelectSearch: React.ComponentType<SelectSearchProps>): React.FC<SelectDadataProps> =>
  observer((props) => {
    const { name, index } = props;
    const dadataName = `${name}_dadata`;
    const store = useStore();

    const onChange = (_value: unknown) => {
      if (index === undefined) store.form.setValueField(dadataName as IFieldKey, _value as never);
      else store.form.setDriverValue(index, dadataName as IFieldDriverKey, _value as never);
    };

    const onSelect = (option: ISelectOption) => {
      onChange(option.dadata);
    };

    return <SelectSearch {...props} validationName={dadataName} onSelect={onSelect} />;
  });
