import { IUiStore } from './uiStore.typings';

export const uiStore: IUiStore = {
  uiLoading: false,
  captchaInit: false,
  captchaToken: '',

  setCaptcha(token) {
    this.captchaToken = token;
  },

  setUiLoading(flag) {
    this.uiLoading = flag;
  },

  setCaptchaInit(flag) {
    this.captchaInit = flag;
  },
};
