import { createTableOffers } from '@core/common/components/_factories/createTableOffers/createTableOffers';
import { TableOffersRow } from './TableOffersRow';
import s from './TableOffers.module.scss';

export const TableOffers = createTableOffers(
  {
    TableOffersRow,
  },
  s
);

TableOffers.displayName = 'TableOffers';
