import { validateByKey, validate } from './methods';
import {
  Logger,
  Obj,
  StoreValidateOptions,
  StoreValidationOptions,
  Subscriber,
  ValidationOptions,
  ValidationFn,
  ValidationRules,
  ValidationStoreParams,
  ValidationResult,
} from '../common/types';

export class Validator<T extends Obj> {
  rules: ValidationRules<T>;

  logger?: Logger;

  subscribers: Subscriber<T>[];

  constructor(params: ValidationStoreParams<T>) {
    this.rules = params.rules;
    this.logger = params.logger;
    this.subscribers = [];

    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.clear = this.clear.bind(this);
    this.validateByKey = this.validateByKey.bind(this);
    this.validate = this.validate.bind(this);
  }

  unsubscribe(key: string) {
    this.subscribers = this.subscribers.filter((i) => i.key !== key);
  }

  subscribe(key: string, rule?: ValidationFn<unknown>) {
    const found = this.subscribers.find((i) => i.key === key);
    if (found) return () => {};

    this.subscribers.push({ key, rule });

    return () => this.unsubscribe(key);
  }

  clear() {
    this.subscribers = [];
  }

  validateByKey<V>(value: V, key: keyof T, options?: StoreValidationOptions<T>): ValidationResult | null;

  validateByKey<V>(value: V, rule: ValidationFn<V, T>, options?: StoreValidationOptions<T>): ValidationResult | null;

  validateByKey<V>(value: V, keyOrRule: keyof T | ValidationFn<V, T>, options?: StoreValidationOptions<T>) {
    const key = typeof keyOrRule === 'string' ? keyOrRule : '';
    const rule = typeof keyOrRule === 'string' ? this.rules[keyOrRule] : keyOrRule;
    const _options: ValidationOptions<T> = {
      ...(options || {}),
      logger: options?.logger || this.logger,
      deps: options?.deps,
      key,
    };
    return validateByKey<V, T>(value, rule as ValidationFn<V, T>, _options);
  }

  validate<D extends Obj>(data: D, options?: StoreValidateOptions<D>): ValidationResult {
    const obj = options?.onlySubscribers ? ({} as D) : data;
    const rules = { ...this.rules, ...(options?.rules || {}) } as unknown as ValidationRules<D>;
    const logger = options?.logger || this.logger;

    if (options?.onlySubscribers) {
      (this.subscribers as unknown as Subscriber<D>[]).forEach(({ key, rule }) => {
        obj[key] = data[key];
        rules[key] = rule || rules[key];
      });
    }

    return validate<D>(obj, { ...(options || {}), rules, logger, deps: options?.deps || data });
  }
}
