import { createTableOffersRow } from '@core/common/components/_factories/createTableOffers';
import { Upsales } from '../../../StepCompanyLinkPay/components/Upsales';
import { Button } from '../../../../../common/components/Button';
import { Skeleton } from '../../../../../common/components/Skeleton';
import s from './TableOffersRow.module.scss';

export const TableOffersRow = createTableOffersRow(
  {
    skeletonStyle: { width: 230, height: 40 },
    Upsales,
    separatedPrice: true,
    Button: (p) => <Button {...p} styleType="accent" />,
    Skeleton,
  },
  s
);
