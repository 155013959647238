import React, { FC } from 'react';
import { BooleanFieldOriginProps } from '@core/common/components/_factories/createBooleanField/types';

export type BooleanFieldProps = BooleanFieldOriginProps & {
  error?: React.ReactNode;
  hiddenError?: boolean;
};

export const createBooleanField =
  <T extends BooleanFieldProps>(
    CheckboxComponent: React.ComponentType<BooleanFieldOriginProps>,
    ErrorBlock: React.ComponentType<{ children: React.ReactNode }>
  ): FC<T> =>
  ({ children, disabled, className, name, error, hiddenError, checked, onCheck }) =>
    (
      <div className={className}>
        <CheckboxComponent disabled={disabled} checked={checked} onCheck={onCheck} name={name}>
          {children}
        </CheckboxComponent>
        {!hiddenError && <ErrorBlock>{error}</ErrorBlock>}
      </div>
    );
