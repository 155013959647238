import { ValidationFn, StringOptions } from '../../common/types';
import { stringFeedback } from '../../common/feedbacks';

export const stringRule =
  (options?: StringOptions): ValidationFn<string> =>
  (value, deps, params) => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...(params || {}), deps, defaultIsSkipped })) return null;

    const {
      asString,
      required,
      regexp,
      message,
      enum: _enum,
      feedback = {},
    } = options?.getConditionalOptions?.({
      deps,
      params,
      currentDeps: params?.currentDeps || deps,
      defaultOptions: options,
    }) ||
    options ||
    {};
    const _feedback = { ...stringFeedback, ...feedback };
    if (asString) {
      value = typeof asString === 'function' ? asString(value) : `${value}`;
    }
    const isString = typeof value === 'string';
    if (!value || (isString && !value.trim())) return required ? _feedback.REQUIRED : null;
    if (isString) {
      if (!regexp && !_enum?.length) return null;

      if (regexp && regexp.test(value)) return null;
      if (_enum && _enum.includes(value)) return null;
      return _feedback.INVALID_FORMAT({
        message: message,
        regexp,
        enum: _enum,
        value,
      });
    }
    return _feedback.INVALID_STRING_FORMAT;
  };
