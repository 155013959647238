import React from 'react';
import cn from 'classnames';
import { inputs } from '@core/constants/texts';
import { CompanyShortViewProps } from '@core/common/components/_factories/createCompanyShortView';
import { BooleanFieldProps as CheckboxBasePropsOrigin } from 'lib/src/common/components/_factories/createBooleanField';
import { InputBrandModelBaseProps } from '@core/common/components/_factories/createInputBrandModelBase';
import { InputCompanyModelProps } from './types';
import s from './createInputCompanyModel.module.scss';

export type InputCompanyModelStyle = {
  root: string;
  main: string;
  checkbox: string;
};

export type CheckboxBaseProps = Pick<
  CheckboxBasePropsOrigin,
  'error' | 'onCheck' | 'children' | 'className' | 'checked'
>;

export const createInputCompanyModel = <T extends InputCompanyModelProps>(
  {
    CheckboxBase,
    CompanyShortView,
    InputBrandModelBase,
  }: {
    CompanyShortView: React.ComponentType<CompanyShortViewProps>;
    CheckboxBase: React.ComponentType<CheckboxBaseProps>;
    InputBrandModelBase: React.ComponentType<InputBrandModelBaseProps>;
  },
  style?: InputCompanyModelStyle
): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    main: cn(s.main, style?.main),
    checkbox: cn(s.checkbox, style?.checkbox),
  };
  return ({
    className,
    brandOptions,
    modelOptions,
    searchModel,
    searchBrand,
    whiteSelects,
    viewSelects,
    company,
    value,
    onChange,
    errorBrand,
    errorModel,
    errorExclude,
    onTypeModel,
    onTypeBrand,
    onSelectModel,
    onSelectBrand,
    onBlurModel,
    onBlurBrand,
    onFocusModel,
    onFocusBrand,
    flagAutoModel,
    flagAutoBrand,
  }) => (
    <div className={cn(cls.root, className)}>
      <div className="InputCompanyModels__wrapper">
        <CompanyShortView company={company} type="small" />
        <div className={cls.main}>
          <CheckboxBase
            error={errorExclude}
            className={cls.checkbox}
            checked={value?.exclude}
            onCheck={(v) => onChange?.({ ...(value || {}), exclude: v })}
          >
            {inputs.partner.exclude.title}
          </CheckboxBase>
          <InputBrandModelBase
            flagAutoModel={flagAutoModel}
            flagAutoBrand={flagAutoBrand}
            onSelectBrand={onSelectBrand}
            onSelectModel={onSelectModel}
            onTypeModel={onTypeModel}
            onTypeBrand={onTypeBrand}
            errorBrand={errorBrand}
            errorModel={errorModel}
            searchModel={searchModel}
            searchBrand={searchBrand}
            whiteSelects={whiteSelects}
            viewSelects={viewSelects}
            onBlurBrand={onBlurBrand}
            onBlurModel={onBlurModel}
            onFocusBrand={onFocusBrand}
            onFocusModel={onFocusModel}
            brandOptions={brandOptions}
            modelOptions={modelOptions}
            value={value}
            columns={false}
            onChange={(v) => onChange?.({ ...(value || {}), ...v })}
          />
        </div>
      </div>
    </div>
  );
};
