import { http } from '../../utils/http/http';
import type { InfoOldPolicyData, InfoOldPolicyParams, InitInsuranceData } from '../../typings/init.typings';
import { API_INGURU_6_2, URL_EOSAGO_COMPANIES } from '../../constants/api';

/**
 * Запрос всех имеющихся страховых компаний
 * @returns Promise<InitInsuranceData>
 */
export const apiGetListInsurance = async (): Promise<InitInsuranceData> =>
  await http.get({
    url: URL_EOSAGO_COMPANIES,
    params: {
      new: 1,
      device: 'SITE',
    },
  });

/**
 * Запрос предыдущего полиса по регистрационному государственному знаку (госномеру, licensePlate)
 * @param params {InfoOldPolicyParams}
 * @returns Promise<InitInsuranceData>
 */
export const apiGetInfoOldPolicy = async (params: InfoOldPolicyParams): Promise<InfoOldPolicyData> =>
  await http.get({
    url: API_INGURU_6_2,
    params: {
      q: `policy`,
      licensePlate: params.rgz,
    },
  });
