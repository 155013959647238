import { checkIframe } from '../../utils/iframe';
import { TStorage } from './types';

const PREFIX_STORAGE = `finuslugi.osago.partner.${process.env.UI}`;
const TEXT_ERROR = 'Error in all Storage. Please replace browser';

export class LocalStorage extends TStorage {
  statusLocalStorageEnabled: boolean;

  statusLocalStorageWindowTop: boolean;

  constructor() {
    super();
    console.log('INIT Storage');
    this.statusLocalStorageEnabled = false;
    this.statusLocalStorageWindowTop = false;

    try {
      if (typeof localStorage !== undefined) {
        this.statusLocalStorageEnabled = true;
      }
    } catch (e) {
      this.statusLocalStorageEnabled = false;
    }

    if (this.statusLocalStorageEnabled) {
      return;
    }

    if (!this.statusLocalStorageEnabled) {
      if (checkIframe()) {
        this.statusLocalStorageWindowTop = true;
      }
    }

    if (!this.statusLocalStorageEnabled && !this.statusLocalStorageWindowTop) {
      console.error(TEXT_ERROR);
    }
  }

  async getItem<T>(title: string): Promise<T | null> {
    const newTitle = `${PREFIX_STORAGE}.${title}`;

    if (this.statusLocalStorageEnabled) {
      let localStoreTitle: string | null;
      try {
        localStoreTitle = localStorage.getItem(newTitle);
        return localStoreTitle ? JSON.parse(localStoreTitle) : null;
      } catch (e) {
        return null;
      }
    }

    if (this.statusLocalStorageWindowTop) {
      return new Promise((resolve) => {
        if (!window.top) {
          return resolve(null);
        }

        const idRandom = `${Math.random()}`;

        window.addEventListener('message', eventListener);
        window.top.postMessage(`localStorage:get:${idRandom}:${newTitle}`, '*');

        function eventListener(event: MessageEvent) {
          if (event.data.toString().indexOf('localStorage') == -1) {
            return;
          }

          try {
            const data = JSON.parse(event.data.toString());

            if (!data.localStorage) {
              clearTimeout(timer);
              return resolve(null);
            }

            if ((data.localStorage.id as string) !== idRandom) {
              return;
            }

            window.removeEventListener('message', eventListener);
            clearTimeout(timer);
            return resolve(data.localStorage.data ? JSON.parse(data.localStorage.data) : null);
          } catch (e) {
            clearTimeout(timer);
            window.removeEventListener('message', eventListener);
            return resolve(null);
          }
        }

        const timer = setTimeout(() => {
          window.removeEventListener('message', eventListener);
          clearTimeout(timer);
          return resolve(null);
        }, 1000);
      });
    }

    return null;
  }

  async setItem(title: string, value: unknown): Promise<boolean> {
    const newTitle = `${PREFIX_STORAGE}.${title}`;

    if (this.statusLocalStorageEnabled) {
      try {
        localStorage.setItem(newTitle, JSON.stringify(value));
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    if (this.statusLocalStorageWindowTop) {
      return new Promise((resolve) => {
        if (!window.top) {
          return resolve(false);
        }

        const idRandom = `${Math.random()}`;

        window.addEventListener('message', eventListener);
        window.top.postMessage(`localStorage:set:${idRandom}:${newTitle}:${JSON.stringify(value)}`, '*');

        function eventListener(event: MessageEvent) {
          if (event.data.toString().indexOf('localStorage') == -1) {
            return;
          }

          try {
            const data = JSON.parse(event.data.toString());

            if (!data.localStorage) {
              clearTimeout(timer);
              return resolve(false);
            }

            if ((data.localStorage.id as string) !== idRandom) {
              return;
            }

            window.removeEventListener('message', eventListener);
            clearTimeout(timer);
            return resolve(data.localStorage.data ? JSON.parse(data.localStorage.data) : false);
          } catch (e) {
            clearTimeout(timer);
            window.removeEventListener('message', eventListener);
            return resolve(false);
          }
        }

        const timer = setTimeout(() => {
          window.removeEventListener('message', eventListener);
          clearTimeout(timer);
          return resolve(false);
        }, 1000);
      });
    }

    return false;
  }

  async removeItem(title: string): Promise<boolean> {
    const newTitle = `${PREFIX_STORAGE}.${title}`;
    if (this.statusLocalStorageEnabled) {
      try {
        localStorage.removeItem(newTitle);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    if (this.statusLocalStorageWindowTop) {
      return new Promise((resolve) => {
        if (!window.top) {
          return resolve(false);
        }

        const idRandom = `${Math.random()}`;

        window.addEventListener('message', eventListener);
        window.top.postMessage(`localStorage:remove:${idRandom}:${newTitle}`, '*');

        function eventListener(event: MessageEvent) {
          if (event.data.toString().indexOf('localStorage') == -1) {
            return;
          }

          try {
            const data = JSON.parse(event.data.toString());

            if (!data.localStorage) {
              clearTimeout(timer);
              return resolve(false);
            }

            if ((data.localStorage.id as string) !== idRandom) {
              return;
            }

            window.removeEventListener('message', eventListener);
            clearTimeout(timer);
            return resolve(data.localStorage.data ? JSON.parse(data.localStorage.data) : false);
          } catch (e) {
            clearTimeout(timer);
            window.removeEventListener('message', eventListener);
            return resolve(false);
          }
        }

        const timer = setTimeout(() => {
          window.removeEventListener('message', eventListener);
          clearTimeout(timer);
          return resolve(false);
        }, 1000);
      });
    }

    return false;
  }

  async clear(): Promise<boolean> {
    if (this.statusLocalStorageEnabled) {
      try {
        localStorage.clear();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    if (this.statusLocalStorageWindowTop) {
      return new Promise((resolve) => {
        if (!window.top) {
          return resolve(false);
        }

        const idRandom = `${Math.random()}`;

        window.addEventListener('message', eventListener);
        window.top.postMessage(`localStorage:clear:${idRandom}`, '*');

        function eventListener(event: MessageEvent) {
          if (event.data.toString().indexOf('localStorage') == -1) {
            return;
          }

          try {
            const data = JSON.parse(event.data.toString());

            if (!data.localStorage) {
              clearTimeout(timer);
              return resolve(false);
            }

            if ((data.localStorage.id as string) !== idRandom) {
              return;
            }

            window.removeEventListener('message', eventListener);
            clearTimeout(timer);
            return resolve(data.localStorage.data ? JSON.parse(data.localStorage.data) : false);
          } catch (e) {
            clearTimeout(timer);
            window.removeEventListener('message', eventListener);
            return resolve(false);
          }
        }

        const timer = setTimeout(() => {
          window.removeEventListener('message', eventListener);
          clearTimeout(timer);
          return resolve(false);
        }, 1000);
      });
    }

    return false;
  }
}
