import React from 'react';
import { observer } from '@core';
import { useSelectHandlers } from '@core/hooks/useInputHandlers';
import { SelectBaseToStored } from '@core/common/components/_factories/select.types';
import { SelectProps } from '@core/common/components/_factories/createSelect';

export type SelectDefaultProps = SelectBaseToStored<SelectProps>;

export const createSelectDefault = <T extends SelectDefaultProps>({
  Select,
}: {
  Select: React.ComponentType<SelectProps>;
}): React.FC<T> =>
  observer(
    ({
      name,
      validationName,
      validationSubscribe,
      validOnInput = true,
      index,
      onSelect,
      nameId,
      options,
      ...props
    }) => {
      const { value, clearAutoValue, flagAuto, error, handleSelect, handleBlur } = useSelectHandlers({
        index,
        name,
        nameId,
        validOnInput,
        validationName,
        validationSubscribe,
        onSelect,
      });

      if (!options) return null;

      const correctValue = options?.find((i) => i.title === value || i.value === value)?.title || '';

      return (
        <Select
          {...props}
          name={name}
          options={options}
          flagAuto={flagAuto}
          error={error}
          onClear={clearAutoValue}
          onBlur={handleBlur}
          onSelect={handleSelect}
          value={correctValue}
        />
      );
    },
  );
