import React from 'react';

import { VEHICLE_DOC_LIST, DOC_TYPE_LIST, observer, useStore } from '@core';

import { FormWrapper } from '../../../../common/components/FormWrapper/FormWrapper';
import { FormRow } from '../../../../common/components/FormRow';
import { InputDefault } from '../../../../common/components/Input/InputDefault';
import { SelectDefault } from '../../../../common/components/Input/SelectDefault';
import { InputMask } from '../../../../common/components/Input/InputMask';
import { InputDate } from '../../../../common/components/Input/InputDate';
import { createBlurTransform } from '@utils/createBlurTransform';

export const IdentifiersForm: React.FC = observer(() => {
  const store = useStore();

  if (!store) {
    return null;
  }

  const titleIdentifier = VEHICLE_DOC_LIST.find((ident) => ident.value == store.form.getValueField('vehicleDoc').value);

  const titleDoc = DOC_TYPE_LIST.find((doc) => +doc.value == store.form.getValueField('docType').value);
  const name = (() => {
    if (titleIdentifier?.value === 'vin') return 'vin';
    if (titleIdentifier?.value === 'body') return 'bodyNum';
    return 'chassisNum';
  })();

  const transform = createBlurTransform(() => store.form.setValueField('unmask_id', ''), name !== 'vin');

  return (
    <div>
      <FormRow columns>
        <SelectDefault view="fullWidth" placeholder="Идентификатор" name="vehicleDoc" options={VEHICLE_DOC_LIST} />
        {titleIdentifier && (
          <InputMask
            mask={name}
            transform={transform}
            view="fullWidth"
            placeholder={titleIdentifier.title}
            validOnInput
            name={name}
          />
        )}
      </FormRow>
      <FormRow columns>
        <SelectDefault view="fullWidth" placeholder="Документ" name="docType" options={DOC_TYPE_LIST} />
        {titleDoc?.value == '0' && (
          <InputMask view="fullWidth" placeholder="Серия и номер ПТС" mask="pts" name="docNumberPTS" />
        )}
        {titleDoc?.value == '1' && (
          <InputMask view="fullWidth" placeholder="Серия и номер СТС" mask="pts" name="docNumberSTS" />
        )}
        {titleDoc?.value == '2' && <InputDefault view="fullWidth" placeholder="Электронный ПТС" name="docNumberETS" />}
      </FormRow>
      <FormRow columns>
        <InputDate view="fullWidth" placeholder="Дата выдачи" name="docDate" />
        <div />
      </FormRow>
    </div>
  );
});
