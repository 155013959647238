import React from 'react';
import classNames from 'classnames';

import s from './FormWrapper.module.scss';

import { Loader } from '../Loader/Loader';

export const FormWrapper: React.FC<{
  children?: React.ReactNode;
  marginBottom?: boolean;
  title?: React.ReactNode;
  loading?: boolean;
}> = ({ children, marginBottom = false, title, loading = false }) => (
  <div className={classNames(s.wrapper, { [s.marginBottom]: marginBottom, [s.loading]: loading })}>
    {title && <div className={s.titleForm}>{title}</div>}
    {loading && <Loader />}
    {children}
  </div>
);
