export const getRangeDate = (date: string) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const dr = new Date(date);
  const drYear = new Date(today.getFullYear(), dr.getMonth(), dr.getDate());
  const age = today.getFullYear() - dr.getFullYear();

  return today < drYear ? age - 1 : age;
};
