import { ValidationFn, PhoneOptions } from '../common/types';
import { stringRule } from './sharedRules';
import { phoneRegexp, phoneRegexpSoft } from '../common/constants';
import { INVALID_PHONE_FORMAT } from '../common/texts';

export const phoneRule =
  (options?: PhoneOptions): ValidationFn<string, { vehicleDoc: string }> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? phoneRegexpSoft : phoneRegexp,
      message: options?.feedback?.INVALID_PHONE_FORMAT || INVALID_PHONE_FORMAT,
    })(value, deps, { ...params, type: 'hard' });
