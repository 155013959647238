import React, { FC } from 'react';
import cn from 'classnames';
import s from './createLoadingIcon.module.scss';

export type LoadingIconProps = Omit<React.HTMLAttributes<HTMLImageElement>, 'src'>;

export const createLoadingIcon =
  (src: string): FC<LoadingIconProps> =>
  ({ className, ...props }) =>
    (
      <div className={cn(s.root, className)}>
        <img {...props} className={s.img} src={src} alt="loading..." />
      </div>
    );
