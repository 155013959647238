export const actionIFrameStorage = (
  message: string,
  idRandom: string,
  type: 'localStorage' | 'sessionStorage' = 'localStorage'
) =>
  new Promise((resolve) => {
    if (!window.top) {
      return resolve(false);
    }

    const timer = setTimeout(() => {
      clear();
      resolve(false);
    }, 1000);

    function clear() {
      window.removeEventListener('message', eventListener);
      clearTimeout(timer);
    }

    function eventListener(event: MessageEvent) {
      if (event.data.toString().indexOf(type) == -1) {
        return;
      }

      try {
        const data = JSON.parse(event.data.toString());

        if (!data[type]) {
          clear();
          return resolve(false);
        }

        if ((data[type].id as string) !== idRandom) return;

        return resolve(data[type].data ? JSON.parse(data[type].data) : false);
      } catch (e) {
        return resolve(false);
      } finally {
        clear();
      }
    }

    window.addEventListener('message', eventListener);
    window.top.postMessage(message, '*');
  });

export const getISODateFromExpire = (expireInSeconds: number) => {
  const currentTime = new Date(); // Текущее время
  const futureTime = new Date(currentTime.getTime() + expireInSeconds * 1000); // Прибавляем секунды, переведенные в миллисекунды
  return futureTime.toISOString(); // Преобразуем в ISO 8601 формат
};
