import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDraftsStorage, useStore } from '@core';
import type { PolicyOrderDrafts } from '@core/features/order/order.types';
import { useDraftStorage } from '@core/common/components/StorageProvider/StorageProvider';

export const useHistoryOrder = () => {
  const draftStorage = useDraftStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const store = useStore();

  const [drafts, setDrafts] = useState<PolicyOrderDrafts>({ drafts: [] });

  const initHistory = ({
    index,
    title,
    step,
    searchUrl,
  }: {
    index: number;
    step: number;
    title: string;
    searchUrl?: string;
  }) => {
    if (!drafts.drafts[index]) return;

    const search = new URLSearchParams(location.search);
    search.delete('company');
    search.set('draft', drafts.drafts[index].id);

    const draft = drafts.drafts[index];
    if (draft) {
      store.setValueFromLocalObject(draft);
    }

    store.step.changeStep(step, title);
    navigate({
      pathname: searchUrl || location.pathname,
      search: search.toString(),
    });
  };

  useEffect(() => {
    const getHistory = async () => {
      const draftsNew = (await getDraftsStorage(draftStorage)) || { drafts: [] };
      draftsNew.drafts = draftsNew.drafts.filter((item) => item.id).reverse();

      setDrafts(draftsNew);
    };

    getHistory();
  }, [draftStorage]);

  return { drafts: drafts.drafts, initHistory };
};
