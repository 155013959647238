import type { PolicyOrderForm } from '@core/features/order/order.types';
import { createFormField } from '@core/features/field/helpers/createFormField';

export const createEmptyPolicyOrderForm = (): PolicyOrderForm => ({
  bodyNum: createFormField(''),
  brand: createFormField(''),
  brand_model: createFormField(''),
  category: createFormField(''),
  chassisNum: createFormField(''),
  date: createFormField(''),
  dc: createFormField(''),
  dcDate: createFormField(''),
  dcIssueDate: createFormField(''),
  docDate: createFormField(''),
  docNumber: createFormField(''),
  docNumberETS: createFormField(''),
  docNumberPTS: createFormField(''),
  docNumberSTS: createFormField(''),
  docSerial: createFormField(''),
  docType: createFormField(0),
  e_day: createFormField(0),
  e_month: createFormField(0),
  email: createFormField(''),
  enablePeriod: createFormField(0),
  hasPrevPolicy: createFormField(0),
  id: createFormField(0),
  insurerIsOwner: createFormField(1),
  kbm: createFormField(0),
  kbmFactor: createFormField(0),
  licensePlate: createFormField(''),
  model: createFormField(null),
  modelId: createFormField(null),
  multidrive: createFormField(0),
  name: createFormField(''),
  owner_address: createFormField(''),
  owner_address_dadata: createFormField(null),
  owner_address_dadata_city: createFormField(''),
  owner_birthdate: createFormField(''),
  owner_city: createFormField(''),
  owner_city_dadata: createFormField(''),
  owner_driverSelect: createFormField(null),
  owner_firstname: createFormField(''),
  owner_id: createFormField(0),
  owner_lastname: createFormField(''),
  owner_middlename: createFormField(''),
  owner_passportDate: createFormField(''),
  owner_passportForeign: createFormField(0),
  owner_passportNumber: createFormField(''),
  owner_passportNumberForeign0: createFormField(''),
  owner_passportSerial: createFormField(''),
  owner_passportSerialForeign0: createFormField(''),
  owner_legalStatus: createFormField('0'),
  owner_inn: createFormField(''),
  owner_name: createFormField(''),
  owner_docNumber: createFormField(''),
  owner_docSerial: createFormField(''),
  owner_docDate: createFormField(''),
  owner_ogrnip: createFormField(''),
  owner_form: createFormField(''),
  owner_kpp: createFormField(''),

  personalUsageOfCar: createFormField(1),
  phone: createFormField(''),

  policyholder_address: createFormField(''),
  policyholder_address_dadata: createFormField(null),
  policyholder_address_dadata_city: createFormField(''),
  policyholder_birthdate: createFormField(''),
  policyholder_city: createFormField(''),
  policyholder_city_dadata: createFormField(''),
  policyholder_driverSelect: createFormField(null),
  policyholder_firstname: createFormField(''),
  policyholder_id: createFormField(0),
  policyholder_lastname: createFormField(''),
  policyholder_middlename: createFormField(''),
  policyholder_passportDate: createFormField(''),
  policyholder_passportForeign: createFormField(0),
  policyholder_passportNumber: createFormField(''),
  policyholder_passportNumberForeign0: createFormField(''),
  policyholder_passportSerial: createFormField(''),
  policyholder_passportSerialForeign0: createFormField(''),
  policyholder_legalStatus: createFormField('0'),
  policyholder_inn: createFormField(''),
  policyholder_name: createFormField(''),
  policyholder_docNumber: createFormField(''),
  policyholder_docSerial: createFormField(''),
  policyholder_docDate: createFormField(''),
  policyholder_ogrnip: createFormField(''),
  policyholder_form: createFormField(''),
  policyholder_kpp: createFormField(''),

  power: createFormField(null),
  prevPolicyCompany: createFormField(null),
  prevPolicyNumber: createFormField(''),
  prevPolicySerial: createFormField(''),
  prevPolicyUnmaskId: createFormField(''),
  promoCode: createFormField(''),
  purpose: createFormField('personal'),
  reuestedLicense: createFormField(''),
  seats: createFormField(''),
  snilsOwner: createFormField(''),
  snilsPolicyholder: createFormField(''),
  stoa: createFormField(''),
  totalSum: createFormField(''),
  type: createFormField(1),
  update_date: createFormField(''),
  usePeriod: createFormField(12),
  useTrailer: createFormField(0),
  weight: createFormField(''),
  maxWeight: createFormField(''),
  vehicleDoc: createFormField('vin'),
  vin: createFormField(''),
  unmask_id: createFormField(''),
  year: createFormField(null),
});
