import React, { useState } from 'react';

import { checkPromoCode, observer, useStore } from '@core';

import { FormWrapper } from '../../../../common/components/FormWrapper/FormWrapper';
import { Button } from '../../../../common/components/Button';
import { InputDefault } from '../../../../common/components/Input/InputDefault';

import creditLimit from '../../../../assets/images/icons/credit-limit.svg';
import face from '../../../../assets/images/icons/face.svg';
import simPay from '../../../../assets/images/icons/sim-pay.svg';
import BlueArrow from '../../../../assets/images/icons/long-arrow.svg';

import s from './index.module.scss';
import { forms } from '@core/constants/texts';

export const PromoCode: React.FC = observer(() => {
  const store = useStore();
  const [applied, setApplied] = useState(false);

  const promoCode = store.form.getValueField('promoCode');
  const promocodeValue = promoCode.value;

  const onApply = async () => {
    const tempDadata = await checkPromoCode({ code: promocodeValue });
    setApplied(tempDadata.canUse);
    store.form.setErrorField('promoCode', tempDadata.canUse ? '' : forms.promoCode.not_found);
  };

  if (applied) {
    return (
      <div className={s.formWrapper}>
        <FormWrapper title="Промокод применен">
          <div className={s.formProgress}>
            <div className={s.formImage}>
              {' '}
              <img src={simPay} alt="" />
              <p className={s.formImageText}>Оплатите полис ОСАГО</p>
            </div>
            <div className={s.formLine}>
              <img src={BlueArrow} alt="" />
            </div>
            <div className={s.formImage}>
              {' '}
              <div className={s.formImage}>
                {' '}
                <img src={creditLimit} alt="" />
                <p className={s.formImageText}>
                  Зарегистрируйтесь на{' '}
                  <a target="__blank" href="https://finuslugi.ru" className={s.promoCodeInfoLink}>
                    finuslugi.ru
                  </a>
                </p>
              </div>
            </div>
            <div className={s.formLine}>
              <img src={BlueArrow} alt="" />
            </div>
            <div className={s.formImage}>
              {' '}
              <img src={face} alt="" />
              <p className={s.formImageText}>Получите 500 ₽ на счет в Финуслугах</p>
            </div>
          </div>
          <p className={s.promoCodeInfo}>
            Подробные{' '}
            <a className={s.promoCodeInfoLink} href="https://finuslugi.ru/bonus" target="_blank" rel="noreferrer">
              {' '}
              условия
            </a>{' '}
            акции
          </p>
        </FormWrapper>
      </div>
    );
  }

  return (
    <div className={s.formWrapper}>
      <FormWrapper title="Есть промокод?">
        <div className={s.promoCodeBlock}>
          <InputDefault view="fullWidth" placeholder="Промокод" name="promoCode" />
          <Button disabled={!promocodeValue} onClick={onApply}>
            Применить
          </Button>
        </div>
        <div />
      </FormWrapper>
    </div>
  );
});

PromoCode.displayName = 'PromoCode';
