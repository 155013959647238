import React from 'react';
import { Offer } from './components/Offer';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { observer } from 'mobx-react-lite';
import { useStepCompanyLink } from '@core/hooks/useStepCompanyLink';
import { Button } from '../../../common/components/Button/Button';
import s from './StepCompanyLinkPay.module.scss';

export const StepCompanyLinkPay: React.FC = observer(() => {
  const {
    onBack,
    link,
    companyId,
    selectedUpsales,
    activeCompany,
    getLinkPay,
    groupedUpSales,
    loadingUpSales,
    loading,
    error,
    totalSum,
    onCheckUpsale,
    date,
  } = useStepCompanyLink();

  return (
    <div className={s.root}>
      <StepTitle className={s.title} />

      {!!companyId && !!activeCompany && (
        <Offer
          loadingUpSales={loadingUpSales}
          onCheckUpsale={onCheckUpsale}
          selectedUpsales={selectedUpsales}
          groupedUpsales={groupedUpSales}
          totalSum={totalSum}
          date={date}
          error={error}
          link={link}
          companyId={companyId}
          loadingLinkPay={loading}
          getLinkPay={getLinkPay}
        />
      )}
      <Button styleType="second" className={s.back} onClick={onBack}>
        Назад
      </Button>
    </div>
  );
});

StepCompanyLinkPay.displayName = 'StepCompanyLink';
