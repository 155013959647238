import React from 'react';

import { Container } from '../../../common/components/Container/Container';
import { TableOffers } from './components/TableOffers';

import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { Button } from '../../../common/components/Button';
import { NotFound } from './components/TableOffers/NotFound';
import { observer } from 'mobx-react-lite';
import { useStepCompanyOffers } from '@core/hooks/useStepCompanyOffers';
import s from './StepCompanyOffers.module.scss';
import { Steps } from '../../../common/components/Steps/Steps';

export const StepCompanyOffers: React.FC = observer(() => {
  const { statusInitPartners, offers, setActiveOffer, prevStep, setNotFound, notFound } = useStepCompanyOffers();

  return (
    <Container className={s.root}>
      <Steps />
      <StepTitle>Выбор компании и оплата</StepTitle>
      {statusInitPartners &&
        (notFound ? (
          <NotFound className={s.notFound} />
        ) : (
          <TableOffers
            setActiveOffer={setActiveOffer}
            offers={offers}
            onNotFound={() => setNotFound(true)}
            logoType="square"
          />
        ))}
      <div className={s.nav}>
        <Button styleType="second" onClick={() => prevStep()}>
          {notFound ? 'Перепроверить данные' : 'Назад'}
        </Button>
      </div>
    </Container>
  );
});

StepCompanyOffers.displayName = 'StepCompanyOffers';
