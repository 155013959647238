import { useState } from 'react';
import { format } from 'date-fns';
import { getVehicle } from '@core';
import type { Vehicle } from '@core/features/vehicle/vehicle.types';
import { useEvent } from '@core/hooks/useEvent';
import { useValidator } from '@core/providers/ValidatorProvider/ValidatorProvider';
import { useRegionCode } from './useRegionCode';
import { useStore } from '../providers/StoreProvider';

interface VehicleByLicensePlateData {
  loading: boolean;
  getVehicleByLicensePlate: () => Promise<boolean>;
}

export const useVehicleByLicensePlate = (): VehicleByLicensePlateData => {
  const [loading, setLoading] = useState(false);
  const { validateByKey } = useValidator();
  const store = useStore();
  const { initCityByLicense } = useRegionCode();

  // todo/arkadii create common hook for loading data
  const getVehicleByLicensePlate = useEvent(async () => {
    const licensePlate = store.form.getValueField('licensePlate');
    if (!licensePlate.value) {
      return false;
    }

    const errAuto = validateByKey(licensePlate.value, 'licensePlateAuto', { type: 'soft' });
    const errBike = validateByKey(licensePlate.value, 'licensePlateBike', { type: 'soft' });
    if (errAuto || errBike) return false;

    try {
      setLoading(true);

      const dataFromApi = await getVehicle({ licensePlate: licensePlate.value.replace(/\s/g, '') });

      // todo/arkadii all the logic below should be moved to helper/utils
      if (dataFromApi && dataFromApi.results && !dataFromApi.errors.length && dataFromApi.results.vehicle) {
        const { vehicle } = dataFromApi.results;
        Object.keys(vehicle).map((key) => {
          const keyInput = key as keyof Vehicle;
          if (
            vehicle[keyInput] &&
            keyInput in store.form.fields &&
            keyInput !== 'docs' &&
            keyInput !== 'loadedData' &&
            keyInput !== 'companiesModels' &&
            keyInput !== 'unmask_id'
          ) {
            store.form.setValueField(keyInput, vehicle[keyInput] || '');
          }

          if (dataFromApi.results.unmaskId) {
            store.form.setValueField('unmask_id', dataFromApi.results.unmaskId);
          }

          if (keyInput == 'docs' && vehicle?.docs && vehicle.docs[0]) {
            const docs = vehicle.docs[0];

            if (docs.docType !== undefined) {
              store.form.setValueField('docType', vehicle.docs[0].docType);
            }

            if (docs.docDate) {
              store.form.setValueField('docDate', format(new Date(docs.docDate.toString()), 'dd.MM.yyyy'));
            }

            if (docs.docNumber && docs.docSerial && docs.docType !== undefined) {
              if (docs.docType == 0) {
                store.form.setValueField('docNumberPTS', `${docs.docSerial}${docs.docNumber}`);
              }

              if (docs.docType == 1) {
                store.form.setValueField('docNumberSTS', `${docs.docSerial}${docs.docNumber}`);
              }
            }

            if (docs.docType == 2 && docs.docNumber) {
              store.form.setValueField('docNumberETS', `${docs.docNumber}`);
            }
          }
        });

        await initCityByLicense(licensePlate.value).then(() => setLoading(false));

        return true;
      }
      setLoading(false);
      return false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setLoading(false);
      return false;
    }
  });

  return { loading, getVehicleByLicensePlate };
};
