import {
  createPlaceholder,
  PlaceholderStyle,
  PlaceholderProps,
} from '@core/common/components/_factories/createPlaceholder';
import s from './Placeholder.module.scss';

export type { PlaceholderProps };

export const Placeholder = createPlaceholder(s as PlaceholderStyle);

Placeholder.displayName = 'Placeholder';
