export const inputs = {
  brand: {
    title: 'Марка',
    empty: 'Начните ввод марки ТС',
  },
  model: {
    title: 'Модель',
    empty: 'Начните ввод модели ТС',
    empty_brand: 'Сначала выберите марку',
  },
  partner: {
    exclude: {
      title: 'Не интересует эта компания',
    },
  },
  common: {
    not_found: 'Ничего не найдено',
  },
};

export const forms = {
  promoCode: {
    not_found: 'Промокод не найден',
  },
};

export const errors = {
  network_error: 'Ошибка интернет соединения',
  required_field: 'Поле обязательно для заполнения',
  pay_link_error: 'Ошибка создания ссылки',
};

export const info = {
  consent_personal_data:
    'Настоящим даю согласие ООО «МБ Маркетплейс» (далее – Компания, адрес местонахождения: 117218, Москва, Нахимовский просп., д. 32) на обработку, а также передачу третьим лицам моих персональных данных (в том числе сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, уничтожение) любым, не противоречащим российскому законодательству способом (в том числе без использования средств автоматизации) в целях осуществление предварительного автоматического расчета стоимости услуг страхования, предоставление консультации по услугам страхования Компанией и контрагентами Компании и в иных указанных в Политике конфиденциальности целях. При этом такое согласие дается на неограниченный срок и может быть отозвано мной в любое время путем передачи Компании соответствующего уведомления в порядке, указанном в Политике конфиденциальности',
};

export const upsales = {
  enable:
    'Доступны дополнительные виды страхования. Вы можете выбрать их перед оплатой, если захотите увеличить страховое покрытие своих рисков.',
  required: 'Содержит обязательные дополнительные виды страхования',
};
