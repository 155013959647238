export const API_INGURU_HOST = process.env.NODE_ENV === 'production' ? '/api/6.1' : 'http://localhost:8081/api/6.1';
export const API_INGURU_6_2 = process.env.NODE_ENV === 'production' ? '/api/6.2' : 'http://localhost:8081/api/6.2';
export const OSAGO_INGURU_HOST = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8081/';

export const EXT_INFODEPO_HOST = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8081/';

export const AUTH_HEADER_CONST = 'INGURU';
export const HTTP_TIMEOUT = 200000; // 3 мин

export const URL_AUTH = `${OSAGO_INGURU_HOST}backend/inguru/eosago`;
export const URL_ORDER = `${OSAGO_INGURU_HOST}backend/inguru/order`;
export const URL_ORDER_UPDATE = `${OSAGO_INGURU_HOST}backend/inguru/order/update`;
export const URL_ORDER_TRACK = `${OSAGO_INGURU_HOST}backend/inguru/order/track`;
export const URL_POLICY_OLD_DATA = `${OSAGO_INGURU_HOST}backend/inguru/policy-info-loader`;
export const URL_DADATA = `${OSAGO_INGURU_HOST}backend/kladr`;
export const URL_POLICE_INFO_LOADER = `${OSAGO_INGURU_HOST}backend/inguru/policy-info-loader2`;

export const URL_DATA_DICTIONARY = `${OSAGO_INGURU_HOST}data/EOsago.json`;
export const URL_DATA_RATINGS = `${OSAGO_INGURU_HOST}data/Raitings.json`;
export const URL_DATA_SK_LIST = `${OSAGO_INGURU_HOST}data/AppCompanies.json`;
export const URL_EOSAGO_COMPANIES = `${OSAGO_INGURU_HOST}data/EOsagoCompanies.json`;

export const URL_VEHICLE_BY_LICENSE_PLATE = `${EXT_INFODEPO_HOST}backend/inguru/vehicle-data`;
export const URL_PRELIMINARY_COST = `${API_INGURU_6_2}`;
export const URL_SEARCH_VEHICLE_BY_PARAMS = `${API_INGURU_6_2}`;
export const URL_LIST_VEHICLE_BY_PARAMS = `${API_INGURU_6_2}`;
export const URL_DATA_KBM = `${API_INGURU_6_2}`;
export const URL_POLICY_NEW_DATA = `${API_INGURU_6_2}`;
export const URL_OFFERS_ORDER = `${API_INGURU_6_2}`;
export const URL_LINK_OFFER_ORDER = `${API_INGURU_6_2}`;
export const URL_UPSALES = `${API_INGURU_6_2}`;

export const URL_PROMO_CODE = `${OSAGO_INGURU_HOST}backend/moex/promo-code/check`;

export const URLS_NOT_AUTH = [URL_AUTH, URL_DATA_DICTIONARY, URL_DATA_RATINGS, URL_DATA_SK_LIST, URL_EOSAGO_COMPANIES];
