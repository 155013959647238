export const driverIndexToString = (index: number): string => {
  if (index === 1) {
    return 'Первый водитель';
  }

  if (index === 2) {
    return 'Второй водитель';
  }

  if (index === 3) {
    return 'Третий водитель';
  }

  if (index === 4) {
    return 'Четвертый водитель';
  }

  if (index === 5) {
    return 'Пятый водитель';
  }

  if (index === 6) {
    return 'Шестой водитель';
  }

  if (index === 7) {
    return 'Седьмой водитель';
  }

  if (index === 8) {
    return 'Восьмой водитель';
  }

  if (index === 9) {
    return 'Девятый водитель';
  }

  return '';
};
