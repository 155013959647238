import {
  createCalendarDropdown,
  CalendarDropdownProps,
} from '@core/common/components/_factories/createCalendarDropdown/createCalendarDropdown';
import { Calendar } from './Calendar';
import { Dropdown } from '../../../Dropdown';

export type { CalendarDropdownProps };

export const CalendarDropdown = createCalendarDropdown({ Dropdown, Calendar });

CalendarDropdown.displayName = 'CalendarDropdown';
