import React, { HTMLAttributes, FC, useState, useEffect, useRef, MutableRefObject } from 'react';
import cn from 'classnames';
import s from './Collapse.module.scss';

export type CollapseProps = HTMLAttributes<HTMLDivElement> & {
  opened: boolean;
  duration?: number;
  classNameWrapper?: string;
  onClose?: (root: MutableRefObject<HTMLDivElement>) => void;
  onOpen?: (root: MutableRefObject<HTMLDivElement>) => void;
};

export const DEFAULT_DURATION = 300;

export const Collapse: FC<CollapseProps> = ({
  className,
  opened,
  duration = DEFAULT_DURATION,
  style = {},
  classNameWrapper,
  onClose,
  onOpen,
  children,
  ...props
}) => {
  const [overflow, setOverflow] = useState<React.CSSProperties['overflow']>(opened ? 'visible' : 'hidden');
  const [height, setHeight] = useState<React.CSSProperties['height']>(opened ? 'auto' : '0');
  const wrapperStyle = { transitionDuration: `${duration}ms` };
  const root = useRef<HTMLDivElement>(null);

  const onTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>): void => {
    if (e.target !== e.currentTarget) return;
    if (!opened) {
      onClose?.(root as MutableRefObject<HTMLDivElement>);
      setHeight('0');
    } else {
      onOpen?.(root as MutableRefObject<HTMLDivElement>);
      setOverflow('visible');
    }
  };

  useEffect(() => {
    if (opened) {
      setHeight('auto');
    } else {
      setOverflow('hidden');
    }
  }, [opened]);

  return (
    <div {...props} ref={root} className={cn(s.root, className)} style={{ ...style, height, overflow }}>
      <div
        className={cn(s.wrapper, classNameWrapper, opened && s.opened)}
        onTransitionEnd={onTransitionEnd}
        style={wrapperStyle}
      >
        {children}
      </div>
    </div>
  );
};
