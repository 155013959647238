import React, { FC } from 'react';
import cn from 'classnames';
import s from './createNotFound.module.scss';

export type NotFoundProps = {
  className?: string;
};

export type NotFoundStyle = {
  root?: string;
  box?: string;
  header?: string;
  text?: string;
  widgets?: string;
  widget?: string;
  btn?: string;
};

export const createNotFound = (style?: NotFoundStyle): FC<NotFoundProps> => {
  const cls = {
    root: cn(s.root, style?.root),
    box: cn(s.box, style?.box),
    header: cn(s.header, style?.header),
    text: cn(s.text, style?.text),
    widgets: cn(s.widgets, style?.widgets),
    widget: cn(s.widget, style?.widget),
    btn: cn(s.btn, style?.btn),
  };
  return ({ className }) => (
    <div className={cn(cls.root, className)}>
      <div className={cls.box}>
        <div className={cls.header}>Нам очень жаль :&#40;</div>
        <div className={cls.text}>
          К сожалению, ни одна страховая компания не предоставила предложение. На это могло повлиять:
        </div>
      </div>
      <div className={cls.widgets}>
        <div className={cls.widget}>Маленький стаж или высокий КБМ одного из водителей</div>
        <div className={cls.widget}>Неограниченный список водителей (мультидрайв)</div>
        <div className={cls.widget}>Собственник не указан в списке водителей</div>
        <div className={cls.widget}>Ошибки при вводе реквизитов документов</div>
      </div>
    </div>
  );
};
