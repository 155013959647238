import { stringRule } from './sharedRules';
import { licensePlateAutoRegexp, licensePlateBikeRegexp, licensePlateSoftRegexp } from '../common/constants';
import { INVALID_LICENSE_PLATE_AUTO, INVALID_LICENSE_PLATE_BIKE } from '../common/texts';
import { ValidationFn, LicencePlaceAutoOptions, LicencePlaceBikeOptions, LicencePlaceOptions } from '../common/types';

export const licensePlateAutoRule = (options?: LicencePlaceAutoOptions) =>
  stringRule({
    ...(options || {}),
    regexp: licensePlateAutoRegexp,
    message: options?.feedback?.INVALID_LICENSE_PLATE_AUTO || INVALID_LICENSE_PLATE_AUTO,
  });

export const licensePlateBikeRule = (options?: LicencePlaceBikeOptions) =>
  stringRule({
    ...(options || {}),
    regexp: licensePlateBikeRegexp,
    message: options?.feedback?.INVALID_LICENSE_PLATE_BIKE || INVALID_LICENSE_PLATE_BIKE,
  });

export const licensePlateRule =
  (options?: LicencePlaceOptions): ValidationFn<string, { type: number }> =>
  (value, deps, opts) => {
    if (opts?.type === 'soft') {
      return stringRule({ regexp: licensePlateSoftRegexp })(value, deps, { ...opts, type: 'hard' });
    }

    if (deps?.type) {
      if (deps.type == 1) return licensePlateAutoRule(options)(value, deps, opts);
      if (deps.type == 7) return licensePlateBikeRule(options)(value, deps, opts);
    }

    return null;
  };
