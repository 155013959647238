import { Alias, MaskRecord, MaskSymbol, Symbols } from './types';

export const getValueArrWithoutMask = (mask: string, symbols: Symbols, value: string) => {
  const clean: string[] = [];
  value.split('').forEach((item, i) => {
    if (item !== mask[i] || item in symbols) clean.push(item);
  });

  return clean;
};

export const createGetMask = (mask: string, symbols: Symbols) => (value: string) => {
  if (!value) return value;

  const clean: string[] = getValueArrWithoutMask(mask, symbols, value);

  let result = '';

  for (let i = 0, symbol = ''; i < mask.length; i++) {
    const maskItem = mask[i];
    const regexp = symbols[maskItem as keyof typeof symbols];

    // Если в маске символ, которого нет в symbols, значит нужно добавить его как элемент маски, но он не должен быть в конце
    if (!regexp) {
      result += clean.length ? maskItem : '';
      continue;
    }

    // Проверяем символы значения, пока не найдем подходящий или не закончатся символы
    do {
      [symbol] = clean.splice(0, 1);
    } while (symbol && !regexp.test(symbol));
    if (!symbol) break;
    result += symbol;
  }

  return result;
};

export const getMinMaskPos = (mask: string, symbols: Symbols) => {
  for (let i = 0; i < mask.length; i++) {
    const sym = mask[i];
    if (sym in symbols) return i;
  }
  return mask.length;
};

export const getMaxMaskPos = (mask: string, symbols: Symbols) => {
  for (let i = mask.length; i >= 0; i--) {
    const sym = mask[i];
    if (sym in symbols) return i + 1;
  }
  return 0;
};

export const defaultClear = (value: string): string => value.replace(/\s/g, '');
export const defaultMaskPreparer = (value: string): string => value;
export const defaultCurrencyClear = (value: string): string => value.replace(/[^-\d.,]/g, '');

export const defaultTransform = (value: string) => value?.toUpperCase();

export const applyAliases = (value: string, aliases?: Alias[]) => {
  if (aliases?.length) {
    let res = value;
    aliases.forEach((alias) => {
      res = res?.replace(alias.key, alias.replace);
    });
    return res;
  }
  return value;
};

export const getMaskSymbols = (maskRecord: MaskRecord, value: string | number): MaskSymbol[] => {
  const { mask } = maskRecord;

  const slicedMask = maskRecord.length === undefined ? mask : mask.slice(0, maskRecord.length);

  const result: MaskSymbol[] = [];

  for (let i = 0; i < slicedMask.length; ++i) {
    const maskSymbol = mask[i];
    const item = `${value}`[i];

    result.push(item ? { hidden: true, symbol: item } : { hidden: false, symbol: maskSymbol });
  }

  return result;
};

export const correctCarriagePosition = (pos: number, input: HTMLInputElement) => {
  // После скриптинга устанавливаем корректную позицию
  queueMicrotask(() => input?.setSelectionRange(pos, pos));
};
