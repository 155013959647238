import { getOrderKeyStorage, reloadOrder as reloadOrderApi, removeActiveOffersStorage } from '@core';

import { useStore } from '../providers/StoreProvider';
import { useEvent } from '@core/hooks/useEvent';

export const useReloadOrder = () => {
  const store = useStore();

  const reloadOrder = useEvent(async () => {
    store.calc.clearOffersData();
    await removeActiveOffersStorage();

    if (store) {
      store.form.fields.totalSum.value = 0;
    }

    const objectToSave = await store.createObjectFormData();
    const orderKey = await getOrderKeyStorage();

    if (orderKey) {
      const flag = await reloadOrderApi({
        order_data: objectToSave,
        order_key: orderKey,
        phone: store.form.fields.phone.value || '',
        step: store.step.currentStep,
      });

      return flag;
    }

    return false;
  });

  return { reloadOrder };
};
