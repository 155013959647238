import React from 'react';
import cn from 'classnames';

import s from './StepTitle.module.scss';

export type StepTitleProps = {
  children?: React.ReactNode;
  className?: string;
};

export const StepTitle = ({ children, className }: StepTitleProps) => (
  <div className={cn(s.root, className)}>{children}</div>
);
