import React, { FC, useRef } from 'react';
import cn from 'classnames';
import { BooleanFieldOriginProps } from '@core/common/components/_factories/createBooleanField/types';
import s from './createSwitchButton.module.scss';

export type SwitchButtonProps = BooleanFieldOriginProps & {
  id?: string;
};

export type SwitchButtonStyle = {
  root?: string;
  input?: string;
  switch?: string;
};

export const createSwitchButton = <T extends SwitchButtonProps>(style: SwitchButtonStyle): FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    input: cn(s.input, style?.input),
    switch: cn(s.switch, style?.switch),
  };
  return ({ className, name, disabled, onCheck, checked, id }) => {
    const input = useRef<HTMLInputElement>(null);
    return (
      <div className={cn(cls.root, className)}>
        <input
          id={id}
          ref={input}
          type="checkbox"
          name={name}
          className={cls.input}
          checked={checked}
          disabled={disabled}
          onChange={(e) => onCheck(e.target.checked)}
        />
        <button type="button" onClick={() => input.current?.click()} className={cls.switch} />
      </div>
    );
  };
};
