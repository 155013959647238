import { getValueCookie, setValueCookie } from '@core';
import { startTalkMe } from '../utils/start-chat';
import { isIframe } from '../utils/iframe';
import { uuid } from '../utils/uuid';

const chatId = 'ui_chat_client_id';

export type chatOptions = {
  style: {
    widgetHeaderBackground: string;
  };
};

export const useChat = () => {
  const startChat = (chatOptions?: chatOptions) => {
    if (!window || !window.top) {
      return;
    }

    let idChatClient = getValueCookie(chatId) || '';

    if (!idChatClient) {
      idChatClient = uuid();
      setValueCookie(chatId, idChatClient, { expires: false, 'max-age': 365 * 60 * 1000 });
    }

    window.top.postMessage(`startChat:${idChatClient}`, '*');
    window.top.postMessage(JSON.stringify({ chatOptions: chatOptions || {} }), '*');
  };

  const openChat = () => {
    if (window && window.top && isIframe()) {
      return window.top.postMessage('openChat', '*');
    }

    if (typeof TalkMe !== 'undefined') {
      return TalkMe('openSupport');
    }

    let idChatClient = getValueCookie(chatId) || '';

    if (!idChatClient) {
      idChatClient = uuid();
      setValueCookie(chatId, idChatClient, { expires: false, 'max-age': 365 * 60 * 1000 });
    }

    startTalkMe(idChatClient);
  };

  return { startChat, openChat };
};
