let PREFIX_PARTNER = process.env.NODE_ENV === 'production' ? '/iframe/t2/' : '/';

if (window.location.hostname.indexOf('osago.t2') !== -1) {
  PREFIX_PARTNER = '/';
}

export const URL_INDEX = PREFIX_PARTNER;

export const URL_STEP_PRELIMINARY_CALCULATION = `${PREFIX_PARTNER}step-preliminary-calculation`;
export const URL_STEP_CAR_DATA = `${PREFIX_PARTNER}step-car-data`;

export const URL_STEP_DRIVERS_DATA = `${PREFIX_PARTNER}step-drivers-data`;
export const URL_STEP_OWNER_DATA = `${PREFIX_PARTNER}step-owner-data`;
export const URL_STEP_OTHER_DATA = `${PREFIX_PARTNER}step-other-data`;
export const URL_STEP_COMPANY_OFFERS = `${PREFIX_PARTNER}step-company-offers`;
export const URL_STEP_COMPANY_LINK_PAY = `${PREFIX_PARTNER}step-company-link`;
