import { IStepStore } from './stepStore.typings';

export const stepStore: IStepStore = {
  currentStep: 1,
  prevStep: 1,
  stepForOpenSavedOrder: 3,
  titleCurrentStep: 'Ввод номера автомобиля',
  steps: [],
  changeStep(step, title = '') {
    this.prevStep = this.currentStep;
    this.currentStep = step;
    if (title) {
      this.titleCurrentStep = title;
    }
  },
  initSteps(steps, stepForOpenSavedOrder) {
    this.steps = steps;
    this.stepForOpenSavedOrder = stepForOpenSavedOrder;

    // FIXME: ИСПРАВИТЬ и добавить валидации
    const curStepBYUrl = steps.find((step) => step.link === window.location.pathname);
    if (curStepBYUrl) {
      this.prevStep = this.currentStep = curStepBYUrl?.id;
      this.titleCurrentStep = curStepBYUrl?.title;
    }
  },
};
