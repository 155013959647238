import { disableCompanies } from '@core/features/company/helpers/disableCompanies';
import { createDefaultCompaniesList } from '@core/features/company/helpers/createDefaultCompaniesList';
import { IPartnerStore } from './partnerStore.typings';

// todo/arkadii move to lib/features/company
export const partnerStore: IPartnerStore = {
  statusInitPartners: false,
  enabledInsuranceCompanies: createDefaultCompaniesList(),

  async disablePartners(insuranceCompanies, enabledInsuranceCompanies) {
    if (this.statusInitPartners) {
      return;
    }

    this.enabledInsuranceCompanies =
      (await disableCompanies(insuranceCompanies, enabledInsuranceCompanies)) ?? this.enabledInsuranceCompanies;
    this.statusInitPartners = true;
  },
};
