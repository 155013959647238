import { PolicyOrder, setOrderKeyStorage } from '@core';
import { IRootStore } from '@core/store/rootStore.typings';
import { FormDriver } from '@core/features/driver/driver.types';
import { convertDate } from '@utils/date';

export const formatDate = (dateStr: string | undefined | null): string => {
  if (!dateStr) return '';
  let date: Date;

  // Проверяем формат строки и создаем объект Date
  if (dateStr.includes('.')) {
    const [day, month, year] = dateStr.split('.');
    date = new Date(Number(year), Number(month) - 1, Number(day));
  } else if (dateStr.includes('-')) {
    const [year, month, day] = dateStr.split('-');
    date = new Date(Number(year), Number(month) - 1, Number(day));
  } else if (dateStr.includes('/')) {
    const [month, day, year] = dateStr.split('/');
    date = new Date(Number(year), Number(month) - 1, Number(day));
  } else {
    return '';
  }

  // Форматируем дату в строку "DD.MM.YYYY"
  return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}.${date.getFullYear()}`;
};

export const updateVehicle = (policyOrder: PolicyOrder, store: IRootStore) => {
  const vehicle = policyOrder.vehicle || {};
  const vehicleDoc = (() => {
    if (vehicle.vin) return 'vin';
    if (vehicle.bodyNum) return 'body';
    if (vehicle.chassisNum) return 'chassis';
    return 'vin';
  })();
  store.form.fields.vehicleDoc = {
    value: vehicleDoc,
    error: '',
    flagAuto: false,
  };
  store.form.fields.type = { value: Number(vehicle.type), error: '', flagAuto: false };
  store.form.fields.purpose = { value: vehicle.purpose as 'personal', error: '', flagAuto: false };
  store.form.fields.brand = { value: vehicle.brand || '', error: '', flagAuto: false };
  store.form.fields.model = { value: vehicle.model || '', error: '', flagAuto: false };
  store.form.fields.power = { value: vehicle.power || 0, error: '', flagAuto: false };
  store.form.fields.year = { value: vehicle.year || 0, error: '', flagAuto: false };
  store.form.fields.licensePlate = { value: vehicle.licensePlate || '', error: '', flagAuto: false };
  store.form.fields.docDate = { value: formatDate(vehicle.docDate || ''), error: '', flagAuto: false };
  store.form.fields.docType = { value: vehicle.docType || 0, error: '', flagAuto: false };

  store.form.fields.vin = {
    value: store.form.fields.vehicleDoc.value === 'vin' ? vehicle.vin || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.bodyNum = {
    value: store.form.fields.vehicleDoc.value === 'body' ? vehicle.bodyNum || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.chassisNum = {
    value: store.form.fields.vehicleDoc.value === 'chassis' ? vehicle.chassisNum || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.docNumberPTS = {
    value: store.form.fields.docType.value == 0 ? `${vehicle.docSerial || ''}${vehicle.docNumber || ''}` : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.docNumberSTS = {
    value: store.form.fields.docType.value == 1 ? `${vehicle.docSerial || ''}${vehicle.docNumber || ''}` : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.docNumberETS = {
    value: vehicle?.docType == 2 ? vehicle?.docNumber || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.unmask_id = {
    value: vehicle?.unmask_id ? vehicle.unmask_id : '',
    error: '',
    flagAuto: false,
  };
};

export const updateInsurer = (policyOrder: PolicyOrder, store: IRootStore) => {
  store.form.fields.policyholder_lastname = {
    value: policyOrder.insurer?.lastname || '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_firstname = {
    value: policyOrder.insurer?.firstname || '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_middlename = {
    value: policyOrder.insurer?.middlename || '',
    error: '',
    flagAuto: false,
  };
  if (policyOrder.insurer?.birthdate) {
    store.form.fields.policyholder_birthdate = {
      value: formatDate(policyOrder.insurer.birthdate),
      error: '',
      flagAuto: false,
    };
  }
  store.form.fields.policyholder_passportForeign = {
    value: policyOrder.insurer?.passportForeign || 0,
    error: '',
    flagAuto: false,
  };

  store.form.fields.policyholder_passportDate = {
    value: policyOrder.insurer?.passportDate || '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_passportDate = {
    value: formatDate(policyOrder.insurer?.passportDate || ''),
    error: '',
    flagAuto: false,
  };

  store.form.fields.policyholder_passportNumber = {
    value:
      store.form.fields.policyholder_passportForeign.value == 0
        ? `${policyOrder.insurer?.passportSerial || ''}${policyOrder.insurer?.passportNumber || ''}`
        : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.policyholder_passportSerialForeign0 = {
    value: store.form.fields.policyholder_passportForeign.value == 1 ? policyOrder.insurer?.passportSerial || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_passportNumberForeign0 = {
    value: store.form.fields.policyholder_passportForeign.value == 1 ? policyOrder.insurer?.passportNumber || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.policyholder_passportSerialForeign0 = {
    value: store.form.fields.policyholder_passportForeign.value == 1 ? policyOrder.insurer?.passportSerial || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_passportNumberForeign0 = {
    value: store.form.fields.policyholder_passportForeign.value == 1 ? policyOrder.insurer?.passportNumber || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.policyholder_address_dadata = {
    value: policyOrder.insurer?.dadata?.value ? { ...policyOrder.insurer.dadata } : null,
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_address_dadata_city = {
    value: policyOrder.insurer?.dadata?.value ? policyOrder.insurer.dadata.value || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_address = {
    value: policyOrder.insurer?.dadata?.value ? policyOrder.insurer.dadata.value || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.policyholder_city = {
    value: policyOrder.insurer?.dadata?.data.city ? policyOrder.insurer.dadata.data.city || '' : '',
    error: '',
    flagAuto: false,
  };
};

export const updateOwner = (policyOrder: PolicyOrder, store: IRootStore) => {
  if (!policyOrder.owner) return;

  store.form.fields.owner_lastname = { value: policyOrder.owner?.lastname || '', error: '', flagAuto: false };
  store.form.fields.owner_firstname = { value: policyOrder.owner?.firstname || '', error: '', flagAuto: false };
  store.form.fields.owner_middlename = { value: policyOrder.owner?.middlename || '', error: '', flagAuto: false };
  store.form.fields.owner_birthdate = {
    value: formatDate(policyOrder.owner?.birthdate),
    error: '',
    flagAuto: false,
  };
  store.form.fields.owner_passportForeign = {
    value: policyOrder.owner?.passportForeign || 0,
    error: '',
    flagAuto: false,
  };
  store.form.fields.owner_passportDate = {
    value: formatDate(policyOrder.owner?.passportDate),
    error: '',
    flagAuto: false,
  };

  store.form.fields.owner_passportNumber = {
    value:
      policyOrder.owner.passportForeign == 0
        ? `${policyOrder.owner.passportSerial || ''}${policyOrder.owner.passportNumber || ''}`
        : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.owner_passportSerialForeign0 = {
    value: policyOrder.owner.passportForeign == 1 ? policyOrder.owner.passportSerial || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.owner_passportNumberForeign0 = {
    value: policyOrder.owner.passportForeign == 1 ? policyOrder.owner.passportNumber || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.owner_address_dadata = {
    value: policyOrder.owner?.dadata?.value ? { ...policyOrder.owner.dadata } : null,
    error: '',
    flagAuto: false,
  };
  store.form.fields.owner_address_dadata_city = {
    value: policyOrder.owner?.dadata?.value ? policyOrder.owner.dadata.value || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.owner_address = {
    value: policyOrder.owner?.dadata?.value ? policyOrder.owner.dadata.value || '' : '',
    error: '',
    flagAuto: false,
  };
  store.form.fields.owner_city = {
    value: policyOrder.owner?.dadata?.data.city ? policyOrder.owner.dadata.data.city || '' : '',
    error: '',
    flagAuto: false,
  };
};

export const updateDrivers = (policyOrder: PolicyOrder, store: IRootStore) => {
  store.form.drivers = [];
  policyOrder.drivers?.map((driver) => {
    const ob = {} as FormDriver;
    ob.lastname = { value: driver.lastname || '', error: '', flagAuto: false };
    ob.firstname = { value: driver.firstname || '', error: '', flagAuto: false };
    ob.middlename = { value: driver.middlename || '', error: '', flagAuto: false };
    ob.birthdate = { value: formatDate(driver.birthdate), error: '', flagAuto: false };
    ob.expYearOnly = { value: driver.expYearOnly || 0, error: '', flagAuto: false };
    ob.age = {
      value: new Date().getFullYear() - new Date(convertDate(driver.birthdate || '')).getFullYear(),
      error: '',
      flagAuto: false,
    };
    ob.licenseForeign = { value: driver.licenseForeign || 0, error: '', flagAuto: false };
    ob.prevLicense = { value: Number(driver.prevLicense), error: '', flagAuto: false };
    ob.exp = {
      value: new Date().getFullYear() - new Date(convertDate(driver.expdate || '')).getFullYear(),
      error: '',
      flagAuto: false,
    };

    ob.expdate = {
      value: formatDate(driver.expdate),
      error: '',
      flagAuto: false,
    };
    ob.expyear = {
      value: formatDate(driver.expdate).split('.')[2],
      error: '',
      flagAuto: false,
    };

    ob.licenseSerial = {
      value: driver.licenseForeign == 0 ? (driver.licenseSerial || '') + (driver.licenseNumber || '') : '',
      error: '',
      flagAuto: false,
    };
    ob.licenseNumber = {
      value: driver.licenseForeign == 1 ? driver.licenseNumber || '' : '',
      error: '',
      flagAuto: false,
    };

    ob.prevLicenseForeign = { value: driver.prevLicenseForeign || 0, error: '', flagAuto: false };
    ob.prevLicenseDate = { value: formatDate(driver.prevLicenseDate), error: '', flagAuto: false };

    ob.prevLicenseSerial = {
      value: driver.prevLicenseForeign == 0 ? (driver.prevLicenseSerial || '') + (driver.prevLicenseNumber || '') : '',
      error: '',
      flagAuto: false,
    };
    ob.prevLicenseNumber = {
      value: driver.prevLicenseForeign == 1 ? driver.prevLicenseNumber || '' : '',
      error: '',
      flagAuto: false,
    };

    ob.prevLicenseName = { value: driver.prevLicenseName || 0, error: '', flagAuto: false };
    ob.prevLicenseFirstname = { value: driver.prevLicenseFirstname || '', error: '', flagAuto: false };
    ob.prevLicenseLastname = { value: driver.prevLicenseLastname || '', error: '', flagAuto: false };
    ob.prevLicenseMiddlename = { value: driver.prevLicenseMiddlename || '', error: '', flagAuto: false };

    store.form.drivers.push(ob);
  });
};

export const updateCompaniesModels = (policyOrder: PolicyOrder, store: IRootStore) => {
  const vehicle = policyOrder.vehicle || {};
  store.form.companiesModelsField.value = {
    values: {},
    options: {},
    errors: {},
    visibleOptions: {},
  };
  store.form.companiesModelsField.companies = [];

  vehicle.companiesModels?.forEach((company) => {
    store.form.companiesModelsField.value.values[company.sk] = {
      brand: company.brand,
      model: company.model,
      exclude: false,
    };
  });
};

export const updateStoreByPolicyOrder = async (policyOrder: PolicyOrder, store: IRootStore): Promise<void> => {
  await setOrderKeyStorage(policyOrder.order_key);

  if (!policyOrder.insurerIsOwner) {
    updateInsurer(policyOrder, store);
  }

  updateOwner(policyOrder, store);

  updateDrivers(policyOrder, store);

  updateCompaniesModels(policyOrder, store);

  updateVehicle(policyOrder, store);

  store.form.fields.date = {
    value: formatDate(policyOrder.date as string),
    error: '',
    flagAuto: false,
  };

  store.form.fields.useTrailer = {
    value: policyOrder.useTrailer || 0,
    error: '',
    flagAuto: false,
  };

  store.form.fields.usePeriod = {
    value: policyOrder.usePeriod || 0,
    error: '',
    flagAuto: false,
  };

  store.form.fields.multidrive = {
    value: policyOrder.multidrive || 0,
    error: '',
    flagAuto: false,
  };

  store.form.fields.purpose = {
    value: policyOrder.purpose || 'personal',
    error: '',
    flagAuto: false,
  };

  store.form.fields.licensePlate = {
    value: policyOrder.licensePlate || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.phone = {
    value: policyOrder.phone || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.email = {
    value: policyOrder.email || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.reuestedLicense = {
    value: policyOrder.reuestedLicense || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.insurerIsOwner = {
    value: policyOrder.insurerIsOwner || 0,
    error: '',
    flagAuto: false,
  };

  store.calc.urlPrefix = policyOrder.url_params || store.calc.urlPrefix;

  store.form.fields.prevPolicySerial = {
    value: policyOrder.prevPolicySerial || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.prevPolicyNumber = {
    value: policyOrder.prevPolicyNumber || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.prevPolicyCompany = {
    value: policyOrder.prevPolicyCompany || 0,
    error: '',
    flagAuto: false,
  };

  store.form.fields.promoCode = {
    value: policyOrder.mmvb_data?.promo_code || '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.hasPrevPolicy = {
    value: policyOrder.hasPrevPolicy || 0,
    error: '',
    flagAuto: false,
  };

  store.form.fields.prevPolicySerial = {
    value: policyOrder.hasPrevPolicy == 1 ? policyOrder.prevPolicySerial || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.prevPolicyNumber = {
    value: policyOrder.hasPrevPolicy == 1 ? policyOrder.prevPolicyNumber || '' : '',
    error: '',
    flagAuto: false,
  };

  store.form.fields.prevPolicyUnmaskId = {
    value: policyOrder.hasPrevPolicy == 1 ? policyOrder.prevPolicyUnmaskId || '' : '',
    error: '',
    flagAuto: false,
  };
};
