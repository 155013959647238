import type { PolicyOfferPayLinkInput } from '@core/features/offer/offer.types';
import {
  apiGetPreliminaryCost,
  apiLinkOffersByIdSk,
  apiOffersByIdSk,
  apiSetOrder,
  apiSetOrderTrack,
  apiUpdateOrder,
} from '../../services/api/order';
import { apiGetToken } from '../../services/api/token';
import { storage } from '../../services/storage';
import {
  CreateOrderByOrderKey32HashProps,
  CreateOrderByOrderKey32HashReturn,
  CreateOrderByOrderKey64HashProps,
  CreateOrderByOrderKey64HashReturn,
  CreateOrderByPartnerProps,
  CreateOrderByPartnerReturn,
  CreateOrderNewFromOldProps,
  CreateOrderNewFromOldReturn,
  CreateOrderNewProps,
  CreateOrderNewReturn,
  CreateOrderProps,
  CreateOrderReturn,
  GetPreliminaryCostProps,
  GetPreliminaryCostReturn,
  LinkOffersOrderData,
  OffersOrderDataApi,
  OffersOrderProps,
  OrderProps,
  OrderReturn,
  UpdateOrderProps,
  UpdateOrderReturn,
} from '../../typings/order.typings';
import { ApiGetTokenPropReturn } from '../../typings/token.typings';
import { ADV_P_ID, OLD_ADV_P_ID, OLD_ORDER_KEY, ORDER_ID, ORDER_KEY, TOKEN_KEY } from '../../constants/keys';
import { ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT, ERROR_TYPE_ORDER_CREATE_TEXT } from '../../constants/error';
import { FORM_TYPE } from '../../constants/form';
import { HttpProperties } from '@core/typings/http.typings';

export const trackOrder = async (order_key: string, adv_p_id: string): Promise<boolean> =>
  !!(await apiSetOrderTrack({ order_key, adv_p_id, frame: 0, moex: 0 }));

export const order = async (props: OrderProps): Promise<OrderReturn> =>
  await apiSetOrder({
    form_data: props,
    form_type: FORM_TYPE,
    name: '',
    phone: '',
  });

export const updateOrder = async (props: UpdateOrderProps): Promise<UpdateOrderReturn> => {
  // FIXME: УПРОСТИТЬ ЛОГИКУ!
  let result = await apiUpdateOrder({
    form_data: props.order_data,
    form_type: FORM_TYPE,
    order_key: props.order_key,
    name: '',
    phone: props.phone,
    step: props.step && props.step > 5 ? 5 : props.step || 1, // FIXME: убрать проверку на 6 шагов
  });

  if (result.error && result.error.indexOf('дубль') !== -1) {
    const dataEosagoOk = await apiGetToken({ order_key: 'new', from: props.order_key });
    await storage.setItem(TOKEN_KEY, dataEosagoOk.token);

    if (dataEosagoOk.order_key) {
      await storage.setItem(ORDER_KEY, dataEosagoOk.order_key);
    }

    result = await apiUpdateOrder({
      form_data: props.order_data,
      form_type: FORM_TYPE,
      order_key: dataEosagoOk.order_key || props.order_key,
      name: '',
      phone: props.phone,
      step: props.step && props.step > 5 ? 5 : props.step || 1, // FIXME: убрать проверку на 6 шагов
    });
  }

  if (result.order_id) {
    await storage.setItem(ORDER_ID, result.order_id);
  }

  return result;
};

const _createOrderNew = async ({ order_data }: CreateOrderNewProps): Promise<CreateOrderNewReturn> => {
  const data = await order({ order_data });
  if (!data) {
    return null;
  }

  return {
    order_key: data.order_key,
  };
};

const _createOrderByOrderKey32Hash = async ({
  order_key,
  adv_p_id,
  old_adv_p_id,
}: CreateOrderByOrderKey32HashProps): Promise<CreateOrderByOrderKey32HashReturn> => {
  const dataEosagoOk = await apiGetToken({ order_key, from: null });
  if (!dataEosagoOk) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_TEXT);
  }

  const adv = adv_p_id || old_adv_p_id;
  if (adv) {
    await trackOrder(order_key, adv);
  }

  return {
    order_key,
    order_data: dataEosagoOk.form_data || null,
    token: dataEosagoOk.token,
    adv_p_id: adv_p_id || old_adv_p_id || null,
  };
};

const _createOrderByOrderKey64Hash = async ({
  order_key,
}: CreateOrderByOrderKey64HashProps): Promise<CreateOrderByOrderKey64HashReturn> => {
  const dataEosagoOk = await apiGetToken({ order_key, from: null });
  if (!dataEosagoOk || !dataEosagoOk.order_key) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_TEXT);
  }

  return {
    order_key: dataEosagoOk?.order_key,
    order_data: dataEosagoOk.form_data || null,
    token: dataEosagoOk.token,
    adv_p_id: dataEosagoOk?.adv_p_id || null,
  };
};

const _createOrderNewFromOld = async ({
  old_order_key,
  adv_p_id,
  order_data,
}: CreateOrderNewFromOldProps): Promise<CreateOrderNewFromOldReturn> => {
  const dataEosagoOk = await apiGetToken({ order_key: 'new', from: old_order_key });
  if (!dataEosagoOk || !dataEosagoOk.order_key || !order_data) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_TEXT);
  }

  await updateOrder({ order_data, order_key: dataEosagoOk.order_key });

  if (dataEosagoOk.adv_p_id && adv_p_id && dataEosagoOk.adv_p_id !== adv_p_id) {
    await trackOrder(dataEosagoOk.order_key, adv_p_id);
  }

  return {
    order_key: dataEosagoOk.order_key,
    order_data: dataEosagoOk.form_data || null,
    token: dataEosagoOk.token,
    adv_p_id: adv_p_id || dataEosagoOk?.adv_p_id || null,
  };
};

const _createOrderByPartner = async ({
  adv_p_id,
  old_adv_p_id,
  order_data,
}: CreateOrderByPartnerProps): Promise<CreateOrderByPartnerReturn> => {
  if (!order_data) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_TEXT);
  }

  const dataOrder = await order({ order_data });
  if (!dataOrder || !dataOrder.order_key) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_TEXT);
  }

  const adv = adv_p_id || old_adv_p_id;
  if (adv) {
    await trackOrder(dataOrder.order_key, adv);
  }

  const dataEosagoOk = await apiGetToken({ order_key: dataOrder.order_key, from: null });
  if (!dataEosagoOk || !dataOrder.order_key || !order_data) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_TEXT);
  }

  return {
    order_key: dataOrder.order_key,
    order_data: dataEosagoOk.form_data || null,
    token: dataEosagoOk.token,
    adv_p_id: adv_p_id || old_adv_p_id || null,
  };
};

export const getPreliminaryCost = async (
  params: GetPreliminaryCostProps,
  options?: Omit<HttpProperties, 'url'>
): Promise<GetPreliminaryCostReturn> => await apiGetPreliminaryCost(params, options);

export const createOrder = async (props: CreateOrderProps): Promise<CreateOrderReturn> => {
  const { orderData, idAgent } = props || {};

  let order_key = await storage.getItem<string>(ORDER_KEY);
  const old_order_key = await storage.getItem<string>(OLD_ORDER_KEY);
  const adv_p_id = (await storage.getItem<string>(ADV_P_ID)) || idAgent;
  const old_adv_p_id = await storage.getItem<string>(OLD_ADV_P_ID);
  const token = await storage.getItem<string>(TOKEN_KEY);

  const order_data = orderData;

  if (!order_key && !old_order_key && (adv_p_id || old_adv_p_id) && !order_data) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT);
  }

  // if (order_data && !(await validateOrder(order_data))) {
  //   throw new Error(ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT);
  // }

  if (!order_key && !old_order_key && !adv_p_id && !old_adv_p_id) {
    order_key = 'new';
  }

  if (order_key === 'new' && !old_order_key && !adv_p_id && !old_adv_p_id && !order_data) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT);
  }

  const createOrderObject: CreateOrderReturn = {
    orderKey: order_key || '',
    advPId: '',
    token: token || '',
    orderData: null,
  };

  if (order_key && order_key !== 'new') {
    order_data.order_key = order_key;
  }

  let dataCreatedOrder;
  if (order_key) {
    if (order_key === 'new' && !old_order_key && !adv_p_id && !old_adv_p_id && order_data) {
      const { order_key: newOrderKey } = (await _createOrderNew({ order_data })) || {};
      if (!newOrderKey) {
        throw new Error(ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT);
      }
      createOrderObject.orderKey = newOrderKey;

      await storage.setItem(ORDER_KEY, newOrderKey);
    }

    if (order_key.length === 32) {
      dataCreatedOrder = await _createOrderByOrderKey32Hash({ order_key, adv_p_id, old_adv_p_id });
    }

    if (order_key.length === 64) {
      dataCreatedOrder = await _createOrderByOrderKey64Hash({ order_key, adv_p_id, old_adv_p_id });
    }
  }

  if (!order_key && old_order_key && !order_data) {
    throw new Error(ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT);
  }

  if (!order_key && old_order_key && order_data) {
    dataCreatedOrder = await _createOrderNewFromOld({ old_order_key, adv_p_id, old_adv_p_id, order_data });
  }

  if (!order_key && (adv_p_id || old_adv_p_id) && order_data) {
    dataCreatedOrder = await _createOrderByPartner({ adv_p_id, old_adv_p_id, order_data });
  }

  if (dataCreatedOrder && dataCreatedOrder.order_key) {
    await storage.setItem(ORDER_KEY, dataCreatedOrder.order_key);
    createOrderObject.orderKey = dataCreatedOrder.order_key;
  }

  if (dataCreatedOrder && dataCreatedOrder.token) {
    await storage.setItem(TOKEN_KEY, dataCreatedOrder.token);
    createOrderObject.token = dataCreatedOrder.token;
  }

  if (dataCreatedOrder && dataCreatedOrder.adv_p_id) {
    await storage.setItem(ADV_P_ID, dataCreatedOrder.adv_p_id);
    createOrderObject.advPId = dataCreatedOrder.adv_p_id;
  }

  if (dataCreatedOrder && dataCreatedOrder.order_data) {
    createOrderObject.orderData = dataCreatedOrder.order_data;
  }

  return createOrderObject;
};

export const getOffersByIdSk = async (params: OffersOrderProps): Promise<OffersOrderDataApi> => {
  const result = await apiOffersByIdSk(params);

  if (result.errors?.length === 0 && result.results?.length === 0) {
    result.errors.push({ code: 102, error: 'Ошибка' });
  }

  return { id: params.sk[0], result };
};

export const getLinkOffersByIdSk = async (params: PolicyOfferPayLinkInput): Promise<LinkOffersOrderData> =>
  await apiLinkOffersByIdSk(params);

export const createNewToken = async (params: { order_key: string }): Promise<ApiGetTokenPropReturn> =>
  await apiGetToken({ order_key: 'new', from: params.order_key });

export const reloadOrder = async (props: UpdateOrderProps): Promise<boolean> => {
  const dataEosagoOk = await apiGetToken({ order_key: 'new', from: props.order_key });
  await storage.setItem(TOKEN_KEY, dataEosagoOk.token);

  if (dataEosagoOk.order_key) {
    await storage.setItem(ORDER_KEY, dataEosagoOk.order_key);
  }

  const result = await apiUpdateOrder({
    form_data: props.order_data,
    form_type: FORM_TYPE,
    order_key: dataEosagoOk.order_key || props.order_key,
    name: '',
    phone: props.phone,
    step: props.step && props.step > 5 ? 5 : props.step || 1, // FIXME: убрать проверку на 6 шагов
  });

  if (result.order_id) {
    await storage.setItem(ORDER_ID, result.order_id);
  }

  return true;
};
