import { logError } from '@utils/logger';
import { CompanyShortViewType } from './types';
import { PartnerLogoType } from '../../../../../../partners/src/components/CompanyLogo';

export const previewTypeToLogoType = (previewType: CompanyShortViewType): PartnerLogoType => {
  switch (previewType) {
    case 'small':
      return 'square';

    case 'colorful':
      return 'colorful';

    case 'black-white':
      return 'black-white';

    default: {
      const unhandled: never = previewType;
      logError(new Error(`unhandled type: ${unhandled}`));
      return 'square';
    }
  }
};
