import { useEffect, useState } from 'react';
import { useChangeStep, useOffers, useStore } from '@core';

export const useStepCompanyOffers = () => {
  const [notFound, setNotFound] = useState(false);
  const { prevStep } = useChangeStep();

  const store = useStore();

  const { statusInitPartners } = store.partner;

  const { getOffers, offers, setActiveOffer } = useOffers();

  useEffect(() => {
    if (statusInitPartners) getOffers();
  }, [getOffers, statusInitPartners]);

  return {
    statusInitPartners,
    prevStep,
    offers,
    setActiveOffer,
    setNotFound,
    notFound,
  };
};
