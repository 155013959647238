import React, { createContext, useContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';

import { IRootStore } from '../store/rootStore.typings';
import { rootStore } from '../store/rootStore';

const StoreContext = createContext<IRootStore | null>(null);

export const StoreProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const storeDefaultValue = useLocalObservable(rootStore);
  return <StoreContext.Provider value={storeDefaultValue}>{children}</StoreContext.Provider>;
};

export const useStore = () => {
  const store = useContext(StoreContext);
  if (store === null) {
    throw new Error('useStore must be used within StoreContext');
  }

  return store;
};
