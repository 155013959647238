import React from 'react';
import cn from 'classnames';
import { inputs } from '@core/constants/texts';
import { FormRowProps } from '@core/common/components/_factories/createFormRow';
import { SearchingSelectProps } from '@core/common/components/_factories/createSearchingSelect';
import { InputBrandModelBaseProps } from './types';
import s from './createInputBrandModelBase.module.scss';

export type InputBrandModelBaseStyle = {
  root: string;
};

export const createInputBrandModelBase = <T extends InputBrandModelBaseProps>(
  {
    FormRow,
    SearchingSelect,
  }: {
    FormRow: React.ComponentType<FormRowProps>;
    SearchingSelect: React.ComponentType<SearchingSelectProps>;
  },
  style?: InputBrandModelBaseStyle
): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
  };

  return ({
    children,
    skeleton,
    className,
    modelOptions,
    brandOptions,
    searchModel,
    searchBrand,
    value,
    viewSelects,
    whiteSelects,
    errorBrand,
    errorModel,
    flagAutoBrand,
    flagAutoModel,
    onSelectModel,
    onSelectBrand,
    onTypeModel,
    onTypeBrand,
    onChange,
    disabled,
    onClearBrand,
    onClearModel,
    onBlurModel,
    onBlurBrand,
    onFocusModel,
    onFocusBrand,
    columns = true,
  }) => (
    <FormRow className={cn(cls.root, className)} columns={columns}>
      <SearchingSelect
        name="brand"
        skeleton={skeleton}
        disabled={disabled}
        error={errorBrand}
        view={viewSelects}
        white={whiteSelects}
        search={searchBrand}
        flagAuto={flagAutoBrand}
        options={brandOptions}
        onSelect={onSelectBrand}
        onType={onTypeBrand}
        onChange={(brand) => onChange?.({ ...(value || {}), brand })}
        value={value?.brand}
        onClear={onClearBrand}
        onBlur={onBlurBrand}
        onFocus={onFocusBrand}
        placeholder={inputs.brand.title}
      />
      <SearchingSelect
        name="model"
        skeleton={skeleton}
        disabled={disabled}
        error={errorModel}
        view={viewSelects}
        white={whiteSelects}
        search={searchModel}
        flagAuto={flagAutoModel}
        options={modelOptions}
        onSelect={onSelectModel}
        onType={onTypeModel}
        onChange={(model) => onChange?.({ ...(value || {}), model })}
        value={value?.model}
        onClear={onClearModel}
        onBlur={onBlurModel}
        onFocus={onFocusModel}
        placeholder={inputs.model.title}
      />
      {children}
    </FormRow>
  );
};
