import { useEffect } from 'react';
import { apiGetVehicleListByParams } from '@core/services/api/vehicle';
import { useEvent, Callback } from '@core/hooks/useEvent';
import { CompaniesOptions, HandleCompanyOptions, ResponseFetchBrandsModelsList } from './types';

export const fetchBrandsModelsList = (
  companiesOptions: CompaniesOptions,
  {
    handleAllCompaniesOptions,
    handleCompanyOptions,
  }: {
    handleCompanyOptions?: HandleCompanyOptions;
    handleAllCompaniesOptions?: (result: PromiseSettledResult<ResponseFetchBrandsModelsList>[]) => void;
  }
) => {
  if (companiesOptions?.length) {
    const promises = companiesOptions.map(
      (item) =>
        new Promise((resolve) => {
          apiGetVehicleListByParams({ q: item.type, sk: item.sk, brand: item.brand }).then((res) => {
            if (res.results) {
              const response: ResponseFetchBrandsModelsList = {
                list: res.results as string[],
                sk: item.sk,
                type: item.type,
              };
              handleCompanyOptions?.(response);
              resolve(response);
            }
          });
        })
    );

    Promise.allSettled(promises).then((res) =>
      handleAllCompaniesOptions?.(res as PromiseSettledResult<ResponseFetchBrandsModelsList>[])
    );
  }
};

/**
 * Отправляет запросы при изменении первого аргумента. Чтобы избежать излишних запросов, мемоизируйте его
 * Второй аргумент мемоизируется под капотом
 * */
export const useFetchBrandsModelsList = (
  companiesOptions: CompaniesOptions,
  {
    handleAllCompaniesOptions,
    handleCompanyOptions,
  }: {
    handleCompanyOptions?: HandleCompanyOptions;
    handleAllCompaniesOptions?: (result: PromiseSettledResult<ResponseFetchBrandsModelsList>[]) => void;
  }
) => {
  const handle = useEvent(handleCompanyOptions as Callback);
  const handleAll = useEvent(handleAllCompaniesOptions as Callback);

  useEffect(() => {
    fetchBrandsModelsList(companiesOptions, { handleAllCompaniesOptions: handleAll, handleCompanyOptions: handle });
  }, [handleAll, handle, companiesOptions]);
};
