export const PERIOD_OF_USE = [
  {
    value: '3',
    title: '3 месяца',
  },
  {
    value: '4',
    title: '4 месяца',
  },
  {
    value: '5',
    title: '5 месяцев',
  },
  {
    value: '6',
    title: 'Полгода',
  },
  {
    value: '7',
    title: '7 месяцев',
  },
  {
    value: '8',
    title: '8 месяцев',
  },
  {
    value: '9',
    title: '9 месяцев',
  },
  {
    value: '12',
    title: 'Год',
  },
];
export const RENE_PERIOD_OF_USE = [
  {
    value: '2',
    title: 'Менее 3 месяцев',
  },
  ...PERIOD_OF_USE,
];

export const CAR_TYPE = [
  { value: '1', title: 'Легковые автомобили' },
  { value: '2', title: 'Легковые такси' },
  { value: '11', title: 'Маршрутные автобусы' },
  { value: '3', title: 'Автобусы <= 16 мест' },
  { value: '4', title: 'Автобусы > 16 мест' },
  { value: '5', title: 'Грузовые авто <= 16 тонн' },
  { value: '6', title: 'Грузовые авто > 16 тонн' },
  { value: '10', title: 'Тракторы и строит. техника' },
  { value: '7', title: 'Мотоциклы' },
  { value: '8', title: 'Троллейбусы' },
  { value: '9', title: 'Трамваи' },
];

export const isTruck = (type: string) => ['5', '6'].includes(type);
export const isBus = (type: string) => ['3', '4'].includes(type);

const platonOptionsValues = ['1', '11', '3', '4', '5', '6'];

export const PLATON_CAR_TYPE = CAR_TYPE.filter((i) => platonOptionsValues.includes(i.value));

export const DOC_TYPE_LIST = [
  { title: 'ПТС', value: '0' },
  { title: 'СТС', value: '1' },
  { title: 'ЭПТС', value: '2' },
];

export const OWNER_TYPE_LIST = [
  { title: 'Физическое лицо', value: '0' },
  { title: 'Юридическое лицо', value: '1' },
  { title: 'Индивидуальный предприниматель', value: '2' },
];

export const VEHICLE_DOC_LIST = [
  { title: 'VIN номер', value: 'vin' },
  { title: 'Номер кузова', value: 'body' },
  { title: 'Номер шасси', value: 'chassis' },
];

export const LICENSE_FOREIGN_LIST = [
  { title: 'Водительские права РФ', value: '0' },
  { title: 'Иностранные водительские права', value: '1' },
];

export const DRIVER_PAST_DOCS_LIST = [
  { title: 'Предыдущее ВУ РФ', value: '0' },
  { title: 'Предыдущее иностранное ВУ', value: '1' },
];

export const PASSPORT_FOREIGN_LIST = [
  { title: 'Паспорт РФ', value: '0' },
  { title: 'Паспорт иностранного государства', value: '1' },
];

export const PASSPORT_FOREIGN_RENE_LIST = PASSPORT_FOREIGN_LIST.concat([
  { title: 'Удостоверение личности офицера', value: '2' },
  { title: 'Военный билет солдата', value: '3' },
  { title: 'Военный билет офицера запаса', value: '4' },
  { title: 'Временное удостоверение гражданина личности РФ', value: '5' },
  { title: 'Другие документы', value: '6' },
]);

export const VEHICLE_PURPOSE_LIST = [
  { value: 'personal', title: 'Личная' },
  { value: 'taxi', title: 'Такси' },
  { value: 'training', title: 'Учебная езда' },
  { value: 'dangerous', title: 'Перевозка опасных и легковоспламеняющихся грузов' },
  { value: 'rental', title: 'Прокат/краткосрочная аренда' },
  { value: 'passenger', title: 'Регулярные пассажирские перевозки/перевозки пассажиров по заказам' },
  { value: 'special', title: 'Дорожные и специальные транспортные средства' },
  { value: 'services', title: 'Экстренные и коммунальные службы' },
  { value: 'other', title: 'Прочее' },
];

export const POLICY_SERIAL_LIST = [
  { title: 'ХХХ', value: 'ХХХ' },
  { title: 'ССС', value: 'ССС' },
  { title: 'МММ', value: 'МММ' },
  { title: 'ККК', value: 'ККК' },
  { title: 'ЕЕЕ', value: 'ЕЕЕ' },
  { title: 'ВВВ', value: 'ВВВ' },
  { title: 'РРР', value: 'РРР' },
  { title: 'ННН', value: 'ННН' },
  { title: 'ААС', value: 'ААС' },
  { title: 'ТТТ', value: 'ТТТ' },
  { title: 'ААВ', value: 'ААВ' },
  { title: 'ААК', value: 'ААК' },
  { title: 'ААМ', value: 'ААМ' },
];
