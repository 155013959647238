import React, { useEffect, useState } from 'react';

import { observer, useStore, useVehicleByLicensePlate, PERIOD_OF_USE } from '@core';

import { PreliminaryPageForm } from './components/PreliminaryPageForm/PreliminaryPageForm';
import { PreliminaryCost } from './components/PreliminaryCost/PreliminaryCost';
import { LicenseWarning } from './components/LicenseWarning/LicenseWarning';

import { Container } from '../../../common/components/Container/Container';
import { Skeleton } from '../../../common/components/Skeleton';

import { SelectDefault } from '../../../common/components/Input/SelectDefault';
import { ENABLE_PRELIMINARY_COST } from '@core/common/constants/preliminaryCost';
import { Switch } from '../../../common/components/Input/Switch';
import { StepTitle } from '../../../common/components/StepTitle/StepTitle';
import { usePreliminaryCost } from '@core/store/utils/usePreliminaryCost';
import { FormNavControl } from '../../../common/components/FormNavControl/FormNavControl';
import { Steps } from '../../../common/components/Steps/Steps';
import s from './StepPreliminaryPage.module.scss';

export const StepPreliminaryPage: React.FC = observer(() => {
  usePreliminaryCost();
  const [open, setOpen] = useState<boolean>(false);
  const store = useStore();

  const { getVehicleByLicensePlate, loading } = useVehicleByLicensePlate();
  const preliminaryLoading = store.form.dataByForm.preliminaryData.loading;

  useEffect(() => {
    // FIXME: Заменить на store.form.vehicleByLicensePlateLoading
    getVehicleByLicensePlate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brand = store.form.getValueField('brand');
  const model = store.form.getValueField('model');
  const enablePeriod = store.form.getValueField('enablePeriod');

  const renderPreliminaryCost = () => {
    if (!ENABLE_PRELIMINARY_COST) return null;
    return loading || preliminaryLoading ? (
      <Skeleton className={s.skeleton} />
    ) : (
      <PreliminaryCost className={s.preliminaryCost} />
    );
  };

  return (
    <Container className={s.root}>
      <Steps />
      {renderPreliminaryCost()}
      {(!brand || !model) && <LicenseWarning />}
      <StepTitle>Автомобиль</StepTitle>
      <div className={s.preliminaryPage}>
        <div>
          <PreliminaryPageForm loading={loading} />
          <div className={s.preliminarySwitchers}>
            <div className={s.switcherBox}>
              <Switch name="enablePeriod">Полис на несколько месяцев</Switch>
              {enablePeriod.value ? (
                <div className={s.preliminaryPeriodInputBox}>
                  <SelectDefault
                    view="fullWidth"
                    placeholder="Период использования"
                    name="usePeriod"
                    options={PERIOD_OF_USE.map((item) => {
                      if (item.value == '6') {
                        return { value: item.value, title: '6  месяцев' };
                      }
                      return { value: item.value, title: item.title };
                    })}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <FormNavControl />
          <div className={s.preliminaryInfoBox}>
            <p className={s.preliminaryInfo}>
              Нажимая на кнопку «Продолжить» вы подтверждаете, что ознакомлены и принимаете{' '}
              <span className={s.preliminaryInfoButton} onClick={() => setOpen((prev) => !prev)}>
                {' '}
                условия передачи данных
              </span>
              .
            </p>
            {open && (
              <p className={s.preliminaryInfo}>
                Настоящим даю согласие ООО «МБ Маркетплейс» (далее – Компания, адрес местонахождения: 117218, Москва,
                Нахимовский просп., д. 32) на обработку, а также передачу третьим лицам моих персональных данных (в том
                числе сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование,
                обезличивание, блокирование, уничтожение) любым, не противоречащим российскому законодательству способом
                (в том числе без использования средств автоматизации) в целях осуществление предварительного
                автоматического расчета стоимости услуг страхования, предоставление консультации по услугам страхования
                Компанией и контрагентами Компании и в иных указанных в Политике конфиденциальности целях. При этом
                такое согласие дается на неограниченный срок и может быть отозвано мной в любое время путем передачи
                Компании соответствующего уведомления в порядке, указанном в Политике конфиденциальности
              </p>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
});
