import React from 'react';
import cn from 'classnames';
import s from './createErrorBlock.module.scss';

export type ErrorBlockProps = {
  children: React.ReactNode;
  className?: string;
};

export type ErrorBlockStyle = {
  root: string;
};

export const createErrorBlock =
  <T extends ErrorBlockProps>(style: ErrorBlockStyle): React.FC<T> =>
  ({ children, className }) => {
    if (!children) return null;

    return (
      <div data-error="true" className={cn(s.root, style.root, className)}>
        {children}
      </div>
    );
  };
