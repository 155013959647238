import {
  arrayRule,
  birthdateRule,
  docDateRule,
  docSerialNumberRule,
  humanNameRule,
  licenseNumberForeignRule,
  licenseSerialForeignRule,
  objRule,
  parseDate,
  ValidationFn,
  ValidationRules,
  yearRule,
  MIN_AGE,
  MIN_BIRTHDAY_DATE,
  brandRule,
  modelRule,
  stringRule,
} from '../../../validator';

export const driversRules: ValidationRules<any> = {
  prevLicenseName: () => null,
  prevLicense: () => null,
  licenseNumber: () => null,
  expYearOnly: () => null,
  prevLicenseNumber: () => null,
  prevLicenseForeign: () => null,
  id: () => null,
  idx: () => null,
  exp: () => null,
  age: () => null,
  licenseForeign: stringRule({ required: true, asString: true, enum: ['0', '1'] }),
  driver: () => null,

  lastname: humanNameRule({ required: true }),
  firstname: humanNameRule({ required: true }),
  middlename: humanNameRule(),
  birthdate: birthdateRule({ required: true }),
  licenseSerial: docSerialNumberRule({
    required: true,
    isSkipped: ({ currentDeps, defaultIsSkipped }) => {
      const licenseForeign = currentDeps?.licenseForeign as string;
      if (licenseForeign !== undefined && licenseForeign != '0') return true;
      return !!defaultIsSkipped?.();
    },
  }),
  licenseNumberForeign0: licenseNumberForeignRule({
    required: true,
    isSkipped: ({ currentDeps, defaultIsSkipped }) => {
      const licenseForeign = currentDeps?.licenseForeign as string;
      if (licenseForeign !== undefined && licenseForeign != '1') return true;
      return !!defaultIsSkipped?.();
    },
  }),
  licenseSerialForeign0: licenseSerialForeignRule({
    isSkipped: ({ currentDeps, defaultIsSkipped }) => {
      const licenseForeign = currentDeps?.licenseForeign as string;
      if (licenseForeign !== undefined && licenseForeign != '1') return true;
      return !!defaultIsSkipped?.();
    },
  }),
  expdate: docDateRule({
    required: true,
    getConditionalOptions: ({ defaultOptions, currentDeps }) => {
      const birthdate = currentDeps?.birthdate as string;
      let min: Date;
      try {
        min = parseDate(birthdate);
        min.setFullYear(min.getFullYear() + MIN_AGE);
      } catch (e) {
        min = MIN_BIRTHDAY_DATE;
      }

      return { ...defaultOptions, min };
    },
    isSkipped: ({ currentDeps, defaultIsSkipped }) => {
      const expYearOnly = currentDeps?.expYearOnly as string;

      if (expYearOnly !== undefined && expYearOnly != '0') return true;
      return !!defaultIsSkipped?.();
    },
  }),
  expyear: yearRule({
    required: true,
    getConditionalOptions: ({ currentDeps, defaultOptions }) => {
      const birthdate = currentDeps?.birthdate as string;
      let min: number;
      try {
        min = parseDate(birthdate).getFullYear() + MIN_AGE;
      } catch (e) {
        min = MIN_BIRTHDAY_DATE.getFullYear();
      }

      return { ...defaultOptions, min };
    },
    isSkipped: ({ currentDeps, defaultIsSkipped }) => {
      const expYearOnly = currentDeps?.expYearOnly as string;

      if (expYearOnly !== undefined && expYearOnly != '1') return true;
      return !!defaultIsSkipped?.();
    },
  }),
  prevLicenseSerial: docSerialNumberRule({
    required: true,
    isSkipped: ({ currentDeps }) => {
      const prevLicenseForeign = currentDeps?.prevLicenseForeign as string;
      const prevLicense = currentDeps?.prevLicense as string;
      return prevLicense ? parseInt(prevLicenseForeign, 10) != 0 : true;
    },
  }),
  prevLicenseNumberForeign0: licenseNumberForeignRule({
    required: true,
    isSkipped: ({ currentDeps }) => {
      const prevLicenseForeign = currentDeps?.prevLicenseForeign as string;
      const prevLicense = currentDeps?.prevLicense as string;
      return prevLicense ? parseInt(prevLicenseForeign, 10) != 1 : true;
    },
  }),
  prevLicenseSerialForeign0: licenseSerialForeignRule({
    isSkipped: ({ currentDeps }) => {
      const prevLicenseForeign = currentDeps?.prevLicenseForeign as string;
      const prevLicense = currentDeps?.prevLicense as string;
      return prevLicense ? parseInt(prevLicenseForeign, 10) != 1 : true;
    },
  }),
  prevLicenseDate: docDateRule({
    required: true,
    getConditionalOptions: ({ defaultOptions, currentDeps }) => {
      const date = currentDeps?.expYearOnly ? `01.01.${currentDeps?.expyear}` : (currentDeps?.expdate as string);
      let min: Date;
      try {
        min = parseDate(date);
        min.setFullYear(min.getFullYear());
      } catch (e) {
        min = MIN_BIRTHDAY_DATE;
      }

      return { ...defaultOptions, min };
    },
    isSkipped: ({ currentDeps, defaultIsSkipped }) => {
      if (!currentDeps?.prevLicense) return true;
      return !!defaultIsSkipped?.();
    },
  }),
  prevLicenseLastname: humanNameRule({
    required: true,
    isSkipped: ({ currentDeps }) => {
      const prevLicense = currentDeps?.prevLicense as number;
      if (prevLicense != 1) return true;
      const prevLicenseName = currentDeps?.prevLicenseName as string;
      return parseInt(prevLicenseName, 10) != 1;
    },
  }),
  prevLicenseFirstname: humanNameRule({
    required: true,
    isSkipped: ({ currentDeps }) => {
      const prevLicense = currentDeps?.prevLicense as number;
      if (prevLicense != 1) return true;
      const prevLicenseName = currentDeps?.prevLicenseName as string;
      return parseInt(prevLicenseName, 10) != 1;
    },
  }),
  prevLicenseMiddlename: humanNameRule({
    isSkipped: ({ currentDeps }) => {
      const prevLicense = currentDeps?.prevLicense as number;
      if (prevLicense != 1) return true;
      const prevLicenseName = currentDeps?.prevLicenseName as string;
      return parseInt(prevLicenseName, 10) != 1;
    },
  }),
};

export const driversRule = (options?: { required?: boolean }) =>
  arrayRule({ ...(options || {}) })(objRule({ required: true })(driversRules));

export const companiesModelsRule = (options?: { required?: boolean }) =>
  arrayRule({ ...(options || {}) })(
    objRule({ required: true })({
      exclude: (() => null) as ValidationFn<boolean>,
      sk: (() => null) as ValidationFn<string>,
      brand: brandRule({
        required: true,
        isSkipped: ({ currentDeps, defaultIsSkipped }) => !!currentDeps?.exclude || !!defaultIsSkipped?.(),
      }),
      model: modelRule({
        required: true,
        isSkipped: ({ currentDeps, defaultIsSkipped }) => !!currentDeps?.exclude || !!defaultIsSkipped?.(),
      }),
    }),
  );
