import React, { useState } from 'react';

import { LicensePlateBlock } from './components/LicensePlateBlock/LicensePlateBlock';
import s from './MainPage.module.scss';
import { Cover } from './components/Cover';
import { WelcomeComponent } from './components/WelcomeComponent/WelcomeComponent';

export const MainPage: React.FC = () => {
  const [first, setFirst] = useState(true);
  return (
    <div className={s.root}>
      <Cover />
      <div className={s.base}>
        <div className={s.wrapper}>
          <div className={s.caption}>Оформите полис ОСАГО и получите кешбэк 10% на связь</div>
          {first ? <WelcomeComponent onClick={() => setFirst(false)} /> : <LicensePlateBlock />}
        </div>
        {first && (
          <div className={s.info}>
            Сервис по подбору полиса ОСАГО предоставляет ООО «МБ Маркетплейс». Нажимая на кнопку «Оформить заявку» вы
            подтверждаете, что ознакомлены и принимаете условия передачи данных.
            <br />
            <br />
            <a
              className={s.link}
              href="https://assets.finuslugi.ru/mp-assets/privacy-policy-mb-marketplace.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Условия акции с кешбэком.
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
