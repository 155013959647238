import { Upsales } from './Upsales';
import { OfferEmailField } from './OfferEmailField';
import { createOffer, OfferProps } from '@core/common/components/_factories/createOffer/createOffer';
import { Button, LinkAsButton } from '../../../../common/components/Button';
import { LoadingIcon } from '../../../../common/components/LoadingIcon';
import s from './Offer.module.scss';

export type { OfferProps };

export const Offer = createOffer(
  {
    LinkAsButton,
    Button,
    Upsales,
    LoadingIcon,
    OfferEmailField,
  },
  s
);
