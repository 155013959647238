import {
  createInputCompanyModels,
  InputCompanyModelsProps,
  InputCompanyModelOptions,
  InputCompanyModelFlagAuto,
  InputCompanyModelsError,
  InputCompanyModelsErrors,
  InputCompanyModelsFlagsAuto,
  InputCompanyModelsOptions,
  InputCompanyModelsValue,
  InputCompanyModelsStyle,
} from '@core/common/components/_factories/createInputCompanyModels';
import { InputCompanyModel } from '../InputCompanyModel';
import { FormRow } from '../../FormRow';
import s from './InputCompanyModels.module.scss';

export type {
  InputCompanyModelsProps,
  InputCompanyModelOptions,
  InputCompanyModelFlagAuto,
  InputCompanyModelsError,
  InputCompanyModelsErrors,
  InputCompanyModelsFlagsAuto,
  InputCompanyModelsOptions,
  InputCompanyModelsValue,
};

export const InputCompanyModels = createInputCompanyModels(
  { FormRow, InputCompanyModel },
  s as InputCompanyModelsStyle
);

InputCompanyModels.displayName = 'InputCompanyModels';
