import React from 'react';
import cn from 'classnames';
import s from './createFormRow.module.scss';

export type FormRowProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  columns?: boolean;
};

export type FormRowStyle = {
  root?: string;
  columns?: string;
};

export const createFormRow = <T extends FormRowProps>(style?: FormRowStyle): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    columns: cn(s.columns, style?.columns),
  };
  return ({ className, columns, ...props }) => (
    <div className={cn(cls.root, columns && cls.columns, className)} {...props} />
  );
};
