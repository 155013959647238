import { createMaskRecord } from '../createMaskRecord';

export const phoneMask = createMaskRecord(
  '+7 (___) ___-__-__',
  { _: /\d/ },
  {
    clear: (v) => v,
    aliases: [{ key: /^8/, replace: '+7' }],
  }
);
