import { KID } from '@core/common/components/Popover/KIDSPopover';

export const KIDS: {
  title: string;
  entities: KID[];
} = {
  title: 'Ключевые информационные документы:',
  entities: [
    {
      title: 'КИД по каско Ущерб стихийные бедствия 27.03.2023',
      href: `https://assets.finuslugi.ru/mp-assets/osago/kid_kasko_disasters.pdf`,
    },
    {
      title: 'КИД по каско Ущерб падение предметов и стихийных бедствий 27.03.2023',
      href: `https://assets.finuslugi.ru/mp-assets/osago/kid_kasko_falling_objects_and_disasters.pdf`,
    },
    {
      title: 'КИД каско от бесполисных только риск ДТП со вторым участником 27.07.2023',
      href: `https://assets.finuslugi.ru/mp-assets/osago/kid_kasko_papper.pdf`,
    },
  ],
};
