import React from 'react';
import { useStore, observer, PASSPORT_FOREIGN_LIST } from '@core';
import { FormWrapper } from '../../../../../common/components/FormWrapper/FormWrapper';
import { FormRow } from '../../../../../common/components/FormRow';

import { InputDefault } from '../../../../../common/components/Input/InputDefault';
import { InputDate } from '../../../../../common/components/Input/InputDate';
import { InputMask } from '../../../../../common/components/Input/InputMask';
import { SelectDefault } from '../../../../../common/components/Input/SelectDefault';
import { SelectDadata } from '../../../../../common/components/Input/SelectDadata';
import { transformHumanName } from '@utils/getValueByEventHumanName';
import { getDriversOptions } from '@utils/getDriversOptions';

export const PolicyholderForm: React.FC = observer(() => {
  const store = useStore();
  if (!store) {
    return null;
  }

  if (!store) {
    return null;
  }

  const driversOption = getDriversOptions(store.form.drivers);

  return (
    <FormWrapper>
      <>
        <FormRow>
          <SelectDefault
            view="fullWidth"
            placeholder="Выбор страхователя"
            name="policyholder_driverSelect"
            options={driversOption}
          />
        </FormRow>
        <FormRow columns>
          <InputDefault
            view="fullWidth"
            transform={transformHumanName}
            placeholder="Фамилия"
            name="policyholder_lastname"
          />
          <InputDefault
            view="fullWidth"
            transform={transformHumanName}
            placeholder="Имя"
            name="policyholder_firstname"
          />
        </FormRow>
        <FormRow columns>
          <InputDefault
            view="fullWidth"
            transform={transformHumanName}
            placeholder="Отчество"
            name="policyholder_middlename"
          />
          <InputDate view="fullWidth" placeholder="Дата рождения" name="policyholder_birthdate" />
        </FormRow>
        <FormRow columns>
          <SelectDefault
            view="fullWidth"
            placeholder="Документ"
            name="policyholder_passportForeign"
            options={PASSPORT_FOREIGN_LIST}
          />
          {store.form.fields.policyholder_passportForeign.value == 0 && (
            <InputMask
              view="fullWidth"
              placeholder="Серия и номер"
              mask="passport"
              name="policyholder_passportNumber"
            />
          )}
        </FormRow>

        {store.form.fields.policyholder_passportForeign.value == 1 && (
          <FormRow columns>
            <InputDefault view="fullWidth" placeholder="Серия (если есть)" name="policyholder_passportSerialForeign0" />
            <InputDefault view="fullWidth" placeholder="Номер" name="policyholder_passportNumberForeign0" />
          </FormRow>
        )}
        <FormRow columns>
          <InputDate view="fullWidth" placeholder="Дата выдачи" name="policyholder_passportDate" />
          <SelectDadata view="fullWidth" placeholder="Адрес регистрации страхователя" name="policyholder_address" />
        </FormRow>
      </>
    </FormWrapper>
  );
});
