import { http } from '@utils/http/http';
import { ApiGetUpsalesProps, ApiGetUpsalesReturn } from '../../typings/upsales.typings';
import { URL_UPSALES } from '../../constants/api';

/**
 * Запрос кроссов (`upsales`) по `eId`
 * @param props {ApiGetUpsalesProps}
 * @returns Promise<ApiGetUpsalesReturn>
 */
export const apiGetUpsales = async (props: ApiGetUpsalesProps): Promise<ApiGetUpsalesReturn> =>
  await http.get({
    url: URL_UPSALES,
    params: {
      q: 'calculationOptions',
      ...props,
    },
  });
