import {
  Obj,
  ValidateOptions,
  ValidationOptions,
  ValidationFn,
  ValidationResult,
  ValidationResultRecord,
  LoggerBrokenRuleInfo,
  ValidationRules,
  LoggerRuleNotFoundInfo,
} from '../common/types';

export const findFirstStringProperty = (obj: Record<string, unknown>): string | null => {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      return obj[key] as string;
    }
    if (typeof obj[key] === 'object') {
      const result = findFirstStringProperty(obj[key] as Record<string, unknown>);
      if (result) return result;
    }
  }
  return null;
};

export const recover = <T extends Obj>(data: T, rules: ValidationRules<T>): T => {
  const result = { ...data };
  Object.keys(rules).forEach((key) => {
    (result as Record<string, unknown>)[key] = result[key];
  });
  return result;
};

export const validateByKey = <T, O extends Obj>(
  value: T,
  rule: ValidationFn<T, O> | undefined,
  options?: ValidationOptions<O>
): ReturnType<ValidationFn<T, O>> => {
  const { deps, ...params } = options || {};
  const { logger, key, type } = params;
  if (!rule) {
    const info: LoggerRuleNotFoundInfo = {
      key: key || '',
      error: new Error(`rule by key "${key}" is not found`),
      type,
    };
    logger?.('rule-not-found', info);
    return null;
  }
  try {
    return rule(value, deps, params);
  } catch (e) {
    const info: LoggerBrokenRuleInfo = { value, rule, error: e, key: key || '' };
    logger?.('broken-rule', info);
    return null;
  }
};

export const validate = <T extends Obj>(
  data: T | null,
  options: Omit<ValidateOptions<T>, 'onlySubscribers'>
): ValidationResult | null => {
  if (!data || typeof data !== 'object') return null;

  const { rules, key: parentKey, changeParamsMap, ...params } = options;
  const { checkStructure } = params;

  if (!rules || typeof rules !== 'object') return null;

  const result = {} as ValidationResultRecord;

  /* validate будет бежать по свойствам объекта. Соответственно, если будет передан например {},
   * то валидации не будет, хотя может быть нужно проверить наличие поля.
   * Функция recover создает копию date, но восстанавливает структуру, опираясь на rules */
  const value = checkStructure ? recover(data, rules as ValidationRules<T>) : data;

  Object.keys(value).forEach((key) => {
    const path = [parentKey, key].filter(Boolean).join('.');
    const rule = rules[key];
    const changeParams = changeParamsMap?.[key];
    const err = validateByKey(value[key] as T[string], rule, { ...params, key: path, changeParams });
    if (err) {
      result[key] = err;
    }
  });

  return Object.keys(result).length ? result : null;
};
