import React, { FC } from 'react';
import cn from 'classnames';
import { SwitchButtonProps } from '@core/common/components/_factories/createSwitchButton';
import { useId } from '@core/hooks/useId';
import s from './createSwitchClear.module.scss';

export type SwitchClearProps = Omit<SwitchButtonProps, 'id'> & {
  children?: React.ReactNode;
};

export type SwitchClearStyle = {
  root?: string;
};

export const createSwitchClear =
  <T extends SwitchClearProps>(SwitchButton: React.ComponentType<SwitchButtonProps>, style?: SwitchClearStyle): FC<T> =>
  ({ className, children, ...props }) => {
    const id = useId();
    return (
      <label htmlFor={id} className={cn(s.root, style?.root, className)}>
        <SwitchButton {...props} id={id} />
        <div>{children}</div>
      </label>
    );
  };
