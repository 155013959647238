import { IFormStore } from '@core/store/formStore/formStore.typings';
import { getPreliminaryCost } from '@core';

let timeoutIdPreliminaryCost: number;
const TIMEOUT = 1500;
let controller = new AbortController();

export function setPreliminaryCost(this: IFormStore) {
  if (
    this.fields.type.value &&
    this.fields.year.value &&
    this.fields.power.value &&
    this.fields.owner_city.value &&
    this.fields.owner_city_dadata.value
  ) {
    clearTimeout(timeoutIdPreliminaryCost);
    timeoutIdPreliminaryCost = window?.setTimeout(async () => {
      controller.abort();
      controller = new AbortController();

      this.dataByForm.preliminaryData.loading = true;
      const city = (this.fields.owner_city_dadata.value as unknown as { data: { kladr_id: string } })?.data?.kladr_id;
      const data = await getPreliminaryCost(
        {
          vehicle: {
            type: +this.fields.type?.value,
            power: +(this.fields.power.value || 0),
            year: +(this.fields.year.value || 0),
          },
          drivers: [{ age: 40, exp: 21 }],
          owner: { city },
          usePeriod: +this.fields.usePeriod.value,
          useTrailer: +this.fields.useTrailer.value,
        },
        {
          signal: controller.signal,
        }
      );

      this.dataByForm.preliminaryData.loading = false;

      if (!data?.errors?.length) {
        this.dataByForm.preliminaryData.data = data;
      }
    }, TIMEOUT);
  }
}
