import { CompaniesModelsFieldValue } from '@core/typings/fields.typings';
import { logError } from '@utils/logger';
import { CompaniesOptions, CompanyOptionsType } from './types';

export const listsTypeToFieldKey = (type: CompanyOptionsType): 'brand' | 'model' => {
  switch (type) {
    case 'list_brands':
      return 'brand';

    case 'list_models':
      return 'model';

    default: {
      const unhandled: never = type;
      logError(new Error(`unhandled type: ${unhandled}`));
      return 'brand';
    }
  }
};

export const fieldKeyToListsType = (type: 'brand' | 'model'): CompanyOptionsType => {
  switch (type) {
    case 'brand':
      return 'list_brands';

    case 'model':
      return 'list_models';

    default: {
      const unhandled: never = type;
      logError(new Error(`unhandled type: ${unhandled}`));
      return 'list_brands';
    }
  }
};

export const getCompaniesOptionsByStore = (
  values: CompaniesModelsFieldValue,
  type: 'brand' | 'model'
): CompaniesOptions => {
  const result: CompaniesOptions = [];
  Object.keys(values).forEach((sk) => {
    const _value = values[sk][type];
    if (values[sk]?.exclude) return;
    const brand = values[sk].brand;
    if (!_value) {
      result.push({
        sk: +sk,
        type: fieldKeyToListsType(type),
        brand: type === 'model' ? brand : undefined,
      });
    }
  });
  return result;
};
