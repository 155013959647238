import { IFieldKey } from '@core';
import { uuid } from '@utils/uuid';
import type { FormDriver } from '@core/features/driver/driver.types';
import type { PolicyOrderDraft, PolicyOrderForm } from '@core/features/order/order.types';

export const createPolicyOrderDraft = ({
  step,
  form,
  drivers,
  flagSendOldPolicyData,
  companiesModels,
  companies,
}: {
  step: number;
  form: PolicyOrderForm;
  drivers: FormDriver[];
  companiesModels: PolicyOrderDraft['companiesModels'];
  companies: PolicyOrderDraft['companies'];
  flagSendOldPolicyData: PolicyOrderDraft['flagSendOldPolicyData'];
}): PolicyOrderDraft => {
  // todo/arkadii refactor
  const getDriver = (driver: FormDriver) => {
    const localDriver: Record<string, unknown> = {};
    Object.keys(driver).map((key) => {
      localDriver[key] = driver[key as keyof typeof driver].value;
    });
    return localDriver;
  };

  return {
    step,
    companies: JSON.parse(JSON.stringify(companies)),
    companiesModels: JSON.parse(JSON.stringify(companiesModels)),
    fields: Object.keys(form).reduce(
      (res, key) => ({
        ...res,
        [key]: form[key as IFieldKey].value,
      }),
      {}
    ),
    drivers: drivers.map(getDriver),
    flagSendOldPolicyData,
    id: uuid(),
  };
};
