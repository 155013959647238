import { ValidationFn, DocDateOptions, DocSerialOptions, SnilsOptions } from '../common/types';
import {
  docSerialNumberRegexp,
  docSerialNumberRegexpSoft,
  MAX_DATE,
  MIN_DATE,
  snilsRegexp,
  snilsRegexpSoft,
} from '../common/constants';
import { dateRule, stringRule } from './sharedRules';
import { INVALID_DOC_NUMBER_SERIAL, INVALID_SNILS } from '../common/texts';

export const docDateRule =
  (options?: DocDateOptions): ValidationFn<Date> =>
  (value, deps, params) => {
    const defaultOptions = { ...(options || {}), min: MIN_DATE, max: MAX_DATE };
    const _options =
      options?.getConditionalOptions?.({
        deps,
        params,
        currentDeps: params?.currentDeps || deps,
        defaultOptions,
      }) ||
      options ||
      {};
    return dateRule({
      ..._options,
    })(value, deps, params);
  };

export const docSerialNumberRule =
  (options?: DocSerialOptions): ValidationFn<string> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? docSerialNumberRegexpSoft : docSerialNumberRegexp,
      message: options?.feedback?.INVALID_DOC_NUMBER_SERIAL || INVALID_DOC_NUMBER_SERIAL,
    })(value, deps, { ...params, type: 'hard' });

export const snilsRule =
  (options?: SnilsOptions): ValidationFn<string> =>
  (value, deps, params) =>
    stringRule({
      ...(options || {}),
      getConditionalOptions: (opts) => options?.getConditionalOptions?.({ ...(opts || {}), params }),
      required: params?.type !== 'soft' && options?.required,
      regexp: params?.type === 'soft' ? snilsRegexpSoft : snilsRegexp,
      message: options?.feedback?.INVALID_SNILS || INVALID_SNILS,
    })(value, deps, { ...params, type: 'hard' });
