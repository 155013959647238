import { getOrderIdStorage } from '@core';
import { useEvent } from '@core/hooks/useEvent';
import { useStore } from '../providers/StoreProvider';

export const useSendMetrics = () => {
  const store = useStore();

  const sendCompleteStep = useEvent(async (step: number) => {
    if (!store) {
      return false;
    }

    const order_id = await getOrderIdStorage();
    const objectToSave = await store.createObjectFormData();
    const offers = await store.createOffersFormData();

    if (order_id) {
      if (window.top) {
        try {
          window.top.postMessage(
            JSON.stringify({
              cb: 'stepComplete',
              value: { step, form_data: { ...objectToSave, offers }, order_id },
            }),
            '*'
          );
        } catch (err) {
          console.log(err, 'Error send by callback stepComplete');
        }

        return true;
      }
    }
  });

  const sendInsResponse = useEvent(async () => {
    const order_id = await getOrderIdStorage();
    const objectToSave = await store.createObjectFormData();
    const offers = await store.createOffersFormData();

    if (order_id) {
      if (window.top) {
        try {
          window.top.postMessage(
            JSON.stringify({
              cb: 'stepComplete',
              value: { step: 'insResponse', form_data: { ...objectToSave, offers }, order_id },
            }),
            '*'
          );
        } catch (err) {
          console.log(err, 'Error send by callback stepComplete');
        }

        return true;
      }
    }
  });

  const sendSelectOffer = useEvent(async (selectedOffer: { title: string; price: number }) => {
    const order_id = await getOrderIdStorage();
    const objectToSave = await store.createObjectFormData();
    const offers = await store.createOffersFormData();

    if (order_id) {
      if (window.top) {
        try {
          window.top.postMessage(
            JSON.stringify({
              cb: 'stepComplete',
              value: {
                step: 'selectedOffer',
                form_data: { ...objectToSave, offers, selectedOffer },
                order_id,
              },
            }),
            '*'
          );
        } catch (err) {
          console.log(err, 'Error send by callback stepComplete');
        }

        return true;
      }
    }
  });

  return { sendCompleteStep, sendInsResponse, sendSelectOffer };
};
