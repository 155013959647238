import { useCallback, useEffect, useRef } from 'react';

import { isIframe } from '../utils/iframe';

const SPEED = 0.3;

export const useScroll = () => {
  // let scroller: ReturnType<typeof requestAnimationFrame> | null = null;

  const scroller = useRef<ReturnType<typeof requestAnimationFrame> | null>(null);

  const scroll = useCallback((element?: Element, topFlag?: boolean) => {
    const iframe = isIframe();

    if (topFlag && iframe) {
      return iframe.postMessage(`scroll:${0}`, '*');
    }

    if (element || (!element && topFlag)) {
      const yOffset = window.pageYOffset;
      const topElement = element ? yOffset + element.getBoundingClientRect().top - 10 : 0;
      const dir = yOffset > topElement ? 1 : 0;

      let start: number | null = null;

      if (iframe) {
        return iframe.postMessage(`scroll:${topElement}`, '*');
      }

      const step = (timestamp: number) => {
        if (start == null) {
          start = timestamp;
        }

        const progress = timestamp - start;
        const delta = dir ? yOffset - progress / SPEED : yOffset + progress / SPEED;

        if (dir ? delta >= topElement : delta <= topElement) {
          window.scrollTo(0, delta);
          scroller.current = window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, topElement);
        }
      };

      setTimeout(() => {
        scroller.current = window.requestAnimationFrame(step);
      }, 0);
    }
  }, []);

  useEffect(
    () => () => {
      if (scroller.current) {
        window.cancelAnimationFrame(scroller.current);
      }
    },
    []
  );

  return { scroll };
};
