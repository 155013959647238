import {
  createInputDateBase,
  InputDateBaseProps,
} from '@core/common/components/_factories/createInputDateBase/createInputDateBase';
import { DateIcon } from '../DateIcon';
import { InputMaskBase } from '../InputMaskBase';
import { CalendarDropdown } from '../Calendar';

export type { InputDateBaseProps };

export const InputDateBase = createInputDateBase({ DateIcon, InputMaskBase, CalendarDropdown });

InputDateBase.displayName = 'InputDateBase';
