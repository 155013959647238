import { ValidationFn, NumberOptions } from '../../common/types';
import { numberFeedback } from '../../common/feedbacks';

export const floatRule =
  (options?: NumberOptions): ValidationFn<number> =>
  (value, deps, params) => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...params, deps, defaultIsSkipped })) return null;

    const {
      required,
      max,
      min,
      feedback = {},
    } = options?.getConditionalOptions?.({
      deps,
      params,
      currentDeps: params?.currentDeps || deps,
      defaultOptions: options,
    }) ||
    options ||
    {};
    const _feedback = { ...numberFeedback, ...feedback };

    if (!value && value !== 0 && required) return _feedback.REQUIRED;

    const _value = Number(value);

    if ((typeof value === 'string' && !/^\-?\d*(\.\d*)?$/.test(value)) || Number.isNaN(_value)) {
      return _feedback.INVALID_NUMBER_FORMAT;
    }

    if (typeof max === 'number' && _value > max) return _feedback.CANT_BE_MORE({ min, max, value: _value });
    if (typeof min === 'number' && _value < min) return _feedback.CANT_BE_LESS({ min, max, value: _value });

    return null;
  };

export const intRule =
  (options?: NumberOptions): ValidationFn<number> =>
  (value, deps, params) => {
    const defaultIsSkipped = () => params?.type === 'soft';
    const isSkipped = options?.isSkipped || defaultIsSkipped;
    if (isSkipped({ ...params, deps, defaultIsSkipped })) return null;

    const { feedback = {} } =
      options?.getConditionalOptions?.({ deps, currentDeps: params?.currentDeps || deps, defaultOptions: options }) ||
      {};
    const _feedback = { ...numberFeedback, ...feedback };

    const _value = Number(value);

    if (
      (typeof value === 'string' && !/^\-?\d*$/.test(value)) ||
      Number.isNaN(_value) ||
      _value !== Math.round(_value)
    ) {
      return _feedback.INVALID_INT_FORMAT;
    }
    return floatRule(options)(value, deps, params);
  };
