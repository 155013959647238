import { Driver } from '@core';
import { convertDate } from '@utils/date';
import { getRangeDate } from '@utils/getAgeExp';
import { FormDriver } from '../driver.types';

const createDriver = (driver: FormDriver): Driver => {
  const ob: Driver = {
    lastname: driver.lastname.value,
    firstname: driver.firstname.value,
    middlename: driver.middlename.value,
    birthdate: convertDate(driver.birthdate.value),
    expYearOnly: driver.expYearOnly.value,
    age: getRangeDate(convertDate(driver.birthdate.value)),
    licenseForeign: driver.licenseForeign.value,
    prevLicense: Boolean(driver.prevLicense.value),
    exp: getRangeDate(convertDate(driver.expdate.value)),
  };

  if (driver.expYearOnly.value == 0) {
    ob.expdate = convertDate(driver.expdate.value);
  } else if (driver.expYearOnly.value == 1 && driver.expyear.value) {
    ob.expyear = parseInt(driver.expyear.value, 10);
    ob.expdate = convertDate(driver.expdate.value || '');
  }

  if (driver.licenseForeign.value == 0) {
    const value = driver.licenseSerial.value.replace(/\s/g, '');
    ob.licenseSerial = value.substring(0, 4);
    ob.licenseNumber = value.substring(4);
  }

  if (driver.licenseForeign.value == 1) {
    ob.licenseSerial = driver.licenseSerialForeign0.value;
    ob.licenseNumber = driver.licenseNumberForeign0.value;
  }

  if (driver.prevLicense.value) {
    ob.prevLicenseForeign = driver.prevLicenseForeign.value;
    ob.prevLicenseDate = convertDate(driver.prevLicenseDate.value);

    if (driver.prevLicenseForeign.value == 0) {
      const num = driver.prevLicenseSerial.value.replace(/\s/g, '');
      ob.prevLicenseSerial = num.slice(0, 4);
      ob.prevLicenseNumber = num.slice(4, 10);
    }

    if (driver.prevLicenseForeign.value == 1) {
      ob.prevLicenseSerial = driver.prevLicenseSerialForeign0.value;
      ob.prevLicenseNumber = driver.prevLicenseNumberForeign0.value;
    }
  }

  if (driver.prevLicenseName.value) {
    ob.prevLicenseName = driver.prevLicenseName.value;
    ob.prevLicenseFirstname = driver.prevLicenseFirstname.value;
    ob.prevLicenseLastname = driver.prevLicenseLastname.value;
    ob.prevLicenseMiddlename = driver.prevLicenseMiddlename.value;
  }

  return ob;
};

export const createDrivers = (drivers: FormDriver[]): Driver[] => drivers.map(createDriver);
