export const inverseKeyboardMap: Record<string, string> = {
  q: 'й',
  w: 'ц',
  e: 'у',
  r: 'к',
  t: 'е',
  y: 'н',
  u: 'г',
  i: 'ш',
  o: 'щ',
  p: 'з',
  '[': 'х',
  ']': 'ъ',
  a: 'ф',
  s: 'ы',
  d: 'в',
  f: 'а',
  g: 'п',
  h: 'р',
  j: 'о',
  k: 'л',
  l: 'д',
  ';': 'ж',
  "'": 'э',
  z: 'я',
  x: 'ч',
  c: 'с',
  v: 'м',
  b: 'и',
  n: 'т',
  m: 'ь',
  ',': 'б',
  '.': 'ю',
  '/': '.',
  [`й`]: 'q',
  [`ц`]: 'w',
  [`у`]: 'e',
  [`к`]: 'r',
  [`е`]: 't',
  [`н`]: 'y',
  [`г`]: 'u',
  [`ш`]: 'i',
  [`щ`]: 'o',
  [`з`]: 'p',
  [`х`]: '[',
  [`ъ`]: ']',
  [`ф`]: 'a',
  [`ы`]: 's',
  [`в`]: 'd',
  [`а`]: 'f',
  [`п`]: 'g',
  [`р`]: 'h',
  [`о`]: 'j',
  [`л`]: 'k',
  [`д`]: 'l',
  [`ж`]: ';',
  [`э`]: "'",
  [`я`]: 'z',
  [`ч`]: 'x',
  [`с`]: 'c',
  [`м`]: 'v',
  [`и`]: 'b',
  [`т`]: 'n',
  [`ь`]: 'm',
  [`б`]: ',',
  [`ю`]: '.',
};

export const inverseLangMap: Record<string, string> = {
  a: 'а',
  b: 'б',
  c: 'с',
  d: 'д',
  e: 'е',
  f: 'ф',
  g: 'г',
  h: 'х',
  i: 'и',
  j: 'ж',
  k: 'к',
  l: 'л',
  m: 'м',
  n: 'н',
  o: 'о',
  p: 'п',
  q: 'к',
  r: 'р',
  s: 'с',
  t: 'т',
  u: 'у',
  v: 'в',
  w: 'в',
  x: 'кс',
  y: 'у',
  z: 'з',
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',
};

export const createInverseString =
  (inverseMap: Record<string, string>) =>
  (inputString: string): string => {
    let convertedString = '';

    for (let i = 0; i < inputString.length; i++) {
      const char = inputString[i];
      let convertedChar = inverseMap[char.toLowerCase()];

      if (!convertedChar) {
        convertedChar = char;
      }

      convertedString += char === char.toLowerCase() ? convertedChar : convertedChar.toUpperCase();
    }

    return convertedString;
  };

export const inverseKeyboardString = createInverseString(inverseKeyboardMap);

export const inverseLangString = createInverseString(inverseLangMap);
