import { getBrandsModelsList, getDadataList } from '@core';
import { useEvent } from '@core/hooks/useEvent';
import type { IFieldKey, IFieldDriverKey } from '../store/formStore/formStore.typings';
import { useStore } from '../providers/StoreProvider';
import { getOptionsByLocalSearch } from '@core/store/formStore/companiesModelsFieldStore/helpers';
import { ISelectOption } from '@core/common/components/_factories/input.types';

type IUseSearchField = IFieldKey | IFieldDriverKey;

export type ISearchFunction = (value: string | number, field: IUseSearchField) => Promise<ISelectOption[]>;

export type ISearchValueById = (value: string | number, field: IUseSearchField) => Promise<string>;

interface IUseSearchReturn {
  searchFunction: ISearchFunction;
  defaultOptionsSearchValue: (field: IUseSearchField) => ISelectOption[];
}

const LIMIT_LIST = 30;

export const useSearch = (): IUseSearchReturn => {
  const store = useStore();

  const searchFunction = useEvent(async (value: string | number, field: IUseSearchField): Promise<ISelectOption[]> => {
    let options: ISelectOption[] = [];

    if (!store) {
      return [{ value: '-1', title: 'Ничего не найдено' }];
    }

    if (field === 'brand') {
      options = [{ value: '-1', title: 'Начните ввод марки ТС' }];
      if (value.toString().length === 0) {
        return options;
      }

      const tempOptions = await getBrandsModelsList();

      const tempOptionsFilter = getOptionsByLocalSearch(
        `${value}`,
        tempOptions?.vehicles?.map((i) => i.brand)
      );

      if (tempOptionsFilter?.length === 0) {
        return [{ value: '-1', title: 'Ничего не найдено' }];
      }

      return tempOptionsFilter?.slice(0, LIMIT_LIST);
    }

    if (field === 'model') {
      const brand = store.form.getValueField('brand');

      if (!brand.value) {
        return [{ value: '-1', title: 'Сначала выберите марку' }];
      }
      if (value.toString().length === 0) {
        return [{ value: '-1', title: 'Начните ввод модели ТС' }];
      }

      const tempOptions = await getBrandsModelsList();

      const models = tempOptions?.vehicles?.find(
        (i) => i.brand.toLowerCase() === brand.value.toString().toLowerCase()
      )?.models;
      const tempOptionsModel = getOptionsByLocalSearch(`${value}`, models?.map((i) => i.model) || []);

      if (tempOptionsModel.length === 0) {
        return [{ value: '-1', title: 'Ничего не найдено' }];
      }

      return tempOptionsModel
        .slice(0, LIMIT_LIST)
        .map((i) => ({ ...i, value: models?.find((l) => l.model === i.value)?.id?.toString() || i.value }));
    }

    if (field === 'owner_city') {
      if (value.toString().length == 0) {
        return [{ value: '-1', title: 'Начните ввод населенного пункта' }];
      }

      const tempDadata = await getDadataList({ only_city: 1, addr_text_search: value.toString() });

      if (tempDadata.length === 0) {
        return [{ value: '-1', title: 'Ничего не найдено' }];
      }

      return tempDadata.slice(0, LIMIT_LIST).map((item) => ({ value: item.value, title: item.value, dadata: item }));
    }

    if (field === 'owner_address' || field === 'policyholder_address') {
      if (value.toString().length == 0) {
        return [{ value: '-1', title: 'Начните ввод населенного пункта' }];
      }

      const tempDadata = await getDadataList({ addr_text_search: value.toString() });

      if (tempDadata.length === 0) {
        return [{ value: '-1', title: 'Ничего не найдено' }];
      }

      return tempDadata.slice(0, LIMIT_LIST).map((item) => ({ value: item.value, title: item.value, dadata: item }));
    }

    if (options.length === 0) {
      options.push({ value: '-1', title: 'Ничего не найдено' });
    }

    return options;
  });

  const defaultOptionsSearchValue = useEvent((field: IUseSearchField): ISelectOption[] => {
    if (!store) {
      return [{ title: 'Начните ввод', value: '-1' }];
    }

    if (field === 'brand') {
      return [{ value: '-1', title: 'Начните ввод марки ТС' }];
    }

    if (field === 'model') {
      const brand = store.form.getValueField('brand');
      return [{ value: '-1', title: brand.value ? 'Начните ввод модели ТС' : 'Сначала выберите марку' }];
    }

    if (field === 'owner_city') {
      return [{ value: '-1', title: 'Начните ввод населенного пункта' }];
    }

    return [{ title: 'Начните ввод', value: '-1' }];
  });

  return { searchFunction, defaultOptionsSearchValue };
};
