import { Dadata, LegalEntity } from '@core';
import { convertDate } from '@utils/date';
import type { PolicyOrderForm } from '@core/features/order/order.types';

export const createLegalEntity =
  (prefix: 'owner_' | 'policyholder_') =>
  (form: PolicyOrderForm): LegalEntity => {
    const result: LegalEntity = {
      legalStatus: Number(form[`${prefix}legalStatus`].value),
    };

    switch (form[`${prefix}legalStatus`].value) {
      case '0': {
        result.lastname = form[`${prefix}lastname`].value;
        result.firstname = form[`${prefix}firstname`].value;
        result.middlename = form[`${prefix}middlename`].value;
        result.birthdate = convertDate(form[`${prefix}birthdate`].value);
        result.passportForeign = form[`${prefix}passportForeign`].value;
        result.passportDate = convertDate(form[`${prefix}passportDate`].value);

        if (form[`${prefix}passportForeign`].value == 0) {
          const value = form[`${prefix}passportNumber`].value.replace(/\s/g, '');
          result.passportSerial = value.substring(0, 4);
          result.passportNumber = value.substring(4, 10);
        }

        if (form[`${prefix}passportForeign`].value == 1) {
          result.passportSerial = form[`${prefix}passportSerialForeign0`].value;
          result.passportNumber = form[`${prefix}passportNumberForeign0`].value;
        }

        if (form[`${prefix}passportForeign`].value == 1) {
          result.passportSerial = form[`${prefix}passportSerialForeign0`].value;
          result.passportNumber = form[`${prefix}passportNumberForeign0`].value;
        }
        break;
      }

      case '1': {
        result.name = form[`${prefix}name`].value;
        result.form = form[`${prefix}form`].value;
        result.inn = form[`${prefix}inn`].value;
        result.kpp = form[`${prefix}kpp`].value;
        result.docSerial = form[`${prefix}docSerial`].value;
        result.docNumber = form[`${prefix}docNumber`].value;
        result.docDate = convertDate(form[`${prefix}docDate`].value);
        break;
      }

      case '2': {
        result.lastname = form[`${prefix}lastname`].value;
        result.firstname = form[`${prefix}firstname`].value;
        result.middlename = form[`${prefix}middlename`].value;
        result.inn = form[`${prefix}inn`].value;
        result.ogrnip = form[`${prefix}ogrnip`].value;
        result.birthdate = convertDate(form[`${prefix}birthdate`].value);
        result.docSerial = form[`${prefix}docSerial`].value;
        result.docNumber = form[`${prefix}docNumber`].value;
        result.docDate = convertDate(form[`${prefix}docDate`].value);

        const value = form[`${prefix}passportNumber`].value.replace(/\s/g, '');
        result.passportSerial = value.substring(0, 4);
        result.passportNumber = value.substring(4, 10);
        result.passportDate = convertDate(form[`${prefix}passportDate`].value);

        break;
      }
    }

    if (form[`${prefix}address_dadata`].value) {
      result.dadata = {
        ...form[`${prefix}address_dadata`].value,
        city: form[`${prefix}address_dadata_city`].value || '',
      } as Dadata;
      result.city = form[`${prefix}address_dadata_city`].value || '';
    }

    return result;
  };
