import React, { FC } from 'react';
import cn from 'classnames';
import { CompanyShortViewProps, CompanyShortViewType } from './types';
import { previewTypeToLogoType } from './helpers';
import { CompanyLogo } from '../../../../../../partners/src/components/CompanyLogo';
import s from './createCompanyShortView.module.scss';

export type CompanyShortViewStyle = Record<CompanyShortViewType, string> & {
  root?: string;
  logo?: string;
  title?: string;
};

export const createCompanyShortView = <T extends CompanyShortViewProps>(style?: CompanyShortViewStyle): FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    logo: cn(s.logo, style?.logo),
    title: cn(s.title, style?.title),
    small: cn(s.small, style?.small),
    colorful: cn(s.colorful, style?.colorful),
    [`black-white`]: cn(s[`black-white`], style?.[`black-white`]),
  };

  return ({ className, type, company }) => {
    if (!company) return null;
    return (
      <div className={cn(cls.root, className)}>
        <CompanyLogo
          className={cn(cls.logo, s[type], style?.[type])}
          type={previewTypeToLogoType(type)}
          companyId={company.id}
        />
        <div className={cn(cls.title, cls[type])}>{company.title}</div>
      </div>
    );
  };
};
