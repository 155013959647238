import { observer } from 'mobx-react-lite';
import { useStore } from '@core';
import cn from 'classnames';
import React from 'react';
import s from './LicencePlateSwitch.module.scss';
import { SquareSwitch } from '../../../features/pages/MainPage/components/SquareSwitch/SquareSwitch';

export type LicencePlateSwitchProps = {
  className?: string;
};

export const LicencePlateSwitch = observer(({ className }: LicencePlateSwitchProps) => {
  const store = useStore();
  const vehicleType = String(store.form.fields.type.value);

  const isMoto = vehicleType == '7';

  const setInputType = (typeNumber: number) => {
    const prevTypeNumber = store.form.getValueField('type');
    if (prevTypeNumber.value === typeNumber) return;
    store.form.setValueField('type', typeNumber);
    store.form.setValueField('licensePlate', '');
    store.form.setErrorField('licensePlate', '');
  };

  const onCheck = (moto: boolean) => {
    if (moto) {
      setInputType(7);
    } else {
      setInputType(1);
    }
  };

  return (
    <SquareSwitch
      className={cn(s.root, className)}
      checked={isMoto}
      checkedNode="Мото"
      uncheckedNode="Авто"
      onCheck={onCheck}
    />
  );
});
