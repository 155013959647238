import React from 'react';

import { PERIOD_OF_USE } from '@core';

import { FormWrapper } from '../../../../common/components/FormWrapper/FormWrapper';
import { FormRow } from '../../../../common/components/FormRow';
import { SelectDefault } from '../../../../common/components/Input/SelectDefault';
import { InputDate } from '../../../../common/components/Input/InputDate';

import s from './index.module.scss';

export const PolicyForm: React.FC = () => (
  <div className={s.formWrapper}>
    <FormWrapper title="Полис">
      <FormRow columns>
        <SelectDefault
          view="fullWidth"
          placeholder="Период использования"
          name="usePeriod"
          options={PERIOD_OF_USE.map((item) => {
            if (item.value == '6') {
              return { value: item.value, title: '6  месяцев' };
            }
            return { value: item.value, title: item.title };
          })}
        />
        <InputDate
          view="fullWidth"
          placeholder="Дата начала действия"
          name="date"
          min={new Date(new Date().setDate(new Date().getDate() + 1))}
          max={new Date(new Date().setMonth(new Date().getMonth() + 2))}
        />
      </FormRow>
    </FormWrapper>
  </div>
);
