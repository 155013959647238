import {
  createCompanyShortView,
  CompanyShortViewProps,
  CompanyShortViewType,
  CompanyShortViewStyle,
} from '@core/common/components/_factories/createCompanyShortView';
import s from './CompanyShortView.module.scss';

export type { CompanyShortViewProps, CompanyShortViewType };

export const CompanyShortView = createCompanyShortView(s as CompanyShortViewStyle);

CompanyShortView.displayName = 'CompanyShortView';
