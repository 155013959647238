import { format } from 'date-fns';
import { months } from '@core/constants/shared';
import { OffersOrderError } from '@core/typings/order.typings';

export const getDateString = (parsedDate: Date | null) => {
  if (!parsedDate || Number.isNaN(parsedDate.getTime())) return '';

  return `${format(parsedDate, 'dd')} ${months[+format(parsedDate, 'M') - 1]} ${format(parsedDate, 'Y')}`;
};

export const getErrorText = (title: string, error?: OffersOrderError) => {
  if (error?.error && error?.code === 102 && error?.error.indexOf('начала действия полиса') !== -1) return error?.error;

  return `Предложение от компании «${title}» не получено.`;
};
