import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ValidatorProvider } from '@core/providers/ValidatorProvider/ValidatorProvider';
import { StoreProvider } from '../../../providers/StoreProvider';

import { RootCaptcha } from '../RootCaptcha/RootCaptcha';

export const OSAGOWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ValidatorProvider>
    <StoreProvider>
      <BrowserRouter>{children}</BrowserRouter>
      <RootCaptcha />
    </StoreProvider>
  </ValidatorProvider>
);
