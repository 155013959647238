import React, { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { Popover } from './Popover';
import s from './KIDSPopover.module.scss';

export interface KID {
  title: string;
  href: string;
}

interface KIDSPopoverProps {
  className?: string;
  title: string;
  entities: KID[];
}

export const KIDSPopover: FC<KIDSPopoverProps> = memo(({ entities, title, className }) => {
  const [opened, setOpened] = React.useState<boolean>(false);
  const toggle = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpened((v) => !v);
  }, []);

  return (
    <div className={cn(s.root, className)}>
      <p className={s.description}>
        Переходя к оплате, я принимаю условия передачи информации, условия страхования и{' '}
        <a className={s.link} href="#" onClick={toggle}>
          ознакомлен с КИД
        </a>
      </p>

      {opened && (
        <Popover className={s.popover} onClose={() => setOpened(false)}>
          <p>{title}</p>
          <ul>
            {entities.map(({ title: _title, href }) => (
              <li key={href}>
                <a href={href} target="_blank" rel="noreferrer">
                  {_title}
                </a>
              </li>
            ))}
          </ul>
        </Popover>
      )}
    </div>
  );
});

KIDSPopover.displayName = 'KIDHelper';
