import { TStorage } from '@core/services/storage';
import { getISODateFromExpire } from '@core/services/storage/helpers';

const PREFIX_STORAGE = `finuslugi.osago.partner.${process.env.UI}`;
const TEXT_ERROR = 'Error in all SessionStorage. Please replace browser';

export class SessionStorage extends TStorage {
  enabled: boolean;

  expire: number;

  constructor(params?: { expire?: number }) {
    super();
    const { expire } = params || {};

    this.expire = expire || 0;

    console.log('INIT SessionStorage');
    this.enabled = false;

    try {
      if (typeof sessionStorage !== undefined) {
        this.enabled = true;
      }
    } catch (e) {
      this.enabled = false;
    }

    if (!this.enabled) {
      console.error(TEXT_ERROR);
    }
  }

  async getItem<T>(title: string): Promise<T | null> {
    const newTitle = `${PREFIX_STORAGE}.${title}`;

    if (!this.enabled) return null;

    let localStoreTitle: string | null;
    try {
      localStoreTitle = sessionStorage.getItem(newTitle);
      const res = localStoreTitle ? JSON.parse(localStoreTitle) : null;
      if (!res || typeof res !== 'object') {
        await this.removeItem(title);
        return null;
      }

      const { value, expire } = res as { value: T; expire: string };
      if (Date.now() > new Date(expire).getTime()) {
        await this.removeItem(title);
        return null;
      }
      return value;
    } catch (e) {
      return null;
    }
  }

  async setItem(title: string, value: unknown): Promise<boolean> {
    const newTitle = `${PREFIX_STORAGE}.${title}`;

    if (!this.enabled) return false;

    try {
      sessionStorage.setItem(newTitle, JSON.stringify({ value, expire: getISODateFromExpire(this.expire) }));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async removeItem(title: string): Promise<boolean> {
    const newTitle = `${PREFIX_STORAGE}.${title}`;

    if (this.enabled) return false;

    try {
      sessionStorage.removeItem(newTitle);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async clear(): Promise<boolean> {
    if (this.enabled) return false;

    try {
      sessionStorage.clear();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
