import React from 'react';
import { ISelectBase, ISelectOption } from '@core/common/components/_factories/select.types';
import { InputBaseProps, ParamsToOutside } from '@core/common/components/_factories/createInputBase';
import { OptionsProps } from '@core/common/components/_factories/createOptions';

export type SelectProps = ISelectBase;
export type SelectStyle = {
  activeWrapper: string;
};

export const createSelect =
  (
    {
      InputBase,
      Options,
      SelectIcon,
    }: {
      InputBase: React.ComponentType<InputBaseProps>;
      Options: React.ComponentType<OptionsProps>;
      SelectIcon: React.ComponentType<{ view: boolean; open: boolean }>;
    },
    style?: SelectStyle
  ): React.FC<SelectProps> =>
  ({ onChange, flagAuto, onSelect, onType, options, onClear, ...props }) => {
    if (!options) return null;

    const handlerChangeInput = (
      value: string,
      event?: React.ChangeEvent<HTMLInputElement>,
      params?: ParamsToOutside
    ) => {
      const { setActive } = params || {};
      setActive?.(true);
      onChange?.(value, event);
      onType?.(value, event);
    };

    const clearAutoValue = () => {
      onClear?.();
      onChange?.('');
    };

    return (
      <InputBase
        {...(props as InputBaseProps)}
        wrapperClassName={({ active }) => (active ? style?.activeWrapper || '' : '')}
        clearAutoValue={clearAutoValue}
        onChange={handlerChangeInput}
        postfix={({ active }) => <SelectIcon view={!flagAuto} open={active} />}
        bottom={({ active, setActive }) => {
          const handlerSelect = (option: ISelectOption) => {
            setActive(false);
            onChange?.(option.value);
            onSelect?.(option);
          };
          return (
            <Options
              onMouseDown={(e) => e.preventDefault()}
              options={options}
              open={active}
              handlerSelect={handlerSelect}
            />
          );
        }}
      />
    );
  };
