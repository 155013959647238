import { ApiSearchVehicleListByParamsProps } from '@core/typings/vehicle.typings';
import { apiSearchVehicleListByParams } from '@core/services/api/vehicle';
import { logError } from '@utils/logger';
import { inputs } from '@core/constants/texts';
import { PartnerCompany } from '@core/features/company/company.types';
import { ISelectOption } from '@core/common/components/_factories/input.types';
import { CompaniesModelsFieldValue, CompanyModelFieldType, CompanyModelFieldValue } from '@core/typings/fields.typings';
import { inverseKeyboardString, inverseLangString } from '@utils/inverseString';
import { ICompaniesModelsFiledStore } from '@core/store/formStore/companiesModelsFieldStore/companiesModelsFieldStore.typings';
import { HttpProperties } from '@core/typings/http.typings';

export const getOptionsByList = (list: string[]): ISelectOption[] =>
  list.map((value) => ({
    value,
    title: value,
  }));

export const getPartnerOptionsBySearch = async (
  search: string,
  company: PartnerCompany,
  { type, brand, options }: { type: CompanyModelFieldType; brand: string; options?: Omit<HttpProperties, 'url'> }
): Promise<ISelectOption[]> => {
  const props: ApiSearchVehicleListByParamsProps = {
    sk: company.id,
    search,
    q: type,
  };

  if (type === 'model') {
    if (!brand) return [{ value: '-1', title: inputs.model.empty_brand }];

    props.brand = brand;
  }

  const { results, errors } = await apiSearchVehicleListByParams(props, options);
  if (errors?.length) {
    errors.forEach((error) => error.code !== 404 && logError(error));
    return [];
  }

  if (!results?.length) return [];

  return getOptionsByList(results as string[]);
};

// Здесь корректируем зависимые друг от друга значения. Например, если изменилась марка - сбрасываем модель
export const getCorrectValue = (
  prevValue: CompaniesModelsFieldValue,
  value: CompaniesModelsFieldValue
): CompaniesModelsFieldValue => {
  const result = { ...(value || {}) };
  Object.keys(value).forEach((companyId) => {
    const item = value[companyId];
    const resultItem = result[companyId];
    const prevItem = prevValue[companyId];
    if (item.brand !== prevItem.brand) {
      resultItem.model = '';
    }
    if (item.brand !== prevItem.brand || item.model !== prevItem.model) {
      resultItem.exclude = false;
    }
  });
  return result;
};

export const fetchOptions = async (
  search: string,
  company: PartnerCompany,
  options: { type: CompanyModelFieldType; brand: string; options?: Omit<HttpProperties, 'url'> }
) => {
  // Запрос как есть
  const forwardOptions = await getPartnerOptionsBySearch(search, company, options);

  if (forwardOptions?.length) return forwardOptions;

  // Запрос с инверсией раскладки
  const inverseKeyboardOptions = await getPartnerOptionsBySearch(inverseKeyboardString(search), company, options);

  if (inverseKeyboardOptions?.length) return inverseKeyboardOptions;

  // кириллица в латиницу и наоборот
  const inverseLandOptions = await getPartnerOptionsBySearch(inverseLangString(search), company, options);

  if (inverseLandOptions?.length) return inverseLandOptions;

  return [];
};

type SearchItem = { index: number; value: string };

export const getOptionsByLocalSearch = (search: string, localOptions: string[]) => {
  const _search = search.toLowerCase();
  const forward: SearchItem[] = [];
  const keyboardInverse: SearchItem[] = [];
  const landInverse: SearchItem[] = [];

  (localOptions || []).forEach((item) => {
    const text = item.toLowerCase();

    const indexForward = text.indexOf(_search);
    if (indexForward > -1) {
      forward.push({ index: indexForward, value: item });
      return;
    }

    const indexKeyboardInverse = text.indexOf(inverseKeyboardString(_search));
    if (indexKeyboardInverse > -1) {
      keyboardInverse.push({ index: indexKeyboardInverse, value: item });
      return;
    }

    const indexLandInverse = text.indexOf(inverseLangString(_search));
    if (indexLandInverse > -1) {
      landInverse.push({ index: indexLandInverse, value: item });
    }
  });

  return getOptionsByList([
    ...forward.sort((a, b) => a.index - b.index).map((i) => i.value),
    ...keyboardInverse.sort((a, b) => a.index - b.index).map((i) => i.value),
    ...landInverse.sort((a, b) => a.index - b.index).map((i) => i.value),
  ]);
};

export const getOptions = async (
  search: string,
  company: PartnerCompany,
  options: { type: CompanyModelFieldType; brand: string; localOptions: string[]; options?: Omit<HttpProperties, 'url'> }
) => {
  // Ищем локально, если есть опции и если в локальных опциях не найдено совпадений - значит и на беке нет
  if (options.localOptions?.length) {
    const localOptions = getOptionsByLocalSearch(search, options.localOptions);

    if (localOptions?.length) return localOptions;

    return [];
  }

  // Запрашиваем
  const removeOptions = await fetchOptions(search, company, options);

  if (removeOptions?.length) return removeOptions;

  return [];
};

export const prepareCompaniesModelsToValidation = (
  raw: ICompaniesModelsFiledStore
): (CompanyModelFieldValue & { sk: string })[] => {
  const value = raw.value.values;
  const result: (CompanyModelFieldValue & { sk: string })[] = [];
  Object.keys(value || {}).forEach((key) => {
    result.push({
      sk: key,
      brand: value[key].brand,
      model: value[key].model,
      exclude: value[key].exclude,
    });
  });

  return result;
};
