import React, { useEffect, useCallback } from 'react';
import { CAPTCHA_KEY } from '@core';
import { useStore } from '../../../providers/StoreProvider';

export const RootCaptcha: React.FC = () => {
  const store = useStore();

  const initCaptcha = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win = window as typeof window & { grecaptcha: any };
    win.grecaptcha.ready(() => {
      store.ui.setCaptchaInit(true);
    });
  }, [store.ui]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_KEY}`;
    script.addEventListener('load', initCaptcha);
    document.body.appendChild(script);
  }, [initCaptcha]);

  return <div className="g-recaptcha" data-sitekey={CAPTCHA_KEY} data-size="invisible" />;
};
