import {
  TOKEN_KEY,
  TOKEN_KEY_TIME,
  LOCAL_STORAGE_OFFERS,
  LOCAL_STORAGE_ACTIVE_OFFERS,
  ORDER_ID,
  ORDER_KEY,
} from '../../constants/keys';
import { LocalStorageOffers } from './types';
import { ActiveOfferOrderData } from '../../typings/order.typings';
import { storage } from '@core/services/storage/index';

export const getTokenStorage = async (): Promise<string | null> => {
  try {
    return await storage.getItem<string>(TOKEN_KEY);
  } catch (e) {
    return null;
  }
};

export const getOrderIdStorage = async (): Promise<string | null> => {
  try {
    const val = await storage.getItem<string>(ORDER_ID);
    if (val == 'undefined') {
      await storage.removeItem(ORDER_ID);
      return null;
    }

    return val || null;
  } catch {
    return null;
  }
};

export const getOffersStorage = async (): Promise<LocalStorageOffers | null> => {
  try {
    return await storage.getItem<LocalStorageOffers | null>(LOCAL_STORAGE_OFFERS);
  } catch (e) {
    return null;
  }
};

export const getActiveOffersStorage = async (): Promise<{ [key: string]: ActiveOfferOrderData } | null> => {
  try {
    return await storage.getItem<{ [key: string]: ActiveOfferOrderData } | null>(LOCAL_STORAGE_ACTIVE_OFFERS);
  } catch (e) {
    return null;
  }
};

export const getOrderKeyStorage = async (): Promise<string | null> => {
  try {
    return await storage.getItem<string | null>(ORDER_KEY);
  } catch (e) {
    return null;
  }
};

export const setOffersStorage = async (value: unknown): Promise<boolean> => {
  try {
    return await storage.setItem(LOCAL_STORAGE_OFFERS, { offersData: value });
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setActiveOffersStorage = async (value: unknown): Promise<boolean> => {
  try {
    return await storage.setItem(LOCAL_STORAGE_ACTIVE_OFFERS, value);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setOrderIdStorage = async (value: unknown): Promise<boolean> => {
  try {
    return storage.setItem(ORDER_ID, value);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setOrderKeyStorage = async (value: unknown): Promise<boolean> => {
  try {
    return storage.setItem(ORDER_KEY, value);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setTokenStorage = async (value: unknown, valueTime: unknown): Promise<boolean> => {
  try {
    storage.setItem(TOKEN_KEY, value);
    return storage.setItem(TOKEN_KEY_TIME, valueTime);
  } catch (error) {
    console.log(error);
    return false;
  }
};

/** REMOVE */
export const removeLoadingActiveOffersStorage = async (): Promise<boolean> => {
  try {
    const active = await getActiveOffersStorage();
    if (!active) {
      return true;
    }

    let newActive = {};

    for (const key in active) {
      if (!active[key].loading) {
        newActive = Object.assign(newActive, { [key]: { ...active[key] } });
      }
    }

    await storage.setItem(LOCAL_STORAGE_ACTIVE_OFFERS, newActive);
  } catch (e) {
    console.error(e, 'Error in removeLoadingActiveOffersLocalStorage');
  }

  return true;
};

export const clearOffersStorage = async (): Promise<boolean> => {
  try {
    return await storage.removeItem(LOCAL_STORAGE_OFFERS);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const removeActiveOffersStorage = async (): Promise<boolean> => {
  try {
    return await storage.removeItem(LOCAL_STORAGE_ACTIVE_OFFERS);
  } catch (e) {
    console.error(e, `${LOCAL_STORAGE_ACTIVE_OFFERS} cannot be removed`);
    return false;
  }
};

export const removeOffersStorage = async (): Promise<boolean> => {
  try {
    return await storage.removeItem(LOCAL_STORAGE_OFFERS);
  } catch (e) {
    console.error(e, ' Error in removeOffersLocalStorage');
    return false;
  }
};
