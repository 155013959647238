import React, { useRef } from 'react';
import { SelectProps } from '@core/common/components/_factories/createSelect';

export type SearchingSelectProps = SelectProps & {
  search: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  searchTimeout?: number;
};

export const createSearchingSelect =
  <T extends SearchingSelectProps>(Select: React.ComponentType<SelectProps>): React.FC<T> =>
  ({ search, searchTimeout = 700, onType, ...props }) => {
    const timeoutId = useRef<number>();

    const handleType = (text: string, event?: React.ChangeEvent<HTMLInputElement>) => {
      onType?.(text, event);
      if (!search) return;

      clearTimeout(timeoutId.current);
      timeoutId.current = window.setTimeout(() => {
        search?.(text, event);
      }, searchTimeout);
    };

    return <Select {...props} onType={handleType} />;
  };
