import React, { useEffect } from 'react';
import { IFieldDriverKey, IFieldKey, observer, useStore } from '@core';
import { useValidator } from '@core/providers/ValidatorProvider';
import { useEvent } from '@core/hooks/useEvent';
import cn from 'classnames';
import s from './createBooleanStoredField.module.scss';

export interface IBooleanField {
  children: React.ReactNode;
  className?: string;
  name?: IFieldKey | IFieldDriverKey;
  index?: number;
  validateOnInput?: boolean;
  validationSubscribe?: boolean;
  disabled?: boolean;
}

export type BooleanFieldOriginProps = Pick<IBooleanField, 'children' | 'className' | 'disabled'> & {
  error?: React.ReactNode;
  name?: string;
  checked: boolean;
  onCheck: (checked: boolean) => void;
};

export type BooleanFieldProps = IBooleanField & {
  className?: string;
};

export type BooleanStoredFieldStyle = {
  root: string;
};

export const createBooleanStoredField = <T extends BooleanFieldProps>(
  BooleanField: React.ComponentType<BooleanFieldOriginProps>,
  style?: BooleanStoredFieldStyle
): React.FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
  };
  return observer(
    ({ className, disabled, validationSubscribe = true, validateOnInput = true, children, name, index }) => {
      const store = useStore();
      if (!store) return null;

      const { subscribe, validateByKey } = useValidator();

      useEffect(() => {
        if (validationSubscribe) return subscribe(name as string);
      }, [validationSubscribe, subscribe, name]);

      const field =
        index === undefined
          ? store.form.getValueField(name as IFieldKey)
          : store.form.getDriverValue(index, name as IFieldDriverKey);

      const onCheck = (checked: boolean) => {
        if (index === undefined) {
          store.form.setValueField(name as IFieldKey, +checked);
        } else {
          store.form.setDriverValue(index, name as IFieldDriverKey, +checked);
        }
      };

      const setError = useEvent((error: string) => {
        if (index === undefined) {
          store.form.setErrorField(name as IFieldKey, error);
        } else {
          store.form.setErrorDriverField(index, name as IFieldDriverKey, error);
        }
      });

      const checked = !!field.value;
      const { error } = field;

      useEffect(() => {
        if (validateOnInput) setError(validateByKey(checked, name as never, { type: 'hard' }) as string);
      }, [validateOnInput, checked, name, setError, validateByKey]);

      return (
        <BooleanField
          className={cn(cls.root, className)}
          checked={checked}
          onCheck={onCheck}
          error={error}
          name={name}
          disabled={disabled}
        >
          {children}
        </BooleanField>
      );
    }
  );
};
