import React from 'react';
import { PartnerLogoProps } from './CompanyLogo.types';
import { getSrc } from './helpers';
import cn from 'classnames';
import s from './CompanyLogo.module.scss';

export const CompanyLogo: React.FC<PartnerLogoProps> = ({ className, companyId, type = 'square' }) => {
  const src = getSrc(companyId, type);
  if (!companyId || !src) return null;

  return <img className={cn(s.root, className)} src={src} alt="" />;
};
