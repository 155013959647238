import React from 'react';

import s from './AutoIcon.module.scss';

import cross from '../../../../../assets/images/icons/cross.svg';

export const AutoIcon: React.FC<{ show: boolean; onClick?: () => void }> = ({ show, onClick }) => {
  if (!show) {
    return null;
  }

  return (
    <span className={s.dateIconWrapper} onClick={onClick}>
      <img src={cross} alt="x" />
    </span>
  );
};
