// import { store } from '../../services/store';
import { ERROR_TYPE_PARAMS_TEXT } from '../../constants/error';

import {
  apiGetVehicleByLicensePlate,
  apiGetBrandsModelsList,
  // apiSearchVehicleListByParams,
  // apiGetVehicleListByParams,
} from '../../services/api/vehicle';

import { reloadCaptcha } from '../../services/captcha/captcha';

import {
  GetVehicleProps,
  GetVehicleReturn,
  GetBrandsModelsListReturn,
  // SearchVehicleListByParamsProps,
  // SearchVehicleListByParamsReturn,
  // GetVehicleListByParamsProps,
  // GetVehicleListByParamsPropsReturn,
  // ApiSearchVehicleListByParamsProps,
} from '../../typings/vehicle.typings'; // ERROR_TYPE_TOKEN_TEXT,

let BRANDS_MODELS: GetBrandsModelsListReturn = {
  vehicleTypes: {},
  vehicles: [],
};

export const getBrandsModelsList = async (): Promise<GetBrandsModelsListReturn> => {
  // FIXME: Вернуть проверку на наличие токена!!!
  // if (!store.checkToken()) {
  //   throw new Error(`${ERROR_TYPE_TOKEN_TEXT}`);
  // }

  if (!BRANDS_MODELS?.vehicles?.length) {
    BRANDS_MODELS = await apiGetBrandsModelsList();
  }

  return BRANDS_MODELS;
};

export const getSavedBrandsModelsList = (): GetBrandsModelsListReturn => BRANDS_MODELS;

export const getVehicle = async (params: GetVehicleProps): Promise<GetVehicleReturn | null> => {
  if (!params.licensePlate) {
    throw new Error(ERROR_TYPE_PARAMS_TEXT);
  }

  await reloadCaptcha();
  return await apiGetVehicleByLicensePlate(params);
};

// export const searchVehicleListByParams = async (
//   props: SearchVehicleListByParamsProps,
// ): Promise<SearchVehicleListByParamsReturn> => {
//   if (!props.id && !props.brand && !props.search) {
//     throw new Error(ERROR_TYPE_PARAMS_TEXT + ' search');
//   }

//   let ob: ApiSearchVehicleListByParamsProps;
//   if (props.id) {
//     ob = {
//       q: 'models',
//       id: props.id,
//     };
//   } else if (props.brand) {
//     ob = {
//       q: 'model',
//       brand: props.brand,
//     };
//   } else {
//     ob = {
//       q: 'brand',
//     };
//   }

//   if (props.sk) {
//     ob.sk = props.sk;
//   }

//   if (props.search) {
//     ob.search = props.search;
//   }

//   return await apiSearchVehicleListByParams(ob);
// };

// export const getVehicleListByParams = async (
//   props: GetVehicleListByParamsProps,
// ): Promise<GetVehicleListByParamsPropsReturn> => {
//   let ob: ApiSearchVehicleListByParamsProps;
//   if (props.brand) {
//     ob = {
//       q: 'list_models',
//       brand: props.brand,
//     };
//   } else {
//     ob = {
//       q: 'list_brands',
//     };
//   }

//   if (props.sk) {
//     ob.sk = props.sk;
//   }

//   return await apiGetVehicleListByParams(ob);
// };
