import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActiveOfferOrderData, getActiveOffersStorage } from '@core';
import { useEvent } from '@core/hooks/useEvent';
import { useStore } from '../providers/StoreProvider';
import { errors } from '@core/constants/texts';

export type UseLinkPayOutput = ReturnType<typeof useLinkPay>;

export const useLinkPay = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);
  const store = useStore();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('company');

  const [activeCompany, setActiveCompany] = useState<ActiveOfferOrderData | null>(null);

  const getLinkPay = useEvent(async (activeOffer: ActiveOfferOrderData, selectedUpsalesKeys?: string[]) => {
    if (!companyId) return;

    setLoading(true);

    return store.calc
      .getLinkPay(
        companyId,
        activeOffer.offer.results[0].eId,
        store.form.fields.phone.value,
        activeOffer.offer.results[0].sk,
        selectedUpsalesKeys
      )
      .then((payLink) => {
        if (payLink && typeof payLink === 'object' && 'error' in payLink) {
          setError(errors.pay_link_error);
          return null;
        }
        setLink(payLink);
        return payLink;
      })
      .catch(async (e) => {
        if (e?.message !== 'code:109') {
          setError(errors.pay_link_error);
        }
      })
      .finally(() => setLoading(false));
  });

  useEffect(() => {
    (async () => {
      if (companyId) {
        const activeOfferAll = (await getActiveOffersStorage()) || {};
        setActiveCompany(activeOfferAll[companyId]);
      }
    })();
  }, [companyId]);

  const resetLinkPay = useCallback(() => {
    setError(null);
    setLoading(false);
    setLink(null);
  }, []);

  return { loading, getLinkPay, link, companyId, activeCompany, error, resetLinkPay };
};
