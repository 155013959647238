import React, { FC, useReducer } from 'react';
import cn from 'classnames';
import { useStore } from '@core';
import { InputBaseToStored } from '@core/common/components/_factories/input.types';
import { InputBaseProps } from '@core/common/components/_factories/createInputBase';
import s from './createOfferEmailField.module.scss';

export type OfferEmailFieldProps = {
  className?: string;
};

export type OfferEmailFieldStyle = {
  root?: string;
  p?: string;
  btn?: string;
  img?: string;
};

export const createOfferEmailField = <T extends OfferEmailFieldProps>(
  {
    edit,
    InputDefault,
  }: {
    edit: string;
    InputDefault: React.ComponentType<InputBaseToStored<InputBaseProps>>;
  },
  style?: OfferEmailFieldStyle
): FC<T> => {
  const cls = {
    root: cn(s.root, style?.root),
    p: cn(s.p, style?.p),
    btn: cn(s.btn, style?.btn),
    img: cn(s.img, style?.img),
  };
  return ({ className }) => {
    const [visible, toggle] = useReducer((v) => !v, false);
    const store = useStore();

    return (
      <div className={cn(cls.root, className)}>
        <p className={cls.p}>
          Страховая компания готовит отдельную страницу для оплаты полиса. Это займет не более одной минуты. Страница
          для оплаты откроется в новой вкладке.
        </p>
        <p className={cls.p}>
          После оплаты на странице страховой компании полис придет на email
          <button type="button" className={cls.btn} onClick={toggle}>
            {store?.form.getValueField('email').value}
            <img className={cls.img} src={edit} alt="edit" />
          </button>
        </p>
        <div>{visible && <InputDefault name="email" placeholder="Электронная почта" view="fullWidth" />}</div>
      </div>
    );
  };
};
