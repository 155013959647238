import { AllOffersOrderData } from 'lib/src/typings/order.typings';

export interface LocalStorageOffers {
  offersData: AllOffersOrderData;
}

export abstract class TStorage {
  abstract getItem<T>(title: string): Promise<T | null>;

  abstract setItem(title: string, value: unknown): Promise<boolean>;

  abstract removeItem(title: string): Promise<boolean>;

  abstract clear(): Promise<boolean>;
}
