export const ERROR_TYPE_JSON = 'json';

export const ERROR_TYPE_API = 'api';
export const ERROR_TYPE_API_TEXT = 'BAD params';
export const ERROR_TYPE_GET_TOKEN_TEXT = 'BAD order_key';

export const ERROR_TYPE_TOKEN = 'token';
export const ERROR_TYPE_TOKEN_TEXT = `You haven't done initialization. Call the method 'initCalculator', then repeat.`;

export const ERROR_TYPE_AUTH = 'auth';
export const ERROR_TYPE_AUTH_TEXT = 'order_key and adv_p_id not initialization';

export const ERROR_TYPE_HTTP = 'http';
export const ERROR_TYPE_HTTP_TEXT = 'HTTP status: ';

export const ERROR_TYPE_ORDER = 'order';
export const ERROR_TYPE_ORDER_CREATE = 'HTTP status: ';
export const ERROR_TYPE_ORDER_CREATE_TEXT = 'BAD params initialization';
export const ERROR_TYPE_ORDER_CREATE_BAD_DATA_TEXT = 'Bad data text';
export const ERROR_TYPE_ORDER_KBM_TEXT = 'Bad params for KBM';

export const ERROR_TYPE_CALCULATOR_DATA = 'calculator-data';
export const ERROR_TYPE_CALCULATOR_DATA_INDEX_TEXT = 'Bad index or license plate in calculator data';
export const ERROR_TYPE_CALCULATOR_DATA_VALIDATE_TEXT = 'Bad index or license plate in calculator data';

export const ERROR_TYPE_PARAMS = 'params';
export const ERROR_TYPE_PARAMS_TEXT = 'Bad params: ';

export const ERROR_TYPE_POLICY = 'policy';
export const ERROR_TYPE_POLICY_TEXT = 'Bad policy params';

export const ERROR_TYPE_CONTROLLER = 'controller';
export const ERROR_TYPE_CONTROLLER_TEXT = 'Error in controller: ';
