import { Vehicle } from '@core';
import { convertDate } from '@utils/date';
import type { PolicyOrderForm } from '@core/features/order/order.types';
import { CompanyModel } from '@core/features/vehicle/vehicle.types';
import { isBus, isTruck } from '@core/constants/calculator-const';

export const createVehicle = (form: PolicyOrderForm, companiesModels: CompanyModel[] | undefined): Vehicle => {
  const vehicle: Vehicle = {
    vehicleDoc: form.vehicleDoc.value,
    type: Number(form.type.value),
    purpose: form.purpose.value,
    brand: form.brand.value,
    model: form.model.value,
    power: form.power.value ? +form.power.value : null,
    year: form.year.value ? +form.year.value : null,
    licensePlate: form.licensePlate.value,
    docDate: convertDate(form.docDate.value),
    docType: +form.docType.value,
    companiesModels,
  };

  if (isBus(String(form.type.value))) {
    vehicle.seats = Number(form.seats.value);
  }

  if (isTruck(String(form.type.value))) {
    vehicle.weight = Number(form.weight.value);
    vehicle.maxWeight = Number(form.maxWeight.value);
  }

  if (form.vehicleDoc.value === 'vin') {
    vehicle.vin = form.vin.value;
  }

  if (form.vehicleDoc.value === 'body') {
    vehicle.bodyNum = form.bodyNum.value;
  }

  if (form.vehicleDoc.value === 'chassis') {
    vehicle.chassisNum = form.chassisNum.value;
  }

  if (form.docType.value == 0) {
    const num = form.docNumberPTS.value.replace(/\s/g, '');
    vehicle.docSerial = num.slice(0, 4);
    vehicle.docNumber = num.slice(4, 10);
  }

  if (form.docType.value == 1) {
    const num = form.docNumberSTS.value.replace(/\s/g, '');
    vehicle.docSerial = num.slice(0, 4);
    vehicle.docNumber = num.slice(4, 10);
  }

  if (form.docType.value == 2) {
    vehicle.docNumber = form.docNumberETS.value;
  }

  if (form.unmask_id.value) {
    vehicle.unmask_id = form.unmask_id.value;
  }

  return vehicle;
};
