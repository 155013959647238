import { ValidationFn, NumberOptions } from '../common/types';
import { intRule, stringRule } from './sharedRules';
import { intRegexp, MAX_YEAR, MIN_YEAR } from '../common/constants';

export const yearRule =
  (options?: NumberOptions): ValidationFn<string | number> =>
  (value, deps, params) => {
    if (params?.type === 'soft') {
      if (typeof value === 'string') {
        return stringRule({ regexp: intRegexp, isSkipped: options?.isSkipped })(value, deps, {
          ...params,
          type: 'hard',
        });
      }
      return null;
    }

    const correct = value && typeof value === 'string' ? parseInt(value, 10) : value;

    return intRule({ ...(options || {}), min: options?.min || MIN_YEAR, max: options?.max || MAX_YEAR })(
      correct as number,
      deps,
      params
    );
  };
